import * as React from 'react';
import styles from './PhysicalBookWarning.module.scss';

import { BottomSheet } from '../core/BottomSheet';
import { LegacyButton, ButtonStyles } from '../core/LegacyButton';
import { OrSeparator } from '../../components/utils/OrSeparator';

export interface PhysicalBookWarningProps {
  onClose: (book?: boolean) => void;
}

export class PhysicalBookWarning extends React.Component<PhysicalBookWarningProps> {
  public render(): JSX.Element {
    const content = `We’re unable to draw labs on weekends, after 4pm on weekdays, or for patients without insurance. Book a physical without labs or select a different time:`;
    return (
      <BottomSheet title={'Confirmation required'} showCloseButton visible onClose={this.onClose}>
        <div className={styles.content}>
          <div className={styles.header}>{content}</div>
          <LegacyButton className={styles.buttonPrimary} onClick={this.onBook} text={'Book without labs'} testId="btn_bookWithoutLabs" />
          <OrSeparator styles={{ marginTop: 0 }} />
          <LegacyButton
            style={ButtonStyles.secondary}
            className={styles.buttonSecondary}
            onClick={this.onClose}
            text={'Choose different time'}
            testId="btn_chooseDifferentTime"
          />
        </div>
      </BottomSheet>
    );
  }

  private onClose = () => {
    this.props.onClose();
  };

  private onBook = () => {
    this.props.onClose(true);
  };
}
