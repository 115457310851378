import moment from 'moment';
import 'moment-timezone';
import React from 'react';
import { AccessibleElement } from '../../components/utils/accessibility/AccessibleElement';
import { TimeSlot } from '../../types/TimeSlot';
import { css } from '../../utils/css';
import styles from './TimeSlotSelector.module.scss';

interface TimeSlotSelectorProps {
  timeSlots: TimeSlot[];
  selectedId?: string;
  onSelect: (timeSlot: TimeSlot) => void;
}

const TimeSlotSelector: React.FC<TimeSlotSelectorProps> = (props) => {
  function renderRows() {
    const rows: JSX.Element[] = [];
    const maxItemsPerRow = 2;

    for (let i = 0; i < props.timeSlots.length; i++) {
      if (i % maxItemsPerRow === 0) {
        const cells: JSX.Element[] = [];
        const jlen = Math.min(i + maxItemsPerRow, props.timeSlots.length);

        for (let j = i; j < jlen; j++) {
          const timeSlot = props.timeSlots[j];
          const timeSlotDisabled = timeSlot.id === 'disabled';
          const automationId = timeSlotDisabled ? `slot_${timeSlot.id}_available` : `slot_${timeSlot.id}`;

          cells.push(
            <AccessibleElement
              elementType="td"
              data-tid={automationId}
              aria-disabled={timeSlotDisabled}
              key={`${i}_${j}`}
              className={css(styles.cell, { [styles.selected]: timeSlot.id === props.selectedId, [styles.disabled]: timeSlot.id === 'disabled' })}
              onClick={() => !timeSlotDisabled && props.onSelect(timeSlot)}
            >
              {`${moment.unix(timeSlot.startTime).tz(timeSlot.timeZone).format('h:mm A')} - ${moment
                .unix(timeSlot.startTime + timeSlot.duration)
                .tz(timeSlot.timeZone)
                .format('h:mm A')}`}
            </AccessibleElement>
          );
        }

        rows.push(<tr key={i}>{cells}</tr>);
      }
    }

    return rows;
  }

  return (
    <table role="none" cellSpacing="10" className={styles.table}>
      <tbody>{renderRows()}</tbody>
    </table>
  );
};

export default TimeSlotSelector;
