import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Patient } from '../../appState/patient/types';
import { RootState } from '../../appState/rootState';
import { SelectableItem, SelectableItemStyles } from '../../components/utils/SelectableItem';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { FileUpload } from '../../types/Files';
import Button from '../core/Button';
import { ButtonLink } from '../core/ButtonLink';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import FileUploader from '../fileUploader/FileUploader';
import styles from './DocumentsPage.module.scss';

const DocumentsPage = () => {
  const history = useHistory();
  const { patientList } = useSelector((state: RootState) => state);
  const [fileUploads, setFileUploads] = useState<FileUpload[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<Patient>();

  const canSubmit = useMemo(() => {
    let canSubmit = fileUploads.length > 0;

    fileUploads.forEach((fileUpload) => {
      if (fileUpload.status !== 'success') {
        canSubmit = false;
      }
    });

    return canSubmit;
  }, [fileUploads]);

  function renderPatients() {
    return (
      <>
        {patientList.data &&
          Object.entries(patientList.data).map(([id, patient]) => {
            return (
              <SelectableItem
                key={id}
                role="button"
                tabIndex={0}
                style={SelectableItemStyles.patient}
                label={patient.fullName}
                avatar={patient.avatarUrl}
                initials={patient.initials}
                testId={`select_patient_${patient.patientId}`}
                selected={false}
                checkboxId={`select_patient_${patient.patientId}`}
                onSelect={() => setSelectedPatient(patient)}
              />
            );
          })}
      </>
    );
  }

  function resetUploader() {
    setSelectedPatient(undefined);
    setFileUploads([]);
  }

  return (
    <Page
      showBackButton
      isLoading={false}
      title={getString(ResourceKey.documentsTitle)}
      onBackPress={() => (selectedPatient ? resetUploader() : history.goBack())}
    >
      <PageHeader
        title={getString(ResourceKey.documentsTitle)}
        subtitle={
          selectedPatient
            ? getString(ResourceKey.documentsDescription).replace('patient', selectedPatient.firstName || 'patient')
            : 'Select the patient the documents apply to.'
        }
      />

      {!selectedPatient && renderPatients()}

      {selectedPatient && (
        <>
          <ButtonLink
            className={styles.changePatient}
            onClick={() => resetUploader()}
            text={getString(ResourceKey.documentsPatientsChange)}
            testId="btn_changePatient"
          />
          <FileUploader fileUploads={fileUploads} setFileUploads={setFileUploads} patientId={selectedPatient.id} />

          {canSubmit && (
            <div className={styles.submitWrapper}>
              <p className={styles.successMsg}>Upload successful</p>
              <Button onClick={() => resetUploader()} text={getString(ResourceKey.documentsDoneButton)} testId="btn_done" className="btn-block" />
            </div>
          )}
        </>
      )}
    </Page>
  );
};

export default DocumentsPage;
