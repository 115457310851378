import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearSuccessMessage, deletePatient } from '../../appState/patient/actions';
import { Patient } from '../../appState/patient/types';
import { RootState } from '../../appState/rootState';
import { ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { BottomSheet } from '../core/BottomSheet';
import Button from '../core/Button';
import { ButtonLink } from '../core/ButtonLink';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import { NotificationsBox } from '../utils/NotificationsBox';
import { SelectableItem, SelectableItemStyles } from '../utils/SelectableItem';
import styles from './PatientsPage.module.scss';

const PatientsPage = () => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [canSelect, setCanSelect] = useState(false);
  const { patientList: patientState } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const patients: Patient[] = useMemo(() => {
    const patientList: Patient[] = [];
    Object.keys(patientState.data || []).forEach((key) => {
      const patient = patientState.data?.[key];
      patient?.id && patientList.push(patient);
    });

    return patientList;
  }, [patientState]);

  // prettier-ignore
  useEffect(() => function clearMessageOnDismount(){dispatch(clearSuccessMessage());}, [dispatch]);

  const history = useHistory();
  const title = getString(ResourceKey.patientsPageTitle);

  const navButtons = [
    {
      action: () => history.push(ROUTES.bookReview),
      text: getString(ResourceKey.topNavButtonBook),
    },
  ];

  const renderConfirmDelete = () => {
    return (
      <BottomSheet title={getString(ResourceKey.patientsButtonRemovePatient)} visible={showDeletePrompt} onClose={closePanel} showCloseButton>
        <div style={{ margin: '20px auto', maxWidth: 280 }}>
          <p>{getString(ResourceKey.patientsButtonRemovePatientPromptText)}</p>
          <Button text={getString(ResourceKey.patientsButtonRemovePatient)} onClick={onSubmitOk} className="btn-block" testId="btn_removePatient" />
          <ButtonLink className="alignCenter" text={getString(ResourceKey.patientsButtonRemovePatientCancel)} onClick={closePanel} />
        </div>
      </BottomSheet>
    );
  };

  const onSubmitOk = () => {
    Object.keys(patientState?.data || []).forEach((key: string) => {
      if (selectedItems.includes(key)) {
        dispatch(deletePatient(key));
      }
    });

    closePanel();
  };

  const closePanel = () => {
    setShowDeletePrompt(false);
    setCanSelect(false);
    setSelectedItems([]);
  };

  const onSelectPatient = (id: string) => {
    if (canSelect) {
      setSelectedItems((prevState) => [...prevState, id]);
    } else {
      history.push(ROUTES.patientsEdit.replace(':id', id));
    }
  };

  return (
    <Page title={title} cssClass={styles.container} isLoading={patientState.isFetching} navButtons={navButtons} showBackButton hideTitleInNavbar>
      <PageHeader title={title} />

      <NotificationsBox type="success" content={patientState.successMessage} />
      <NotificationsBox type="error" content={patientState.error?.description ?? ''} />
      {patients.length > 1 && (
        <NotificationsBox
          type="info"
          content="We no longer support having multiple patients within a single account. Contact CenterWell to help move patients into their own account."
        />
      )}

      <div className={styles.container}>
        {patients.map((patient) => (
          <SelectableItem
            key={patient.patientId}
            role={canSelect ? 'checkbox' : 'button'}
            tabIndex={0}
            style={SelectableItemStyles.patient}
            label={patient.fullName}
            value={patient.mainPatientOfAccount ? 'Main patient' : ''}
            avatar={patient.avatarUrl && patient.avatarUrl.length > 0 ? patient.avatarUrl : undefined}
            initials={patient.initials}
            testId={`select_patient_${patient.patientId}`}
            selected={selectedItems.includes(patient.patientId)}
            checkboxId={`select_patient_${patient.patientId}`}
            checkboxVisible={canSelect}
            checkboxExternalLabel={canSelect}
            onSelect={() => onSelectPatient(patient.patientId)}
          />
        ))}
      </div>

      {canSelect && (
        <>
          <Button
            text={getString(ResourceKey.patientsButtonRemovePatient)}
            onClick={() => setShowDeletePrompt(true)}
            disabled={selectedItems.length === 0}
            className="btn-block"
            testId="btn_addPatient"
          />
          <ButtonLink className={styles.link} text={getString(ResourceKey.patientsButtonRemovePatientCancel)} onClick={closePanel} />
        </>
      )}

      {!canSelect && (
        <>
          {/* <Button
            text={getString(ResourceKey.patientsButtonAddPatient)}
            onClick={() => history.push(ROUTES.patientsAdd)}
            className="btn-block"
            testId="btn_addPatient"
          /> */}
          <ButtonLink
            className={styles.link}
            text={getString(ResourceKey.patientsButtonRemovePatient)}
            onClick={() => setCanSelect(true)}
            testId="btn_removePatient"
          />
        </>
      )}
      {renderConfirmDelete()}
    </Page>
  );
};

export default PatientsPage;
