import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { object } from 'yup';
import { RootState } from '../../appState/rootState';
import { registerViaSAML } from '../../appState/saml/actions';
import { IMAGES, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages/index';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { getNumDays, MONTHS } from '../../utils/date';
import { YUP_VALIDATIONS } from '../../utils/forms';
import Button from '../core/Button';
import { ButtonLink } from '../core/ButtonLink';
import Input from '../core/Input';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import Select, { SelectOption } from '../core/Select';
import { NotificationsBox } from '../utils/NotificationsBox';
import styles from './SamlRegister.module.scss';

interface RegisterFormData {
  firstName: string;
  lastName: string;
  sex: string;
  dobMonth: string;
  dobDay: string;
  dobYear: string;
  email: string;
  phone: string;
  password: string;
  memberId: string;
  memberNumber: string;
  groupId: string;
  zipcode: string;
}

export function SamlRegister() {
  const [creating, setCreating] = useState(false);
  const { saml, config } = useSelector((state: RootState) => state);

  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId;

  const headerImageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!saml.isFetching && saml.error) {
      setCreating(false);
      headerImageRef.current?.parentElement?.parentElement?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [saml]);

  const {
    watch,
    control,
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors },
  } = useForm<RegisterFormData>({
    mode: 'onTouched',
    resolver: yupResolver(
      object().shape({
        firstName: YUP_VALIDATIONS.firstName,
        lastName: YUP_VALIDATIONS.lastName,
        sex: YUP_VALIDATIONS.sex,
        dobMonth: YUP_VALIDATIONS.dobMonth,
        dobDay: YUP_VALIDATIONS.dobDay,
        dobYear: YUP_VALIDATIONS.dobYear,
        email: YUP_VALIDATIONS.email,
        phone: YUP_VALIDATIONS.phone,
        password: YUP_VALIDATIONS.passwordRegister,
        zipcode: YUP_VALIDATIONS.zipcode,
      })
    ),
    defaultValues: {
      firstName: saml.data?.firstName || '',
      lastName: saml.data?.lastName || '',
      email: saml.data?.email || '',
      sex: saml.data?.gender ? (saml.data.gender === 'M' ? '0001433013870063-d279fc27ffff816b-0001' : '0001433013870063-d279fc27ffff816b-0002') : '',
      phone: saml.data?.phone || '',
      password: '',
      memberId: saml.data?.memberId || '',
      memberNumber: saml.data?.memberNumber || '',
      groupId: saml.data?.groupId || '',
    },
  });

  useEffect(() => {
    reset({
      ...getValues(),
      memberId: saml.data?.memberId || '',
      memberNumber: saml.data?.memberNumber || '',
      groupId: saml.data?.groupId || '',
    });
  }, [saml.data, reset, getValues]);

  const memberId = watch('memberId');
  const groupId = watch('groupId');

  // Date of birth <option>'s
  const watchDobMonth = watch('dobMonth');
  const watchDobDay = watch('dobDay');
  const watchDobYear = watch('dobYear');

  const dobMonthOptions = useMemo<SelectOption[]>(() => MONTHS.map((month, index) => ({ value: index + 1, text: month.text })), []);

  const dobDayOptions = useMemo<SelectOption[]>(() => {
    const days = [];
    const numDays = getNumDays(parseInt(watchDobMonth), parseInt(watchDobYear));

    for (let i = 1; i <= numDays; i++) {
      days.push({ text: i.toString(), value: i });
    }

    return days;
  }, [watchDobMonth, watchDobYear]);

  const dobYearOptions = useMemo<SelectOption[]>(() => {
    const years = [];

    for (let i = new Date().getFullYear(); i >= 1900; i--) {
      years.push({ text: i.toString(), value: i });
    }

    return years;
  }, []);

  function onSubmit(data: RegisterFormData) {
    setCreating(true);
    dispatch(
      registerViaSAML(clientId, {
        firstName: data.firstName,
        lastName: data.lastName,
        genderId: data.sex,
        dateOfBirth: `${data.dobMonth}/${data.dobDay}/${data.dobYear}`,
        mobile: data.phone,
        password: data.password,
        phone: data.phone,
        email: data.email,
        signupZipCode: data.zipcode,
        memberId: data.memberId,
        memberNumber: data.memberNumber,
        groupId: data.groupId,
      })
    );
  }

  return (
    <Page title={getString(ResourceKey.SSORegisterTitle)}>
      <PageHeader title="Tell Us About Yourself" subtitle={getString(ResourceKey.SAMLRegisterHeader)} image={IMAGES.registerIcon} />

      {saml.error && <NotificationsBox type="error" content={saml.error.description || getString(ResourceKey.errorSignInGeneric)} />}

      <form onSubmit={handleSubmit(onSubmit)} title="Register account">
        <Input label="First name" id="firstName" error={errors.firstName?.message} required {...register('firstName')} />

        <Input label="Last name" id="lastName" error={errors.lastName?.message} required {...register('lastName')} />

        <Select
          label="Sex"
          id="sex"
          options={config.gendersForRadio.map((option) => ({ value: option.key, text: option.text }))}
          error={errors.sex?.message}
          required
          {...register('sex')}
        />

        <div className="form-group required" role="group" aria-labelledby="dobLabel">
          <div className="form-label" id="dobLabel">
            Date of birth
          </div>
          <div className="form-control-group">
            <Select
              id="dobMonth"
              label="Month"
              className={`form-control-month ${watchDobMonth ? '' : 'empty'}`}
              error={errors.dobMonth?.message}
              options={dobMonthOptions}
              required
              {...register('dobMonth')}
            />
            <Select
              id="dobDay"
              label="Day"
              className={`form-control-day ${watchDobDay ? '' : 'empty'}`}
              error={errors.dobDay?.message}
              options={dobDayOptions}
              required
              {...register('dobDay')}
            />
            <Select
              id="dobYear"
              label="Year"
              className={`form-control-year ${watchDobYear ? '' : 'empty'}`}
              error={errors.dobYear?.message}
              options={dobYearOptions}
              required
              {...register('dobYear')}
            />
          </div>
        </div>

        <Input
          label="Email"
          id="email"
          helpText={getString(ResourceKey.registerEmailVerifyText)}
          error={errors.email?.message}
          required
          {...register('email')}
        />

        <Input label="ZIP code" id="zipcode" error={errors.zipcode?.message} required {...register('zipcode')} />

        <Controller
          name="phone"
          render={({ field }) => (
            <div className={`form-group required ${errors.phone ? 'invalid' : ''}`}>
              <label className="form-label" htmlFor={field.name}>
                Phone
              </label>
              <PhoneInput
                value={field.value}
                inputProps={{
                  name: field.name,
                  id: field.name,
                  required: true,
                  'data-lpignore': true,
                  'aria-describedby': errors.phone ? `${field.name}_error` : undefined,
                  'data-tid': `inp_${field.name}`,
                }}
                onChange={(_value, _data, _event, formattedValue) => field.onChange(formattedValue)}
                onBlur={field.onBlur}
                inputClass="form-control"
                country="us"
              />
              {errors.phone && (
                <div className="form-control-error" id={`${field.name}_error`}>
                  {errors.phone?.message}
                </div>
              )}
            </div>
          )}
          control={control}
        />

        <Input label="Password" id="password" type="password" error={errors.password?.message} required {...register('password')} />

        {(memberId || groupId) && <div className={styles.info}>{getString(ResourceKey.SSORegisterInfo)}</div>}

        {memberId && <Input label="Insurance Member ID" id="memberId" disabled {...register('memberId')} />}

        {groupId && <Input label="Insurance Group ID" id="groupId" disabled {...register('groupId')} />}

        <ButtonLink
          className={styles.alreadyHaveAccount}
          onClick={() => history.push(ROUTES.partnerLink.replace(':clientId', clientId))}
          text={getString(ResourceKey.signInHaveAccount)}
          testId="btn_ssoLink"
        />

        <div className={styles.contactUs}>
          <div className={styles.privacyText}>{getString(ResourceKey.registerLegalUrlLinks)}</div>
          <span>
            <a href="https://www.humana.com/legal/privacy-policy" target="_blank" rel="noreferrer">
              {getString(ResourceKey.registerPrivacyPolicy)}
            </a>
            ,&nbsp;
            <a href="https://www.humana.com/legal" target="_blank" rel="noreferrer">
              {getString(ResourceKey.registerTermsOfService)}
            </a>
            <span className={styles.privacyText}>,</span>&nbsp;
            <a href="https://www.centerwell.com/en/privacy-practices-footer.html" target="_blank" rel="noreferrer">
              {getString(ResourceKey.registerPrivacyPractices)}
            </a>
            <span className={styles.privacyText}>, and&nbsp;</span>
            <a href="https://www.humana.com/legal/non-discrimination-disclosure" target="_blank" rel="noreferrer">
              {getString(ResourceKey.registerNondiscriminationNotice)}
            </a>
          </span>
        </div>

        <Button text={getString(ResourceKey.SSORegisterBottomButton)} testId="btn_linkHeal" loading={creating} className="btn-block" />
      </form>
    </Page>
  );
}
