import { yupResolver } from '@hookform/resolvers/yup';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { object } from 'yup';
import { clearError, setLocalUsername } from '../../appState/auth/actions';
import { RootState } from '../../appState/rootState';
import { setBlueshieldSsoUser } from '../../appState/userPreferences/actions';
import { oktaAuth, oktaConfig } from '../../services/auth';
import { IMAGES, ROUTES } from '../../services/constants';
import { createMarkup, getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { YUP_VALIDATIONS } from '../../utils/forms';
import Button from '../core/Button';
import Input from '../core/Input';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import { NotificationsBox } from '../utils/NotificationsBox';
import { OrSeparator } from '../utils/OrSeparator';
import styles from './LoginPage.module.scss';
import { HealAPI } from '../../services/api';

declare const healConfig: any;

interface LoginFormData {
  email: string;
  password: string;
}

const LoginPage = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<string>();

  const { userPreferences } = useSelector((state: RootState) => state);
  const history = useHistory();
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated ?? false;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginFormData>({
    mode: 'onTouched',
    resolver: yupResolver(
      object().shape({
        email: YUP_VALIDATIONS.email,
        password: YUP_VALIDATIONS.password,
      })
    ),
  });

  useEffect(() => {
    dispatch(clearError());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      history.replace({ pathname: ROUTES.bookPatient, search: history.location.search });
    }
  }, [history, isAuthenticated]);

  useEffect(() => {
    if (userPreferences.blueshieldSSOUser) {
      setTimeout(() => dispatch(setBlueshieldSsoUser(false)), 1000);
    }
  }, [userPreferences, dispatch]);

  function onSubmit(data: LoginFormData) {
    if (!isFetching) {
      setIsFetching(true);
      healConfig.env === 'local' && dispatch(setLocalUsername(data.email));

      oktaAuth
        .signInWithCredentials({ username: data.email, password: data.password })
        .then((transaction) => {
          // This may redirect the user away before Branch has finished fetching campaign data. Future refactor, pass URL query params to the redirect URL.
          if (transaction.status === 'SUCCESS') {
            oktaAuth.token.getWithRedirect({ ...oktaConfig, sessionToken: transaction.sessionToken });
            setError(undefined);
          } else if (transaction.status === 'PASSWORD_EXPIRED') {
            const oktaId = transaction.user?.id;
            if (oktaId !== undefined) {
              HealAPI.forgotPassword(data.email)
                .then((response) => {
                  if (!response.ok) {
                    setError(response.data?.description);
                  }
                })
                .finally(() => {
                  if (error === undefined) {
                    setError('Your password has expired. Please check your email in order to reset and log in.');
                  }
                  setIsFetching(false);
                });
            } else {
              setError("The user's password is expired and the ID could not be retrieved");
            }
          } else {
            setError('We cannot handle the ' + transaction.status + ' status'); // Hit this...
          }
        })
        .catch((err) => {
          if (err.errorCode === 'E0000004') {
            // Authentication failed.
            setError(getString(ResourceKey.loginAuthenticationFailed));
            setIsFetching(false);
          }
        });
    }
  }

  function renderLoginForm() {
    const navButtons = [
      {
        action: () => {
          dispatch(clearError());
          history.push(ROUTES.register);
        },
        text: getString(ResourceKey.registerButtonCreateAccount),
      },
    ];

    let title = getString(ResourceKey.signInTitle);

    return (
      <Page cssClass={styles.container} title={title} loader={isFetching} navButtons={navButtons}>
        <PageHeader title={title} image={IMAGES.loginTitle} />

        {error && <NotificationsBox type="error" content={error} />}

        <form onSubmit={handleSubmit(onSubmit)} title="Log in">
          <Input label="Email address" id="email" type="email" error={errors.email?.message} lpIgnore={false} required {...register('email')} />
          <Input label="Password" id="password" type="password" error={errors.password?.message} lpIgnore={false} required {...register('password')} />
          <Button text="Log in" disabled={isFetching} loading={isFetching} testId="btn_pageBottom" className="btn-block" />
        </form>

        <div className={styles.forgot}>
          <Link to={ROUTES.forgotPassword}>{getString(ResourceKey.signInForgotPassword)}</Link>
        </div>

        <OrSeparator />

        <div className={styles.noAccountContainer}>
          <div className={styles.text}>{getString(ResourceKey.signInNewToCenterWell)}</div>
          <Link
            to={{
              pathname: ROUTES.register,
              search: history.location?.search,
              state: { from: ROUTES.register },
            }}
          >
            {getString(ResourceKey.signInRegister)}
          </Link>
        </div>

        <div className={styles.contactUsContainer}>
          <span dangerouslySetInnerHTML={createMarkup(getString(ResourceKey.signInSupportLink))} />
        </div>
      </Page>
    );
  }

  return renderLoginForm();
};

export default LoginPage;
