import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { OrSeparator } from '../../components/utils/OrSeparator';
import { IMAGES } from '../../services/constants';
import { createMarkup, getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { LegacyButton } from '../core/LegacyButton';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import styles from './BlueShieldLogin.module.scss';

declare const healConfig: any;

class BlueShieldLogin extends React.Component<RouteComponentProps> {
  public handleClick(url: string) {
    window.location.href = url;
  }

  public render(): JSX.Element {
    const BlueShield = () => {
      return (
        <div className={styles.loginContent}>
          <div className={styles.image}>
            <img alt={getString(ResourceKey.blueShieldLogoAlt)} src={IMAGES.blueShieldLogo} />
          </div>
          <p className={styles.label}>{getString(ResourceKey.blueShieldLabel)}</p>
          <LegacyButton
            testId="btn_blueShieldButton"
            text={getString(ResourceKey.blueShieldLoginButton)}
            onClick={() => this.handleClick(healConfig.blueshieldSsoLink)}
            className={styles.loginButton}
          />
        </div>
      );
    };

    const EngagementPoint = () => {
      return (
        <div className={styles.loginContent}>
          <div className={styles.image}>
            <img alt={getString(ResourceKey.engagementPointLogoAlt)} src={IMAGES.engagementPointLogo} />
          </div>
          <div className={styles.label} dangerouslySetInnerHTML={createMarkup(getString(ResourceKey.engagementPointLabel))} />
          <LegacyButton
            testId="btn_engagementPointButton"
            text={getString(ResourceKey.engagementPointLoginButton)}
            onClick={() => this.handleClick(healConfig.engagementPointSsoUrl)}
            className={styles.loginButton}
          />
        </div>
      );
    };

    const Bar = () => {
      return <div className={styles.bar} />;
    };

    return (
      <Page title={getString(ResourceKey.signInTitle)}>
        <PageHeader title={getString(ResourceKey.signInTitle)} />
        <Bar />
        <BlueShield />
        <OrSeparator />
        <EngagementPoint />
      </Page>
    );
  }
}

export default BlueShieldLogin;
