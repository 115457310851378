import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { resetCheckout } from '../../appState/checkout/actions';
import { resetFluShots, setVisit } from '../../appState/fluShots/actions';
import { PatientSecondaryContact } from '../../appState/patient/types';
import { RootState } from '../../appState/rootState';
import { getVisit } from '../../appState/thankYou/actions';
import { setSecondaryContactSkipped } from '../../appState/userPreferences/actions';
import { FEATURES, IMAGES, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { daysFromToday } from '../../utils/date';
import { isFluShotService, isHouseCallService } from '../../utils/heal';
import { BottomSheet } from '../core/BottomSheet';
import Button from '../core/Button';
import { ButtonLink } from '../core/ButtonLink';
import { ButtonStyles, LegacyButton } from '../core/LegacyButton';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import FluShots from '../fluShots/FluShots';
import SecondaryContactCard from '../secondaryContact/SecondaryContactCard';
import { SecondaryContactForm } from '../secondaryContact/SecondaryContactForm';
import styles from './ThankYouPage.module.scss';

export const ThankYouPage = () => {
  const [showEditSecondaryContact, setShowEditSecondaryContact] = useState(false);
  const [showSecondaryContact, setShowSecondaryContact] = useState<boolean>();
  const [showFluShots, setShowFluShots] = useState<boolean>();
  const [secondaryContact, setSecondaryContact] = useState<PatientSecondaryContact>();
  const { code } = useParams<{ code: string }>();

  const { userAccountInfo: account, userPreferences, thankYou, config, fluShots } = useSelector((state: RootState) => state);
  const visit = thankYou.data;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getVisit(code));
    return () => {
      dispatch(resetCheckout());
    };
  }, [code, dispatch]);

  useEffect(() => {
    if (visit) {
      setSecondaryContact(visit?.patient.secondaryContact);
    }
  }, [visit]);

  const skipSecondaryContact = useMemo(() => {
    if (account.data?.features.includes(FEATURES.hideSecondaryContactInWTE)) {
      return true;
    }

    if (thankYou.data && userPreferences.secondaryContactSkipped) {
      return !!userPreferences.secondaryContactSkipped[thankYou.data.patient.id];
    }

    return false;
  }, [account.data?.features, thankYou.data, userPreferences.secondaryContactSkipped]);

  useEffect(() => {
    const canShowPrompt = visit?.groupType === 'PRE_ACTIVE' && showSecondaryContact === undefined && showFluShots === undefined;
    if (!visit || !canShowPrompt) {
      return;
    }

    const isHouseCall = isHouseCallService(visit.service.serviceCode);
    const isFluShot = isFluShotService(visit.service.serviceCode);
    const isFutureVisit = daysFromToday(visit.timeSlot.startTime, visit.timeSlot.timeZone) >= 3;
    const visitHasFluShotTrait = visit?.traits.includes('flu_shot_addon');
    const shouldShowFluShot = isHouseCall && isFutureVisit && !visit.isOnSite && !isFluShot && visitHasFluShotTrait;

    if (shouldShowFluShot) {
      setShowFluShots(true);
      setShowSecondaryContact(false);
    } else if (!skipSecondaryContact) {
      setShowFluShots(false);
      setShowSecondaryContact(true);
    } else {
      setShowFluShots(false);
      setShowSecondaryContact(false);
    }
  }, [showFluShots, showSecondaryContact, skipSecondaryContact, visit]);

  useEffect(() => {
    if (thankYou.data) {
      if (!fluShots.visit) {
        dispatch(setVisit(thankYou.data));
      } else if (fluShots.visit.code !== thankYou.data.code) {
        dispatch(resetFluShots());
      }
    }
  }, [dispatch, fluShots.visit, thankYou.data]);

  const renderSecondaryContactBottomSheet = () => {
    if (!visit) {
      return null;
    }

    const { fullName } = visit.patient;

    return (
      <BottomSheet
        title={getString(ResourceKey.bookPatientSecondaryContactTitle)}
        testId="secondaryContactBottomSheet"
        visible={showSecondaryContact}
        onClose={onCloseSecondaryContact}
        showCloseButton
      >
        <div style={{ margin: '20px auto', maxWidth: '380px' }}>
          {!showEditSecondaryContact && secondaryContact && renderReadOnlySecondaryContact(fullName, secondaryContact)}
          {(showEditSecondaryContact || !secondaryContact) && renderEditSecondaryContact(secondaryContact)}
        </div>
      </BottomSheet>
    );
  };

  const renderReadOnlySecondaryContact = (patientName: string, secondaryContact: PatientSecondaryContact) => {
    const relationshipOption = config.relationshipsForDropdown.find((r) => r.key === secondaryContact.relationshipId);

    return (
      <div>
        <div className={styles.image}>
          <img src={IMAGES.secondary} alt="" />
        </div>
        <div className={styles.sheetHeading}>{getString(ResourceKey.bookPatientSecondaryContactCurrent, [patientName])}</div>
        <SecondaryContactCard
          firstName={secondaryContact.firstName}
          lastName={secondaryContact.lastName}
          phone={secondaryContact.phone}
          relationship={relationshipOption ? relationshipOption.text : ''}
          onClick={() => setShowEditSecondaryContact(true)}
        />
        <LegacyButton
          text={getString(ResourceKey.genericButtonContinue)}
          testId="btn_continue"
          className={styles.button}
          style={ButtonStyles.secondary}
          onClick={() => setShowSecondaryContact(false)}
        />
      </div>
    );
  };

  const renderEditSecondaryContact = (patientSecondaryContact?: PatientSecondaryContact) => {
    return (
      <SecondaryContactForm
        patientId={visit?.patient.id}
        contact={patientSecondaryContact}
        onSaved={(contact: PatientSecondaryContact) => {
          onSecondaryContactSaved(contact);
        }}
      />
    );
  };

  const renderFluShotsBottomSheet = () => {
    return (
      <BottomSheet
        className={styles.fluShots}
        contentClassName={styles.fluShotsContent}
        showCloseButton
        visible={showFluShots}
        onClose={() => setShowFluShots(false)}
      >
        <div className={styles.fluShotsInner}>
          <FluShots onClose={() => setShowFluShots(false)} />
        </div>
      </BottomSheet>
    );
  };

  const onSecondaryContactSaved = (secondaryContact: PatientSecondaryContact) => {
    if (thankYou.data) {
      setShowEditSecondaryContact(false);
      setSecondaryContact(secondaryContact);
    }
  };

  const onCloseSecondaryContact = () => {
    if (visit && !visit.patient.secondaryContact) {
      dispatch(setSecondaryContactSkipped(visit.patient.id, true));
    }
    setShowSecondaryContact(false);
  };

  return (
    <Page
      showCloseButton
      showMenuButton={false}
      onClosePress={() => {
        history.replace(ROUTES.visits);
      }}
      isLoading={thankYou.isFetching || account.isFetching || !visit}
      title={getString(ResourceKey.bookThankYouTitle)}
    >
      <PageHeader title={getString(ResourceKey.bookThankYouTitle)} subtitle={getString(ResourceKey.bookThankYouVisit)} />
      <div className={styles.container}>
        <img className={styles.image} alt="" src={IMAGES.whatToExpect} />
        <div className={styles.text}>What To Expect Next</div>
        <div className={styles.subtext}>How to prep for your upcoming appointment.</div>
        <ButtonLink text="Read More" onClick={() => history.push(ROUTES.visitsWhatToExpect.replace(':code', visit!.code))} testId="btn_wteReadMore" />

        <Button className={`btn-block ${styles.button}`} text="View My Visits" onClick={() => history.replace(ROUTES.visits)} testId="btn_viewMyVisits" />
        <ButtonLink text="Sign Out" onClick={() => history.push(ROUTES.logout)} testId="btn_signOut" />
      </div>
      {renderSecondaryContactBottomSheet()}
      {renderFluShotsBottomSheet()}
    </Page>
  );
};
