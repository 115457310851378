import { PhoneNumberUtil } from 'google-libphonenumber';
import { ref, string } from 'yup';
import { getString } from '../services/languages';
import { ResourceKey } from '../services/languages/ResourceKey';

export function isValidPhone(phoneNumber?: string) {
  if (!phoneNumber) {
    return false;
  }
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phoneObject = phoneUtil.parse(phoneNumber);
    return phoneUtil.isValidNumber(phoneObject);
  } catch (err) {
    return false;
  }
}

export const PASSWORD_VALIDATIONS = {
  rule1: {
    value: /^.{8,}$/,
    message: getString(ResourceKey.registerPasswordValidation1),
  },
  rule2: {
    value: /(?=.*[a-z])(?=.*[A-Z])/,
    message: getString(ResourceKey.registerPasswordValidation2),
  },
  rule3: {
    value: /(?=.*\d)/,
    message: getString(ResourceKey.registerPasswordValidation3),
  },
};

export const YUP_VALIDATIONS = {
  firstName: string().required('Please enter a first name'),
  lastName: string().required('Please enter a last name'),
  sex: string().required('Please select a sex'),
  dobMonth: string().required('Select a month'),
  dobDay: string().required('Select a day'),
  dobYear: string().required('Select a year'),
  email: string().email('Invalid email address').trim().required('Please enter an email'),
  phone: string()
    .required('Please enter a phone number')
    .test('validPhone', 'Invalid phone number', (value) => isValidPhone(value)),
  password: string().required('Please enter a password'),
  passwordRegister: string()
    .required('Please enter a password')
    .min(8, PASSWORD_VALIDATIONS.rule1.message)
    .matches(PASSWORD_VALIDATIONS.rule2.value, PASSWORD_VALIDATIONS.rule2.message)
    .matches(PASSWORD_VALIDATIONS.rule3.value, PASSWORD_VALIDATIONS.rule3.message),
  passwordConfirmation: string()
    .oneOf([ref('password')], 'Passwords must match')
    .required(),
  relationshipId: string().required('Please select a relationship'),
  primaryMemberId: string().when('primaryPayerId', {
    is: (value: string) => Boolean(value),
    then: (schema) => schema.required('Required'),
  }),
  primaryGroupId: string().when(['primaryPayerId', 'primaryRequestGroupId'], {
    is: (payerId: string, requestGroupId: boolean) => Boolean(payerId) && requestGroupId,
    then: (schema) => schema.required('Required'),
  }),
  secondaryMemberId: string().when('secondaryPayerId', {
    is: (value: string) => Boolean(value),
    then: (schema) => schema.required('Required'),
  }),
  secondaryGroupId: string().when(['secondaryPayerId', 'secondaryRequestGroupId'], {
    is: (payerId: string, requestGroupId: boolean) => Boolean(payerId) && requestGroupId,
    then: (schema) => schema.required('Required'),
  }),
  zipcode: string()
    .required('Please enter a ZIP code')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits'),
};
