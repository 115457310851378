import { useOktaAuth } from '@okta/okta-react';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorCodes, IMAGES, ROUTES } from '../../services/constants';
import { createMarkup, getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import Page from '../core/Page';
import { ZeroData } from '../core/ZeroData';
import styles from './ErrorPage.module.scss';

const ErrorPage = () => {
  const history = useHistory();
  const params = useParams<{ errorCode: string }>();
  const { authState } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated ?? false;

  const errorCode = ErrorCodes?.[params.errorCode];
  const title = errorCode?.title ?? ResourceKey.notFoundPagePrimaryText;
  const description = errorCode?.description ?? ResourceKey.samlGenericError;

  const navButtons = [
    {
      action: () => {
        history.push(isAuthenticated ? ROUTES.book : ROUTES.login);
      },
      text: getString(isAuthenticated ? ResourceKey.menuNavBookPage : ResourceKey.signInButtonSignIn),
    },
  ];

  return (
    <Page title="Error Page" navButtons={navButtons} hideTitleInNavbar>
      <div className={styles.container}>
        <ZeroData image={{ source: IMAGES.notFoundTitle }} text={getString(title)} textSecondary={getString(description)} />
        <div className={styles.contactContainer}>
          <div className={styles.contact}>
            <div dangerouslySetInnerHTML={createMarkup(getString(ResourceKey.notFoundPageMessageContactLine))} />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ErrorPage;
