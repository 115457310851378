import { OnsiteActions } from './actions';
import { OnsiteState } from './types';
import { ReduxAction } from '../types';

export const onsiteInitialState: OnsiteState = {
  isFetching: false,
};

export function onsiteReducer(state: OnsiteState = onsiteInitialState, action: ReduxAction): OnsiteState {
  switch (action.type) {
    case OnsiteActions.SET_ONSITE_PUBLIC_CODE:
      return {
        ...state,
        publicCode: action.data as string,
      };

    case OnsiteActions.FETCH_ONSITE_PENDING:
      return {
        ...state,
        isFetching: true,
        error: undefined,
        data: undefined,
      };

    case OnsiteActions.FETCH_ONSITE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        data: action.data,
      };

    case OnsiteActions.FETCH_ONSITE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.data,
        data: undefined,
      };

    case OnsiteActions.CLEAR_ONSITE:
      return { ...onsiteInitialState };

    default:
      return state;
  }
}
