import { Address } from '../../appState/address/types';

export function getAddressByType(addressMap: { [index: string]: Address }, addressType: string): Address {
  for (let key in addressMap) {
    if (addressMap.hasOwnProperty(key)) {
      const address = addressMap[key];
      if (address.addressType?.toLowerCase() === addressType?.toLowerCase()) {
        return address;
      }
    }
  }

  // This returns an empty address object with an address type.
  // It seems the intention was to pass this to pre-fill the AddressType on EditAddress.
  return {
    addressType: addressType,
  } as Address;
}

export function getAddressById(addressMap: { [index: string]: Address }, addressId: string): Address | null {
  if (!addressId) {
    return null;
  }

  const addressByLookup = addressMap[addressId];
  if (addressByLookup) {
    return addressByLookup;
  }

  for (let key in addressMap) {
    if (addressMap.hasOwnProperty(key)) {
      const address = addressMap[key];
      if (address.addressId.toLowerCase() === addressId.toLowerCase()) {
        return address;
      }
    }
  }

  return null;
}

export function getAddressByName(addressMap: { [index: string]: Address }, name: string): Address | null {
  for (let key in addressMap) {
    if (addressMap.hasOwnProperty(key)) {
      const address = addressMap[key];
      if (address.streetAddress.toLowerCase() === name.toLowerCase()) {
        return address;
      }
    }
  }

  return null;
}
