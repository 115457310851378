export function grabInitials(name: string): string {
  // empty string return
  if (!name || name.length === 0) {
    return '';
  }
  // grab first and last initial
  const nameArray = name.trim().split(' ');
  if (nameArray.length >= 2) {
    return (nameArray[0][0] + nameArray[nameArray.length - 1][0]).toUpperCase();
  }
  // only a first name just return first initial
  return name.length > 0 ? name[0].toUpperCase() : '';
}

function r4(): string {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export function createGuid(): string {
  return r4() + r4() + '-' + r4() + '-' + r4() + '-' + r4() + '-' + r4() + r4() + r4();
}

export interface PathnameAndSearch {
  pathname: string;
  search?: string;
}

export function splitPathnameAndSearch(fullPath: string): PathnameAndSearch {
  const pathParts = fullPath.split('?', 2);
  const pathnameAndSearch: PathnameAndSearch = {
    pathname: pathParts[0],
  };
  if (pathParts.length > 1) {
    pathnameAndSearch.search = pathParts[1];
  }
  return pathnameAndSearch;
}

export function getNamePossessive(name: string): string {
  return name + (name[name.length - 1] === 's' ? "'" : "'s");
}

export function upperCaseFirst(text: string): string {
  return text ? text[0].toUpperCase() + text.slice(1).toLowerCase() : text;
}

export function camelCase(text: string) {
  return text
    .replace(/^\W+/, '')
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}
