import * as React from 'react';
import styles from './OrSeparator.module.scss';

export class OrSeparator extends React.Component<{
  styles?: React.CSSProperties;
}> {
  public render(): JSX.Element {
    return (
      <div style={this.props.styles} className={styles.container}>
        <div className={styles.bar} />
        <div className={styles.orLabel}>OR</div>
        <div className={styles.bar} />
      </div>
    );
  }
}
