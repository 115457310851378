import { Dispatch } from 'redux';
import { samlLogin } from '../../appState/auth/actions';
import { RootState } from '../../appState/rootState';
import { ReduxAction } from '../../appState/types';
import { HealAPI } from '../../services/api';
import { removeHealUserCookie } from '../../utils/cookies';

export enum SAMLActions {
  GET_SAML_DATA_REQUEST = 'GET_SAML_DATA_REQUEST',
  GET_SAML_DATA_SUCCESS = 'GET_SAML_DATA_SUCCESS',
  GET_SAML_DATA_FAILURE = 'GET_SAML_DATA_FAILURE',

  REGISTER_VIA_SAML_REQUEST = 'REGISTER_VIA_SAML_REQUEST',
  REGISTER_VIA_SAML_SUCCESS = 'REGISTER_VIA_SAML_SUCCESS',
  REGISTER_VIA_SAML_FAILURE = 'REGISTER_VIA_SAML_FAILURE',

  LINK_SAML_REQUEST = 'LINK_SAML_REQUEST',
  LINK_SAML_SUCCESS = 'LINK_SAML_SUCCESS',
  LINK_SAML_FAILURE = 'LINK_SAML_FAILURE',

  CLEAR_ERROR = 'LINK_CLEAR_ERROR',

  SAML_PAGE_CHANGE = 'SAML_PAGE_CHANGE',

  SET_ACCESS_AND_REFRESH_TOKEN = 'SET_ACCESS_AND_REFRESH_TOKEN',
}

export function setAccessAndRefreshToken(accessToken: string, refreshToken: string): ReduxAction<{ accessToken: string; refreshToken: string }> {
  return {
    type: SAMLActions.SET_ACCESS_AND_REFRESH_TOKEN,
    data: {
      accessToken,
      refreshToken,
    },
  };
}

export function samlGetAccountData(clientId: string) {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const accessToken = getState().saml.accessToken!;

    dispatch({ type: SAMLActions.GET_SAML_DATA_REQUEST });
    HealAPI.samlGetAccountData(clientId, accessToken).then(
      (response) => {
        if (response.data && response.data.status === 'OK') {
          dispatch({
            type: SAMLActions.GET_SAML_DATA_SUCCESS,
            data: response.data,
          });
        } else {
          dispatch({
            type: SAMLActions.GET_SAML_DATA_FAILURE,
            data: response.data,
          });
        }
      },
      (response) => {
        dispatch({
          type: SAMLActions.GET_SAML_DATA_FAILURE,
          data: response.data,
        });
      }
    );
  };
}

export function registerViaSAML(clientId: string, data: any) {
  return (dispatch: any, getState: () => RootState) => {
    const accessToken = getState().saml.accessToken!;

    dispatch({ type: SAMLActions.REGISTER_VIA_SAML_REQUEST });
    HealAPI.samlRegisterAccount(clientId, accessToken, data).then(
      (response) => {
        if (response.data.status === 'OK') {
          dispatch({
            type: SAMLActions.REGISTER_VIA_SAML_SUCCESS,
            data: response.data,
          });
          removeHealUserCookie();
          dispatch(samlLogin(data.email, data.password));
        } else {
          dispatch({
            type: SAMLActions.REGISTER_VIA_SAML_FAILURE,
            data: response.data,
          });
        }
      },
      (response) => {
        dispatch({
          type: SAMLActions.REGISTER_VIA_SAML_FAILURE,
          data: response.data,
        });
      }
    );
  };
}

export function linkSAML(clientId: string, data: any) {
  return (dispatch: any, getState: () => RootState) => {
    const accessToken = getState().saml.accessToken!;

    dispatch({ type: SAMLActions.LINK_SAML_REQUEST });
    HealAPI.linkSAML(clientId, accessToken, data).then(
      (response) => {
        if (response.data.status === 'OK') {
          dispatch({
            type: SAMLActions.LINK_SAML_SUCCESS,
            data: response.data,
          });
          removeHealUserCookie();
          dispatch(samlLogin(data.email, data.password));
        } else {
          dispatch({
            type: SAMLActions.LINK_SAML_FAILURE,
            data: response.data,
          });
        }
      },
      (response) => {
        dispatch({
          type: SAMLActions.LINK_SAML_FAILURE,
          data: response.data,
        });
      }
    );
  };
}

export function clearError(): ReduxAction {
  return {
    type: SAMLActions.CLEAR_ERROR,
  };
}
