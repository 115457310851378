import { AnalyticsCallOptions, initializeAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';
import { updateAttributionExpiryIfNeeded } from '../appState/store';

declare const healConfig: any;

const firebaseApp = initializeApp(healConfig.firebaseConfig);

const analytics = initializeAnalytics(firebaseApp, { config: { send_page_view: false } });

const remoteConfig = getRemoteConfig(firebaseApp);
remoteConfig.settings = healConfig.firebaseRemoteConfig.settings;
remoteConfig.defaultConfig = healConfig.firebaseRemoteConfig.defaults;

fetchAndActivate(remoteConfig).then(() => updateAttributionExpiryIfNeeded());

export function getRemoteConfigValue(key: string) {
  return getValue(remoteConfig, key);
}

export function analyticsLogEvent(
  name: string,
  params: {
    [key: string]: any;
  },
  options?: AnalyticsCallOptions
) {
  return logEvent(analytics, name, params, options);
}
