import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HealAPI, ServerResponse } from '../services/api';
import { RootState } from './rootState';
import { ServicePricing } from './serviceOfferings/types';
import { Status } from './services';

export interface ServicePricingState {
  servicePricing?: ServicePricing;
  error: string | null;
  status: Status;
}

const servicePricingInitialState: ServicePricingState = {
  servicePricing: undefined,
  status: Status.idle,
  error: null,
};

export interface ServicePricingRequest {
  serviceCode: string;
  patientId: string;
  addressId?: string;
  partnerAddressId?: string;
  timeSlotId?: string;
  promoCode?: string;
}
export interface ServicePricingResponse extends ServerResponse {
  data: ServicePricing;
}

export const fetchServicePricing = createAsyncThunk<any, any, { state: RootState; rejectValue: string | undefined }>(
  'services/fetchServicePricing',
  async (data: ServicePricingRequest, thunkApi) => {
    const response = await HealAPI.getServicePricing(data);
    const servicePricing = response.data?.data;

    if (response.ok && servicePricing) {
      return servicePricing;
    }

    return thunkApi.rejectWithValue(response.data?.description);
  }
);

export const servicePricingSlice = createSlice({
  name: 'servicePricing',
  initialState: servicePricingInitialState,
  reducers: {
    resetServicePricing: (state) => {
      state.error = servicePricingInitialState.error;
      state.servicePricing = servicePricingInitialState.servicePricing;
      state.status = servicePricingInitialState.status;
    },
    setServicePricing: (state, action: PayloadAction<ServicePricing>) => {
      state.error = null;
      state.servicePricing = action.payload;
      state.status = Status.succeeded;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServicePricing.pending, (state) => {
      state.status = Status.loading;
      state.error = null;
      state.servicePricing = undefined;
    });
    builder.addCase(fetchServicePricing.fulfilled, (state, { payload }) => {
      state.servicePricing = payload;
      state.status = Status.succeeded;
      state.error = null;
    });
    builder.addCase(fetchServicePricing.rejected, (state, { payload }) => {
      state.servicePricing = undefined;
      state.status = Status.idle;
      state.error = payload || 'Something went wrong fetching services. Please try again.';
    });
  },
});

export const { resetServicePricing, setServicePricing } = servicePricingSlice.actions;
export default servicePricingSlice.reducer;
