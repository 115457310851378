import { CurrentStatus } from '../../appState/status/actions';
import { Account } from '../../appState/account/types';
import { AddressActions } from './actions';
import { Address, AddressState } from './types';

export const addressInitialState: AddressState = {
  isFetching: false,
  isModifying: false,
  error: undefined,
  data: undefined,
};

/*
 * Get current status
 */
function handleGetAddressListRequest(state: AddressState): AddressState {
  return {
    ...state,
    isFetching: true,
    isModifying: false,
    error: undefined,
    data: undefined,
  };
}

function handleGetAddressListSuccess(state: AddressState, data: Account): AddressState {
  let addressMap: { [index: string]: Address } = {};

  if (data.addresses.length > 0) {
    addressMap = data.addresses.reduce((map, address) => {
      map[address.addressId] = address;
      return map;
    }, addressMap);
  }

  return {
    ...state,
    isFetching: false,
    isModifying: false,
    error: undefined,
    data: addressMap,
  };
}

function handleGetAddressListFailure(state: AddressState, error: string): AddressState {
  return {
    ...state,
    isFetching: false,
    isModifying: false,
    error,
    data: undefined,
  };
}

/*
 * Add address
 */
function handleAddAddressRequest(state: AddressState): AddressState {
  return {
    ...state,
    isModifying: true,
    error: undefined,
  };
}

function handleAddAddressSuccess(state: AddressState, data: Address): AddressState {
  const newData = { ...state.data };

  for (const addressId in newData) {
    if (newData[addressId].addressType === data.addressType) {
      delete newData[addressId];
    }
  }

  newData[data.addressId] = data;

  return {
    ...state,
    isModifying: false,
    error: undefined,
    data: newData,
  };
}

function handleAddAddressFailure(state: AddressState, error: string): AddressState {
  return {
    ...state,
    isModifying: false,
    isFetching: false,
    error,
  };
}

export function addressReducer(state: AddressState = addressInitialState, action: any): AddressState {
  switch (action.type) {
    case CurrentStatus.GET_CURRENT_STATUS_REQUEST:
      return handleGetAddressListRequest(state);
    case CurrentStatus.GET_CURRENT_STATUS_SUCCESS:
      return handleGetAddressListSuccess(state, action.data);
    case CurrentStatus.GET_CURRENT_STATUS_FAIL:
      return handleGetAddressListFailure(state, action.data);

    case AddressActions.ADD_ADDRESS_REQUEST:
      return handleAddAddressRequest(state);
    case AddressActions.ADD_ADDRESS_SUCCESS:
      return handleAddAddressSuccess(state, action.data);
    case AddressActions.ADD_ADDRESS_FAIL:
      return handleAddAddressFailure(state, action.data);

    default:
      return state;
  }
}
