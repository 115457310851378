import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { addAddress } from '../../appState/address/actions';
import { setLocation } from '../../appState/cart/actions';
import { RootState } from '../../appState/rootState';
import { AddLocation, AddLocationProps } from '../location/AddLocation';

function mapStateToProps(state: RootState, ownProps: AddLocationProps): AddLocationProps {
  return {
    ...ownProps,
    addressState: state.addressList,
    cart: state.cart,
    accountState: state.userAccountInfo,
  };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: AddLocationProps): AddLocationProps {
  return {
    ...ownProps,
    actions: bindActionCreators(
      {
        addAddress,
        setLocation,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddLocation);
