import { ResourceKey } from './ResourceKey';

const resources: { [key: string]: string } = {
  [ResourceKey.agentAccountAlt]: 'Agent Account',
  [ResourceKey.agentAccountBackText]: 'Back to Patient Log In',
  [ResourceKey.agentAccountButtonText]: 'Go to Partner Portal →',
  [ResourceKey.agentAccountPageContent]: 'You cannot log in to the Patient Portal with an agent account. Please use the Partner Portal instead.',
  [ResourceKey.agentAccountPageTitle]: 'Invalid Patient Account',
  [ResourceKey.ANPSWarning]:
    "Patients who receive multiple vaccines will have additional charges. Our Patient Support team will call you before the visit to review the patient's vaccination history. ",
  [ResourceKey.blueShieldLabel]: 'Use your existing Blue Shield of California login to use CenterWell.',
  [ResourceKey.blueShieldLoginButton]: 'Log in with Blue Shield of California',
  [ResourceKey.blueShieldLogoAlt]: 'Blue Shield of California logo',
  [ResourceKey.bookAgreementsLabel]: '%s has reviewed and agrees to the',
  [ResourceKey.bookAgreementsTitle]: 'Agreements',
  [ResourceKey.bookFailureReceivedCopy]: "We've received your order but it will take up to 24 hours for it to be available to use.",
  [ResourceKey.bookLocationAddressPlaceholder]: 'Address',
  [ResourceKey.bookLocationAddTitle]: 'Where Do You Live?',
  [ResourceKey.bookLocationButtonSave]: 'Save',
  [ResourceKey.bookLocationButtonSetLocation]: 'Set location',
  [ResourceKey.bookLocationEntryInstructionsPlaceholder]: 'Entry and Parking instructions',
  [ResourceKey.bookLocationErrorNotCovered]: 'Sorry, this address is not within our coverage area. Check back soon.',
  [ResourceKey.bookLocationErrorNoZip]: 'Please enter a full address including the zip code',
  [ResourceKey.bookLocationHeaderTitle]: "Where is the patient's location?",
  [ResourceKey.bookLocationSelectOrEdit]: 'Select or edit an address',
  [ResourceKey.bookLocationTitle]: 'Visit location',
  [ResourceKey.bookLocationTypePlaceholder]: 'Save this address as:',
  [ResourceKey.bookLocationUnitPlaceholder]: 'Unit #',
  [ResourceKey.bookMainHeaderMessageReturning]: 'Please review your visit details prior to booking.',
  [ResourceKey.bookMainHeaderMessageWorkplace]: 'Please review your appointment details',
  [ResourceKey.bookMainLookingForWorkplaceMessage]: 'Did you mean to book a CenterWell event appointment instead of a home visit?',
  [ResourceKey.bookCompleteAppointment]: 'Complete Appointment',
  [ResourceKey.bookOnsiteThankYouHeader]: 'A CenterWell Primary Care appointment has been booked for %s!',
  [ResourceKey.bookOnsiteThankYouVisit]: 'Thank you for booking a CenterWell Primary Care Anywhere appointment!',
  [ResourceKey.bookPatientAddNewPatient]: '+ Add new patient',
  [ResourceKey.bookPatientAddPageTitle]: 'Add patient',
  [ResourceKey.bookPatientButtonAdd]: 'Add',
  [ResourceKey.bookPatientButtonContinue]: 'Continue',
  [ResourceKey.bookPatientButtonSave]: 'Save',
  [ResourceKey.bookPatientDateOfBirth]: 'Date of birth',
  [ResourceKey.bookPatientEmailAddress]: 'Email',
  [ResourceKey.bookPatientEmailError]: 'Invalid email address',
  [ResourceKey.bookPatientEmailNotVerified]: 'This email has not been verified',
  [ResourceKey.bookPatientFirstNameError]: 'Invalid first name',
  [ResourceKey.bookPatientFirstNamePlaceholder]: 'First name',
  [ResourceKey.bookPatientGender]: 'Sex',
  [ResourceKey.bookPatientHasMedicaid]: 'Patient uses Medicaid',
  [ResourceKey.bookPatientInsuranceGroup]: 'Group ID',
  [ResourceKey.bookPatientInsuranceMember]: 'Member ID',
  [ResourceKey.bookPatientInsuranceProvider]: 'Use insurance?',
  [ResourceKey.bookPatientLastNameError]: 'Invalid last name',
  [ResourceKey.bookPatientLastNamePlaceholder]: 'Last name',
  [ResourceKey.bookPatientMemberIdError]: 'Invalid member ID',
  [ResourceKey.bookPatientPageHeaderTitle]: 'Who is this visit for?',
  [ResourceKey.bookPatientPhoneError]: 'Invalid phone number',
  [ResourceKey.bookPatientPhoneNumber]: 'Phone',
  [ResourceKey.bookPatientProfileCompleteInsurance]: 'Insurance',
  [ResourceKey.bookPatientRelationship]: 'Relationship to account holder',
  [ResourceKey.bookPatientSecondaryContact]: 'Secondary contact',
  [ResourceKey.bookPatientSecondaryContactCurrent]: 'This is the secondary contact that we have on file for %s.',
  [ResourceKey.bookPatientSecondaryContactMessage]: `We recommend adding a secondary contact in case we have difficulty reaching the patient's primary phone number.`,
  [ResourceKey.bookPatientSecondaryContactRelationship]: 'Relationship to patient',
  [ResourceKey.bookPatientSecondaryContactTitle]: 'Secondary contact',
  [ResourceKey.bookPatientTitle]: 'Select patient',
  [ResourceKey.bookPatientUpdatePageTitle]: 'Edit patient',
  [ResourceKey.bookReasonButtonSubmit]: 'Save',
  [ResourceKey.bookReasonCheckboxLegalGuardian]: 'I agree that a legal guardian will be present. If not, a $159 cancellation fee will be charged.',
  [ResourceKey.bookReasonCovid19Message]: `If you have COVID-19 symptoms, such as fever, cough, shortness of breath, body aches, or sore throat, then please select the Talk to a doctor option.`,
  [ResourceKey.bookReasonEmergencyInfo]: 'CenterWell doctors are unable to treat patients in case of an emergency. If this is an emergency, please call 911.',
  [ResourceKey.bookReasonHeader]: 'What service does %s need?',
  [ResourceKey.bookReasonHighDemandTitle]: "We're experiencing high demand",
  [ResourceKey.bookReasonHighDemandMessage]: 'To book your CenterWell visit, please contact us and we’ll do our best to accommodate you.',
  [ResourceKey.bookReasonNoServices]:
    'Your address is not in our service area. We may still be able to deliver service to you. Please call <a href="tel:+18446444325">(844) 644-4325</a>.',
  [ResourceKey.bookReasonSymptomsError]: 'Please provide more details',
  [ResourceKey.bookReasonSymptomsHeader]: 'Describe any symptoms for the doctor to review before your visit',
  [ResourceKey.bookReasonSymptomsPlaceholder]: 'Symptoms',
  [ResourceKey.bookReasonSymptomsPlaceholderOptional]: 'Symptoms (optional)',
  [ResourceKey.bookReasonTitle]: 'Select Service',
  [ResourceKey.bookReviewDoctorMessage]: 'Preferred Doctor',
  [ResourceKey.bookReviewInsuranceLabel]: 'Insurance',
  [ResourceKey.bookReviewWhatMessage]: 'Service',
  [ResourceKey.bookReviewWhenMessage]: 'Preferred Time Slot',
  [ResourceKey.bookReviewWhereMessage]: 'Patient Location',
  [ResourceKey.bookReviewWhereWithMarketMessage]: 'Patient Location in %s',
  [ResourceKey.bookReviewWhoMessage]: 'Patient Name',
  [ResourceKey.bookReviewWorkplaceMessage]: 'Location of CenterWell Event',
  [ResourceKey.bookScheduleBookAsap]: `Book ASAP`,
  [ResourceKey.bookScheduleBookAsapSubtitle]: `2 hours or less`,
  [ResourceKey.bookScheduleContinue]: 'Save',
  [ResourceKey.bookScheduleHeaderMessage]: 'When is your preferred time slot?',
  [ResourceKey.bookScheduleNoTimeSlots]: 'There are no time slots available on this day',
  [ResourceKey.bookScheduleTitle]: 'Schedule',
  [ResourceKey.bookScheduleHighDemandNotice]: 'We are currently experiencing high demand. If your desired time slot is unavailable, please contact us.',
  [ResourceKey.bookSuccessConfirmationCopy]: 'A confirmation email has been sent to<br/><b>%s</b>',
  [ResourceKey.bookSuccessPurchaseConfirmed]: 'Purchase confirmed',
  [ResourceKey.bookSymptomsCheckerHeader]: 'Does %s have any of the following?',
  [ResourceKey.bookThankYouBookAHouseCall]: 'You can book a visit for you or your family, 365 days a year, 8am - 8pm on your schedule.',
  [ResourceKey.bookThankYouBookAnother]: 'Book a house call',
  [ResourceKey.bookThankYouGladToHelp]: 'We’re glad that we can help you stay well and be well.',
  [ResourceKey.bookThankYouHeader]: 'Your house call has been booked for %s!',
  [ResourceKey.bookThankYouHeaderTelemedicine]: 'Your video call has been booked for %s!',
  [ResourceKey.bookThankYouTitle]: 'Appointment Scheduled',
  [ResourceKey.bookThankYouVisit]: 'Thanks and see you soon!',
  [ResourceKey.bookWith]: 'Book with',
  [ResourceKey.bookWithASAP]: 'See the next available doctor on your schedule',
  [ResourceKey.bookWithPCP]: 'Rebook with your CenterWell Doctor',
  [ResourceKey.bottomSheetBlockEmailTitle]: 'Update email',
  [ResourceKey.bottomSheetBlockEmailText]: 'Please call <a href="tel:+18446444325">(844) 644-4325</a> to update your account email.',
  [ResourceKey.bottomSheetBlockNameBirthDateTitle]: 'Update Patient',
  [ResourceKey.bottomSheetBlockNameBirthDateText]: 'You cannot edit the name or date of birth of a patient with one or more completed visits.',
  [ResourceKey.bottomSheetButtonClose]: 'Close',
  [ResourceKey.bottomSheetCallCenterWellText1]:
    'To find the answers to your questions about CenterWell quickly, please check out our <a href="https://www.centerwellprimarycare.com/en/primary-care-anywhere/faqs.html" target="_blank" rel="noreferrer">FAQs</a>.',
  [ResourceKey.bottomSheetCallCenterWellText2]: 'To call CenterWell directly, we can be reached at <a href="tel:+18446444325">(844) 644-4325</a>.',
  [ResourceKey.bottomSheetCallCenterWellTitle]: 'Contact CenterWell',
  [ResourceKey.bottomSheetCancelReschedule]: 'Reschedule',
  [ResourceKey.bottomSheetCancelVisitButtonBookAnother]: 'Book another visit',
  [ResourceKey.bottomSheetCancelVisitButtonCancel]: 'Submit',
  [ResourceKey.bottomSheetCancelVisitCanceledTitle]: 'Visit Canceled',
  [ResourceKey.bottomSheetCancelVisitHeader]: 'To cancel your house call, please let us know why you are canceling:',
  [ResourceKey.bottomSheetCancelVisitNotesPlaceholder]: "Please tell us why you're canceling?",
  [ResourceKey.bottomSheetCancelVisitScheduledFor]: "%s's visit scheduled for:",
  [ResourceKey.bottomSheetCancelVisitTellUsWhy]: 'Cancel and tell us why',
  [ResourceKey.bottomSheetCancelVisitTitle]: 'Cancel visit',
  [ResourceKey.bottomSheetCVCInfo]: `The Card Verification Code, or CVC, is an extra code printed on your debit or credit card. 
  With most cards (Visa, MasterCard, bank cards, etc.) it is the final three digits of the number printed on the signature strip on the reverse of your card.`,
  [ResourceKey.bottomSheetCVCTitle]: 'What is a CVC?',
  [ResourceKey.bottomSheetDeductibleText]: `You have % s remaining of your deductible.This is the amount you must pay before % s begins 
  contributing to covered medical expenses(excluding preventative care and most office visits).`,
  [ResourceKey.bottomSheetDeductibleTitle]: 'What is a deductible?',
  [ResourceKey.bottomSheetIncompleteProfileText]: 'The patient profile you selected is incomplete. Please complete your profile before booking a visit.',
  [ResourceKey.bottomSheetIncompleteProfileTitle]: 'Complete your profile',
  [ResourceKey.bottomSheetInsuranceTitle]: 'Insurance info',
  [ResourceKey.bottomSheetLegalGuardianText]: `A legal guardian must be present during the visit or the legal guardian must provide proof of authority for the 
  third party entrusted to consent to medical care of the minor.<br /> <br />
  <strong>NOTE:</strong> If the above requirements are not met upon arrival of the doctor, the credit card on file will be charged <strong>a cancellation fee of $159</strong>.`,
  [ResourceKey.bottomSheetLegalGuardianTitle]: 'Legal guardian requirement',
  [ResourceKey.bottomSheetLocationErrorText]: 'Please enable location services to use this function.',
  [ResourceKey.bottomSheetLocationErrorTitle]: 'Cannot determine location',
  [ResourceKey.bottomSheetMedicaidText]: `Due to federal regulations, we cannot serve our Medicaid patients in their homes. 
  We are working very hard to change these regulations. Please stay in touch for updates.`,
  [ResourceKey.bottomSheetMedicaidTitle]: 'Limitation of use',
  [ResourceKey.bottomSheetOrCancelVisitTellUsWhy]: 'Or cancel and tell us why',
  [ResourceKey.bottomSheetReschedulePickTimeMessage]: 'Select another date/time to reschedule your visit.',
  [ResourceKey.bottomSheetRescheduleThankYou]: 'Thank you!',
  [ResourceKey.bottomSheetRescheduleThankYouSuccess]: 'Your visit was successfully rescheduled.',
  [ResourceKey.bottomSheetRescheduleVisitButton]: 'Reschedule visit',
  [ResourceKey.bottomSheetRescheduleVisitErrorMessage]: 'Something went wrong. Please try rescheduling your visit again.',
  [ResourceKey.bottomSheetRescheduleVisitErrorTitle]: 'There was an error rescheduling your visit',
  [ResourceKey.bottomSheetRescheduleVisitMessage]: 'Quickly reschedule by selecting one of the next available times:',
  [ResourceKey.bottomSheetRescheduleVisitPickAnotherTime]: 'Pick another time',
  [ResourceKey.bottomSheetRescheduleVisitRescheduledTitle]: 'Visit Rescheduled',
  [ResourceKey.bottomSheetRescheduleVisitScheduledFor]: "%s's visit scheduled for:",
  [ResourceKey.bottomSheetRescheduleVisitTitle]: 'Reschedule Visit',
  [ResourceKey.bottomSheetRescheduleVisitTryAgainButton]: 'Try again',
  [ResourceKey.buttonClose]: 'Close',
  [ResourceKey.californiaLicensed]: 'Licensed to practice in California',
  [ResourceKey.campaignTfsVisitNote]: `Account owner expressed interested in Thermo Fisher Immunocap testing for Food or Respiratory allergens. 
  If appropriate, order Immunocap testing for this patient.`,
  [ResourceKey.checkmarkAlt]: 'Checkmark',
  [ResourceKey.consentAgreed]: '%s agreed to the',
  [ResourceKey.consentInvalidServiceCode]: 'Invalid service code',
  [ResourceKey.consentNoAgreements]: 'No agreements available.',
  [ResourceKey.consentSaveError]: "Sorry, we couldn't save your consent. Please try again.",
  [ResourceKey.contactPageTitle]: 'Contact us',
  [ResourceKey.continueWithBlueShield]: 'Continue with Blue Shield',
  [ResourceKey.creditCardAlt]: 'Credit card',
  [ResourceKey.dateOfBirthDayAria]: 'Date of birth day',
  [ResourceKey.dateOfBirthMonthAria]: 'Date of birth month',
  [ResourceKey.dateOfBirthYearAria]: 'Date of birth year',
  [ResourceKey.documentsTitle]: 'Upload documents',
  [ResourceKey.documentsDescription]: 'Upload relevant medical documents for patient below.',
  [ResourceKey.documentsPatientsTitle]: 'Select patient',
  [ResourceKey.documentsPatientsDescription]: 'Select a patient to upload their documents.',
  [ResourceKey.documentsPatientsChange]: 'Change patient',
  [ResourceKey.documentsPatientsManage]: 'Manage patients',
  [ResourceKey.documentsDoneButton]: 'Done',
  [ResourceKey.engagementPointLabel]:
    'Use your existing EngagementPoint login to use CenterWell. Visit <a href="https://www.engagementpoint.com">www.engagementpoint.com</a> for more info.',
  [ResourceKey.engagementPointLoginButton]: 'Log in with EngagementPoint',
  [ResourceKey.engagementPointLogoAlt]: 'EngagementPoint logo',
  [ResourceKey.errorAutoLogin]: 'Account was created, but failed to log in. Please click OK to be redirected to login.',
  [ResourceKey.errorCreditCard]: `Your card won''t go through! Either there was a typo, the card has expired, or there was a banking error. 
  Please double-check your payment details, use a different card, or contact your bank.`,
  [ResourceKey.errorEmailVerificationLinkExpiredDescription]: 'Please login and resend the email verification link.',
  [ResourceKey.errorEmailVerificationLinkExpiredTitle]: 'Email verification link expired',
  [ResourceKey.errorGeneric]: 'An error has occurred.',
  [ResourceKey.errorGenericWithContact]:
    'Sorry, something went wrong. Please try again. If you need help, call <a href="tel:+18446444325">(844) 644-4325</a>',
  [ResourceKey.errorPageMessageContactLine]: 'please call <a href="tel:+18446444325">(844) 644-4325</a>',
  [ResourceKey.errorPagePrimaryText]: 'Something went wrong.',
  [ResourceKey.errorPageSecondaryText]: 'But don’t worry - we are diagnosing it and will be back online soon.',
  [ResourceKey.errorSignInGeneric]: 'An error has occurred or your account does not exist.',
  [ResourceKey.errorTitle]: 'Error',
  [ResourceKey.fileUploadCapture]: 'Upload',
  [ResourceKey.fileUploadDateMessage]: `Upload the documents by<br /><b>%s</b><br />to avoid visit cancellation.`,
  [ResourceKey.fileUploadDefaultDate]: '48 hours prior to visit',
  [ResourceKey.fileUploadDescription]: `Please upload Patient's most recent immunization records.`,
  [ResourceKey.fileUploadHeader]: 'Immunization records required',
  [ResourceKey.fileUploadOnFileMessage]: 'Immunization records are required to receive well-child care with CenterWell.',
  [ResourceKey.fileUploadTitle]: 'Attach documents',
  [ResourceKey.forgotPasswordHeader]: "Please use the email associated with the account and we'll send you an email to reset your password",
  [ResourceKey.forgotPasswordPageTitle]: 'Forgot password?',
  [ResourceKey.forgotPasswordSupportLink]: `Having trouble?<br />Call <a href="tel:+18446444325">(844) 644-4325</a>`,
  [ResourceKey.forgotPasswordTitle]: 'Forgot password',
  [ResourceKey.forgotRecoverPassword]: 'Submit',
  [ResourceKey.forgotRequestSent]: "Please check your email. We've sent you a link to reset your password.",
  [ResourceKey.genericButtonBook]: 'Book',
  [ResourceKey.genericButtonCancel]: 'Cancel',
  [ResourceKey.genericButtonClose]: 'Close',
  [ResourceKey.genericButtonContinue]: 'Continue',
  [ResourceKey.genericButtonDelete]: 'Delete',
  [ResourceKey.genericButtonEdit]: 'Edit',
  [ResourceKey.genericButtonManage]: 'Manage',
  [ResourceKey.genericButtonNo]: 'No',
  [ResourceKey.genericButtonOk]: 'OK',
  [ResourceKey.genericButtonRemove]: 'Remove',
  [ResourceKey.genericButtonRetry]: 'Retry',
  [ResourceKey.genericButtonSave]: 'Save',
  [ResourceKey.genericButtonSkip]: 'Skip',
  [ResourceKey.genericButtonSubmit]: 'Submit',
  [ResourceKey.genericButtonYes]: 'Yes',
  [ResourceKey.humanaTitle]: 'Doctor house calls are covered by your Humana plan.',
  [ResourceKey.humanaSubtitle]: 'CenterWell services include:',
  [ResourceKey.humanaService1]: 'Open 365 days a year, 8AM-8PM, same-day appointments available',
  [ResourceKey.humanaService2]: 'Primary, preventive and urgent care when you need it',
  [ResourceKey.humanaService3]: 'Doctor house calls or video calls in the comfort and safety of your home',
  [ResourceKey.humanaService4]: 'Convenient in-home testing, lab draws and prescriptions',
  [ResourceKey.invalidCredentials]: 'Please validate your credentials',
  [ResourceKey.genericVisitFileDownloadError]:
    'Something went wrong downloading your files. Please try again. If you need help, please call (844) 644-4325',
  [ResourceKey.infoNA]: 'N/A',
  [ResourceKey.insuranceBottomSheetButtonClose]: 'Close',
  [ResourceKey.insuranceButtonUpdate]: 'Continue',
  [ResourceKey.insuranceEditTitle]: 'Edit insurance',
  [ResourceKey.insuranceInfoAlt]: 'Insurance information',
  [ResourceKey.insuranceMemberID]: 'Member ID',
  [ResourceKey.insuranceText]: 'Covered by many insurance plans, including:',
  [ResourceKey.invalidZipcode]: 'Please enter a valid zipcode',
  [ResourceKey.leftAlt]: 'Left',
  [ResourceKey.legalPageTitle]: 'Legal notices',
  [ResourceKey.loginAuthenticationFailed]:
    'Your email address and password don’t seem to match. Please try again. If you need help, please call (844) 644-4325',
  [ResourceKey.mapMarkerAlt]: 'Map marker',
  [ResourceKey.medicaidInfoAlt]: 'Medicaid information',
  [ResourceKey.menuNavBookPage]: 'Book now',
  [ResourceKey.menuNavCopyright]: 'CenterWell Senior Primary Care © %s All rights reserved.',
  [ResourceKey.menuNavDocumentsPage]: 'Documents',
  [ResourceKey.menuNavHealthProfile]: 'Health Profile',
  [ResourceKey.menuNavHelpPage]: 'Help',
  [ResourceKey.menuNavLegalPage]: 'Legal',
  [ResourceKey.menuNavPatientsPage]: 'Patients',
  [ResourceKey.menuNavPaymentPage]: 'Payment',
  [ResourceKey.menuNavSignOut]: 'Sign Out',
  [ResourceKey.menuNavVisitsPage]: 'Visits',
  [ResourceKey.menuNavChat]: 'Chat',
  [ResourceKey.nextAvailable]: 'Next available:',
  [ResourceKey.noneOfTheAbove]: 'None of the above',
  [ResourceKey.notFoundPageMessageContactLine]: 'please call <a href="tel:+18446444325">(844) 644-4325</a>',
  [ResourceKey.notFoundPagePrimaryText]: 'Whoops!',
  [ResourceKey.notFoundPageSecondaryText]: "The page you're looking for doesn't exist.",
  [ResourceKey.openCalendarAlt]: 'Open calendar',
  [ResourceKey.pageLoadingAlt]: 'The page is loading',
  [ResourceKey.partnerPopup]: 'Welcome to CenterWell Primary Care!',
  [ResourceKey.partnerPopupBullet1]: 'Create your CenterWell account.',
  [ResourceKey.partnerPopupBullet2]: 'Schedule an appointment 8AM-8PM, available 365 days.',
  [ResourceKey.partnerPopupBullet3]: 'A caring, qualified doctor will call you.',
  [ResourceKey.partnerPortalUrl]: 'https://partner.heal.com',
  [ResourceKey.partnerQuote]: '"A total game changer"',
  [ResourceKey.partnerQuoteAuthor]: '-Katie H.',
  [ResourceKey.partnerYelp]: 'on Yelp',
  [ResourceKey.patientAvatarAlt]: 'Photo for %s',
  [ResourceKey.patientsButtonAddPatient]: 'Add patient',
  [ResourceKey.patientsButtonRemovePatient]: 'Remove patient',
  [ResourceKey.patientsButtonRemovePatientCancel]: 'Cancel',
  [ResourceKey.patientsButtonRemovePatientPromptText]: `Are you sure you want to remove this patient profile? 
  You will lose all patient data connected with this patient. This is permanent and the patient profile cannot be retrieved.`,
  [ResourceKey.patientsPageTitle]: 'Patients',
  [ResourceKey.paymentManagementTitle]: 'Manage payment method',
  [ResourceKey.paymentManagementSelectTitle]: 'Select payment method',
  [ResourceKey.paymentManagementEditPaymentDetails]: 'Edit payment details',
  [ResourceKey.pcpNotAvailable1]: 'Apologies',
  [ResourceKey.pcpNotAvailable2]: 'There are no CenterWell Doctors available at this zipcode.',
  [ResourceKey.pcpNotFound1]: 'Unfortunately this doctor is not available.',
  [ResourceKey.pcpNotFound2]: 'Click the button below to browse through available CenterWell Doctors.',
  [ResourceKey.pcpTimeSlotDivider]: 'OR',
  [ResourceKey.popupButton]: "Let's get started",
  [ResourceKey.preferredDoctorAlt]: 'Preferred doctor',
  [ResourceKey.preferredDoctorTitle]: 'Preferred doctor',
  [ResourceKey.preselectionError]: 'Unfortunately the time you originally selected is unavailable for this service. Please select another time.',
  [ResourceKey.promoAddTitle]: 'Add promo code',
  [ResourceKey.promoButtonApplyCode]: 'Apply code',
  [ResourceKey.promoButtonRemoveCode]: 'Remove code',
  [ResourceKey.promoDiscountLabel]: 'Discounted',
  [ResourceKey.promoEditTitle]: 'Edit promo code',
  [ResourceKey.promoError]: 'Invalid promo code',
  [ResourceKey.promoErrorWarning]: 'Invalid or expired promo code',
  [ResourceKey.promoPlaceholder]: 'Promo code',
  [ResourceKey.questionMarkAlt]: 'Question mark',
  [ResourceKey.registerButtonCreateAccount]: 'Create account',
  [ResourceKey.registerButtonSignIn]: 'Log in',
  [ResourceKey.registerCheckboxRememberMe]: 'Remember me',
  [ResourceKey.registerContentTitle]: "Let's get started by creating an account.",
  [ResourceKey.registerContentTitleWithMarket]: "To book your visit in %s let's create an account",
  [ResourceKey.registerContentTitleWithMarketAndDate]: "To book your visit in %s on %s let's create an account",
  [ResourceKey.registerEmail]: 'Email',
  [ResourceKey.registerEmailError]: 'Invalid email address',
  [ResourceKey.registerEmailVerifyText]: 'A verification link will be sent to your email account.',
  [ResourceKey.registerFirstName]: 'First name',
  [ResourceKey.registerFirstNameError]: 'Invalid first name',
  [ResourceKey.registerLastName]: 'Last name',
  [ResourceKey.registerLastNameError]: 'Invalid last name',
  [ResourceKey.registerLegalUrlLinks]: 'By creating an account, you agree to our',
  [ResourceKey.registerNondiscriminationNotice]: 'Nondiscrimination Notice',
  [ResourceKey.registerPassword]: 'Create password',
  [ResourceKey.registerPasswordConfirm]: 'Confirm password',
  [ResourceKey.registerPasswordError]: "Passwords don't match",
  [ResourceKey.registerPasswordMatch]: 'Passwords match',
  [ResourceKey.registerPasswordValidation1]: 'Use 8 or more characters',
  [ResourceKey.registerPasswordValidation2]: 'Use upper and lower case letters (e.g. Aa)',
  [ResourceKey.registerPasswordValidation3]: 'Use a number (e.g. 1234)',
  [ResourceKey.passwordConstraintsId]: 'password-constraints',
  [ResourceKey.confirmPasswordConstraintsId]: 'confirm-password-constraints',
  [ResourceKey.registerPhone]: 'Phone',
  [ResourceKey.registerPhoneError]: 'Invalid phone number',
  [ResourceKey.registerPrivacyPolicy]: 'Privacy Policy',
  [ResourceKey.registerPrivacyPractices]: 'Notice of Privacy Practices',
  [ResourceKey.registerTermsOfService]: 'Terms of Service',
  [ResourceKey.removeInsuranceAlt]: 'Remove insurance',
  [ResourceKey.resetPasswordAlt]: 'Reset password',
  [ResourceKey.resetPasswordButtonBackToSignIn]: 'Back to log in',
  [ResourceKey.resetPasswordButtonSubmit]: 'Submit',
  [ResourceKey.resetPasswordConfirmPlaceholder]: 'Confirm password',
  [ResourceKey.resetPasswordError]: "Passwords don't match",
  [ResourceKey.resetPasswordPageTitle]: 'Reset password',
  [ResourceKey.resetPasswordPlaceholder]: 'Create password',
  [ResourceKey.resetPasswordSubTitle]: 'Please enter a new password for your account.',
  [ResourceKey.resetPasswordSupportLink]: `Having trouble? Call <strong><a href="tel:+18446444325">(844) 644-4325</a></strong>`,
  [ResourceKey.resetPasswordTitle]: 'Reset password',
  [ResourceKey.resetPasswordValidation1]: 'Use 8 or more characters',
  [ResourceKey.resetPasswordValidation2]: 'Use upper and lower case letters (e.g. Aa)',
  [ResourceKey.resetPasswordValidation3]: 'Use a number (e.g. 1234)',
  [ResourceKey.resetRequestSent]: 'Thank you! Your password has been reset.',
  [ResourceKey.residency]: 'Residency',
  [ResourceKey.rightAlt]: 'Right',
  [ResourceKey.SAMLLinkAccount]: 'Link my account',
  [ResourceKey.SAMLLinkHeader]: 'Log in',
  [ResourceKey.SAMLLinkInfo]: 'If you would like to link to an existing account, please provide your username and password. You may also create a new account.',
  [ResourceKey.blueshieldLinkInfo]: 'If you would like to link to your account, please provide your password. You can also skip this step.',
  [ResourceKey.SAMLRegister]: 'Create an account',
  [ResourceKey.SAMLRegisterHeader]: `Let's get started by creating an account.`,
  [ResourceKey.samlGenericError]: 'There was an error processing your request',
  [ResourceKey.samlErrorCreatingAccount]: 'There was an error creating your account.',
  [ResourceKey.saveYourPreferredDoctor]: 'Select',
  [ResourceKey.selectableItemPhotoAlt]: 'Photo',
  [ResourceKey.services]: 'Services',
  [ResourceKey.signInButtonSignIn]: 'Log in',
  [ResourceKey.signInEmailError]: 'Invalid email address',
  [ResourceKey.signInEmailPlaceholder]: 'Email',
  [ResourceKey.signInForgotPassword]: 'Forgot password',
  [ResourceKey.signInHaveAccount]: 'Already have an account? ',
  [ResourceKey.signInHaveAccountSignIn]: 'Log in',
  [ResourceKey.signInOauth]: 'Logging in...',
  [ResourceKey.signInPasswordError]: 'Invalid password',
  [ResourceKey.signInPasswordPlaceholder]: 'Password',
  [ResourceKey.signInNewToCenterWell]: 'New to CenterWell?',
  [ResourceKey.signInRegister]: 'Create an account',
  [ResourceKey.signInRememberMeLabel]: 'Remember me',
  [ResourceKey.signInSupportLink]: `Having trouble?<br />Call <a href="tel:+18446444325">(844) 644-4325</a>`,
  [ResourceKey.signInTitle]: 'Log in',
  [ResourceKey.SSOAddress]: 'Address',
  [ResourceKey.SSOLandingIntroduction]: 'Have you used CenterWell before?',
  [ResourceKey.SSOLandingTitle]: 'Welcome to CenterWell Primary Care',
  [ResourceKey.SSOLandingWelcome]: 'Welcome to CenterWell Primary Care! Log in or create an account to book a doctor right to your home or office.',
  [ResourceKey.SSOLinkBottomButton]: 'Link account',
  [ResourceKey.SSOLinkButton]: 'Yes',
  [ResourceKey.SSOLinkHeader]: 'Link your CenterWell account',
  [ResourceKey.SSOLinkInfo]: 'Log in with your CenterWell account to connect your insurance.',
  [ResourceKey.SSOLinkTitle]: 'Link account',
  [ResourceKey.SSORegisterBottomButton]: 'Create account',
  [ResourceKey.SSORegisterButton]: 'No',
  [ResourceKey.SSORegisterGroupID]: 'Insurance Group ID',
  [ResourceKey.SSORegisterHeader]: 'Create your CenterWell Primary Care account',
  [ResourceKey.SSORegisterInfo]: `We've included your insurance plan information to make it easier to register your new CenterWell account and more convenient to pay using insurance. 
  All information is stored and transmitted securely.`,
  [ResourceKey.SSORegisterMemberID]: 'Insurance Member ID',
  [ResourceKey.SSORegisterTitle]: 'Register account',
  [ResourceKey.SSOZipCode]: 'ZIP Code',
  [ResourceKey.TdapWarning]: 'If you are pregnant, it is recommended that you only receive the Tdap vaccine between the 27 to 36 week gestation window.',
  [ResourceKey.topNavButtonBook]: 'Book now',
  [ResourceKey.verifyEmailBanner]: 'Please verify your email address.',
  [ResourceKey.verifyEmailBannerResendButton]: 'Resend',
  [ResourceKey.verifyEmailContent]: 'To continue, press the button below and check your inbox.',
  [ResourceKey.verifyEmailResendButton]: 'Send verification link',
  [ResourceKey.verifyEmailResendSuccess]: 'Thanks! Check your inbox.',
  [ResourceKey.verifyEmailResendSupport]: 'Having problems? Contact our support team at <a href="tel:+18446444325">(844) 644-4325</a>.',
  [ResourceKey.verifyEmailTitle]: 'Verify your email address',
  [ResourceKey.visitsAllPatients]: 'All patients',
  [ResourceKey.visitsBookAgainText]: 'Book a follow up visit',
  [ResourceKey.visitsButtonBookAgain]: 'Book again',
  [ResourceKey.visitsButtonBookAgainThankYou]: 'Book another visit',
  [ResourceKey.visitsButtonCancelVisit]: 'Cancel visit',
  [ResourceKey.visitsButtonJoinVideoCall]: 'Join video call',
  [ResourceKey.visitsButtonReadLess]: 'Read less',
  [ResourceKey.visitsButtonReadMore]: 'Read more',
  [ResourceKey.visitsButtonReschedule]: 'Reschedule',
  [ResourceKey.visitsCenterWellCarePlanSignIn]: 'Sign in to view %s',
  [ResourceKey.visitsCenterWellCarePlanSignInHeader]: 'To protect patient privacy, please sign in to confirm that you are authorized to view this document.',
  [ResourceKey.visitsStatusActive]: 'Active',
  [ResourceKey.visitsStatusCanceled]: 'Canceled',
  [ResourceKey.visitsStatusCompleted]: 'Completed',
  [ResourceKey.visitsStatusEnroute]: 'En route',
  [ResourceKey.visitsStatusRefunded]: 'Refunded',
  [ResourceKey.visitsStatusUpcoming]: 'Upcoming',
  [ResourceKey.visitsTapToUpload]: 'Tap to upload',
  [ResourceKey.visitsTapToUploadMore]: 'Upload additional documents',
  [ResourceKey.visitsTitle]: 'Your Visits',
  [ResourceKey.visitsVaccinationRecordsOnFile]: 'Vaccination records uploaded',
  [ResourceKey.visitsVaccinationRecordsRequired]: 'Vaccination records required',
  [ResourceKey.visitsWhatToExpectMessage]: 'Now that your visit is scheduled, please make sure you’ve reviewed the CenterWell prep check list.',
  [ResourceKey.visitsWhatToExpectTitle]: 'What to expect',
  [ResourceKey.visitsZeroDataLine1]: 'No visits yet.',
  [ResourceKey.visitsZeroDataLine2]: 'Please start booking a visit now',
  [ResourceKey.wallStreetText]: '"The Heal app sent an excellent Stanford-trained doctor to my door to check out an injury in under an hour."',
  [ResourceKey.warningAlt]: 'Warning',
  [ResourceKey.whatToExpectTitle]: 'What To Expect Next',
  [ResourceKey.whatToExpectSubTitle]: 'How to prep for your upcoming appointment',
  [ResourceKey.workplaceOnsiteAddress]: 'Address',
  [ResourceKey.workplaceOnsiteBookNow]: 'Book now',
  [ResourceKey.workplaceOnsiteDate]: 'Date',
  [ResourceKey.workplaceOnsiteDates]: 'Date',
  [ResourceKey.workplaceOnsiteMessage]: 'Good news! A CenterWell event is coming up.',
  [ResourceKey.workplaceOnsiteMessageNoTimeslots]: 'No CenterWell events scheduled.\nTry booking a CenterWell house call.',
  [ResourceKey.workplaceOnsiteNoDates]: 'No dates available',
  [ResourceKey.workplaceOnsiteRoom]: 'Room',
  [ResourceKey.workplaceOnsiteService]: 'Service',
  [ResourceKey.workplaceOnsiteServices]: 'Services',
  [ResourceKey.workplaceOR]: 'OR',
  [ResourceKey.workplaceRegularBookAHouseCall]: 'Book a house call',
  [ResourceKey.workplaceRegularMessage]: 'You can book a visit for you or your family, 365 days a year, 8am - 8pm on your schedule.',
  [ResourceKey.workplaceRegularTitle]: 'Book a CenterWell house call',
  [ResourceKey.workplaceTitle]: 'CenterWell event',
  [ResourceKey.workplaceWallStreetLogoAlt]: 'Wall street logo',
  [ResourceKey.workplaceYelpStarsAlt]: 'Yelp 5 stars',
};

export default resources;
