import { SAMLActions } from './actions';
import { SAMLResponse, SAMLState } from './types';

export const samlInitialState: SAMLState = {
  isFetching: false,
  error: undefined,
  data: undefined,
};

function handleGetSAMLDataRequest(state: SAMLState): SAMLState {
  return {
    ...state,
    isFetching: true,
    error: undefined,
    data: undefined,
  };
}

function handleGetSAMLDataSuccess(state: SAMLState, data: SAMLResponse): SAMLState {
  return {
    ...state,
    error: undefined,
    isFetching: false,
    data: data,
  };
}

function handleGetSAMLDataFailure(state: SAMLState, error: any): SAMLState {
  return {
    ...state,
    data: undefined,
    isFetching: false,
    error: error,
  };
}

function handleRegisterViaSAMLRequest(state: SAMLState): SAMLState {
  return {
    ...state,
    isFetching: true,
    error: undefined,
    data: undefined,
  };
}

function handleRegisterViaSAMLSuccess(state: SAMLState, data: any): SAMLState {
  return {
    ...state,
    error: undefined,
    isFetching: false,
    data: data,
  };
}

function handleRegisterViaSAMLFailure(state: SAMLState, error: any): SAMLState {
  return {
    ...state,
    data: undefined,
    isFetching: false,
    error: error,
  };
}

function handleLinkSAMLRequest(state: SAMLState): SAMLState {
  return {
    ...state,
    isFetching: true,
    error: undefined,
    data: undefined,
  };
}

function handleLinkSAMLSuccess(state: SAMLState, data: any): SAMLState {
  return {
    ...state,
    error: undefined,
    isFetching: false,
    data: data,
  };
}

function handleLinkSAMLFailure(state: SAMLState, error: any): SAMLState {
  return {
    ...state,
    data: undefined,
    isFetching: false,
    error: error,
  };
}

export function SAMLReducer(
  state: SAMLState = samlInitialState,
  // action: Action & { data: any }
  action: any
): SAMLState {
  switch (action.type) {
    case SAMLActions.GET_SAML_DATA_REQUEST:
      return handleGetSAMLDataRequest(state);
    case SAMLActions.GET_SAML_DATA_SUCCESS:
      return handleGetSAMLDataSuccess(state, action.data);
    case SAMLActions.GET_SAML_DATA_FAILURE:
      return handleGetSAMLDataFailure(state, action.data);

    case SAMLActions.REGISTER_VIA_SAML_REQUEST:
      return handleRegisterViaSAMLRequest(state);
    case SAMLActions.REGISTER_VIA_SAML_SUCCESS:
      return handleRegisterViaSAMLSuccess(state, action.data);
    case SAMLActions.REGISTER_VIA_SAML_FAILURE:
      return handleRegisterViaSAMLFailure(state, action.data);

    case SAMLActions.LINK_SAML_REQUEST:
      return handleLinkSAMLRequest(state);
    case SAMLActions.LINK_SAML_SUCCESS:
      return handleLinkSAMLSuccess(state, action.data);
    case SAMLActions.LINK_SAML_FAILURE:
      return handleLinkSAMLFailure(state, action.data);

    case SAMLActions.CLEAR_ERROR:
      return { ...state, error: undefined };

    case SAMLActions.SET_ACCESS_AND_REFRESH_TOKEN:
      return {
        ...state,
        accessToken: action.data.accessToken,
        refreshToken: action.data.refreshToken,
      };

    default:
      return state;
  }
}
