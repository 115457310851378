export interface AddressState {
  isFetching: boolean;
  isModifying: boolean;
  error?: string;
  data?: { [index: string]: Address };
}

export interface AddressRequest {
  establishment: string;
  /**
   * @deprecated Passing the full string address is deprecated as of August 2022.
   * It should be kept in the typedef for interop until it is removed from the API response.
   */
  address?: string;
  streetAddress: string;
  city: string;
  unit: string;
  country: string;
  zipcode: string;
  instructions: string;
  state: string;
  addressType: AddressType;
  latitude: number;
  longitude: number;
}

export interface Address extends AddressRequest {
  addressId: string;
}

export enum AddressType {
  HOME = 'HOME',
  WORK = 'WORK',
  OTHER = 'OTHER',
  HOUSING_FACILITY = 'HOUSING_FACILITY',
  CORPORATE_FACILITY = 'CORPORATE_FACILITY',
}

export interface Coverage {
  coverageStatus: 'COVERED' | 'NOT_COVERED' | 'DELAYED_COVERAGE' | 'TELEMEDICINE_ONLY';
}
