import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Account } from '../../appState/account/types';
import { ConfigState } from '../../appState/config/types';
import { Patient, PatientState } from '../../appState/patient/types';
import Page from '../core/Page';
import { ROUTES } from '../../services/constants';
import { getString } from '../../services/languages/index';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { PageHeader } from '../core/PageHeader';
import PatientForm from '../profile/PatientForm';

export interface EditPatientActions {
  getCurrentStatus: () => void;
  resetCart: () => void;
  clearSuccessMessage: () => void;
}

export interface EditPatientProps extends RouteComponentProps<{ id: string }> {
  actions: EditPatientActions;
  patientList: PatientState;
  location: any;
  isAdd: boolean;
  account?: Account;
  config: ConfigState;
}

export interface EditPatientState {
  patient?: Patient;
  updated?: boolean;
}

export class EditPatient extends React.Component<EditPatientProps, EditPatientState> {
  constructor(props: EditPatientProps) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    this.props.actions.getCurrentStatus();
  }

  public componentDidUpdate(prevProps: EditPatientProps) {
    if (this.props.match.params.id && this.props.patientList.data && prevProps.patientList !== this.props.patientList) {
      this.setState({ patient: this.props.patientList.data[this.props.match.params.id] });
    }

    const previousPatientList = prevProps.patientList.data;
    const updatedPatientList = this.props.patientList.data;
    const hasSavedPatient = this.state.updated && prevProps.patientList.isFetching && !this.props.patientList.isFetching && !this.props.patientList.error;

    if (hasSavedPatient && updatedPatientList) {
      const previousListOfPatientIds = Object.keys(previousPatientList || {});
      const patientId = this.props.match.params.id || Object.keys(updatedPatientList).find((key) => previousListOfPatientIds.indexOf(key) === -1);
      patientId && this.onFormComplete(updatedPatientList[patientId]);
    }
  }

  public render(): JSX.Element {
    const title = this.props.isAdd ? getString(ResourceKey.bookPatientAddPageTitle) : getString(ResourceKey.bookPatientUpdatePageTitle);

    return (
      <Page title={title} showBackButton>
        <PageHeader title={title} />
        <PatientForm patient={this.state.patient} formComplete={() => this.setState({ updated: true })} />
      </Page>
    );
  }

  private onFormComplete(patient: Patient | undefined) {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const book = params.get('book');

    if (book) {
      if (patient && patient.hasMedicaid) {
        this.props.actions.resetCart();
        this.props.history.replace(ROUTES.bookPatient);
      } else {
        this.props.history.goBack();
      }
    } else {
      this.props.actions.getCurrentStatus();
      this.props.history.goBack();
    }
  }
}
