import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { NotificationsBox } from '../utils/NotificationsBox';
import { IMAGES, ROUTES } from '../../services/constants';
import { createMarkup, getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import Page from '../core/Page';
import styles from './ResetPasswordPage.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { YUP_VALIDATIONS } from '../../utils/forms';
import Input from '../core/Input';
import Button from '../core/Button';
import { HealAPI } from '../../services/api';
import { PageHeader } from '../core/PageHeader';

interface ResetFormData {
  password: string;
  passwordConfirmation: string;
}

export function ResetPasswordPage() {
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);

  const history = useHistory();
  const params = useParams<{ id: string }>();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ResetFormData>({
    mode: 'onTouched',
    resolver: yupResolver(
      object().shape({
        password: YUP_VALIDATIONS.passwordRegister,
        passwordConfirmation: YUP_VALIDATIONS.passwordConfirmation,
      })
    ),
  });

  function onSubmit(data: ResetFormData) {
    setError(undefined);
    setCreating(true);
    HealAPI.resetPassword(data.password, params.id)
      .then((response) => {
        setSuccess(response.ok);
        if (!response.ok) {
          setError(response.data?.description);
        }
      })
      .finally(() => setCreating(false));
  }

  return (
    <Page
      title={getString(ResourceKey.resetPasswordTitle)}
      navButtons={[
        {
          action: () => history.push(ROUTES.login),
          text: getString(ResourceKey.signInButtonSignIn),
        },
      ]}
    >
      <PageHeader title={getString(ResourceKey.resetPasswordPageTitle)} image={IMAGES.loginTitle} />

      {error && <NotificationsBox type="error" content={error} />}
      {success && (
        <div className={styles.message}>
          <div>{getString(ResourceKey.resetRequestSent)}</div>
          <br />
          <Button text="Back to Login" testId="btn_backToLogin" className="btn-block" onClick={() => history.replace(ROUTES.login)} />
        </div>
      )}

      {!success && (
        <form onSubmit={handleSubmit(onSubmit)} title="Reset password">
          <Input
            label={getString(ResourceKey.resetPasswordPlaceholder)}
            id="password"
            type="password"
            error={errors.password?.message}
            required
            {...register('password')}
          />
          <Input
            label={getString(ResourceKey.resetPasswordConfirmPlaceholder)}
            id="passwordConfirmation"
            type="password"
            error={errors.passwordConfirmation?.message}
            required
            {...register('passwordConfirmation')}
          />

          <Button loading={creating} text={getString(ResourceKey.forgotRecoverPassword)} testId="btn_resetPassword" className="btn-block" />
        </form>
      )}

      <div className={styles.contactUs}>
        <span dangerouslySetInnerHTML={createMarkup(getString(ResourceKey.signInSupportLink))} />
      </div>
    </Page>
  );
}
