import { ReduxAction } from '../types';
import { ConfigActions } from './actions';
import { ConfigResponse, ConfigState, DropdownItem, Payer } from './types';

export const configInitialState: ConfigState = {
  isFetching: false,
  error: undefined,
  data: undefined,
  payersForDropdown: [],
  relationshipsForDropdown: [],
  gendersForRadio: [],
};

function handleGetConfigDataRequest(state: ConfigState): ConfigState {
  return {
    ...state,
    isFetching: true,
    error: undefined,
    data: undefined,
  };
}

function handleGetConfigDataSuccess(state: ConfigState, data: ConfigResponse): ConfigState {
  const config = data?.data;

  const payersForDropdown: DropdownItem<Payer>[] = config.payers.map((payer) => ({ key: payer.payerId, text: payer.name, data: payer }));
  const relationshipsForDropdown: DropdownItem[] = config.relationships.map((relationship) => ({ key: relationship.relationshipId, text: relationship.name }));
  const gendersForRadio: DropdownItem[] = config.genders.map((gender) => ({ key: gender.genderId, text: gender.name }));

  return {
    isFetching: false,
    error: undefined,
    data,
    payersForDropdown,
    relationshipsForDropdown,
    gendersForRadio,
  };
}

function handleGetConfigDataFailure(state: ConfigState, error: string): ConfigState {
  return {
    ...state,
    isFetching: false,
    error,
    data: undefined,
  };
}

export function configReducer(state: ConfigState = configInitialState, action: ReduxAction<any>): ConfigState {
  switch (action.type) {
    case ConfigActions.GET_CONFIG_REQUEST:
      return handleGetConfigDataRequest(state);
    case ConfigActions.GET_CONFIG_SUCCESS:
      return handleGetConfigDataSuccess(state, action.data as ConfigResponse);
    case ConfigActions.GET_CONFIG_FAIL:
      return handleGetConfigDataFailure(state, action.data);
    default:
      return state;
  }
}
