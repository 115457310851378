/**
 * Compares two files for equality by comparing name, size, lastModified.
 * @param {File} file2 First file for comparison (File object).
 * @param {File} file2 Second file for comparison (File object).
 * @returns {boolean} True/false if the name, size, & lastModified properties are equal.
 */
export function filesAreEqual(file1: File, file2: File) {
  return file1.name === file2.name && file1.size === file2.size && file1.lastModified === file2.lastModified;
}

export function getFileExtension(file: File) {
  return file.name.split('.').pop() || '';
}

export function humanFileSize(bytes: number, decimalPlaces: number = 1) {
  var thresh = 1024;
  var units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (bytes < thresh) {
    return bytes + ' B';
  }

  var u = -1;
  do {
    bytes /= thresh;
    u++;
  } while (bytes >= thresh && u < units.length - 1);

  return bytes.toFixed(decimalPlaces) + ' ' + units[u];
}

export function mbToBytes(mb: number) {
  return mb * 1024 * 1024;
}

// 'image/png, image/jpeg, application/pdf' → ['png', 'jpeg', 'pdf']
export function supportedFileTypes(acceptFileTypes: string): string[] {
  const fileTypes = acceptFileTypes.split(', ');

  fileTypes.forEach((fileType, index) => {
    const typeSplit = fileType.split('/');
    fileTypes[index] = typeSplit[typeSplit.length - 1];
  });

  return fileTypes;
}
