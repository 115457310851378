const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 -2 40 40">
      <title>Close</title>
      <g fill="none" fillRule="evenodd" stroke="#676B71" strokeLinecap="round" strokeWidth="2">
        <path d="M29.799 28.532L10 9M29.799 9L10 28.532" />
      </g>
    </svg>
  );
};

export default CloseIcon;
