//  @deprecated - e.keyCode may not be supported by browsers in the future.
export enum KeyCodes {
  a = 65,
  backspace = 8,
  comma = 188,
  del = 46,
  down = 40,
  end = 35,
  enter = 13,
  escape = 27,
  home = 36,
  left = 37,
  pageDown = 34,
  pageUp = 33,
  right = 39,
  semicolon = 186,
  space = 32,
  tab = 9,
  up = 38,
}
