import 'moment-timezone';

import moment from 'moment';

const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

function toDate(input: Date | string): Date {
  if (typeof input === 'string') {
    return new Date(input);
  } else {
    return input as Date;
  }
}

export function friendlyFullTime(input: Date | string): string {
  const date = toDate(input);
  let hours = date.getHours();
  let minutes: string | number = date.getMinutes();
  const suffix = hours >= 12 ? 'PM' : 'AM';
  if (minutes === 0) {
    minutes = '00';
  } else {
    minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  }
  hours = hours % 12 || 12;
  return `${hours}:${minutes} ${suffix}`;
}

export function friendlyHour(input: Date | string): string {
  const date = toDate(input);
  let hours = date.getHours();
  const suffix = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  return `${hours}${suffix}`;
}

export function friendlyFullDate(input: Date | string): string {
  const today = getZeroTimeDate();
  const tomorrow = getZeroTimeDate();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const dayAfterTomorrow = getZeroTimeDate();
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
  const date = toDate(input);
  const dayName = days[date.getDay()];
  const month = months[date.getMonth()];
  if (date.getTime() >= today.getTime() && date.getTime() < tomorrow.getTime()) {
    return 'Today';
  } else if (date.getTime() >= tomorrow.getTime() && date.getTime() < dayAfterTomorrow.getTime()) {
    return 'Tomorrow';
  } else {
    return `${dayName}, ${month} ${date.getDate()}, ${date.getFullYear()}`;
  }
}

export function getZeroTimeDate(date?: Date) {
  let d = new Date();
  if (date) {
    d = new Date(date);
  }
  d.setHours(0, 0, 0, 0);
  return d;
}

export function isChild(date: string): boolean {
  let patientAge = calculateAge(new Date(date));
  if (isNaN(patientAge) || patientAge >= 18) {
    return false;
  }
  return true;
}

export function isInfant(dob: string, format?: string) {
  return moment().diff(moment(dob, format), 'months') < 6;
}

export function calculateAge(birthday: Date | string): number {
  if (!birthday) {
    return NaN;
  }
  const date = toDate(birthday);
  const today = new Date();
  var age = today.getFullYear() - date.getFullYear();
  var mos = today.getMonth() - date.getMonth();
  if (mos < 0 || (mos === 0 && today.getDate() < date.getDate())) {
    age--;
  }
  return age;
}

export function formatTime(time: number, format: string, timeZone: string = 'America/Los_Angeles'): string {
  const timeMoment = moment(time).tz(timeZone);
  return timeMoment.format(format);
}

export function formatTimeslot(start: number, end: number, timeZone: string = 'America/Los_Angeles'): string {
  const message = getTodayOrTomorrowPrefix(start);
  const startTime = moment(start).tz(timeZone);
  const endTime = moment(end).tz(timeZone);
  if (startTime.format('mm') === endTime.format('mm')) {
    return `${message}${startTime.format('M/D/YY, hA')}-${endTime.format('hA')}`;
  }
  return `${message}${startTime.format('M/D/YY, h:mmA')}-${endTime.format('h:mmA')}`;
}

export function formatTimeslotForTodayOrTomorrow(start: number, end: number, timeZone: string = 'America/Los_Angeles'): string {
  const message = getTodayOrTomorrowPrefix(start);
  const startTime = moment(start).tz(timeZone);
  const endTime = moment(end).tz(timeZone);
  let tzSuffix = '';
  if (moment.tz.guess() !== timeZone) {
    tzSuffix = ' z';
  }
  return `${message}${startTime.format('h:mmA')} - ${endTime.format('h:mmA' + tzSuffix)}`;
}

export function getTodayOrTomorrowPrefix(time: number): string {
  let message = '';
  if (moment(time).isSame(moment(), 'day')) {
    message += 'Today, ';
  } else if (moment(time).isSame(moment().add(1, 'day'), 'day')) {
    message += 'Tomorrow, ';
  }
  return message;
}

export function isToday(date: Date | number | string, timeZone?: string) {
  const todayDate = timeZone ? moment(date).tz(timeZone) : moment(date);
  return todayDate.isSame(moment(), 'day');
}

export function isFuture(date: Date | number | string) {
  return moment(date).isAfter(moment());
}

// TODO: Do Onsites use different timeStamp value?
export function daysFromToday(timestampInSeconds: number, timeZone: string) {
  const timestampInMilliseconds = timestampInSeconds * 1000;
  const d = timeZone ? moment(timestampInMilliseconds).tz(timeZone) : moment(timestampInMilliseconds);
  return d.startOf('day').diff(moment().startOf('day'), 'days');
}

export const MONTHS = [
  { text: 'January', days: 31 },
  { text: 'February', days: 29 },
  { text: 'March', days: 31 },
  { text: 'April', days: 30 },
  { text: 'May', days: 31 },
  { text: 'June', days: 30 },
  { text: 'July', days: 31 },
  { text: 'August', days: 31 },
  { text: 'September', days: 30 },
  { text: 'October', days: 31 },
  { text: 'November', days: 30 },
  { text: 'December', days: 31 },
];

export function getNumDays(month?: number, year?: number) {
  let numDays = 31;

  if (month === 2 && year) {
    // Handle leap years
    numDays = year % 4 === 0 ? 29 : 28;
  } else if (month && MONTHS[month - 1]) {
    numDays = MONTHS[month - 1].days;
  }

  return numDays;
}

export function parseDateOfBirth(dateOfBirth: string) {
  const dobSplit = dateOfBirth.split('/');

  if (dobSplit.length !== 3) {
    throw new Error('Invalid dateOfBirth parameter passed to parseDateOfBirth(): ' + dateOfBirth);
  }

  return {
    month: dobSplit[0].replace(/^0/, ''),
    day: dobSplit[1].replace(/^0/, ''),
    year: dobSplit[2],
  };
}
