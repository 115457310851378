import { Dispatch } from 'redux';
import { RootState } from '../../appState/rootState';
import { ReduxAction } from '../../appState/types';
import { HealAPI } from '../../services/api';

export enum CampaignActions {
  SET_CAMPAIGN = 'SET_CAMPAIGN',
  SET_CAMPAIGN_LAUNCH_PARAMS = 'SET_CAMPAIGN_LAUNCH_PARAMS',
}

export function setCampaign(campaign: any): ReduxAction<{}> {
  return {
    type: CampaignActions.SET_CAMPAIGN,
    data: campaign,
  };
}

export function setCampaignLaunchParams(launchParams: any): ReduxAction<{}> {
  return {
    type: CampaignActions.SET_CAMPAIGN_LAUNCH_PARAMS,
    data: launchParams,
  };
}

export function resetCampaignData() {
  // campaignId will persist
  return {
    type: CampaignActions.SET_CAMPAIGN,
    data: undefined,
  };
}

export function sendCampaignData() {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const branchData = getState().campaign?.branchDataToSend;
    if (branchData) {
      let campaign = JSON.parse(JSON.stringify(branchData));
      campaign._source = 'web';
      campaign._appVersion = '';
      HealAPI.updateUserCampaign(campaign).then((response) => {
        if (response.data?.data === true && response.ok) {
          dispatch(resetCampaignData());
        }
      });
    }
  };
}
