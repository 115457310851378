import moment from 'moment';
import 'moment-timezone';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { AccountState } from '../../appState/account/types';
import { CartState } from '../../appState/cart/types';
import { PatientDoctorRatingRequestParams } from '../../appState/rating/types';
import { Visit } from '../../appState/visit/types';
import { HealAPI } from '../../services/api';
import { FEATURES, IMAGES, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { css } from '../../utils/css';
import { formatTimeslot } from '../../utils/date';
import { grabInitials } from '../../utils/strings';
import { BottomSheet } from '../core/BottomSheet';
import { ButtonLink } from '../core/ButtonLink';
import { ContactHealButton } from '../core/ContactHealButton';
import { Identity } from '../core/Identity';
import { ButtonStyles, LegacyButton } from '../core/LegacyButton';
import CancelVisit from './CancelVisit';
import { RescheduleVisit } from './RescheduleVisit';
import styles from './VisitCard.module.scss';
import VisitFile from './VisitFile';

export interface VisitCardProps {
  account: AccountState;
  cart?: CartState;
  visit: Visit;
  isPDValid?: boolean;
  isBlueShieldUser?: boolean;
  submitRating?: (patientId: string, visitCode: string, data: PatientDoctorRatingRequestParams) => void;
  cancelVisitComplete?: () => void;
  rescheduleVisitComplete?: () => void;
  getVisits?: () => void;
  getCurrentStatus?: () => void;
  onBioClicked?: (visit: Visit) => void;
}

declare var healConfig: any;

const VisitCard: React.FC<VisitCardProps> = (props) => {
  const history = useHistory();

  const [showCancel, setShowCancel] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [visitWasCanceled, setVisitWasCanceled] = useState(false);
  const [visitWasReschedule, setVisitWasReschedule] = useState(false);

  const classNames = css(styles.container, {
    [styles.stateUpcoming]: props.visit.groupType === 'PRE_ACTIVE',
    [styles.stateActive]: props.visit.groupType === 'ACTIVE',
    [styles.stateCompleted]: props.visit.groupType === 'FINISHED_VISIT',
    [styles.stateCanceled]: props.visit.groupType === 'CANCELLED',
  });

  function formatTime(visit: Visit): string {
    return formatTimeslot(visit.timeSlot.startTime * 1000, (visit.timeSlot.startTime + visit.timeSlot.duration) * 1000, visit.timeSlot.timeZone);
  }

  function onRescheduleClick() {
    if (props.visit.canReschedule) {
      setShowCancel(false);
      setShowReschedule(true);
    } else {
      setShowCancel(true);
    }
  }

  function renderHeader() {
    const { visit } = props;
    let status: JSX.Element | string;
    const doctorsETA = visit.doctorExpectedArrival;
    let indicator = <div className={styles.indicator} data-tid="txt_visitStatus" />;

    if (visit.groupType === 'PRE_ACTIVE') {
      status = getString(ResourceKey.visitsStatusUpcoming);
    } else if (visit.groupType === 'ACTIVE') {
      status = getString(ResourceKey.visitsStatusActive);
    } else if (visit.groupType === 'FINISHED_VISIT') {
      status = getString(ResourceKey.visitsStatusCompleted);
      indicator = <img alt="" src={IMAGES.visitCompleted} style={{ verticalAlign: 'middle', marginRight: 4 }} width="10px" />;
    } else {
      status = getString(ResourceKey.visitsStatusCanceled);
      indicator = <img alt="" src={IMAGES.visitCanceled} style={{ verticalAlign: 'middle', marginRight: 4 }} width="8px" />;
    }
    const disableVisitETA = props.account.data?.features.includes(FEATURES.disableVisitETA);
    if (doctorsETA && !disableVisitETA) {
      return (
        <div className={styles.etaheader}>
          <div className={styles.etadate}>
            <div data-tid="txt_visitDate">{formatTime(visit)}</div>
            <div className={styles.etatime}>{doctorsETA}</div>
          </div>
          <div className={styles.etastatus}>
            {indicator}
            {status}
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.header}>
          <div className={styles.date} data-tid="txt_visitDate">
            {formatTime(visit)}
          </div>
          <div className={styles.status}>
            {indicator}
            {status}
          </div>
        </div>
      );
    }
  }

  function renderPatientDetails() {
    const { visit } = props;

    return (
      <div className={styles.patientDetails} data-tid="txt_patientDetails">
        {healConfig.env !== 'prd' && (
          <div data-tid="txt_visitCode" className={styles.visitCode}>
            {visit.code}
          </div>
        )}
        {visit.partnerLogoUrl && (
          <div>
            <img alt="" className={styles.partnerLogo} src={visit.partnerLogoUrl} />
          </div>
        )}
        <div className={styles.patientName} data-tid="txt_visitPatientName">
          {visit.patient.fullName}
        </div>
        <div data-tid="txt_visitServiceName">{visit.service.shortName}</div>
        <div data-tid="txt_visitAddress">{visit.address.address}</div>
        {visit.address.establishment && visit.isOnSite && <div data-tid="txt_visitEstablishment">{visit.address.establishment}</div>}
      </div>
    );
  }

  function renderFileUpload() {
    const { visit } = props;

    if (!visit.requiresFiles || !visit.files || visit.groupType !== 'PRE_ACTIVE') {
      return null;
    }

    if (visit.files.length > 0) {
      return (
        <div
          className={styles.fileUploadContainer}
          onClick={() => history.push(ROUTES.uploadPage.replace(':code', props.visit.code))}
          data-tid="btn_fileUpload_additional"
        >
          <div className={css(styles.imageContainer, styles.greenBorder)}>
            <img alt="" src={IMAGES.greenCheckmark} />
          </div>
          <div className={styles.title}>{getString(ResourceKey.visitsVaccinationRecordsOnFile)}</div>
          <div className={styles.cta}>{getString(ResourceKey.visitsTapToUploadMore)}</div>
        </div>
      );
    } else {
      return (
        <div
          className={styles.fileUploadContainer}
          onClick={() => history.push(ROUTES.uploadPage.replace(':code', props.visit.code))}
          style={{ borderColor: '#e8b129' }}
          data-tid="btn_fileUpload_required"
        >
          <div className={styles.imageContainer}>
            <img src={IMAGES.cameraAdd} alt="" />
          </div>
          <img src={IMAGES.alertYellow} style={{ float: 'left', marginTop: 4, marginRight: 4 }} alt="" />
          <div className={styles.title}>{getString(ResourceKey.visitsVaccinationRecordsRequired)}</div>
          <div className={styles.cta}>{getString(ResourceKey.visitsTapToUpload)}</div>
        </div>
      );
    }
  }

  function renderFiles() {
    const { visit, isBlueShieldUser } = props;

    if (visit.groupType !== 'FINISHED_VISIT') {
      return null;
    }

    return (
      <div>
        {visit.files.map((file) => (
          <VisitFile key={file.id} file={file} visit={visit} isBlueShieldUser={isBlueShieldUser} testId={`file_${visit.code}_${file.id}`} />
        ))}
      </div>
    );
  }

  function renderDoctorDetails() {
    const { visit } = props;

    if (visit.isOnSite || visit.groupType === 'CANCELLED') {
      return null;
    }

    if (visit.doctor?.providerId) {
      return (
        <div className={styles.doctorContainer}>
          <div>
            <div className={styles.doctorAvatar} onClick={() => props.onBioClicked && props.onBioClicked(visit)}>
              <Identity hideName initials={grabInitials(`${visit.doctor.firstName} ${visit.doctor.lastName}`)} size={48} url={visit.doctor.avatarUrl} />
            </div>
            <div className={styles.doctorDetails}>
              <div className={styles.doctorNameText}>{`${visit.doctor.firstName} ${visit.doctor.lastName}`}</div>
              {renderPDLabel()}
            </div>
          </div>
          {visit.canDoctorBecomePreferred &&
            !visit.isPreferredDoctorVisit &&
            visit.groupType !== 'PRE_ACTIVE' &&
            visit.groupType !== 'ACTIVE' &&
            props.getVisits && (
              <LegacyButton
                style={ButtonStyles.secondary}
                className={styles.setPdButton}
                text="Set as preferred doctor"
                onClick={() => {
                  if (props.visit.doctor?.providerId) {
                    HealAPI.setPreferredDoctor(props.visit.patient.id, props.visit.doctor.providerId).then(
                      (response) => {
                        props.getVisits!();
                        if (props.getCurrentStatus) {
                          props.getCurrentStatus();
                        }
                      },
                      (response) => {
                        // TODO Legacy: handle error scenario
                      }
                    );
                  }
                }}
                testId="btn_setPD"
              />
            )}
          {visit.isPreferredDoctorVisit && visit.groupType !== 'PRE_ACTIVE' && visit.groupType !== 'ACTIVE' && props.getVisits && (
            <LegacyButton
              style={ButtonStyles.primary}
              className={styles.setPdButton}
              text="Manage preferred doctor"
              onClick={() => history.push(ROUTES.patientSelectDoctor.replace(':id', visit.patient.id))}
              testId="btn_managePD"
            />
          )}
        </div>
      );
    }
    return null;
  }

  function renderPDLabel() {
    const { visit } = props;
    let label: JSX.Element | string | null = '';

    if (visit.groupType === 'PRE_ACTIVE' || visit.groupType === 'ACTIVE') {
      return <ButtonLink className={styles.readBio} text="Read bio" onClick={() => props.onBioClicked && props.onBioClicked(visit)} testId="btn_bioReadMore" />;
    } else {
      if (visit.isPreferredDoctorVisit) {
        if (props.isPDValid) {
          label = (
            <div>
              <img alt="" src={IMAGES.checkmark} className={styles.pdIcon} /> Preferred doctor
            </div>
          );
        } else {
          label = (
            <div>
              <img alt="" src={IMAGES.warning} className={styles.pdIcon} /> {`${visit.doctor?.firstName} ${visit.doctor?.lastName}`} is no longer available.
            </div>
          );
        }
      } else {
        if (visit.canDoctorBecomePreferred) {
          label = (
            <div>
              <img alt="" src={IMAGES.checkmarkGrey} className={styles.pdIcon} /> {`${visit.doctor?.firstName} ${visit.doctor?.lastName}`} is available to see
              you again.
            </div>
          );
        } else {
          label = `${`${visit.doctor?.firstName} ${visit.doctor?.lastName}`} is only available on - demand.`;
        }
      }
    }
    return <div className={styles.pdLabel}>{label}</div>;
  }

  function renderJoinVideoCall() {
    const { groupType, remoteMeetingUrl, timeSlot } = props.visit;

    const minutesUntilVisitStart = moment.unix(timeSlot.startTime).tz(timeSlot.timeZone).diff(moment(), 'minutes');
    const showButtonForVisit = groupType === 'ACTIVE' || groupType === 'PRE_ACTIVE';
    const disableButtonForVisit = groupType !== 'ACTIVE' && minutesUntilVisitStart >= 30;

    if (remoteMeetingUrl && showButtonForVisit) {
      return (
        <div className={styles.actionsContainer}>
          <a
            className={css(styles.successButton, disableButtonForVisit && styles.disabled)}
            href={remoteMeetingUrl}
            data-tid="btn_joinVideoCall"
            rel="noreferrer"
            target="_blank"
            aria-label="Join video call (opens in new tab)"
          >
            {getString(ResourceKey.visitsButtonJoinVideoCall)}
          </a>
        </div>
      );
    }

    return null;
  }

  function renderActions() {
    if (props.visit.groupType === 'PRE_ACTIVE') {
      return (
        <div className={styles.actionsContainer}>
          <ContactHealButton className={styles.action} />
          {props.visit.canReschedule && (
            <LegacyButton
              className={styles.secondaryButton}
              text={getString(ResourceKey.visitsButtonReschedule)}
              onClick={onRescheduleClick}
              testId="btn_rescheduleVisit"
            />
          )}
          <ButtonLink
            className={styles.linkAction}
            text={getString(ResourceKey.visitsButtonCancelVisit)}
            onClick={() => setShowCancel(true)}
            testId="btn_cancelVisit"
          />
        </div>
      );
    } else {
      return <div className={styles.actionsContainer}>{props.visit.groupType === 'ACTIVE' && <ContactHealButton className={styles.action} />}</div>;
    }
  }

  function renderWhatToExpect() {
    if (props.visit.groupType === 'ACTIVE' || props.visit.groupType === 'PRE_ACTIVE') {
      return (
        <div
          className={styles.whatToExpectContainer}
          onClick={() => history.push(ROUTES.visitsWhatToExpect.replace(':code', props.visit.code))}
          data-tid="btn_whatToExpect"
        >
          <div className={styles.image}>
            <img style={{ width: 100 }} alt="" src={IMAGES.whatToExpect} />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.title}>{getString(ResourceKey.visitsWhatToExpectTitle)}</div>
            <div className={styles.message}>{getString(ResourceKey.visitsWhatToExpectMessage)}</div>
            <ButtonLink
              className={styles.button}
              text={getString(ResourceKey.visitsButtonReadMore)}
              onClick={() => {
                /** bubbled to container */
              }}
              testId="btn_wteReadMore"
              ariaLabel="Read more about what to expect"
            />
          </div>
        </div>
      );
    }

    return null;
  }

  function renderCancelSheet() {
    const title = visitWasCanceled ? getString(ResourceKey.bottomSheetCancelVisitCanceledTitle) : getString(ResourceKey.bottomSheetCancelVisitTitle);
    return props.visit.canCancel ? (
      <BottomSheet
        title={title}
        testId={`${props.visit.code}_visitCanceledBottomSheet`}
        visible={showCancel}
        onClose={() => {
          setShowCancel(false);
          if (visitWasCanceled && props.cancelVisitComplete) {
            props.cancelVisitComplete();
          }
        }}
        showCloseButton
      >
        <CancelVisit visit={props.visit} onCancelComplete={() => setVisitWasCanceled(true)} onRescheduleClick={onRescheduleClick} />
      </BottomSheet>
    ) : null;
  }

  function renderRescheduleSheet() {
    const title = visitWasReschedule
      ? getString(ResourceKey.bottomSheetRescheduleVisitRescheduledTitle)
      : getString(ResourceKey.bottomSheetRescheduleVisitTitle);

    return props.visit.canReschedule ? (
      <BottomSheet
        testId={`${props.visit.code}_rescheduleBottomSheet`}
        title={title}
        visible={showReschedule}
        onClose={() => {
          setShowReschedule(false);
          if (visitWasReschedule) {
            if (props.rescheduleVisitComplete) {
              props.rescheduleVisitComplete();
            }
            setVisitWasReschedule(false);
          }
        }}
        showCloseButton
      >
        <RescheduleVisit visit={props.visit} onRescheduled={() => setVisitWasReschedule(true)} />
      </BottomSheet>
    ) : null;
  }

  return (
    <div className={classNames} data-tid="ctn_visit" data-vc={props.visit.code}>
      {renderHeader()}
      {renderPatientDetails()}
      {renderFileUpload()}
      {renderDoctorDetails()}
      {renderWhatToExpect()}
      {renderJoinVideoCall()}
      {renderActions()}
      {renderFiles()}
      {renderCancelSheet()}
      {renderRescheduleSheet()}
    </div>
  );
};

export default VisitCard;
