import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { deleteFile } from '../../appState/cart/actions';
import { Visit } from '../../appState/visit/types';
import { HealAPI } from '../../services/api';
import { IMAGES } from '../../services/constants';
import { FileUpload } from '../../types/Files';
import { css } from '../../utils/css';
import { filesAreEqual, mbToBytes, supportedFileTypes } from '../../utils/file';
import { BottomSheet } from '../core/BottomSheet';
import Button, { ButtonStyle } from '../core/Button';
import { Loading } from '../core/Loading';
import styles from './FileUploader.module.scss';

export const VACCINE_RECORDS_KEY = 'vaccines';

interface FileUploadProps {
  fileUploads: FileUpload[];
  setFileUploads: (fileUpload: FileUpload[]) => void;
  visit?: Visit;
  patientId?: string;
  documentType?: string;
  accept?: string;
  maxSizeMb?: number;
}

const FileUploader: React.FC<FileUploadProps> = ({ documentType = 'other', accept = 'image/jpeg, image/png, application/pdf', maxSizeMb = 25, ...props }) => {
  const dispatch = useDispatch();
  const [selectedFileUpload, setSelectedFileUpload] = useState<FileUpload>();

  /**
   * Store pending fileUpload updates & removals for propagation to parent on following render.
   * fileUploads.findIndex breaks as it references stale fileUploads array in logic within same render.
   * Need to propagate changes with useEffect on next render to guarantee updated array reference.
   */
  const [pendingUpdates, setPendingUpdates] = useState<FileUpload[]>([]);
  const [pendingRemovals, setPendingRemovals] = useState<FileUpload[]>([]);

  useEffect(() => {
    if (pendingUpdates.length) {
      const updatedFileUploads = [...props.fileUploads];

      pendingUpdates.forEach((fileUpload) => {
        const index = props.fileUploads.findIndex((f) => filesAreEqual(f.file, fileUpload.file));

        // Add new or replace existing element
        if (index === -1) {
          updatedFileUploads.push(fileUpload);
        } else {
          updatedFileUploads[index] = fileUpload;
        }
      });

      props.setFileUploads(updatedFileUploads);
      setPendingUpdates([]);
    }
  }, [pendingUpdates, props]);

  useEffect(() => {
    if (pendingRemovals.length) {
      const updatedFileUploads = [...props.fileUploads];

      pendingRemovals.forEach((fileUpload) => {
        const index = props.fileUploads.findIndex((f) => filesAreEqual(f.file, fileUpload.file));
        updatedFileUploads.splice(index, 1);
      });

      props.setFileUploads(updatedFileUploads);
      setPendingRemovals([]);
    }
  }, [pendingRemovals, props]);

  function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;

    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        file && handleFile(file);
      }
    }
  }

  // Load file data
  function handleFile(file: File) {
    // Prevent duplicate files
    if (props.fileUploads.some((fileToUpload) => filesAreEqual(fileToUpload.file, file))) {
      return;
    }

    const fileReader = new FileReader();

    fileReader.onloadend = async () => {
      const fileUpload = await createFileUpload(file, fileReader.result);
      setPendingUpdates([...pendingUpdates, fileUpload]);
      uploadFile(fileUpload);
    };

    // Read & encode base64 data, triggers 'onloadend' when complete
    fileReader.readAsDataURL(file);
  }

  // Generate file upload object with signed upload URL
  async function createFileUpload(file: File, fileData: string | ArrayBuffer | null) {
    const fileUpload: FileUpload = {
      file,
      fileData,
      status: 'uploading',
      visitCode: props.visit?.code,
      patientId: props.patientId || props.visit?.patient.id,
    };

    if (!fileUpload.patientId) {
      throw new Error('Could not request signed upload URL: FileUploadObject is missing patientId');
    }

    const createFileResponse = await HealAPI.createFile(fileUpload.patientId, { contentType: fileUpload.file.type, documentType });

    if (!createFileResponse.data?.data?.uploadUrl) {
      fileUpload.status = 'failure';
      throw new Error('Could not upload file: Request for signed upload URL failed');
    }

    fileUpload.createFileResponse = createFileResponse.data.data;
    return fileUpload;
  }

  // Upload file upload object to signed upload URL
  async function uploadFile(fileUpload: FileUpload) {
    const pendingFileUpload = { ...fileUpload };

    if (pendingFileUpload.createFileResponse?.uploadUrl) {
      pendingFileUpload.status = 'uploading';
      pendingFileUpload.uploadStarted = new Date();

      const uploadFileResponse = await HealAPI.uploadFile(pendingFileUpload.createFileResponse.uploadUrl, pendingFileUpload.file);
      pendingFileUpload.status = uploadFileResponse.ok ? 'success' : 'failure';
      setPendingUpdates([...pendingUpdates, pendingFileUpload]);
    }
  }

  function renderBottomSheet() {
    return (
      <BottomSheet showCloseButton={false} visible onClose={() => setSelectedFileUpload(undefined)}>
        <div className={styles.bottomSheetContent}>
          {selectedFileUpload?.status === 'failure' && (
            <Button
              className={styles.bottomSheetButton}
              onClick={async () => {
                uploadFile(await createFileUpload(selectedFileUpload.file, selectedFileUpload.fileData));
                setSelectedFileUpload(undefined);
              }}
              text="Retry"
              testId="btn_retry"
            />
          )}

          <Button
            className={styles.bottomSheetButton}
            onClick={async () => {
              if (selectedFileUpload) {
                if (selectedFileUpload.createFileResponse && selectedFileUpload.patientId) {
                  dispatch(deleteFile(selectedFileUpload.patientId, selectedFileUpload.createFileResponse.id));
                }

                setPendingRemovals([selectedFileUpload, ...pendingRemovals]);
                setSelectedFileUpload(undefined);
              }
            }}
            text="Remove"
            testId="btn_delete"
          />

          <Button
            buttonStyle={ButtonStyle.SECONDARY}
            className={css(styles.bottomSheetButton, styles.bottomSheetSecondaryButton)}
            onClick={() => setSelectedFileUpload(undefined)}
            text="Cancel"
            testId="btn_cancel"
          />
        </div>
      </BottomSheet>
    );
  }

  return (
    <>
      <Dropzone accept={accept} onDrop={(files) => files.forEach(handleFile)} maxSize={mbToBytes(maxSizeMb)}>
        {({ getRootProps, isDragActive }) => {
          return (
            <div className={styles.dragContainer} {...getRootProps()}>
              {isDragActive && <div className={styles.dragOverlay} />}

              <div className={styles.fileUploadBox}>
                <div className={styles.title}>Drag files here</div>
                <div className={styles.or}>Or</div>

                <input
                  type="file"
                  id="selectFiles"
                  accept={accept}
                  multiple
                  onChange={onFileChange}
                  data-tid="btn_selectFiles"
                  aria-label="Select files (opens file selection dialog)"
                />

                <label htmlFor="selectFiles" className={styles.button}>
                  Select files
                </label>

                {accept && (
                  <div className={styles.fileTypes}>
                    <strong>Supported types:</strong> {supportedFileTypes(accept).join(', ')}
                  </div>
                )}

                <div className={styles.fileSizeLimit}>Up to {maxSizeMb} MB each</div>
              </div>

              {props.fileUploads.length > 0 && (
                <div className={styles.filesContainer}>
                  {props.fileUploads.map((fileUpload) => {
                    const key = (fileUpload.file.name + '_' + fileUpload.file.size + '_' + fileUpload.file.lastModified).replaceAll(' ', '_');

                    return (
                      <Button
                        text={key}
                        buttonStyle={ButtonStyle.NONE}
                        className={styles.fileThumbnail}
                        style={{ backgroundImage: (('url(' + fileUpload.fileData) as string) + ')' }}
                        key={key}
                        onClick={() => setSelectedFileUpload(fileUpload)}
                        data-tid={`btn_fileUpload_${fileUpload.status}_${key}`}
                      >
                        {fileUpload.status === 'uploading' && <Loading className={styles.loading} />}
                        {fileUpload.status === 'success' && <img className={styles.success} src={IMAGES.checkmark} alt="" />}
                        {fileUpload.status === 'failure' && <img className={styles.error} src={IMAGES.alertWhite} alt="" />}
                      </Button>
                    );
                  })}
                </div>
              )}
            </div>
          );
        }}
      </Dropzone>

      {selectedFileUpload && renderBottomSheet()}
    </>
  );
};

export default FileUploader;
