import * as React from 'react';
import styles from './Box.module.scss';

export interface BoxProps {}

export class Box extends React.Component<BoxProps, object> {
  public render(): JSX.Element {
    return <div className={styles.box}>{this.props.children}</div>;
  }
}
