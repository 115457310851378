import { AccessToken, UserClaims } from '@okta/okta-auth-js';
import { Dispatch } from 'redux';
import { resetCampaignData, sendCampaignData, setCampaign } from '../../appState/campaign/actions';
import { RootState } from '../../appState/rootState';
import { setFontSize } from '../../appState/userPreferences/actions';
import { HealAPI } from '../../services/api';
import { oktaAuth, oktaConfig } from '../../services/auth';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { removeHealUserCookie, setHealUserCookie } from '../../utils/cookies';
import { resetCart } from '../cart/actions';
import { CurrentStatus } from '../status/actions';
import { persistor } from '../store';
import { PatientCreateUserRequest } from './types';

declare var healConfig: any;

export enum AuthActions {
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAIL = 'LOGIN_FAIL',

  CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST',
  CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAIL = 'CREATE_ACCOUNT_FAIL',

  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',

  CLEAR_ERROR = 'AUTH_CLEAR_ERROR',

  SET_LOCAL_USERNAME = 'SET_LOCAL_USERNAME',
}

export function setLocalUsername(username: string) {
  return {
    data: username,
    type: AuthActions.SET_LOCAL_USERNAME,
  };
}

export function samlCallbackLogin(accessTokenValue: string) {
  const expiresAt = Math.floor(Date.now() / 1000) + 3600;
  const scopes = ['openid', 'email', 'profile'];

  const claims: UserClaims = {
    sub: '00unddugvs8yqf1Ko0h7',
  };

  const accessToken: AccessToken = {
    scopes,
    expiresAt,
    accessToken: accessTokenValue,
    claims,
    authorizeUrl: `${healConfig.oktaIssuer}/v1/authorize`,
    tokenType: 'accessToken',
    userinfoUrl: `${healConfig.oktaIssuer}v1/userinfo`,
  };

  oktaAuth.tokenManager.add('accessToken', accessToken);
  return getAccount();
}

export function samlLogin(username: string, password: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: AuthActions.LOGIN_REQUEST });
    signInToOkta(dispatch, username, password);
  };
}

export function getAccount() {
  return (dispatch: any) => {
    dispatch({ type: CurrentStatus.GET_CURRENT_STATUS_REQUEST });

    HealAPI.getCurrentStatus().then((response) => {
      if (response.ok && response.data?.data) {
        const account = response.data.data;
        setHealUserCookie(account.fullName, account.avatarUrl);

        dispatch({ type: CurrentStatus.GET_CURRENT_STATUS_SUCCESS, data: account });

        dispatch({
          type: AuthActions.LOGIN_SUCCESS,
          data: response.data,
        });
        dispatch(sendCampaignData());
      } else {
        dispatch({
          type: AuthActions.LOGIN_FAIL,
          data: 'GENERIC_ERROR',
        });
        dispatch(logout());
      }
    });
  };
}

export function logout() {
  return (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: AuthActions.LOGOUT_REQUEST });

    /* https://github.com/okta/okta-oidc-js/issues/753
     * oktaAuth.signOut(), specifically revokeAccessToken() is broken
     *  if App is configured incorrectly. Maybe setup a new Okta app?
     */

    oktaAuth
      .signOut({
        revokeAccessToken: false, // https://github.com/okta/okta-auth-js/issues/496
      })
      .finally(() => {
        HealAPI.closeOktaSessionViaBackend();
        dispatch(resetCart());
        const { userPreferences } = getState();
        removeHealUserCookie();
        persistor.purge().then(() => {
          dispatch(setFontSize(userPreferences.baseFontSize));
        });

        dispatch({ type: AuthActions.LOGOUT_SUCCESS });
      })
      .catch((e: any) => {
        /*do nothing*/
      });
  };
}

export function logoutAgent() {
  return (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: AuthActions.LOGOUT_REQUEST });

    oktaAuth
      .signOut({
        revokeAccessToken: false, // https://github.com/okta/okta-auth-js/issues/496
        postLogoutRedirectUri: window.location.origin + '/login/agent',
      })
      .finally(() => {
        HealAPI.closeOktaSessionViaBackend();
        const { userPreferences, campaign } = getState();
        removeHealUserCookie();
        persistor.purge().then(() => {
          dispatch(setFontSize(userPreferences.baseFontSize));
          dispatch(setCampaign(campaign));
        });

        dispatch({ type: AuthActions.LOGOUT_SUCCESS });
      })
      .catch((error) => {
        /*do nothing*/
      });
  };
}

export function createAccount(data: PatientCreateUserRequest) {
  return (dispatch: Dispatch) => {
    dispatch({ type: AuthActions.CREATE_ACCOUNT_REQUEST, data });
    HealAPI.createAccount(data).then(
      (response) => {
        if (response.ok && response.data) {
          // If Branch campaign data is present it will be passed with account registration data on createAccount.
          // Reset campaign on createAccount success.
          dispatch(resetCampaignData());

          dispatch({
            type: AuthActions.CREATE_ACCOUNT_SUCCESS,
            data: response.data,
          });

          signInToOkta(dispatch, data.username, data.password);
        } else {
          if (response.data) {
            dispatch({
              type: AuthActions.CREATE_ACCOUNT_FAIL,
              data: response.data,
            });
          } else {
            dispatch({
              type: AuthActions.CREATE_ACCOUNT_FAIL,
              data: 'GENERIC_ERROR',
            });
          }
        }
      },
      (response) => {
        dispatch({
          type: AuthActions.CREATE_ACCOUNT_FAIL,
          data: response.data,
        });
      }
    );
  };
}

export function clearError() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AuthActions.CLEAR_ERROR,
      data: null,
    });
  };
}

function signInToOkta(dispatch: Dispatch, username: string, password: string) {
  oktaAuth
    .signInWithCredentials({
      username,
      password,
    })
    .then((transaction) => {
      if (transaction.status === 'SUCCESS') {
        oktaAuth.token.getWithRedirect({ ...oktaConfig, sessionToken: transaction.sessionToken }).catch((error) => {
          dispatch({
            type: AuthActions.LOGIN_FAIL,
            data: error.message,
          });
          oktaAuth.signOut().catch((error) => {
            /*do nothing*/
          });
        });
      } else {
        dispatch({
          type: AuthActions.LOGIN_FAIL,
          data: getString(ResourceKey.errorAutoLogin),
        });
      }
    })
    .catch(function (err) {
      console.error(err);
    });
}
