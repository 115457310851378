import * as React from 'react';
import Geosuggest, { Suggest as GeosuggestResult } from 'react-geosuggest';
import { Address, AddressType } from '../../appState/address/types';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import Input from '../core/Input';
import TextArea from '../core/TextArea';
import styles from './AddressEditor.module.scss';

export interface Suggest {
  streetAddress: string;
  latitude: number;
  longitude: number;
  street: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  establishment?: string;
}

/**
 * The original implementation of AddressForm shoehorned google.maps.places.PlaceResult into google.maps.GeocoderResult.
 * This works because the data returned by GeocoderResult is a subset of that returned by PlaceResult.
 * However, if you want conditional access to the non-overlapping parts of PlaceResult, you need to define it.
 * 
 * See the "Caution" annotation at:
 * https://developers.google.com/maps/documentation/javascript/places#place_search_fields
 */
 type GeocoderResultOrPlaceDetail = google.maps.GeocoderResult & Partial<Pick<google.maps.places.PlaceResult, "name" | "adr_address">>;

export interface AddressEditorProps {
  address: Address;
  onChange: (address: Address) => void;
  onAllowSubmitChange: (allowSubmit: boolean) => void;
}

export interface AddressEditorState {
  instructions?: string;
  unit?: string;
  type?: AddressType;
  suggest?: Suggest;
  lastValidAddress?: string;
  addressInputValue?: string;
  initialAddressInputValue?: string;
}

const COMPONENT_TYPES = {
  city: ['locality', 'administrative_area_level_3', 'sublocality_level_1'],
  state: ['administrative_area_level_1'],
  country: ['country'],
  zipCode: ['postal_code'],
  postal_code: ['postal_code'],
};

export class AddressEditor extends React.Component<AddressEditorProps, AddressEditorState> {
  private geosuggest: Geosuggest | null | undefined;

  private mounted: boolean = false;

  constructor(props: AddressEditorProps) {
    super(props);

    this.state = {
      instructions: props.address.instructions,
      unit: props.address.unit,
      type: props.address.addressType || AddressType.HOME,
      initialAddressInputValue: this.formatAddress(this.props.address),
      lastValidAddress: this.formatAddress(this.props.address),
    };
  }

  public componentDidMount() {
    if (this.geosuggest) {
      if (this.props.address.streetAddress) {
        (this.geosuggest as any).selectSuggest();
      } else {
        this.geosuggest.focus();
      }
    }

    this.mounted = true;
  }

  public componentDidUpdate(prevProps: AddressEditorProps) {
    if (prevProps.address !== this.props.address) {
      this.setState({ instructions: this.props.address.instructions, unit: this.props.address.unit, type: this.props.address.addressType });
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): JSX.Element {
    return (
      <div className={styles.container}>
        <div className="form-group required">
          <label htmlFor="geosuggest__input" className="form-label">
            {getString(ResourceKey.bookLocationAddressPlaceholder)}
          </label>
          <div className={styles.suggestContainer} data-tid="inp_address">
            <Geosuggest
              aria-label="address"
              country="US"
              autoActivateFirstSuggest
              ref={(geosuggest) => {
                this.geosuggest = geosuggest;
              }}
              placeholder=""
              onSuggestSelect={this.onSuggestSelect}
              initialValue={this.getInitialAddress()}
              onChange={this.handleAddressInputChange}
              autoComplete="on"
            />
            <button aria-label="Clear address field" className={styles.clearButton} onClick={this.onClearClick} data-tid="btn_clearField">
              <Close />
            </button>
          </div>
        </div>
        <Input
          id="unit"
          name="unit"
          label={getString(ResourceKey.bookLocationUnitPlaceholder)}
          autoComplete="address-line2"
          value={this.state.unit ?? ''}
          onChange={this.onChange}
          testId="inp_unit"
          maxLength={20}
          className={styles.inputContainer}
        />
        <TextArea
          id="instructions"
          name="instructions"
          label={getString(ResourceKey.bookLocationEntryInstructionsPlaceholder)}
          value={this.state.instructions ?? ''}
          onChange={this.onChange}
          testId="inp_instructions"
          maxLength={200}
          className={styles.inputContainer}
        />
      </div>
    );
  }

  private onClearClick = () => {
    if (this.geosuggest) {
      this.geosuggest.clear();
      setTimeout(
        () => {
          if (this.geosuggest) {
            this.geosuggest.focus();
          }
        },
        // geosuggest hides suggest dropdown on blur for 100 ms, so we refocus after that
        120
      );
    }
    this.setState({ suggest: {} as Suggest }, () => {
      this.fireChange();
    });
  };

  private onChange = (event: any) => {
    const value: string = event.target.value;
    const name: string = event.target.name;
    this.setState({ [name]: value }, () => {
      this.fireChange();
    });
  };

  /**
   * Update state and validation in the parent (`AddLocation.tsx`) in response to changes to the Geosuggest input.
   * @param value A string containing the updated value of the Geosuggest input.
   */
  private handleAddressInputChange = (value: string) => {
    this.setState({ addressInputValue: value });
    this.updateAllowSubmit(value, this.state.lastValidAddress, this.state.initialAddressInputValue);
  }

  /**
   * Delegating some responsibility for enabling/disabling the Save buton to AddressEditor.
   * This is because AddLocation houses the button, but is only passed an onChange when a suggestion is selected.
   * updateAllowSubmit creates an additional pathway by which to update the Save status more frequently, namely
   * whenever the user changes the content of the address field.
   * @param addressValue - An analog of this.state.addressInputValue, passed as an argument so that it can be overridden
   * @param lastSuggest - An analog of this.state.lastValidAddress, passed as an argument so that it can be overridden
   * @param initial - A copy of this.state.initialAddressInputValue
   * @see `onAllowSubmitChange` in AddLocation.tsx
   */
  private updateAllowSubmit = (addressValue: string, lastSuggest: string | undefined, initial: string | undefined) => {
    const addressMatchesLastSuggest = lastSuggest === addressValue;
    const addressDiffersFromInitial = initial !== addressValue;

    this.props.onAllowSubmitChange(addressMatchesLastSuggest && addressDiffersFromInitial);
  }

  private parseGooglePlace = (place: GeocoderResultOrPlaceDetail) => {
    const suggest = {} as Suggest;

    if (place && typeof place === 'object') {
      const address = place.formatted_address;

      suggest.latitude = place.geometry.location.lat();
      suggest.longitude = place.geometry.location.lng();

      const adrString = place.adr_address;

      if (adrString) {
        // Replace the code below with a RegEx match as shown once lookbehind assertions are supported in Safari.
        // newSuggest.streetAddress = htmlString.match(/(?<=street-address">)[^<]*/)?.[0] ?? '';
        const searchTerm = "street-address\">";
        const tagLocation = adrString.indexOf(searchTerm);
        if (tagLocation > -1) {
          suggest.streetAddress = adrString.substring(tagLocation + searchTerm.length, adrString.indexOf("<", tagLocation));
        }

        // If the place name we were given has a name that doesn't match the street address, it is likely an establishment name.
        // For non-establishment results (e.g., a home address), `name` generally mirrors the street address.
        if (place.name !== suggest.streetAddress) {
          suggest.establishment = place.name;
        }
      }

      // If we can't find the street address using the method above, fall back to grabbing the full address up to the first comma.
      // This may get the wrong segment if the street address itself contains a comma, or a business name is included in the address.
      // For this reason, the comma method is a last resort. Ultimately, if this messes up, the user can just retype their address.
      suggest.streetAddress ??= address.match(/^[^,]+/)?.[0] ?? '';

      place.address_components.forEach((component: any) => {
        const value = component.long_name;

        if (component.types.some((type: string) => COMPONENT_TYPES.city.indexOf(type) > -1)) {
          suggest.city = value;
        } else if (component.types.some((type: string) => COMPONENT_TYPES.state.indexOf(type) > -1)) {
          suggest.state = component.short_name; // 2 letter name
        } else if (component.types.some((type: string) => COMPONENT_TYPES.country.indexOf(type) > -1)) {
          suggest.country = value;
        } else if (component.types.some((type: string) => COMPONENT_TYPES.postal_code.indexOf(type) > -1)) {
          suggest.zipcode = value;
        }
      });

      return suggest;
    }

    return undefined;
  }

  /**
   * Pass data about a suggestion to the parent (`AddLocation.tsx`)
   * Data passed in this way is interpreted by the parent to be a newly selected suggestion.
   * @param suggest An instance of Suggest in the format returned by parseGooglePlace
   */
  private commitSuggest = (suggest: Suggest) => {
    if (this.mounted) {
      this.setState({ suggest }, () => {
        this.fireChange();
      });
    }
  }

  private onSuggestSelect = (selectedSuggest: GeosuggestResult) => {
    if (!selectedSuggest) {
      return;
    }

    const suggestHasStreetNumber = selectedSuggest.gmaps?.address_components.some((component: google.maps.GeocoderAddressComponent) =>
      component.types.includes('street_number')
    );

    if (suggestHasStreetNumber && !!selectedSuggest.gmaps) {
      const suggest = this.parseGooglePlace(selectedSuggest.gmaps);

      if (suggest) {
        this.commitSuggest(suggest);

        const formattedAddress = this.formatAddress(suggest);

        // Update the address input field.
        this.geosuggest?.update(formattedAddress);
        this.setState({ lastValidAddress: formattedAddress });

        // Update the allowSubmit boolean in the parent.
        this.updateAllowSubmit(formattedAddress, formattedAddress, this.state.initialAddressInputValue);
      }

      return;
    } else {
      // If the address doesn't have a street number or the data is malformed, blank the address field.
      // This deviates from the behavior in the partner app. Since the patient app is on its way out,
      // the additional functionality in the partner app of requerying the Places API for nearby street
      // addresses was deemed not worth porting over.
      this.onClearClick();
    }
  };

  /**
   * Convert Address or Suggest data to a string of a standardized format.
   * Address is typically obtained from the API and passed via props, while Suggest is generated in this component and is usually obtained from state.
   * Both types contain the required fields to 
   * @param address An Address or a Suggest containing address data.
   * @returns A string containing the Address or Suggest address in a standardized format, or an empty string if any keys are missing.
   */
  private formatAddress(address?: Address | Suggest) {
    const currentAddressValues = [address?.streetAddress, address?.city, address?.state, address?.zipcode, address?.country];

    // If any of the fields required to assemble the address is undefined or empty (''), return nothing.
    // This will most often occur in practice when the API returns the address as a "Plus Code".
    if (!address || currentAddressValues.some(v => v === undefined || v === '')) return '';

    return `${address?.streetAddress}, ${address?.city}, ${address?.state} ${address?.zipcode}, ${address?.country}`;
  }

  private getInitialAddress(): string {
    return this.formatAddress(this.props.address);
  }

  private fireChange() {
    if (this.props.onChange) {
      this.props.onChange({
        ...this.props.address,
        address: undefined, // Remove the full address string from the saved data (see APP-8711).
        streetAddress: (this.state.suggest && this.state.suggest.streetAddress) || '',
        city: (this.state.suggest && this.state.suggest.city) || '',
        country: (this.state.suggest && this.state.suggest.country) || '',
        zipcode: (this.state.suggest && this.state.suggest.zipcode) || '',
        latitude: (this.state.suggest && this.state.suggest.latitude) || 0,
        longitude: (this.state.suggest && this.state.suggest.longitude) || 0,
        state: (this.state.suggest && this.state.suggest.state) || '',
        unit: this.state.unit || '',
        instructions: this.state.instructions || '',
        addressType: this.state.type?.toUpperCase() as AddressType || 'HOME',
        establishment: (this.state.suggest && this.state.suggest.establishment) || '',
      });
    }
  }
}

const Close = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 -2 40 40">
      <g fill="none" fillRule="evenodd" stroke="#545759" strokeLinecap="round" strokeWidth="2">
        <path d="M29.799 28.532L10 9M29.799 9L10 28.532" />
      </g>
    </svg>
  );
};
