import { CurrentStatus } from '../status/actions';
import { Account, AccountState } from './types';

export const accountInitialState: AccountState = {
  isFetching: false,
  error: undefined,
  data: undefined,
};

function handleCurrentStatusRequest(state: AccountState): AccountState {
  return {
    isFetching: true,
    error: undefined,
    data: undefined,
  };
}

function handleCurrentStatusSuccess(state: AccountState, data: Account): AccountState {
  return {
    isFetching: false,
    error: undefined,
    data,
  };
}

export function accountReducer(state: AccountState = accountInitialState, action: any): AccountState {
  switch (action.type) {
    case CurrentStatus.GET_CURRENT_STATUS_REQUEST:
      return handleCurrentStatusRequest(state);
    case CurrentStatus.GET_CURRENT_STATUS_SUCCESS:
      return handleCurrentStatusSuccess(state, action.data);
    default:
      return state;
  }
}
