import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../appState/auth/actions';
import { Loading } from '../../components/core/Loading';

export const LogoutPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return <Loading padding={30} />;
};
