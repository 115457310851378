import { WhatToExpectActions } from '../../appState/whatToExpect/actions';
import { WhatToExpectPageItem, WhatToExpectState } from '../../appState/whatToExpect/types';

export const wteInitialState: WhatToExpectState = {
  isFetching: false,
  data: undefined,
  error: undefined,
};

function handleSubmitWhatToExpectRequest(state: WhatToExpectState): WhatToExpectState {
  return {
    isFetching: true,
    data: undefined,
    error: undefined,
  };
}

function handleSubmitWhatToExpectSuccess(state: WhatToExpectState, data: WhatToExpectPageItem[]): WhatToExpectState {
  return {
    isFetching: false,
    data,
    error: undefined,
  };
}

function handleSubmitWhatToExpectFail(state: WhatToExpectState, error: string): WhatToExpectState {
  return {
    isFetching: false,
    data: undefined,
    error,
  };
}

export function whatToExpectReducer(state: WhatToExpectState = wteInitialState, action: any): WhatToExpectState {
  switch (action.type) {
    case WhatToExpectActions.WHAT_TO_EXPECT_REQUEST:
      return handleSubmitWhatToExpectRequest(state);
    case WhatToExpectActions.WHAT_TO_EXPECT_SUCCESS:
      return handleSubmitWhatToExpectSuccess(state, action.data);
    case WhatToExpectActions.WHAT_TO_EXPECT_FAILURE:
      return handleSubmitWhatToExpectFail(state, action.data);
    default:
      return state;
  }
}
