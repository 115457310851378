import { Dispatch } from 'redux';
import { AgreementsActions } from '../../appState/agreements/actions';
import { CartActions, removeFile } from '../../appState/cart/actions';
import { showBottomSheet } from '../../appState/notification/actions';
import { OnsiteActions } from '../../appState/onsite/actions';
import { RootState } from '../../appState/rootState';
import { HealAPI } from '../../services/api';
import { analyticsLogEvent } from '../../services/firebase';
import { getVisit } from '../thankYou/actions';
import { resetTimeSlots } from '../timeSlots';
import { CreateVisitRequest } from './types';

export enum CheckoutActions {
  SAVE_VISIT_REQUEST = 'SAVE_VISIT_REQUEST',
  SAVE_VISIT_SUCCESS = 'SAVE_VISIT_SUCCESS',
  SAVE_VISIT_FAIL = 'SAVE_VISIT_FAIL',
  RESET_ERROR = 'RESET_ERROR',
  RESET_CHECKOUT = 'RESET_CHECKOUT',
}

export function resetError() {
  return (dispatch: Dispatch) => {
    dispatch({ type: CheckoutActions.RESET_ERROR });
  };
}

export function saveVisit(data: CreateVisitRequest) {
  return (dispatch: any, getState: () => RootState) => {
    dispatch({ type: CheckoutActions.SAVE_VISIT_REQUEST });
    HealAPI.saveVisit(data).then(
      (response) => {
        if (response.ok) {
          const visit = response.data?.data;

          if (visit) {
            getState().cart.filesPendingVisitCodeAssociation.forEach((fileId, index, array) => {
              // Visit page may be rendered before patchFiles completes, refetch to avoid vaccine records status race condition
              const shouldRefetchVisit = !visit.files.length && index === array.length - 1;
              dispatch(patchFileToVisit(fileId, data.patientId, visit.code, 3, shouldRefetchVisit));
            });

            dispatch({
              type: CheckoutActions.SAVE_VISIT_SUCCESS,
              data: visit,
            });

            dispatch({ type: CartActions.RESET });
            dispatch({ type: AgreementsActions.RESET_AGREEMENTS });
            dispatch({ type: OnsiteActions.CLEAR_ONSITE });
            dispatch(resetTimeSlots());

            analyticsLogEvent('purchase', {
              currency: visit.currencyCode,
              transaction_id: visit.code,
              value: visit.chargeAmount,
            });
          }
        } else {
          const errorMessage = response.data?.description;
          dispatch(showBottomSheet('Error', errorMessage, true));
          dispatch({ type: CheckoutActions.SAVE_VISIT_FAIL, data: errorMessage });
        }
      },
      (response) => {
        dispatch({
          type: CheckoutActions.SAVE_VISIT_FAIL,
          data: response.data,
        });
      }
    );
  };
}

export function patchFileToVisit(file: string, patientId: string, visitCode: string, retries: number = 3, refetchVisit?: boolean) {
  return (dispatch: any) => {
    HealAPI.patchFile(patientId, file, { visitCode }).then((response) => {
      if (response.ok) {
        refetchVisit && dispatch(getVisit(visitCode));
      } else if (retries > 0) {
        patchFileToVisit(file, patientId, visitCode, retries - 1);
      } else {
        dispatch(removeFile(file));
      }
    });
  };
}

export function resetCheckout() {
  return {
    type: CheckoutActions.RESET_CHECKOUT,
  };
}
