import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Slider from 'react-slick';
import { AddressState } from '../../appState/address/types';
import { CartState, Location, PatientIdentity } from '../../appState/cart/types';
import { PatientState, PreferredDoctor } from '../../appState/patient/types';
import { AvailablePreferredDoctorsState } from '../../appState/preferredDoctor/available/types';
import { DoctorCard } from '../../components/healDoctor/DoctorCard';
import { HealAPI } from '../../services/api';
import { IMAGES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { BottomSheet } from '../core/BottomSheet';
import { ButtonStyles, LegacyButton } from '../core/LegacyButton';
import { Loading } from '../core/Loading';
import Page from '../core/Page';
import styles from './HealDoctors.module.scss';

interface DispatchActions {
  actions: {
    getAvailablePreferredDoctors: (patientId: string) => void;
    resetCart: () => void;
    setLocation: (location: Location) => void;
    setPatient: (patient: PatientIdentity) => void;
    getCurrentStatus: () => void;
  };
}

export interface HealDoctorsProps extends DispatchActions, RouteComponentProps<{ id: string }> {
  healDoctors: AvailablePreferredDoctorsState;
  patientList: PatientState;
  addressList: AddressState;
  cart?: CartState;
}

export interface HealDoctorsState {
  healDoctors: PreferredDoctor[];
  selectedIndex: number;
  patientId: string;
  buttonText: string;
  removingPreferredDoctor: boolean;
  changingToPreferredDoctor?: string;
}

export class HealDoctors extends React.Component<HealDoctorsProps, HealDoctorsState> {
  constructor(props: HealDoctorsProps) {
    super(props);
    this.state = {
      healDoctors: [],
      selectedIndex: 0,
      patientId: '',
      buttonText: getString(ResourceKey.saveYourPreferredDoctor),
      removingPreferredDoctor: false,
    };
  }

  public componentDidMount() {
    if (this.props.match.params && this.props.match.params.id) {
      this.setState({
        patientId: this.props.match.params.id,
      });

      if (!this.props.patientList.data) {
        this.props.actions.getCurrentStatus();
      } else {
        this.props.actions.getAvailablePreferredDoctors(this.props.match.params.id);
      }
    }
  }

  public componentDidUpdate(prevProps: HealDoctorsProps) {
    if (prevProps.patientList.isFetching && !this.props.patientList.isFetching) {
      this.props.actions.getAvailablePreferredDoctors(this.props.match.params.id);
    }

    if (prevProps.healDoctors.isFetching && !this.props.healDoctors.isFetching) {
      this.setState({ selectedIndex: 0 });

      if (this.props.patientList.data && this.props.healDoctors.data && this.props.healDoctors.data.data?.availableProviders) {
        let doctors: PreferredDoctor[] = [];
        const patient = this.props.patientList.data[this.state.patientId];

        if (patient.preferredDoctor) {
          doctors = [patient.preferredDoctor.doctor];
        }

        doctors = doctors.concat(this.props.healDoctors.data.data.availableProviders);
        this.setState({ healDoctors: doctors });
      }
    }
  }

  public render(): JSX.Element {
    return (
      <Page title={getString(ResourceKey.preferredDoctorTitle)} showBackButton>
        <div className={styles.container}>{this.renderDoctorSlider()}</div>
        {this.renderRemoveConfirmationBottomSheet()}
        {this.renderChangeConfirmationBottomSheet()}
      </Page>
    );
  }

  private renderDoctorSlider(): JSX.Element {
    if (
      this.props.patientList.isFetching ||
      !this.props.patientList ||
      !this.props.patientList.data ||
      this.props.healDoctors.isFetching ||
      this.props.addressList.isFetching
    ) {
      return <Loading padding={60} />;
    }

    if (this.state.healDoctors.length === 0) {
      const patient = this.props.patientList?.data?.[this.state.patientId];
      return (
        <div className={styles.noDoctors}>
          <b>We are sorry.</b>
          <div>
            {`There are no preferred doctors available at this time for ${patient?.firstName}. 
            Please book on-demand house call with a caring, qualified doctor.`}
          </div>
        </div>
      );
    }

    const settings = {
      dots: this.hasMoreThanOnePCP(),
      infinite: false,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: this.state.selectedIndex,
      nextArrow: this.canNext() ? <Arrow alt={getString(ResourceKey.rightAlt)} className={styles.rightArrow} image={IMAGES.arrowRight} /> : null,
      prevArrow: this.canPrev() ? <Arrow alt={getString(ResourceKey.leftAlt)} className={styles.leftArrow} image={IMAGES.arrowLeft} /> : null,
      adaptiveHeight: false,
      afterChange: this.onChange,
    };

    return (
      <div className={styles.sliderContainer}>
        <Slider {...settings}>{this.renderDoctors()}</Slider>
      </div>
    );
  }

  private canNext(): boolean {
    return !!this.props.healDoctors.data && this.state.selectedIndex !== this.state.healDoctors.length - 1;
  }

  private canPrev(): boolean {
    return this.state.selectedIndex !== 0;
  }

  private hasMoreThanOnePCP(): boolean {
    return !!this.props.healDoctors.data && this.state.healDoctors.length > 1;
  }

  private renderDoctors(): JSX.Element[] | null {
    if (this.state.healDoctors) {
      return this.state.healDoctors.map((doctor: PreferredDoctor, index: number) => {
        const patient = this.props.patientList.data![this.state.patientId];

        return (
          <div key={index}>
            <DoctorCard
              doctor={doctor}
              isPreferredDoctor={this.isDoctorPatientPD(doctor.providerId)}
              stillValid={patient.preferredDoctor && patient.preferredDoctor.stillValid}
              setPreferredDoctor={this.setPreferredDoctor}
            />
          </div>
        );
      });
    }

    return null;
  }

  private isDoctorPatientPD(doctorId: string): boolean {
    const patient = this.props.patientList.data![this.state.patientId];
    if (!patient.preferredDoctor) {
      return false;
    } else {
      return patient.preferredDoctor.doctor.providerId === doctorId;
    }
  }

  private onChange = (index: number) => {
    this.setState({ selectedIndex: index });
  };

  private setPreferredDoctor = (newPreferredDoctorId: string | null) => {
    const patient = this.props.patientList.data![this.state.patientId];
    if (!newPreferredDoctorId && !this.state.removingPreferredDoctor) {
      this.setState({ removingPreferredDoctor: true });
    } else if (this.state.changingToPreferredDoctor !== newPreferredDoctorId && !!newPreferredDoctorId && patient.preferredDoctor) {
      this.setState({ changingToPreferredDoctor: newPreferredDoctorId });
    } else {
      this.setState({
        removingPreferredDoctor: false,
        changingToPreferredDoctor: undefined,
      });
      HealAPI.setPreferredDoctor(this.state.patientId, newPreferredDoctorId).then(
        (response: any) => {
          this.props.actions.getCurrentStatus();
          if (newPreferredDoctorId) {
            this.props.history.goBack();
          }
        },
        (response: any) => {
          // TODO Legacy: handle error scenario
        }
      );
    }
  };

  private renderRemoveConfirmationBottomSheet(): JSX.Element {
    return (
      <BottomSheet title={'Remove your preferred doctor?'} showCloseButton visible={this.state.removingPreferredDoctor} onClose={this.closeRemoveBottomSheet}>
        <div className={styles.bottomSheetContent}>
          <div className={styles.bottomSheetMessage} data-tid="txt_sheetMessage">
            Are you sure you want to remove your preferred doctor?
          </div>
          <LegacyButton className={styles.bottomSheetButton} onClick={this.confirmRemovePreferredDoctor} text="Yes" testId="btn_closeBottomSheet" />
          <LegacyButton
            className={styles.bottomSheetButton}
            onClick={this.closeRemoveBottomSheet}
            style={ButtonStyles.secondary}
            text="No"
            testId="btn_closeBottomSheet"
          />
        </div>
      </BottomSheet>
    );
  }

  private closeRemoveBottomSheet = () => {
    this.setState({ removingPreferredDoctor: false });
  };

  private confirmRemovePreferredDoctor = () => {
    this.setPreferredDoctor(null);
  };

  private renderChangeConfirmationBottomSheet(): JSX.Element {
    return (
      <BottomSheet title="Change your preferred doctor?" showCloseButton visible={!!this.state.changingToPreferredDoctor} onClose={this.closeChangeBottomSheet}>
        <div className={styles.bottomSheetContent}>
          <div className={styles.bottomSheetMessage} data-tid="txt_sheetMessage">
            Are you sure you want to change your preferred doctor?
          </div>
          <LegacyButton className={styles.bottomSheetButton} onClick={this.confirmChangePreferredDoctor} text="Yes" testId="btn_closeBottomSheet" />
          <LegacyButton
            className={styles.bottomSheetButton}
            onClick={this.closeChangeBottomSheet}
            style={ButtonStyles.secondary}
            text="No"
            testId="btn_closeBottomSheet"
          />
        </div>
      </BottomSheet>
    );
  }

  private closeChangeBottomSheet = () => {
    this.setState({ changingToPreferredDoctor: undefined });
  };

  private confirmChangePreferredDoctor = () => {
    this.setPreferredDoctor(this.state.changingToPreferredDoctor!);
  };
}

const Arrow: React.StatelessComponent<any> = (props: any) => {
  const { alt, className, image, onClick } = props;
  return <img alt={alt} src={image} className={className} onClick={onClick} />;
};
