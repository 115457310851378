import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetCart, setLocation, setPatient } from '../../appState/cart/actions';
import { CartPreferredDoctor } from '../../appState/cart/types';
import { RootState } from '../../appState/rootState';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import { NotificationsBox } from '../utils/NotificationsBox';
import { SelectableItem, SelectableItemStyles } from '../utils/SelectableItem';
import { FEATURES, IMAGES, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { AddressType } from '../../appState/address/types';
import Button from '../core/Button';
import styles from './PatientSelectionPage.module.scss';
import { calculateAge } from '../../utils/date';

const PatientSelectionPage = () => {
  const { userAccountInfo: account, onsite } = useSelector((state: RootState) => state);
  const history = useHistory();
  const dispatch = useDispatch();

  const patient = account.data?.patients.find((p) => p.mainPatientOfAccount);
  const incomplete = patient?.completionStatus === 'PROFILE_INCOMPLETE';
  const homeAddress = account.data?.addresses.find((a) => a.addressType === AddressType.HOME);

  const publicCode = onsite.publicCode || '';

  const title = `Hi ${patient?.firstName}`;
  const subtitle = "Here's the information we have on you.<br/>Is this correct?";

  const onContinue = useCallback(() => {
    if (patient && homeAddress && !incomplete) {
      let preferredDoctor: CartPreferredDoctor | undefined;
      if (patient.preferredDoctor && patient.preferredDoctor.doctor) {
        preferredDoctor = {
          ...patient.preferredDoctor.doctor,
          type: 'PREFERRED_DOCTOR',
          stillValid: patient.preferredDoctor.stillValid,
        };
      }

      dispatch(resetCart());

      dispatch(
        setPatient({
          id: patient.patientId,
          firstName: patient.firstName,
          lastName: patient.lastName,
          preferredDoctor,
        })
      );

      if (!onsite.data) {
        dispatch(
          setLocation({
            streetAddress: homeAddress.streetAddress,
            latitude: homeAddress.latitude,
            longitude: homeAddress.longitude,
            zipcode: homeAddress.zipcode,
            state: homeAddress.state,
            city: homeAddress.city,
            unit: homeAddress.unit,
            entryInstructions: homeAddress.instructions,
            addressId: homeAddress.addressId,
            addressType: homeAddress.addressType,
          })
        );
      }

      const pdEnabled = account.data && account.data.features.includes(FEATURES.preferredDoctor);
      if (onsite.data) {
        history.push(ROUTES.bookReason);
      } else if (pdEnabled) {
        history.push(ROUTES.bookDoctor);
      } else {
        history.push(ROUTES.bookReason);
      }
    }
  }, [patient, homeAddress, dispatch, incomplete, onsite.data, account.data, history]);

  const canContinue = useMemo(() => {
    return patient && homeAddress && !incomplete && patient.insurances.length > 0;
  }, [homeAddress, incomplete, patient]);

  let patientDetails = '';
  if (incomplete) {
    patientDetails = `Some information is missing, please update`;
  } else if (patient && patient.gender && patient.dateOfBirth) {
    patientDetails = `${patient.gender}, ${calculateAge(patient.dateOfBirth)} years old`;
  }

  return (
    <Page isLoading={account.isFetching} title="Welcome" showEmailBanner>
      {!onsite.data && publicCode && (
        <NotificationsBox
          type="info"
          content={getString(ResourceKey.bookMainLookingForWorkplaceMessage) + ` <a href="${ROUTES.workplace.replace(':code', publicCode)}">Click here</a>.`}
        />
      )}
      <PageHeader title={title} subtitle={subtitle} image={IMAGES.bookVisitTitle} />
      <div className={styles.listContainer}>
        <SelectableItem
          key="main_patient"
          testId="main_patient"
          label="Name"
          value={patient?.fullName}
          footer={patientDetails}
          icon={IMAGES.patient}
          style={SelectableItemStyles.review}
          selected={false}
          tabIndex={-1}
          onEdit={() => history.push(ROUTES.patientsEdit.replace(':id', patient!.id))}
        />
        <SelectableItem
          key="home_address"
          testId="home_address"
          label="Location"
          required
          value={homeAddress?.address || ''}
          footer={homeAddress ? `${homeAddress.city} ${homeAddress.state} ${homeAddress.zipcode}` : ''}
          icon={IMAGES.location}
          style={SelectableItemStyles.review}
          selected={false}
          tabIndex={-1}
          editLabel={homeAddress ? 'Edit' : 'Add'}
          onEdit={() => history.push(ROUTES.bookLocation)}
        />
        <SelectableItem
          key="primary_insurance"
          testId="primary_insurance"
          label="Insurance"
          required
          value={patient && patient.insurances.length > 0 ? patient.insurances[0].payer.name : ''}
          footer={patient && patient.insurances.length > 0 ? patient.insurances[0].memberId : ''}
          icon={IMAGES.insurance}
          style={SelectableItemStyles.review}
          selected={false}
          tabIndex={-1}
          editLabel={patient && patient.insurances.length > 0 ? 'Edit' : 'Add'}
          onEdit={() => history.push(ROUTES.patientsEdit.replace(':id', patient!.id))}
        />
        <SelectableItem
          key="secondary_insurance"
          testId="secondary_insurance"
          label="Secondary Insurance"
          value={patient && patient.insurances.length > 1 ? patient.insurances[1].payer.name : 'No secondary insurance selected'}
          footer={patient && patient.insurances.length > 1 ? patient.insurances[1].memberId : ''}
          icon={IMAGES.insurance}
          style={SelectableItemStyles.review}
          selected={false}
          tabIndex={-1}
          editLabel={patient && patient.insurances.length > 1 ? 'Edit' : 'Add'}
          onEdit={() => history.push(ROUTES.patientsEdit.replace(':id', patient!.id))}
        />
      </div>

      <Button text="Next" disabled={!canContinue} testId="btn_pageBottom" className="btn-block" onClick={onContinue} />
    </Page>
  );
};

export default PatientSelectionPage;
