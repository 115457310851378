import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { RootState } from '../../../appState/rootState';
import { IMAGES, LINKS, ROUTES } from '../../../services/constants';
import { getString } from '../../../services/languages';
import { ResourceKey } from '../../../services/languages/ResourceKey';
import { css } from '../../../utils/css';
import { grabInitials } from '../../../utils/strings';
import { Identity } from '../../core/Identity';
import { Loading } from '../../core/Loading';
import { VersionLabel } from '../../versionLabel/VersionLabel';
import AppStoreBadges from '../AppStoreBadges';
import styles from './MenuNav.module.scss';

export interface MenuNavProps {
  isAuthenticated: boolean;
}

export const MenuNav = ({ isAuthenticated }: MenuNavProps) => {
  const { userAccountInfo } = useSelector((state: RootState) => state);
  const location = useLocation();

  const {
    fullName,
    firstName,
    avatarUrl: url,
  } = useMemo(() => {
    if (!!userAccountInfo.data) {
      const { fullName, firstName, avatarUrl } = userAccountInfo.data;
      return { fullName, firstName, avatarUrl };
    }

    return { fullName: undefined, fistName: undefined, avatarUrl: undefined };
  }, [userAccountInfo.data]);

  const containerStyles = css(styles.menu, {
    [styles.unauthorized]: !isAuthenticated,
  });

  const Header = () => {
    if (!isAuthenticated) {
      return null;
    }

    return (
      <div className={styles.header}>
        {!firstName || !fullName ? (
          <Loading padding={20} />
        ) : (
          <div className={styles.avatarContainer}>
            {fullName && <Identity name={fullName} size={60} url={url} initials={grabInitials(fullName)} hideName selected />}
            <div className={styles.nameLabel}>{firstName}</div>
          </div>
        )}
      </div>
    );
  };

  const unauthenticatedItems: MenuItemProps[] = [
    {
      className: location?.pathname === ROUTES.legal ? styles.active : undefined,
      path: ROUTES.legal,
      image: IMAGES.menuNavLegal,
      title: getString(ResourceKey.menuNavLegalPage),
      testId: 'menu_legal',
    },
    {
      href: LINKS.helpSite,
      image: IMAGES.menuNavHelp,
      title: getString(ResourceKey.menuNavHelpPage),
      testId: 'menu_help',
    },
  ];

  const authenticatedItems: MenuItemProps[] = [
    {
      className: location?.pathname.startsWith(ROUTES.book) ? styles.active : undefined,
      path: ROUTES.bookReview,
      image: IMAGES.menuNavBookNow,
      title: getString(ResourceKey.menuNavBookPage),
      testId: 'menu_bookNow',
    },
    {
      className: location?.pathname === ROUTES.visits ? styles.active : undefined,
      path: ROUTES.visits,
      image: IMAGES.menuNavVisits,
      title: getString(ResourceKey.menuNavVisitsPage),
      testId: 'menu_visits',
    },
    {
      image: IMAGES.menuNavPatients,
      title: getString(ResourceKey.menuNavPatientsPage),
      testId: 'menu_patients',
      className: location?.pathname === ROUTES.patients ? styles.active : undefined,
      path: ROUTES.patients,
    },
    {
      className: location?.pathname === ROUTES.documentsPage ? styles.active : undefined,
      path: ROUTES.documentsPage,
      image: IMAGES.menuNavDocuments,
      title: getString(ResourceKey.menuNavDocumentsPage),
      testId: 'menu_documents',
    },
    {
      className: location?.pathname === ROUTES.legal ? styles.active : undefined,
      path: ROUTES.legal,
      image: IMAGES.menuNavLegal,
      title: getString(ResourceKey.menuNavLegalPage),
      testId: 'menu_legal',
    },
    {
      href: LINKS.helpSite,
      image: IMAGES.menuNavHelp,
      title: getString(ResourceKey.menuNavHelpPage),
      testId: 'menu_help',
    },
  ];

  const Footer = () => {
    return (
      <div className={styles.footer}>
        <AppStoreBadges className={styles.badges} />
        <VersionLabel />
        <div className={styles.copyrightFooter}>{getString(ResourceKey.menuNavCopyright, [new Date().getFullYear()])}</div>
      </div>
    );
  };

  return (
    <div className={containerStyles}>
      <Header />
      <ul className={styles.content}>
        {(isAuthenticated ? authenticatedItems : unauthenticatedItems).map((item, index) => (
          <MenuItem {...item} key={index} />
        ))}
        {isAuthenticated && (
          <div className={styles.logout}>
            <MenuItem path={ROUTES.logout} image={IMAGES.menuNavSignOut} title={getString(ResourceKey.menuNavSignOut)} testId="menu_signOut" />
          </div>
        )}
      </ul>
      <Footer />
    </div>
  );
};

interface MenuItemProps {
  title: string;
  path?: string;
  href?: string;
  action?: (event: any) => void;
  image?: string;
  className?: string;
  testId: string;
}

const MenuItem = (props: MenuItemProps) => {
  let content: JSX.Element;
  let image = null;
  let action;

  if (props.image) {
    image = (
      <div className={styles.menuIcon}>
        <ReactSVG src={props.image} className="themeSVGFill" />
      </div>
    );
  }
  if (props.href) {
    content = (
      <a className={css(styles.menuItem, props.className)} href={props.href} target="_blank" rel="noreferrer">
        {image}
        {props.title}
      </a>
    );
  } else if (props.path) {
    content = (
      <Link className={css(styles.menuItem, props.className)} to={props.path}>
        {image}
        {props.title}
      </Link>
    );
  } else if (props.action) {
    action = props.action;
    content = (
      <span className={css(styles.menuItem, props.className)} tabIndex={0}>
        {image}
        {props.title}
      </span>
    );
  } else {
    throw Error('you must specify a path, href, or action for this menu item: ' + props.title);
  }

  return (
    <li onClick={action} data-tid={props.testId}>
      {content}
    </li>
  );
};
