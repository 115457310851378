import React, { useEffect } from 'react';
import { css } from '../../utils/css';
import styles from './Flyout.module.scss';

export interface FlyoutProps {
  className?: string;
  showOverlay?: boolean;
  visible?: boolean;
  onClose?: () => void;
  fullPage?: boolean;
  topOffset?: number;
  from?: 'LEFT' | 'RIGHT' | 'TOP' | 'BOTTOM';
}

export const Flyout: React.FC<FlyoutProps> = ({ className, showOverlay = true, visible, onClose, fullPage, topOffset = 0, from = 'RIGHT', ...props }) => {
  useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflow = visible ? 'hidden' : 'auto';
  }, [visible]);

  return (
    <div className={css(styles.wrapper, { [styles.enter]: !!visible }, className)} aria-hidden={!visible}>
      {showOverlay && <div className={styles.overlay} style={{ top: topOffset }} onClick={() => onClose && onClose()} />}

      <div
        className={css(styles.content, {
          [styles.fromLeft]: from === 'LEFT',
          [styles.fromRight]: from === 'RIGHT',
          [styles.fromBottom]: from === 'BOTTOM',
          [styles.fromTop]: from === 'TOP',
          [styles.fullPage]: !!fullPage,
        })}
      >
        {visible && props.children}
      </div>
    </div>
  );
};
