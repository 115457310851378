export const debounce = <F extends (...args: any[]) => any>(func: F, delay: number) => {
  let timeout: any = 0;

  const debounced = (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };

  return (debounced as unknown) as (...args: Parameters<F>) => ReturnType<F>;
};

export function downloadFile(data: any, filename: string, type: string) {
  const a = document.createElement('a');
  a.style.display = 'none';

  const file = new Blob([data], { type });
  a.href = URL.createObjectURL(file);
  a.setAttribute('download', filename);

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(a.href);
      a.removeEventListener('click', clickHandler);
      document.body.removeChild(a);
    }, 150);
  };

  document.body.appendChild(a);
  a.addEventListener('click', clickHandler);
  a.click();
}

export const isLocal = process.env.NODE_ENV === 'development';
export const isTesting = process.env.REACT_APP_TESTING === 'true';

export enum HostEnv {
  LOCAL = 'localhost',
  DEV = 'dev',
  QA = 'qa',
  PRD = 'prd',
}

export function getHostEnv() {
  const { hostname } = window.location;

  if (hostname.indexOf(HostEnv.LOCAL) !== -1) {
    return HostEnv.LOCAL;
  } else if (hostname.indexOf(`.${HostEnv.DEV}.`) !== -1) {
    return HostEnv.DEV;
  } else if (hostname.indexOf(`.${HostEnv.QA}.`) !== -1) {
    return HostEnv.QA;
  } else {
    return HostEnv.PRD;
  }
}

export function getSMSLink(body: string) {
  let separator = '?';
  let userAgent = navigator.userAgent;
  if (userAgent.indexOf('iPhone') !== -1) {
    let version = userAgent.substr(userAgent.indexOf('OS') + 3, 1);
    if (version === '5' || version === '6' || version === '7') {
      separator = ';';
    } else {
      separator = '&';
    }
  }
  return `sms:${separator}body=${body}`;
}

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();
