import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { object } from 'yup';
import { PatientSecondaryContact } from '../../appState/patient/types';
import { RootState } from '../../appState/rootState';
import { HealAPI } from '../../services/api';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { YUP_VALIDATIONS } from '../../utils/forms';
import Button from '../core/Button';
import Input from '../core/Input';
import Select from '../core/Select';
import { NotificationsBox } from '../utils/NotificationsBox';
import styles from './SecondaryContactForm.module.scss';

interface Props {
  patientId?: string;
  contact?: PatientSecondaryContact;
  onSaved: (contact: PatientSecondaryContact) => void;
}

export function SecondaryContactForm({ patientId, contact, onSaved }: Props) {
  const { config } = useSelector((state: RootState) => state);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();

  const defaultValues = useMemo(
    () => ({
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      phone: contact?.phone ?? '+1',
      relationshipId: contact?.relationshipId,
    }),
    [contact]
  );

  const onSubmit = (updated: PatientSecondaryContact) => {
    if (patientId) {
      setError(undefined);
      setSaving(true);
      HealAPI.setSecondaryContact(patientId, updated)
        .then((response) => {
          if (response.data.status === 'OK') {
            onSaved(updated);
          } else if (response.data.description && response.data.description.length > 0) {
            setError(response.data.description);
          }
        })
        .finally(() => setSaving(false));
    } else {
      onSaved(updated);
    }
  };

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<PatientSecondaryContact>({
    mode: 'onTouched',
    resolver: yupResolver(
      object().shape({
        firstName: YUP_VALIDATIONS.firstName,
        lastName: YUP_VALIDATIONS.lastName,
        phone: YUP_VALIDATIONS.phone,
        relationshipId: YUP_VALIDATIONS.relationshipId,
      })
    ),
    defaultValues,
  });

  const relationshipIdOptions = useMemo(
    () => config.relationshipsForDropdown.map((relationship) => ({ value: relationship.key, text: relationship.text })),
    [config]
  );

  return (
    <div>
      <div className={styles.message}>{getString(ResourceKey.bookPatientSecondaryContactMessage)}</div>
      {error && <NotificationsBox type="error" content={error} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input label="First name" id="firstName" error={errors.firstName?.message} required {...register('firstName')} />
        <Input label="Last name" id="lastName" error={errors.lastName?.message} required {...register('lastName')} />
        <Controller
          name="phone"
          render={({ field }) => (
            <div className={`form-group required ${errors.phone ? 'invalid' : ''}`}>
              <label className="form-label" htmlFor={field.name}>
                Phone
              </label>
              <PhoneInput
                value={field.value}
                inputProps={{
                  name: field.name,
                  id: field.name,
                  required: true,
                  'data-lpignore': true,
                  'aria-describedby': errors.phone ? `${field.name}_error` : undefined,
                  'data-tid': `inp_${field.name}`,
                }}
                onChange={(_value, _data, _event, formattedValue) => field.onChange(formattedValue)}
                onBlur={field.onBlur}
                inputClass="form-control"
                country="us"
              />
              {errors.phone && (
                <div className="form-control-error" id={`${field.name}_error`}>
                  {errors.phone?.message}
                </div>
              )}
            </div>
          )}
          control={control}
        />
        <Select
          label="Relationship to patient"
          id="relationshipId"
          options={relationshipIdOptions}
          error={errors.relationshipId?.message}
          required
          {...register('relationshipId')}
        />
        <Button loading={saving} text="Save" className="btn-block" />
      </form>
    </div>
  );
}
