import * as React from 'react';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { css } from '../../utils/css';
import { grabInitials } from '../../utils/strings';
import styles from './Identity.module.scss';

export interface IdentityProps {
  name?: string;
  initials?: string;
  prefix?: string;
  selected?: boolean;
  size?: number;
  url?: string;
  hideName?: boolean;
}

export class Identity extends React.Component<IdentityProps, object> {
  public render(): JSX.Element {
    const containerClasses = css(styles.container, {
      [styles.selected]: !!this.props.selected,
    });

    return (
      <div className={containerClasses}>
        {this.renderCircle()}
        {this.renderText()}
      </div>
    );
  }

  private renderCircle(): JSX.Element {
    const { name, url, initials } = this.props;

    let content: JSX.Element | null = null;
    const size = this.props.size || 50;

    const initialsSize: React.CSSProperties = {
      fontSize: `${size / 3 / 12}em`,
    };

    const circleStyles: React.CSSProperties = {
      width: size,
      height: size,
      lineHeight: size + 'px',
      overflow: 'hidden',
    };

    if (url) {
      content = <img alt={getString(ResourceKey.patientAvatarAlt, name)} src={url} width={size} height={size} />;
    } else {
      content = (
        <div className={styles.initialsText} style={initialsSize}>
          {initials || grabInitials(name as string)}
        </div>
      );
    }

    return (
      <div className={css(styles.circle)} style={circleStyles}>
        {content}
      </div>
    );
  }

  private renderText(): JSX.Element | null {
    const { hideName, name, prefix } = this.props;

    if (hideName || !name) {
      return null;
    }

    const nameStyles: React.CSSProperties = {
      width: this.props.size || 50,
    };

    return (
      <div className={styles.nameContainer} style={nameStyles}>
        {!!prefix && <div className={styles.prefixText}>{prefix}</div>}
        <div className={styles.nameText}>{name}</div>
      </div>
    );
  }
}
