import { AgreementsActions } from './actions';
import { Agreement, AgreementsState } from './types';
import { ReduxAction } from '../../appState/types';

export const agreementsInitialState: AgreementsState = {
  isFetching: false,
  isSubmitting: false,
  hasSubmit: false,
};

export function agreementsReducer(state: AgreementsState = agreementsInitialState, action: ReduxAction<any>): AgreementsState {
  switch (action.type) {
    case AgreementsActions.GET_AGREEMENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case AgreementsActions.GET_AGREEMENTS_SUCCESS:
      if (action.data.data.length) {
        const agreements: {
          [key: string]: { value: boolean; data: Agreement };
        } = {};

        action.data.data.forEach((a: Agreement) => {
          agreements[a.id] = { value: false, data: a };
        });

        return {
          ...state,
          isFetching: false,
          fetchSuccess: true,
          agreements,
        };
      } else {
        return {
          ...state,
          isFetching: false,
          fetchSuccess: true,
          agreements: undefined,
        };
      }

    case AgreementsActions.GET_AGREEMENTS_FAIL:
      return {
        ...state,
        isFetching: false,
        fetchSuccess: false,
      };

    case AgreementsActions.SUBMIT_AGREEMENTS_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };

    case AgreementsActions.SUBMIT_AGREEMENTS_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        hasSubmit: true,
      };

    case AgreementsActions.SUBMIT_AGREEMENTS_FAIL:
      return {
        ...state,
        isSubmitting: false,
        hasSubmit: true,
      };

    case AgreementsActions.SET_AGREEMENT_VALUE:
      const { agreementId, value } = action.data;
      const agreement = state.agreements?.[agreementId];

      return {
        ...state,
        agreements: {
          ...state.agreements,
          [agreementId]: {
            ...agreement,
            value,
          },
        },
      };

    case AgreementsActions.RESET_AGREEMENTS:
      return agreementsInitialState;

    default:
      return state;
  }
}
