import { IMAGES } from '../../services/constants';

interface LogoProps {
  className?: string;
}

const Logo = ({ className }: LogoProps) => {
  return (
    <span className={className} data-tid="img_logo">
      <title>CenterWell Logo</title>
      <img src={IMAGES.logo} alt="CenterWell Logo" />
    </span>
  );
};

export default Logo;
