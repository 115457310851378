import React, { useMemo, useState } from 'react';
import { IMAGES } from '../../services/constants';
import { css } from '../../utils/css';
import Button, { ButtonStyle } from './Button';

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  error?: React.ReactNode;
  testId?: string;
  id: string;
  lpIgnore?: boolean; // Disables LastPass autofill & hides icons
  helpText?: React.ReactNode;
  accessory?: React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLInputElement>; // Overrides incorrect React.FormEventHandler<HTMLInputElement> inherited prop type
}

/**
 * Uses React.forwardRef to allow passing a ref prop down to the 'input' element (required for React Hook Form).
 */
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ type, label, placeholder = ' ', error, testId, lpIgnore = true, helpText, accessory, className, ...props }, ref) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const inputType = useMemo(() => {
      switch (type) {
        case 'password':
          return passwordVisible ? 'text' : 'password';
        case undefined:
          return 'text';
        default:
          return type;
      }
    }, [type, passwordVisible]);

    const inputMode = useMemo(() => {
      switch (type) {
        case 'number':
          return 'numeric';
        case 'tel':
        case 'search':
        case 'email':
        case 'url':
          return type;
        default:
          return 'text';
      }
    }, [type]);

    const ariaDescribedBy = useMemo(() => {
      if (helpText && error) {
        return `${props.id}_help ${props.id}_error`;
      } else if (helpText) {
        return `${props.id}_help`;
      } else if (error) {
        return `${props.id}_error`;
      }
    }, [error, helpText, props.id]);

    function renderShowPasswordToggle() {
      return (
        <Button
          text="Show password"
          buttonStyle={ButtonStyle.NONE}
          type="button"
          className="form-control-accessory form-control-accessory-button password-accessory"
          onClick={() => setPasswordVisible(!passwordVisible)}
          role="switch"
          aria-checked={passwordVisible}
        >
          <img alt="" src={passwordVisible ? IMAGES.showPassword : IMAGES.hidePassword} />
        </Button>
      );
    }

    return (
      <div className={css('form-group', { invalid: !!error, required: !!props.required }, className)}>
        {label && (
          <label htmlFor={props.id} className="form-label">
            {label}
          </label>
        )}

        <div className="form-control-wrapper">
          <input
            type={inputType}
            ref={ref}
            className="form-control"
            placeholder={placeholder}
            name={props.id}
            data-lpignore={lpIgnore}
            inputMode={props.inputMode || inputMode}
            data-tid={testId || `inp_${props.id}`}
            aria-invalid={!!error}
            aria-describedby={ariaDescribedBy}
            {...props}
          />
          {type === 'password' ? renderShowPasswordToggle() : accessory && <div className="form-control-accessory">{accessory}</div>}
        </div>

        {helpText && (
          <div className="form-control-help" id={`${props.id}_help`} role="alert">
            {helpText}
          </div>
        )}

        {error && (
          <div className="form-control-error" id={`${props.id}_error`} role="alert">
            {error}
          </div>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';
export default Input;
