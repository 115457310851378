import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { setPreferredDoctor, setTimeSlot, setVisitNotes } from '../../appState/cart/actions';
import { RootState } from '../../appState/rootState';
import { getCurrentStatus } from '../../appState/status/actions';
import { getTimeSlotsFail, getTimeSlotsRequest, getTimeSlotsSuccess } from '../../appState/timeSlots';
import { HealAPI } from '../../services/api';
import { FEATURES, IMAGES, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { TimeSlot } from '../../types/TimeSlot';
import { grabInitials } from '../../utils/strings';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import { RedirectWithSearch } from '../core/RedirectWithSearch';
import { VACCINE_RECORDS_KEY } from '../fileUploader/FileUploader';
import { PhysicalBookWarning } from '../login/PhysicalBookWarning';
import { NotificationsBox } from '../utils/NotificationsBox';
import { OnDemand } from '../utils/OnDemand';
import { OrSeparator } from '../utils/OrSeparator';
import { Steps } from '../utils/Steps';
import styles from './SchedulePage.module.scss';
import TimeSlots from './TimeSlots';
import { getRemoteConfigValue } from '../../services/firebase';

const SchedulePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { cart, timeSlots: timeSlotsState, onsite, userAccountInfo, agreements } = useSelector((state: RootState) => state);
  const firstTimeSlot = useMemo(() => timeSlotsState.data?.[0], [timeSlotsState]);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot>();
  const [showPhysicalBookingWarning, setShowPhysicalBookingWarning] = useState<boolean>();

  const minimumAdvancedBookingDays = useMemo(() => getRemoteConfigValue('minimum_advanced_booking_days').asNumber(), []);

  const showHighDemandNotice = useMemo(
    () => !cart.preferredDoctor && !onsite.data && getRemoteConfigValue('high_demand_notice_enabled').asBoolean(),
    [cart.preferredDoctor, onsite]
  );

  useEffect(() => {
    if (!userAccountInfo.data && !userAccountInfo.isFetching) {
      dispatch(getCurrentStatus());
    }
  }, [userAccountInfo, dispatch]);

  // Set selected date & time slot from cart
  useEffect(() => {
    if (cart.when) {
      setSelectedTimeSlot(cart.when);
    }
  }, [cart.when]);

  // Fetch time slots
  useEffect(() => {
    const { what, where, who } = cart;

    async function getTimeSlots(serviceCode: string, addressId: string, providerId?: string) {
      dispatch(getTimeSlotsRequest());
      const response = await (onsite.data
        ? HealAPI.getTimeSlotsOnsite(serviceCode, addressId)
        : HealAPI.getTimeSlots(serviceCode, addressId, undefined, providerId));

      if (response.ok && response.data?.data) {
        dispatch(getTimeSlotsSuccess(response.data.data));
      } else {
        dispatch(getTimeSlotsFail(response.problem ?? 'Failed to fetch time slots'));
      }
    }

    if (!timeSlotsState.data && !timeSlotsState.isFetching) {
      if (what) {
        if (where) {
          getTimeSlots(what.serviceCode, where.addressId, cart.preferredDoctor?.providerId);
        } else if (onsite.data) {
          getTimeSlots(what.serviceCode, onsite.data.partnerAddressId);
        }
      } else if (who) {
        history.push(ROUTES.bookReview);
      }
    }
  }, [cart, dispatch, history, onsite, timeSlotsState]);

  function selectSlot() {
    if (agreements.agreements) {
      history.push(ROUTES.bookAgreements);
    } else {
      history.push(cart.what?.serviceCode === 'ANPS_CHILD' ? ROUTES.uploadPage.replace(':code', VACCINE_RECORDS_KEY) : ROUTES.bookReview);
    }
  }

  function onContinue(timeSlot: TimeSlot) {
    dispatch(setTimeSlot(timeSlot));

    const zipCode = cart.where?.zipcode ? parseInt(cart.where.zipcode) : -1;

    // California zipcode range: 90001 - 96162
    if (!onsite.data && cart.what?.serviceCode === 'ANPS_ADULT' && zipCode >= 90001 && zipCode <= 96162) {
      const startTime = moment.unix(timeSlot.startTime).tz(timeSlot.timeZone);

      // Only on Sunday
      if (startTime.day() >= 6) {
        setShowPhysicalBookingWarning(true);
        return;
      } else {
        dispatch(setVisitNotes(undefined));
      }
    }

    selectSlot();
  }

  function renderHighDemandNotice() {
    return (
      <div className={styles.notice}>
        <div className={styles.header}>
          <div className={styles.title}>
            <div className={styles.icon}>
              <ReactSVG src={IMAGES.alertPink} className="themeSVGFill" alt="" />
            </div>
            High demand
          </div>
        </div>
        {getString(ResourceKey.bookScheduleHighDemandNotice)}
      </div>
    );
  }

  return !cart.who ? (
    <RedirectWithSearch to={ROUTES.bookPatient} />
  ) : (
    <Page isLoading={timeSlotsState.isFetching || userAccountInfo.isFetching} showBackButton title={getString(ResourceKey.bookScheduleTitle)}>
      <Steps history={history} currentStep="schedule" />

      {cart.preferredDoctor ? (
        <div className={styles.preferredDoctorHeader}>
          {cart.preferredDoctor.avatarUrl && <img className={styles.avatar} src={cart.preferredDoctor.avatarUrl} alt="" />}
          {!cart.preferredDoctor.avatarUrl && (
            <div className={styles.avatar}>{grabInitials(`${cart.preferredDoctor.firstName} ${cart.preferredDoctor.lastName}`)}</div>
          )}
          Select a time from
          <br />
          {`${cart.preferredDoctor.prefix} ${cart.preferredDoctor.firstName} ${cart.preferredDoctor.lastName}'s schedule`}
          {firstTimeSlot && <span> ({moment.unix(firstTimeSlot.startTime).tz(firstTimeSlot.timeZone).format('z')})</span>}
        </div>
      ) : (
        <PageHeader
          title={getString(ResourceKey.bookScheduleTitle)}
          subtitle={
            firstTimeSlot
              ? `${getString(ResourceKey.bookScheduleHeaderMessage)} (${moment.unix(firstTimeSlot.startTime).tz(firstTimeSlot.timeZone).format('z')})`
              : getString(ResourceKey.bookScheduleHeaderMessage)
          }
        />
      )}

      {showHighDemandNotice && renderHighDemandNotice()}

      {(minimumAdvancedBookingDays >= 3 || moment(selectedDate).startOf('day').isSameOrAfter(moment().startOf('day').add(3, 'd'))) && (
        <NotificationsBox
          type="info"
          content="CenterWell will call you 3 days before your appointment to confirm a two hour window within your preferred time slot."
        />
      )}

      <TimeSlots
        onChangeDate={(date: Date) => setSelectedDate(date)}
        onChangeTimeSlot={(selectedTimeSlot: TimeSlot) => {
          if (selectedTimeSlot) {
            setSelectedTimeSlot(selectedTimeSlot);
            onContinue(selectedTimeSlot);
          }
        }}
        timeSlots={timeSlotsState.data || []}
        cart={cart}
        isFetching={timeSlotsState.isFetching}
        isOnsite={!!onsite.data}
        isPreferredDoctor={!!cart.preferredDoctor}
      />

      {minimumAdvancedBookingDays > 0 && (
        <NotificationsBox
          type="info"
          jsxContent={
            <p style={{ margin: 0 }}>
              To book a visit within the next {minimumAdvancedBookingDays} days, please call <a href="tel:+18446444325">(844) 644-4325</a>
            </p>
          }
        />
      )}

      {cart.preferredDoctor && (
        <div>
          <OrSeparator />
          <OnDemand
            onClick={() => {
              dispatch(setPreferredDoctor(undefined));
              history.push(ROUTES.bookReason);
            }}
          />
        </div>
      )}

      {!cart.preferredDoctor &&
        cart.who?.preferredDoctor?.stillValid &&
        !onsite.data &&
        userAccountInfo.data?.features &&
        userAccountInfo.data.features.indexOf(FEATURES.preferredDoctor) >= 0 && (
          <OnDemand
            message="Want to book with your preferred doctor?"
            cta="Book with preferred doctor"
            onClick={() => {
              dispatch(setPreferredDoctor(cart.who!.preferredDoctor));
              history.push(ROUTES.bookReason);
            }}
          />
        )}

      {showPhysicalBookingWarning && (
        <PhysicalBookWarning
          onClose={(book?: boolean) => {
            setShowPhysicalBookingWarning(false);
            if (book) {
              dispatch(setVisitNotes('Patient consented to book without labs.'));
              selectSlot();
            } else if (selectedTimeSlot) {
              dispatch(setTimeSlot(selectedTimeSlot));
            }
          }}
        />
      )}
    </Page>
  );
};

export default SchedulePage;
