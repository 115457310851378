import React from 'react';
import { IMAGES } from '../../services/constants';
import { css } from '../../utils/css';
import styles from './AppStoreBadges.module.scss';

interface AppStoreBadgesProps {
  className?: string;
}

const AppStoreBadges: React.FC<AppStoreBadgesProps> = ({ className }) => {
  return (
    <div className={css(styles.badges, className)}>
      <a href="https://link.heal.com/ios" target="_blank" rel="noreferrer" className={styles.badge} aria-label="App Store (opens in new tab)">
        <img src={IMAGES.badgeAppStore} alt="App Store" />
      </a>
      <a href="https://link.heal.com/android" target="_blank" rel="noreferrer" className={styles.badge} aria-label="Google Play (opens in new tab)">
        <img src={IMAGES.badgeGooglePlay} alt="Google Play" />
      </a>
    </div>
  );
};

export default AppStoreBadges;
