import { HealAPI } from '../../services/api';

export enum ThankYouActions {
  GET_VISIT_BY_VISIT_CODE_REQUEST = 'GET_VISIT_BY_VISIT_CODE_REQUEST',
  GET_VISIT_BY_VISIT_CODE_SUCCESS = 'GET_VISIT_BY_VISIT_CODE_SUCCESS',
  GET_VISIT_BY_VISIT_CODE_FAIL = 'GET_VISIT_BY_VISIT_CODE_FAIL',
}

export function getVisit(visitCode: string) {
  return (dispatch: any) => {
    dispatch({ type: ThankYouActions.GET_VISIT_BY_VISIT_CODE_REQUEST });
    HealAPI.getVisit(visitCode).then(
      (response) => {
        if (response.ok) {
          dispatch({
            type: ThankYouActions.GET_VISIT_BY_VISIT_CODE_SUCCESS,
            data: response.data?.data,
          });
        } else {
          dispatch({
            type: ThankYouActions.GET_VISIT_BY_VISIT_CODE_FAIL,
            data: response.data,
          });
        }
      },
      (response) => {
        dispatch({
          type: ThankYouActions.GET_VISIT_BY_VISIT_CODE_FAIL,
          data: response.data,
        });
      }
    );
  };
}
