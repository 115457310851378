import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CancelReason } from '../../appState/config/types';
import { showBottomSheet } from '../../appState/notification/actions';
import { RootState } from '../../appState/rootState';
import { cancelVisit, cancelVisitClearState } from '../../appState/visit/actions';
import { Visit } from '../../appState/visit/types';
import { ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { css } from '../../utils/css';
import { formatTimeslot } from '../../utils/date';
import { shuffle } from '../../utils/math';
import Button, { ButtonStyle } from '../core/Button';
import { LegacyButton } from '../core/LegacyButton';
import { Loading } from '../core/Loading';
import TextArea from '../core/TextArea';
import styles from './CancelVisit.module.scss';

export interface CancelVisitProps {
  visit: Visit;
  onCancelComplete: () => void;
  onRescheduleClick: () => void;
}

const CancelVisit: React.FC<CancelVisitProps> = ({ visit, onCancelComplete, onRescheduleClick }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isFetchingConfig = useSelector((state: RootState) => state.config.isFetching);
  const cancelReasons = useSelector((state: RootState) => state.config.data?.data.cancelReasons);
  const onsiteCancelReasons = useSelector((state: RootState) => state.config.data?.data.onsiteCancelReasons);
  const { isCanceling, cancelResponse, cancelError } = useSelector((state: RootState) => state.visitList);

  const [selectedReason, setSelectedReason] = useState<CancelReason>();
  const [note, setNote] = useState('');

  const reasons = useMemo(() => shuffleReasons(cancelReasons), [cancelReasons]);
  const onsiteReasons = useMemo(() => shuffleReasons(onsiteCancelReasons), [onsiteCancelReasons]);
  const reasonsForCurrentVisit = useMemo(() => (visit.isOnSite ? onsiteReasons : reasons) || [], [visit, onsiteReasons, reasons]);
  const visitTime = useMemo(
    () => formatTimeslot(visit.timeSlot.startTime * 1000, (visit.timeSlot.startTime + visit.timeSlot.duration) * 1000, visit.timeSlot.timeZone),
    [visit.timeSlot]
  );

  function shuffleReasons(reasons?: CancelReason[]) {
    return reasons?.length ? shuffle(reasons.slice(0, -1)).concat(reasons[reasons.length - 1]) : undefined;
  }

  // Reset cancel visit response/error on mount
  useEffect(() => {
    dispatch(cancelVisitClearState());
  }, [dispatch]);

  // Run completion callback on cancel
  useEffect(() => {
    cancelResponse && onCancelComplete();
  }, [cancelResponse, onCancelComplete]);

  // Show error
  useEffect(() => {
    if (cancelError) {
      dispatch(showBottomSheet(getString(ResourceKey.warningAlt), cancelError, true));
      dispatch(cancelVisitClearState());
    }
  }, [cancelError, dispatch]);

  if (isCanceling || !reasons || isFetchingConfig) {
    return <Loading padding={20} />;
  } else if (cancelResponse) {
    return (
      <div className={styles.container}>
        <span>{cancelResponse}</span>
        <LegacyButton
          className={styles.bookAnotherVisitButton}
          text={getString(ResourceKey.bottomSheetCancelVisitButtonBookAnother)}
          onClick={() => (visit.publicCode ? history.push(ROUTES.workplace.replace(':code', visit.publicCode)) : history.push(ROUTES.bookReview))}
          testId="btn_BookAgain"
        />
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <div className={styles.visitSummaryContainer}>
          <div className={styles.title}>{getString(ResourceKey.bottomSheetCancelVisitScheduledFor).replace('%s', visit.patient.firstName)}</div>
          <div className={styles.time}>{visitTime}</div>
        </div>
        {visit.canReschedule && (
          <div>
            <div className={styles.text}>{visit.rescheduleLabel}</div>
            <Button
              text={getString(ResourceKey.bottomSheetCancelReschedule)}
              onClick={onRescheduleClick}
              buttonStyle={ButtonStyle.SUCCESS}
              className={styles.rescheduleButton}
              testId="btn_Reschedule"
              disabled={!visit.canReschedule}
            />
          </div>
        )}

        <fieldset className={styles.reasonOptions}>
          <legend className={styles.text}>{getString(ResourceKey.bottomSheetOrCancelVisitTellUsWhy)}</legend>
          {reasonsForCurrentVisit.map((reason) => (
            <label className={css(styles.reasonOption, { [styles.selected]: reason.id === selectedReason?.id })} key={reason.id}>
              <input
                type="radio"
                value={reason.id}
                name="reason"
                checked={reason.id === selectedReason?.id}
                onChange={() => {
                  setSelectedReason(reason);
                  setNote('');
                }}
                data-tid={`btn_cancelReason_${reason.id}`}
              />
              {reason.label}
            </label>
          ))}
        </fieldset>

        {reasons && selectedReason?.allowNotes && (
          <div className={styles.notesContainer}>
            <TextArea
              id="note"
              name="note"
              label={getString(ResourceKey.bottomSheetCancelVisitNotesPlaceholder)}
              value={note}
              onChange={(e) => setNote(e.currentTarget.value)}
              testId="inp_cancelNote"
            />
          </div>
        )}

        <Button
          className="btn-block"
          text={getString(ResourceKey.bottomSheetCancelVisitButtonCancel)}
          onClick={() => selectedReason && dispatch(cancelVisit(visit.code, { reasonId: selectedReason.id, note }))}
          disabled={!selectedReason || (selectedReason.allowNotes && !note.trim().length)}
          testId="btn_cancelVisit"
        />
      </div>
    );
  }
};

export default CancelVisit;
