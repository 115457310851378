import { useOktaAuth } from '@okta/okta-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { LINKS, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import styles from './LegalPage.module.scss';

const LegalPage = () => {
  const history = useHistory();
  const { authState } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated ?? false;

  const navButtons = [];
  if (isAuthenticated) {
    navButtons.push({
      action: () => history.push(ROUTES.bookReview),
      text: getString(ResourceKey.topNavButtonBook),
    });
  } else {
    navButtons.push({
      action: () => history.push(ROUTES.login),
      text: getString(ResourceKey.signInButtonSignIn),
    });
  }

  const legalItems: { title: string; link: string }[] = [
    { title: getString(ResourceKey.registerPrivacyPolicy), link: LINKS.legalPrivacySite },
    { title: getString(ResourceKey.registerTermsOfService), link: LINKS.legalTermsSite },
    { title: getString(ResourceKey.registerPrivacyPractices), link: LINKS.legalPracticesSite },
    { title: getString(ResourceKey.registerNondiscriminationNotice), link: LINKS.legalNondiscriminationSite },
  ];

  return (
    <Page navButtons={navButtons} title={getString(ResourceKey.legalPageTitle)} hideTitleInNavbar>
      <PageHeader title={getString(ResourceKey.legalPageTitle)} />
      <ul className={styles.container}>
        {legalItems.map((item) => (
          <li className={styles.item}>
            <a href={item.link} target="_blank" rel="noreferrer" aria-label={`${item.title} opens in new tab`}>
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </Page>
  );
};

export default LegalPage;
