import FocusTrap from 'focus-trap-react';
import * as React from 'react';
import { useEffect } from 'react';
import { IMAGES, PARTNERS } from '../../services/constants';
import { css } from '../../utils/css';
import { useIsMounted, useOnKeyDown } from '../hooks';
import styles from './BottomSheet.module.scss';
import CloseIcon from './CloseIcon';

export interface BottomSheetProps {
  visible?: boolean;
  showCloseButton?: boolean;
  title?: string;
  onClose: () => void;
  className?: string;
  contentClassName?: string;
  partner?: string;
  testId?: string;
}

export const BottomSheet: React.FC<BottomSheetProps> = ({
  visible,
  showCloseButton,
  title,
  className,
  contentClassName,
  partner,
  testId,
  children,
  ...props
}) => {
  const isMounted = useIsMounted();

  const onClose = () => isMounted.current && props.onClose();

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflow = visible ? 'hidden' : 'auto';
    return () => {
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    };
  }, [visible]);

  useOnKeyDown('Escape', onClose, !visible);

  const renderTitleAndCloseButton = () => {
    return title || showCloseButton ? (
      <div className={styles.bottomSheetHeader}>
        {partner === PARTNERS.humana && (
          <div className={styles.partnerHeader}>
            <img src={IMAGES.lockupHumana} alt="" />
          </div>
        )}

        {showCloseButton && (
          <>
            <button
              style={{ border: 'none', background: 'none', margin: 0, padding: 0 }}
              aria-label="Close dialog"
              className={styles.bottomSheetCloseButton}
              onClick={onClose}
              data-tid="btn_closeDialog"
              onKeyDown={(e) => ['Space', 'Enter'].includes(e.code) && onClose()}
            >
              <CloseIcon />
            </button>
            {!title && <div className={styles.bottomSheetTitle} />}
          </>
        )}

        {title && (
          <h2 className={styles.bottomSheetTitle} data-tid="txt_bottomSheetTitle">
            {title}
          </h2>
        )}
      </div>
    ) : null;
  };

  return (
    <div role="dialog" aria-live="polite" className={css(styles.bottomSheetWrapper, visible && styles.visible)} aria-modal data-tid={testId}>
      <div className={styles.overlay} onClick={onClose} />
      <div className={css(styles.bottomSheet, className)} data-tid="ctn_bottomSheet">
        <FocusTrap active={!!visible} focusTrapOptions={{ clickOutsideDeactivates: true }}>
          <div className={css(styles.bottomSheetContent, contentClassName)}>
            {visible && (
              <>
                {renderTitleAndCloseButton()}
                {children}
              </>
            )}
          </div>
        </FocusTrap>
      </div>
    </div>
  );
};
