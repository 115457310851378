import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.module.scss';
import App from './App';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
// import { getHostEnv } from './utils/Utils';

// Sentry.init({
//   dsn: 'https://c7181912884241d6a1a4cd63bb446cb8@o442888.ingest.sentry.io/5647852',
//   environment: getHostEnv(),
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
//   autoSessionTracking: true,
// });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
