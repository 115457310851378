import { LoginCallback, SecureRoute, useOktaAuth } from '@okta/okta-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { logoutAgent } from '../../appState/auth/actions';
import { setCampaign, setCampaignLaunchParams } from '../../appState/campaign/actions';
import { getConfig } from '../../appState/config/actions';
import { RootState } from '../../appState/rootState';
import { Status } from '../../appState/services';
import { getCurrentStatus } from '../../appState/status/actions';
import { getVisits } from '../../appState/visit/actions';
import BrowserNotSupportedPage from '../../components/error/BrowserNotSupportedPage';
import { INSECURE_ROUTES, REDIRECT_BLOCK_LIST, ROUTES } from '../../services/constants';
import { isIE } from '../../utils/browser';
import { CookieName, getCookie, removeCookie, setCookie } from '../../utils/cookies';
import { parseJwt } from '../../utils/jwt';
import Agreements from '../agreements/Agreements';
import ConsentsPage from '../consents/Consents';
import AddPatient from '../containers/AddPatient';
import BookVisit from '../containers/BookVisit';
import EditPatient from '../containers/EditPatient';
import HealDoctors from '../containers/HealDoctors';
import LocationEdit from '../containers/LocationEdit';
import OauthSSO from '../containers/OauthSSO';
import PDSelectDoctor from '../containers/PDSelectDoctor';
import SamlLanding from '../containers/SamlLanding';
import WhatToExpect from '../containers/WhatToExpect';
import { Loading } from '../core/Loading';
import ErrorPage from '../error/ErrorPage';
import usePageView from '../hooks/usePageView';
import LegalPage from '../legal/LegalPage';
import AgentAccount from '../login/AgentAccount';
import BlueShieldLogin from '../login/BlueShieldLogin';
import { ResetPasswordPage } from '../login/ResetPasswordPage';
import { LogoutPage } from '../logout/Logout';
import { NotFound } from '../notFound/NotFound';
import { SamlLink } from '../saml/SamlLink';
import { SamlLinkBlueShield } from '../saml/SamlLinkBlueShield';
import { SamlRegister } from '../saml/SamlRegister';
import SchedulePage from '../schedule/SchedulePage';
import DocumentsPage from './DocumentsPage';
import { ForgotPasswordPage } from './ForgotPasswordPage';
import LoginPage from './LoginPage';
import OnsitePage from './OnsitePage';
import PatientSelectionPage from './PatientSelectionPage';
import PatientsPage from './PatientsPage';
import RegisterPage from './RegisterPage';
import ServiceSelectionPage from './ServiceSelectionPage';
import { ThankYouPage } from './ThankYouPage';
import UploadPage from './UploadPage';
import VisitsPage from './VisitsPage';

declare const window: any;
declare const healConfig: any;

const Routes: React.FC = () => {
  const [parseCampaignStatus, setParseCampaignStatus] = useState(Status.idle);

  const { campaign } = useSelector((state: RootState) => state);
  const { authState } = useOktaAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  usePageView();

  useEffect(() => {
    dispatch(getConfig());
  }, [dispatch]);

  useEffect(() => {
    const accessTokenValue = authState?.accessToken?.accessToken;
    if (authState?.isAuthenticated && accessTokenValue) {
      const jwt = parseJwt(accessTokenValue);
      const isAgent = jwt.groups?.includes('ENTERPRISE');
      if (isAgent) {
        dispatch(logoutAgent());
        history.push(ROUTES.agentAccount);
      } else {
        dispatch(getCurrentStatus());
        dispatch(getVisits());
      }
    }
  }, [authState, dispatch]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('utm_campaign')) {
      queryParams.delete('_branch_match_id');
      queryParams.delete('at');
      queryParams.delete('rt');

      const launchParams = Object.fromEntries(queryParams);

      // Call trackLaunchParams() rather than trackCampaign() because store has not been updated yet
      dispatch(setCampaignLaunchParams(launchParams));
    }
  }, [dispatch]);

  useEffect(() => {
    /*
     * We track advertising by including it on registration & booking API calls.
     * We get campaign data in two ways;
     * When a user clicks a Branch link that takes them to my.heal.com we parse & save campaign data.
     * Or, when a user clicks a Branch link to www.heal.com a cookie is created with campaign data.
     */

    if (parseCampaignStatus === Status.idle) {
      const userWillBeRedirected = (!INSECURE_ROUTES.includes(location.pathname) && !authState?.isAuthenticated) || location.pathname === ROUTES.logout;
      if (userWillBeRedirected || !authState) {
        return;
      }

      setParseCampaignStatus(Status.loading);

      /*
       * In our app a user from a Branch link could get redirected and interrupted before Branch can initialize.
       * If branch_match_id cookie exists, use it. Else, Branch.init will automatically get it from location.search.
       *
       * branch_match_id:_Only necessary if ?_branch_match_id is lost due to multiple redirects in your flow.
       * https://help.branch.io/developers-hub/docs/web-full-reference
       */

      const branchMatchId = getCookie('branch_match_id') as string | null;
      removeCookie('branch_match_id');
      window.branch.init(healConfig.branchKey, { branch_match_id: branchMatchId }, (err: any, branchData: any) => {
        let campaignData = branchData?.data_parsed;

        // Redundant branch data often contains only two properties
        const branchHasCampaignData = campaignData && Object.keys(campaignData).length > 2;
        const healCampaignCookie = getCookie(CookieName.heal_branch_data) as string | null;

        if (branchHasCampaignData) {
          // User clicked my.heal.com Branch link. Remove stale campaign cookie from www.heal.com.
          removeCookie(CookieName.heal_branch_data);
          saveCampaignData(campaignData);
        } else if (healCampaignCookie !== null) {
          campaignData = JSON.parse(healCampaignCookie);
          saveCampaignData(campaignData);
        }

        parseOnsiteEventIfNeeded(campaignData);
        setParseCampaignStatus(Status.succeeded);
      });
    }

    if (parseCampaignStatus === Status.succeeded && authState?.isAuthenticated) {
      redirectIfNeeded();
    }

    function saveCampaignData(data: { [key: string]: number | boolean | string }) {
      const matchesExistingCampaign = data?.['~campaign'] === campaign.name && !!campaign.name;
      if (!matchesExistingCampaign) {
        dispatch(setCampaign(data));
      }
    }

    function parseOnsiteEventIfNeeded(data: { [key: string]: number | boolean | string }) {
      const publicCode = data?.p as string;
      if (data?.['~campaign'] === 'onsite' && publicCode) {
        setCookie(CookieName.public_code, publicCode, 8 / 24);
        setCookie(CookieName.redirect_after_login, ROUTES.workplace.replace(':code', publicCode), 1 / 48);
      }
    }

    function redirectIfNeeded() {
      /*
       * "redirect_after_login" cookie is set when Okta <Security/> -> onAuthRequired() catches an
       * unauthenticated user trying to reach a SecureRoute. For instance, a Branch link might point
       * a new user at a page that is inside a SecureRoute.
       *
       * On the next Authenticated page load user will be redirected to the route in "redirect_after_login"
       * cookie if it exists and is allowed.
       */
      const redirectAfterLogin = getCookie(CookieName.redirect_after_login) as string | null;

      const onsiteEventCode = getCookie(CookieName.public_code) as string | null;

      if (!!onsiteEventCode) {
        removeCookie(CookieName.public_code);
        history.push(ROUTES.workplace.replace(':code', onsiteEventCode));
      } else if (!!redirectAfterLogin && REDIRECT_BLOCK_LIST.includes(redirectAfterLogin)) {
        // In the unlikely event that a disallowed route was set for this cookie, remove and do nothing.
        removeCookie(CookieName.redirect_after_login);
      } else if (!!redirectAfterLogin) {
        removeCookie(CookieName.redirect_after_login);
        history.push(redirectAfterLogin);
      }
    }
  }, [dispatch, campaign, history, parseCampaignStatus, location.pathname, authState]);

  if (!authState && location.pathname !== ROUTES.loginCallback && location.pathname !== ROUTES.ssoOauth) {
    return <Loading />;
  } else if (isIE) {
    return <BrowserNotSupportedPage />;
  }

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={{ pathname: authState?.isAuthenticated ? ROUTES.bookPatient : ROUTES.login, search: location.search }} />}
      />
      <Route path={ROUTES.loginCallback} component={LoginCallback} />
      <Route exact path={ROUTES.agentAccount} component={AgentAccount} />
      <Route path={ROUTES.blueShieldLogin} component={BlueShieldLogin} />
      <Route exact path={ROUTES.login} component={LoginPage} />
      <Route path={ROUTES.logout} component={LogoutPage} />
      <Route path={ROUTES.forgotPassword} component={ForgotPasswordPage} />
      <Route path={ROUTES.resetPassword} component={ResetPasswordPage} />
      <Route path={ROUTES.register} component={RegisterPage} />
      <Route path={ROUTES.legal} component={LegalPage} />
      <Route exact path={ROUTES.partnerLanding} component={SamlLanding} />
      <Route path={ROUTES.blueshieldLink} component={SamlLinkBlueShield} />
      <Route path={ROUTES.ssoOauth} component={OauthSSO} />
      <Route path={ROUTES.partnerRegister} component={SamlRegister} />
      <Route path={ROUTES.partnerLink} component={SamlLink} />
      <Route path={ROUTES.partnerError} component={ErrorPage} />
      <Route path={ROUTES.errorPage} component={ErrorPage} />
      <SecureRoute path={ROUTES.bookDoctor} component={PDSelectDoctor} />
      <SecureRoute path={ROUTES.patientSelectDoctor} component={HealDoctors} />
      <SecureRoute exact path={ROUTES.bookReview} component={BookVisit} />
      <SecureRoute exact path={[ROUTES.bookPatient, ROUTES.book]} component={PatientSelectionPage} />
      <SecureRoute path={ROUTES.workplace} component={OnsitePage} />
      <SecureRoute exact path={ROUTES.visits} component={VisitsPage} />
      <SecureRoute path={ROUTES.visitsWhatToExpect} component={WhatToExpect} />
      <SecureRoute path={ROUTES.uploadPage} component={UploadPage} />
      <SecureRoute path={ROUTES.documentsPage} component={DocumentsPage} />
      <SecureRoute path={ROUTES.consentsPage} component={ConsentsPage} />

      <SecureRoute exact path={ROUTES.patients} component={PatientsPage} />
      <SecureRoute path={ROUTES.patientsAdd} component={AddPatient} />
      <SecureRoute path={ROUTES.patientsEdit} component={EditPatient} />
      <SecureRoute path={ROUTES.bookLocation} component={LocationEdit} />
      <SecureRoute path={ROUTES.bookReason} component={ServiceSelectionPage} />
      <SecureRoute path={ROUTES.bookSchedule} component={SchedulePage} />
      <SecureRoute path={ROUTES.bookAgreements} component={Agreements} />
      <SecureRoute path={ROUTES.bookThankYou} component={ThankYouPage} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
