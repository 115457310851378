import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { object, string } from 'yup';
import { HealAPI } from '../../services/api';
import { IMAGES, ROUTES } from '../../services/constants';
import { createMarkup, getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import Button from '../core/Button';
import Input from '../core/Input';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import { NotificationsBox } from '../utils/NotificationsBox';
import styles from './ForgotPasswordPage.module.scss';

interface ForgotPasswordFormData {
  email: string;
}

export function ForgotPasswordPage() {
  const [sending, setSending] = useState(false);
  const [error, setError] = useState();
  const history = useHistory();

  const { handleSubmit, register, formState } = useForm<ForgotPasswordFormData>({
    mode: 'onTouched',
    resolver: yupResolver(
      object().shape({
        email: string().email('Invalid email address').required('Please enter an email'),
      })
    ),
  });

  const requestSent = useMemo(() => !sending && formState.isSubmitted, [sending, formState]);

  function onSubmit(data: ForgotPasswordFormData) {
    setSending(true);
    HealAPI.forgotPassword(data.email)
      .then((response) => {
        if (response.ok) {
        } else {
          setError(response.data?.description);
        }
      })
      .finally(() => setSending(false));
  }

  const navButtons = [
    {
      action: () => history.push(ROUTES.login),
      text: getString(ResourceKey.signInButtonSignIn),
    },
  ];

  return (
    <Page navButtons={navButtons} title={getString(ResourceKey.forgotPasswordTitle)} showBackButton onBackPress={() => history.goBack()}>
      <PageHeader title={getString(ResourceKey.forgotPasswordTitle)} image={IMAGES.loginTitle} />

      {!requestSent && <div className={styles.message}>{getString(ResourceKey.forgotPasswordHeader)}</div>}

      {error && <NotificationsBox type="error" content={error || getString(ResourceKey.errorSignInGeneric)} />}

      {requestSent && <div className={styles.message}>{getString(ResourceKey.forgotRequestSent)}</div>}

      {(!requestSent || error) && (
        <form onSubmit={handleSubmit(onSubmit)} title="Forgot password">
          <Input
            label="Email"
            id="email"
            type="email"
            testId="inp_username"
            disabled={sending}
            error={formState.errors.email?.message}
            required
            {...register('email')}
          />
          <Button loading={sending} disabled={sending} text={getString(ResourceKey.forgotRecoverPassword)} testId="btn_forgotPassword" className="btn-block" />
        </form>
      )}

      <div className={styles.contactUs}>
        <span dangerouslySetInnerHTML={createMarkup(getString(ResourceKey.forgotPasswordSupportLink))} />
      </div>
    </Page>
  );
}
