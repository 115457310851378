import * as React from 'react';
import { WhatToExpectBullet, WhatToExpectPageItem } from '../../appState/whatToExpect/types';
import { createMarkup } from '../../services/languages';
import { ZeroData } from '../core/ZeroData';
import styles from './WhatToExpectItems.module.scss';

export interface WhatToExpectItemsProps {
  wte?: WhatToExpectPageItem[];
}
export interface WhatToExpectItemsState {}

export class WhatToExpectItems extends React.Component<WhatToExpectItemsProps, WhatToExpectItemsState> {
  public render(): JSX.Element {
    return <div className={styles.container}>{this.renderSections()}</div>;
  }

  private renderSections(): JSX.Element[] | JSX.Element {
    if (this.props.wte) {
      return this.props.wte.map((page, index) => {
        return <InfoCard suppressDivider={index === 0} key={index} title={page.title} bullets={page.bullets} image={page.image} />;
      });
    } else {
      return <ZeroData text={'Nothing to See Here'} />;
    }
  }
}

interface InfoCardProps {
  title: string;
  bullets: WhatToExpectBullet[];
  image: string;
  suppressDivider?: boolean;
}

const InfoCard: React.StatelessComponent<InfoCardProps> = (props: InfoCardProps) => {
  const bullets: JSX.Element[] = props.bullets.map((bullet, index) => {
    if (bullet.bulletHTML) {
      return <div className={styles.bullet} key={index} dangerouslySetInnerHTML={createMarkup(bullet.bulletHTML)} />;
    } else {
      return (
        <div className={styles.bullet} key={index}>
          {bullet.bulletText}
        </div>
      );
    }
  });

  return (
    <div className={styles.card}>
      {!props.suppressDivider && <div className={styles.divider} />}
      <div className={styles.section}>
        <div className={styles.image}>
          <img alt="" src={props.image} width="60" />
        </div>
        <h2 className={styles.title}>{props.title}</h2>
        {bullets}
      </div>
    </div>
  );
};
