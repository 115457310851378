import { sendCampaignData } from '../../appState/campaign/actions';
import { HealAPI } from '../../services/api';
import { CookieName, setCookie } from '../../utils/cookies';
import { logout } from '../auth/actions';

export enum CurrentStatus {
  GET_CURRENT_STATUS_REQUEST = 'GET_CURRENT_STATUS_REQUEST',
  GET_CURRENT_STATUS_SUCCESS = 'GET_CURRENT_STATUS_SUCCESS',
  GET_CURRENT_STATUS_FAIL = 'GET_CURRENT_STATUS_FAIL',
}

export function getCurrentStatus() {
  return (dispatch: any) => {
    dispatch({ type: CurrentStatus.GET_CURRENT_STATUS_REQUEST });
    HealAPI.getCurrentStatus().then(
      (response) => {
        if (response.ok && response.data?.data) {
          const account = response.data.data;
          dispatch(sendCampaignData());
          dispatch({ type: CurrentStatus.GET_CURRENT_STATUS_SUCCESS, data: account });
        } else {
          setCookie(CookieName.redirect_after_login, window.location.pathname + window.location.search, 1 / 48);
          dispatch(logout());
        }
      },
      (response) => {
        dispatch({ type: CurrentStatus.GET_CURRENT_STATUS_FAIL, data: response.data });
      }
    );
  };
}
