import { getCurrentStatus } from '../../appState/status/actions';
import { HealAPI } from '../../services/api';
import { AddressRequest } from './types';

export enum AddressActions {
  ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST',
  ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS',
  ADD_ADDRESS_FAIL = 'ADD_ADDRESS_FAIL',
  CLEAR_ERROR_LOCATION = 'CLEAR_ERROR_LOCATION',
}

export function addAddress(data: AddressRequest) {
  return (dispatch: any) => {
    dispatch({ type: AddressActions.ADD_ADDRESS_REQUEST });

    HealAPI.addAddress(data).then(
      (response) => {
        if (response.ok) {
          const address = response.data?.data;

          if (address) {
            dispatch(getCurrentStatus());
            dispatch({ type: AddressActions.ADD_ADDRESS_SUCCESS, data: address });
          }
        } else {
          dispatch({ type: AddressActions.ADD_ADDRESS_FAIL, data: response.data?.description });
        }
      },
      (response) => dispatch({ type: AddressActions.ADD_ADDRESS_FAIL, data: response.data?.description })
    );
  };
}
