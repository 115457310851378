import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setAgreementValue, submitAgreements } from '../../appState/agreements/actions';
import { RootState } from '../../appState/rootState';
import { ThemeName } from '../../components/core/Theme';
import { ToggleSwitch } from '../../components/utils/ToggleSwitch';
import { IMAGES, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { isChildVisit } from '../../utils/heal';
import { getNamePossessive } from '../../utils/strings';
import Button from '../core/Button';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import { VACCINE_RECORDS_KEY } from '../fileUploader/FileUploader';
import styles from './Agreements.module.scss';

const Agreements = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { cart, agreements, userPreferences } = useSelector((state: RootState) => state);

  function patientNameForAgreements() {
    if (cart.what && isChildVisit(cart.what.serviceCode) && cart.who) {
      return getNamePossessive(cart.who.firstName) + ' legal guardian';
    } else if (cart.who) {
      return cart.who.firstName + ' ' + cart.who.lastName;
    } else {
      return 'Patient';
    }
  }

  const canSubmit = () => !!agreements.agreements && !Object.values(agreements.agreements).some((agreement) => !agreement.value);

  function onSubmit() {
    if (cart.who && agreements.agreements && !agreements.hasSubmit) {
      const agreementIds = Object.values(agreements.agreements).map((agreement) => agreement.data.id);
      dispatch(submitAgreements(cart.who.id, agreementIds));
    }

    if (cart?.what?.serviceCode === 'ANPS_CHILD') {
      history.push(ROUTES.uploadPage.replace(':code', VACCINE_RECORDS_KEY));
    } else {
      history.push(ROUTES.bookReview);
    }
  }

  function renderAgreements() {
    if (agreements.agreements) {
      const labelText = getString(ResourceKey.bookAgreementsLabel, [patientNameForAgreements() ?? 'Patient']);

      return (
        <div className={styles.agreementList}>
          {Object.values(agreements.agreements).map((agreement) => {
            const { id } = agreement.data;

            return (
              <div className={styles.agreement} key={id}>
                <div id={`${id}_label`}>
                  {labelText}{' '}
                  <a href={agreement.data.fileUrl} target="_blank" rel="noreferrer" aria-label={`${agreement.data.title} opens in new tab`}>
                    {agreement.data.title}
                  </a>
                </div>
                <ToggleSwitch
                  checkboxId={id}
                  on={agreement.value}
                  onToggle={() => dispatch(setAgreementValue(agreement.data.id, !agreement.value))}
                  disabled={agreements.hasSubmit}
                  ariaLabel={`${labelText} ${agreement.data.title}`}
                />
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  }

  return (
    <Page showEmailBanner title={getString(ResourceKey.bookAgreementsTitle)} isLoading={!agreements.agreements} showBackButton>
      <PageHeader
        title={getString(ResourceKey.bookAgreementsTitle)}
        image={userPreferences.theme.name === ThemeName.BLUE_SHIELD ? IMAGES.agreementsBSC : IMAGES.agreements}
      />
      {renderAgreements()}
      <Button text={agreements.hasSubmit ? 'Continue' : 'Agree & Continue'} onClick={onSubmit} disabled={!canSubmit()} type="button" className="btn-block" />
    </Page>
  );
};

export default Agreements;
