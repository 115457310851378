import { FluShotsActions, FluShotsStep } from './actions';
import { FluShotsState } from './types';

export const fluShotsInitialState: FluShotsState = {
  selectedPatients: {},
  step: FluShotsStep.INIT,
};

export function fluShotsReducer(state: FluShotsState = fluShotsInitialState, action: any): FluShotsState {
  const { type, payload } = action;

  switch (type) {
    case FluShotsActions.ADD_SELECTED_PATIENT:
      return {
        ...state,
        selectedPatients: {
          ...state.selectedPatients,
          [payload.patientId]: {
            chargeAmount: payload.chargeAmount,
          },
        },
      };

    case FluShotsActions.REMOVE_SELECTED_PATIENT:
      const selectedPatients = { ...state.selectedPatients };
      delete selectedPatients[payload];

      return {
        ...state,
        selectedPatients,
      };

    case FluShotsActions.SET_SELECTED_PATIENT_BOOK_SUCCESS:
      const { patientId, bookSuccess } = payload;

      return {
        ...state,
        selectedPatients: {
          ...state.selectedPatients,
          [patientId]: {
            ...state.selectedPatients[patientId],
            bookSuccess,
          },
        },
      };

    case FluShotsActions.RESET:
      return fluShotsInitialState;

    case FluShotsActions.SET_STEP:
      return {
        ...state,
        step: payload,
      };

    case FluShotsActions.SET_VISIT:
      return {
        ...state,
        visit: payload,
      };

    default:
      return state;
  }
}
