import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import expireIn from 'redux-persist-transform-expire-in';
import storage from 'redux-persist/lib/storage';
import { getRemoteConfigValue } from '../services/firebase';
import { NotificationActions } from './notification/actions';
import { rootReducer } from './rootReducer';

declare const healConfig: any;

// Slices to persist in local storage via redux-persist
const whitelist = ['cart', 'agreements', 'onsite', 'flag', 'userPreferences', 'campaign'];

// Needed for running against local backend
if (healConfig.env === 'local') {
  whitelist.push('auth');
}

// Expire cart in 24 hours
const cartExpirationTime = 24 * 60 * 60 * 1000;

/*
 * On Page Load Redux-persist will persist 'campaign' state if it has not expired.
 * Then, expiration for 'campaign' will be set to Firebase config default value for
 * 'attribution_session_minutes' because Firebase config has not been fetched yet.
 *
 * Once Firebase config has fetchAndActivated() call updateAttributionExpiry() to
 * manually set the expiration for 'campaign' to the latest config value.
 */
const attributionSessionMinutes = getRemoteConfigValue('attribution_session_minutes').asNumber();
const defaultCampaignExpirationTime = attributionSessionMinutes * 60 * 1000;

export function updateAttributionExpiryIfNeeded() {
  const activeAttributionExpiry = localStorage.getItem('campaign');
  if (!activeAttributionExpiry) {
    return;
  }

  const isAttributionWindowExpired = parseInt(activeAttributionExpiry) <= Date.now();

  if (!isAttributionWindowExpired) {
    const value = getRemoteConfigValue('attribution_session_minutes').asNumber();
    const expireIn = value * 60 * 1000;
    const expireValue = (Date.now() + expireIn).toString();
    localStorage.setItem('campaign', expireValue);
  }
}

const persistConfig = {
  key: 'heal_patient_app',
  version: 1,
  storage,
  whitelist,
  transforms: [expireIn(cartExpirationTime, 'cart'), expireIn(defaultCampaignExpirationTime, 'campaign')],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 100 },
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, NotificationActions.SHOW_BOTTOM_SHEET],
      },
    }),
});

export const persistor = persistStore(store);
