import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AccountState } from '../../appState/account/types';
import { RootState } from '../../appState/rootState';
import { getCurrentStatus } from '../../appState/status/actions';
import { HealAPI } from '../../services/api';
import { createMarkup, getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { BottomSheet } from '../core/BottomSheet';
import { ButtonLink } from '../core/ButtonLink';
import { LegacyButton } from '../core/LegacyButton';
import styles from './VerifyEmail.module.scss';

export interface OwnProps {
  onClose: () => void;
}
interface StateProps {
  account: AccountState;
}
interface DispatchProps {
  actions: {
    getCurrentStatus: () => void;
  };
}

export type VerifyEmailProps = OwnProps & StateProps & DispatchProps;

export interface VerifyEmailState {
  resendingEmailSent?: boolean;
  resendingEmail?: boolean;
}

class Component extends React.Component<VerifyEmailProps, VerifyEmailState> {
  public state: Readonly<VerifyEmailState> = {};

  public render(): JSX.Element | null {
    if (!this.props.account.data) {
      return null;
    }
    return (
      <BottomSheet title={getString(ResourceKey.verifyEmailTitle)} showCloseButton visible onClose={this.onCloseEmailVerificationSheet}>
        <div className={styles.content}>
          <div className={styles.header} dangerouslySetInnerHTML={createMarkup(getString(ResourceKey.verifyEmailContent))} />
          <div className={styles.email}>{this.props.account.data.email}</div>
          {!!this.state.resendingEmailSent && (
            <ButtonLink className={styles.success} text="Sent - check your email" onClick={this.onCloseEmailVerificationSheet} />
          )}
          {!this.state.resendingEmailSent && (
            <LegacyButton
              className={styles.button}
              onClick={this.onClickResendEmail}
              text={getString(ResourceKey.verifyEmailResendButton)}
              testId="btn_closeBottomSheet"
              loader={this.state.resendingEmail}
              disabled={this.state.resendingEmail}
            />
          )}
          <div className={styles.support} dangerouslySetInnerHTML={createMarkup(getString(ResourceKey.verifyEmailResendSupport))} />
        </div>
      </BottomSheet>
    );
  }

  private onCloseEmailVerificationSheet = () => {
    if (this.state.resendingEmailSent) {
      this.props.actions.getCurrentStatus();
    }
    this.setState({ resendingEmail: false, resendingEmailSent: false });
    this.props.onClose();
  };

  private onClickResendEmail = () => {
    this.setState({ resendingEmail: true });
    HealAPI.resendEmailVerification().then((response) => {
      this.setState({ resendingEmail: false, resendingEmailSent: true });
    });
  };
}

export function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
  return {
    account: state.userAccountInfo,
  };
}

export function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    actions: bindActionCreators({ getCurrentStatus }, dispatch),
  };
}

export const VerifyEmail = connect(mapStateToProps, mapDispatchToProps)(Component);
