import { PatientActions } from '../../appState/patient/actions';
import { PatientSecondaryContact } from '../patient/types';
import { Visit } from '../visit/types';
import { ThankYouActions } from './actions';
import { ThankYouState } from './types';

export const thankYouInitialState: ThankYouState = {
  isFetching: false,
};

function handleGetVisitByVisitCodeRequest(state: ThankYouState): ThankYouState {
  return {
    isFetching: true,
    error: undefined,
    data: undefined,
  };
}

function handleGetVisitByVisitCodeSuccess(state: ThankYouState, data: Visit): ThankYouState {
  return {
    isFetching: false,
    error: undefined,
    data,
  };
}

function handleGetVisitByVisitCodeFailure(state: ThankYouState, error: string): ThankYouState {
  return {
    isFetching: false,
    error,
    data: undefined,
  };
}

function handleUpdateSecondaryContact(state: ThankYouState, patientId: string, secondaryContact: PatientSecondaryContact): ThankYouState {
  if (state.data?.patient.id === patientId) {
    return {
      ...state,
      data: {
        ...state.data,
        patient: { ...state.data.patient, secondaryContact },
      },
    };
  }
  return state;
}

export function thankYouReducer(state: ThankYouState = thankYouInitialState, action: any): ThankYouState {
  switch (action.type) {
    case ThankYouActions.GET_VISIT_BY_VISIT_CODE_REQUEST:
      return handleGetVisitByVisitCodeRequest(state);
    case ThankYouActions.GET_VISIT_BY_VISIT_CODE_SUCCESS:
      return handleGetVisitByVisitCodeSuccess(state, action.data);
    case ThankYouActions.GET_VISIT_BY_VISIT_CODE_FAIL:
      return handleGetVisitByVisitCodeFailure(state, action.data);
    case PatientActions.UPDATE_SECONDARY_CONTACT:
      return handleUpdateSecondaryContact(state, action.data.patientId, action.data.secondaryContact);
    default:
      return state;
  }
}
