import { FlagActions } from './actions';
import { FlagState } from './types';
import { ReduxAction } from '../../appState/types';

export const flagInitialState: FlagState = {
  infinite: true,
  name: {},
};

export function flagReducer(state: FlagState = flagInitialState, action: ReduxAction): FlagState {
  switch (action.type) {
    case FlagActions.SET_FLAG:
      return {
        ...state,
        name: {
          ...state.name,
          [action.data.name]: action.data.value,
        },
      };

    case FlagActions.CLEAR_FLAG:
      const { [action.data.name as string]: value, ...newName } = state.name;
      return {
        ...state,
        name: newName,
      };

    default:
      return state;
  }
}
