import { QuickActionsActions } from './actions';
import { QuickActionsPatient, QuickActionsState } from './types';

export const quickActionInitialState: QuickActionsState = {
  isFetching: false,
  error: null,
  success: false,
  data: null,
};

function handleQuickActionsRequest(state: QuickActionsState): QuickActionsState {
  return {
    isFetching: true,
    success: false,
    error: null,
    data: null,
  };
}

function handleQuickActionsSuccess(state: QuickActionsState, data: QuickActionsPatient): QuickActionsState {
  return {
    isFetching: false,
    success: true,
    error: null,
    data,
  };
}

function handleQuickActionsFailure(state: QuickActionsState, error: any): QuickActionsState {
  return {
    isFetching: false,
    success: false,
    error,
    data: null,
  };
}

export function quickActionsReducer(
  state: QuickActionsState = quickActionInitialState,
  // action: Action & { data: any }
  action: any
): QuickActionsState {
  switch (action.type) {
    case QuickActionsActions.GET_QUICK_ACTIONS_REQUEST:
      return handleQuickActionsRequest(state);
    case QuickActionsActions.GET_QUICK_ACTIONS_SUCCESS:
      return handleQuickActionsSuccess(state, action.data);
    case QuickActionsActions.GET_QUICK_ACTIONS_FAIL:
      return handleQuickActionsFailure(state, action.data);

    default:
      return state;
  }
}
