import React, { useMemo } from 'react';
import { css } from '../../utils/css';

interface InputProps extends React.HTMLProps<HTMLTextAreaElement> {
  label?: string;
  error?: React.ReactNode;
  testId?: string;
  id: string;
  lpIgnore?: boolean; // Disables LastPass autofill & hides icons
  helpText?: React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>; // Overrides incorrect React.FormEventHandler<HTMLSelectElement> inherited prop type
}

/**
 * Uses React.forwardRef to allow passing a ref prop down to the 'textarea' element (required for React Hook Form).
 */
const TextArea = React.forwardRef<HTMLTextAreaElement, InputProps>(
  ({ label, placeholder = ' ', error, testId, lpIgnore = true, helpText, className, ...props }, ref) => {
    const ariaDescribedBy = useMemo(() => {
      if (helpText && error) {
        return `${props.id}_help ${props.id}_error`;
      } else if (helpText) {
        return `${props.id}_help`;
      } else if (error) {
        return `${props.id}_error`;
      }
    }, [error, helpText, props.id]);

    return (
      <div className={css('form-group', { invalid: !!error, required: !!props.required }, className)}>
        {label && (
          <label htmlFor={props.id} className="form-label">
            {label}
          </label>
        )}

        <div className="form-control-wrapper">
          <textarea
            aria-label={placeholder}
            ref={ref}
            className="form-control"
            placeholder={placeholder}
            name={props.id}
            data-lpignore={lpIgnore}
            data-tid={testId || `inp_${props.id}`}
            aria-invalid={!!error}
            aria-describedby={ariaDescribedBy}
            {...props}
          />
        </div>

        {helpText && (
          <div className="form-control-help" id={`${props.id}_help`} role="alert">
            {helpText}
          </div>
        )}

        {error && (
          <div className="form-control-error" id={`${props.id}_error`} role="alert">
            {error}
          </div>
        )}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';
export default TextArea;
