import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { visitRescheduled } from '../../appState/visit/actions';
import { Visit } from '../../appState/visit/types';
import { HealAPI } from '../../services/api';
import { IMAGES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { TimeSlot } from '../../types/TimeSlot';
import { formatTimeslot, formatTimeslotForTodayOrTomorrow, getZeroTimeDate } from '../../utils/date';
import { LegacyButton } from '../core/LegacyButton';
import { Loading } from '../core/Loading';
import TimeSlots from '../schedule/TimeSlots';
import { NotificationsBox } from '../utils/NotificationsBox';
import styles from './RescheduleVisit.module.scss';

enum Screen {
  Loading,
  QuickPick,
  PickATimeSlot,
  Success,
  Failure,
}

interface DispatchActions {
  actions?: {
    visitRescheduled: (visitCode: string, timeSlot: TimeSlot, canReschedule: boolean) => void;
  };
}

export interface RescheduleVisitProps extends DispatchActions {
  visit: Visit;
  onRescheduled: () => void;
}

export interface RescheduleVisitState {
  timeSlots?: TimeSlot[];
  screen: Screen;
  selectedTimeSlotId?: string;
  selectedDateTime?: number;
  slot1?: TimeSlot;
  slot2?: TimeSlot;
  error?: string;
  selectedTimeSlot?: TimeSlot;
}

class Component extends React.Component<RescheduleVisitProps, RescheduleVisitState> {
  constructor(props: RescheduleVisitProps) {
    super(props);
    this.state = {
      screen: Screen.Loading,
    };
  }

  public componentDidMount() {
    this.getTimeSlots();
  }

  public render(): JSX.Element | null {
    switch (this.state.screen) {
      case Screen.Loading:
        return <Loading padding={20} />;

      case Screen.QuickPick:
        return this.renderQuickPickContent();

      case Screen.PickATimeSlot:
        return this.renderPickTimeContent();

      case Screen.Success:
        return this.renderSuccessContent();

      case Screen.Failure:
        return this.renderFailureContent();

      default:
        return null;
    }
  }

  private renderQuickPickContent(): JSX.Element {
    return (
      <div className={styles.container}>
        <div className={styles.visitSummaryContainer}>
          <div className={styles.title}>{getString(ResourceKey.bottomSheetRescheduleVisitScheduledFor).replace('%s', this.props.visit.patient.firstName)}</div>
          <div className={styles.time}>{this.formatVisitTime(this.props.visit)}</div>
        </div>
        <div className={styles.quickPickContainer}>
          <div className={styles.text}>{getString(ResourceKey.bottomSheetRescheduleVisitMessage)}</div>
          {this.state.slot1 && (
            <LegacyButton
              className={styles.button}
              text={this.formatTimeslotData(this.state.slot1)}
              onClick={this.onFirstAvailableClick}
              testId="btn_FirstAvailableTime"
            />
          )}
          {this.state.slot2 && (
            <LegacyButton
              className={styles.button}
              text={this.formatTimeslotData(this.state.slot2)}
              onClick={this.onSecondAvailableClick}
              testId="btn_SecondAvailableTime"
            />
          )}
          <LegacyButton
            className={styles.secondaryButton}
            text={getString(ResourceKey.bottomSheetRescheduleVisitPickAnotherTime)}
            onClick={this.onPickTimeClick}
            testId="btn_PickAnotherTime"
          />
        </div>
      </div>
    );
  }

  private renderPickTimeContent(): JSX.Element {
    return (
      <div className={styles.container}>
        <div className={styles.visitSummaryContainer}>
          <div className={styles.title}>{getString(ResourceKey.bottomSheetRescheduleVisitScheduledFor).replace('%s', this.props.visit.patient.firstName)}</div>
          <div className={styles.time}>{this.formatVisitTime(this.props.visit)}</div>
        </div>
        <div className={styles.pickTimeslotContainer}>
          <div className={styles.text}>{getString(ResourceKey.bottomSheetReschedulePickTimeMessage)}</div>

          <TimeSlots
            timeSlots={this.state.timeSlots || []}
            onChangeDate={this.onDateChange}
            onChangeTimeSlot={this.onTimeSlotChange}
            isFetching={false}
            isOnsite={!!this.props.visit.isOnSite}
            isPreferredDoctor={this.props.visit.isPreferredDoctorVisit}
          />

          <LegacyButton
            className={styles.rescheduleButton}
            text={getString(ResourceKey.bottomSheetRescheduleVisitButton)}
            disabled={!this.canSubmitReschedule()}
            onClick={this.onSubmitReschedule}
            testId="btn_SubmitReschedule"
          />
        </div>
      </div>
    );
  }

  private renderSuccessContent(): JSX.Element {
    const { visit } = this.props;
    const { selectedTimeSlot } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.thankYouContainer}>
          <img className={styles.image} alt="" src={IMAGES.success} />
          <div className={styles.textContainer}>
            <div className={styles.text}>{getString(ResourceKey.bottomSheetRescheduleThankYou)}</div>
            <div className={styles.text}>{getString(ResourceKey.bottomSheetRescheduleThankYouSuccess)}</div>
          </div>
        </div>
        <div className={styles.visitSummaryContainer}>
          <div className={styles.title}>{visit.patient.fullName}</div>
          <div className={styles.time}>
            {formatTimeslot(selectedTimeSlot!.startTime * 1000, (selectedTimeSlot!.startTime + selectedTimeSlot!.duration) * 1000, selectedTimeSlot!.timeZone)}
          </div>
          <div className={styles.time}>{visit.service.shortName}</div>
          <div className={styles.time}>${visit.chargeAmount}</div>
          <div className={styles.time}>{visit.address.address}</div>
        </div>
      </div>
    );
  }

  private renderFailureContent(): JSX.Element {
    return (
      <div className={styles.container}>
        <NotificationsBox
          type="warning"
          content={this.state.error || getString(ResourceKey.bottomSheetRescheduleVisitErrorMessage)}
          title={getString(ResourceKey.bottomSheetRescheduleVisitErrorTitle)}
        />
        <LegacyButton
          className={styles.tryAgainButton}
          text={getString(ResourceKey.bottomSheetRescheduleVisitTryAgainButton)}
          onClick={this.onTryAgainClick}
          testId={'btn_TryAgain'}
        />
      </div>
    );
  }

  private onDateChange = (date: Date) => {
    if (this.state.selectedDateTime !== date.getTime()) {
      this.setState({
        selectedTimeSlotId: '',
        selectedDateTime: date.getTime(),
      });
    }
  };

  private onTimeSlotChange = (timeSlot: TimeSlot) => {
    this.setState({ selectedTimeSlotId: timeSlot.id });
  };

  private canSubmitReschedule(): boolean {
    return !!this.state.selectedTimeSlotId;
  }

  private onSubmitReschedule = () => {
    this.reschedule(this.state.selectedTimeSlotId!);
  };

  private onFirstAvailableClick = () => {
    this.reschedule(this.state.slot1!.id);
  };

  private onSecondAvailableClick = () => {
    this.reschedule(this.state.slot2!.id);
  };

  private onPickTimeClick = () => {
    this.setState({ screen: Screen.PickATimeSlot });
  };

  private onTryAgainClick = () => {
    this.getTimeSlots();
  };

  private reschedule(timeSlotId: string) {
    const { props, state } = this;
    const timeSlot = state.timeSlots?.find((t) => t.id === timeSlotId);

    if (timeSlot) {
      this.setState({ screen: Screen.Loading, selectedTimeSlot: timeSlot });

      HealAPI.rescheduleVisit(props.visit.code, timeSlot.id).then((response) => {
        if (response.ok) {
          HealAPI.getVisit(props.visit.code).then((visitResponse) => {
            if (visitResponse.ok) {
              const rescheduledVisit = visitResponse.data?.data;

              if (rescheduledVisit) {
                this.setState({ screen: Screen.Success });
                props.actions?.visitRescheduled(props.visit.code, timeSlot, rescheduledVisit.canReschedule);
                props.onRescheduled();
              }
            } else {
              this.setState({
                screen: Screen.Failure,
                error: visitResponse.data?.description,
              });
            }
          });
        } else {
          this.setState({
            screen: Screen.Failure,
            error: response.data?.description,
          });
        }
      });
    }
  }

  private getTimeSlots() {
    const { visit } = this.props;
    this.setState({
      screen: Screen.Loading,
      timeSlots: undefined,
      selectedTimeSlotId: undefined,
      selectedDateTime: undefined,
      slot1: undefined,
      slot2: undefined,
      error: undefined,
    });

    HealAPI.getTimeSlots(visit.service.serviceCode, visit.address.addressId, visit.code).then((response) => {
      const timeSlots = response.data?.data;

      if (timeSlots) {
        const maxDate = getZeroTimeDate();
        maxDate.setDate(maxDate.getDate() + 2);
        let slot1: TimeSlot | undefined = undefined;
        let slot2: TimeSlot | undefined = undefined;

        for (let i = 0; i < timeSlots.length; i++) {
          if (timeSlots[i].id === 'ASAP') {
            continue;
          }

          if (timeSlots[i].startTime + timeSlots[i].duration === visit.timeSlot.startTime + visit.timeSlot.duration) {
            continue;
          }

          if (timeSlots[i].startTime * 1000 >= maxDate.getTime()) {
            break;
          }

          if (!slot1) {
            slot1 = timeSlots[i];
          } else if (!slot2) {
            slot2 = timeSlots[i];
            break;
          }
        }

        // This is done outside of the loop above to ensure the current active slot is removed
        for (let i = 0; i < timeSlots.length; i++) {
          if (timeSlots[i].startTime === visit.timeSlot.startTime) {
            timeSlots.splice(i, 1);
          }
        }

        if (slot1 || slot2) {
          this.setState({
            timeSlots,
            slot1,
            slot2,
            screen: Screen.QuickPick,
          });
        } else {
          this.setState({
            timeSlots,
            screen: Screen.PickATimeSlot,
          });
        }
      }
    });
  }

  private formatTimeslotData(slot?: TimeSlot): string {
    if (!slot) {
      return '';
    }

    return formatTimeslotForTodayOrTomorrow(slot.startTime * 1000, (slot.startTime + slot.duration) * 1000, slot.timeZone);
  }

  private formatVisitTime(visit: Visit): string {
    return formatTimeslot(visit.timeSlot.startTime * 1000, (visit.timeSlot.startTime + visit.timeSlot.duration) * 1000, visit.timeSlot.timeZone);
  }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: RescheduleVisitProps): RescheduleVisitProps {
  return {
    ...ownProps,
    actions: bindActionCreators({ visitRescheduled }, dispatch),
  };
}

export const RescheduleVisit = connect(undefined, mapDispatchToProps)(Component);
