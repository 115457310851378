import { ApiResponse } from 'apisauce';
import { Dispatch } from 'redux';
import { HealAPI } from '../../services/api';
import { AgreementsResponse } from './types';

export enum AgreementsActions {
  GET_AGREEMENTS_REQUEST = 'GET_AGREEMENTS_REQUEST',
  GET_AGREEMENTS_SUCCESS = 'GET_AGREEMENTS_SUCCESS',
  GET_AGREEMENTS_FAIL = 'GET_AGREEMENTS_FAIL',
  SET_AGREEMENT_VALUE = 'SET_AGREEMENT_VALUE',
  SUBMIT_AGREEMENTS_REQUEST = 'SUBMIT_AGREEMENTS_REQUEST',
  SUBMIT_AGREEMENTS_SUCCESS = 'SUBMIT_AGREEMENTS_SUCCESS',
  SUBMIT_AGREEMENTS_FAIL = 'SUBMIT_AGREEMENTS_FAIL',
  RESET_AGREEMENTS = 'RESET_AGREEMENTS',
}

export function getAgreements(patientId: string, addressId?: string, partnerAddressId?: string, serviceCode?: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AgreementsActions.GET_AGREEMENTS_REQUEST,
    });

    HealAPI.getAgreements(patientId, addressId, partnerAddressId, serviceCode).then((response) => {
      if (response.ok) {
        dispatch({
          type: AgreementsActions.GET_AGREEMENTS_SUCCESS,
          data: response.data,
        });
      } else {
        dispatch({
          type: AgreementsActions.GET_AGREEMENTS_FAIL,
        });
      }
    });
  };
}

export function submitAgreements(patientId: string, agreementIds: string[]) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AgreementsActions.SUBMIT_AGREEMENTS_REQUEST,
    });

    HealAPI.submitAgreements(patientId, agreementIds).then((response: ApiResponse<AgreementsResponse>) => {
      if (response.ok) {
        dispatch({
          type: AgreementsActions.SUBMIT_AGREEMENTS_SUCCESS,
        });
      } else {
        dispatch({
          type: AgreementsActions.SUBMIT_AGREEMENTS_FAIL,
        });
      }
    });
  };
}

export function setAgreementValue(agreementId: string, value: boolean) {
  return {
    type: AgreementsActions.SET_AGREEMENT_VALUE,
    data: {
      agreementId,
      value,
    },
  };
}

export function resetAgreements() {
  return {
    type: AgreementsActions.RESET_AGREEMENTS,
  };
}
