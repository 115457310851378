import { yupResolver } from '@hookform/resolvers/yup';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { object } from 'yup';
import { clearError, createAccount } from '../../appState/auth/actions';
import { RootState } from '../../appState/rootState';
import { IMAGES, REDIRECT_AFTER_LOGIN_BLOCKLIST, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages/index';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { CookieName, getCookie, removeCookie } from '../../utils/cookies';
import { getNumDays, MONTHS } from '../../utils/date';
import { YUP_VALIDATIONS } from '../../utils/forms';
import { splitPathnameAndSearch } from '../../utils/strings';
import { isLocal, isTesting } from '../../utils/utils';
import { BottomSheet } from '../core/BottomSheet';
import Button from '../core/Button';
import Input from '../core/Input';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import Select, { SelectOption } from '../core/Select';
import { PartnerRegisterPopup } from '../register/PartnerRegisterPopup';
import { NotificationsBox } from '../utils/NotificationsBox';
import styles from './RegisterPage.module.scss';

interface RegisterFormData {
  firstName: string;
  lastName: string;
  sex: string;
  dobMonth: string;
  dobDay: string;
  dobYear: string;
  email: string;
  phone: string;
  password: string;
  zipcode: string;
}

const RegisterPage = () => {
  const [creating, setCreating] = useState(false);
  const [openFlyout, setOpenFlyout] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();

  const { auth, campaign, config } = useSelector((state: RootState) => state);

  const removeRedirectCookie = useRef(false);

  const showErrorAutoLoginFlyout = auth.error === getString(ResourceKey.errorAutoLogin);

  const params = useMemo(() => new URLSearchParams(location.search), [location]);
  const utmCampaign = params.get('utm_campaign') || undefined;
  const utmSource = params.get('utm_source') || undefined;
  const utmMedium = params.get('utm_medium') || undefined;

  useEffect(() => {
    if (!!authState?.isAuthenticated) {
      const publicCode = getCookie(CookieName.public_code);

      if (publicCode) {
        history.push(ROUTES.workplace.replace(':code', publicCode));
      } else {
        let pathname = ROUTES.bookPatient;
        const redirectAfterLogin = getCookie(CookieName.redirect_after_login);

        if (redirectAfterLogin) {
          pathname = redirectAfterLogin === '/' ? ROUTES.bookPatient : redirectAfterLogin;
          removeRedirectCookie.current = true;
        }

        const pathnameAndSearch = splitPathnameAndSearch(pathname);

        if (REDIRECT_AFTER_LOGIN_BLOCKLIST.indexOf(pathnameAndSearch.pathname) === -1) {
          return history.push(pathnameAndSearch);
        } else {
          return history.push(ROUTES.bookPatient);
        }
      }
    }
  }, [authState, history]);

  useEffect(() => {
    setOpenFlyout(!!utmCampaign);
    dispatch(clearError());

    return () => {
      if (removeRedirectCookie.current) {
        removeCookie(CookieName.redirect_after_login);
      }
    };
  }, [params, utmCampaign, utmSource, dispatch]);

  useEffect(() => {
    if (!auth.isFetching && auth.error) {
      setCreating(false);
      window?.scroll({ top: 0, behavior: 'smooth' });
    }
  }, [auth]);

  const envVars = isLocal && !isTesting ? process.env : undefined;

  const {
    watch,
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<RegisterFormData>({
    mode: 'onTouched',
    resolver: yupResolver(
      object().shape({
        firstName: YUP_VALIDATIONS.firstName,
        lastName: YUP_VALIDATIONS.lastName,
        sex: YUP_VALIDATIONS.sex,
        dobMonth: YUP_VALIDATIONS.dobMonth,
        dobDay: YUP_VALIDATIONS.dobDay,
        dobYear: YUP_VALIDATIONS.dobYear,
        email: YUP_VALIDATIONS.email,
        phone: YUP_VALIDATIONS.phone,
        password: YUP_VALIDATIONS.passwordRegister,
        zipcode: YUP_VALIDATIONS.zipcode,
      })
    ),
    defaultValues: {
      firstName: params.get('first_name') || '',
      lastName: params.get('last_name') || '',
      email: params.get('email') || '',
      sex: '',
      phone: '',
      password: '',
    },
  });

  // Date of birth <option>'s
  const watchDobMonth = watch('dobMonth');
  const watchDobDay = watch('dobDay');
  const watchDobYear = watch('dobYear');

  const dobMonthOptions = useMemo<SelectOption[]>(() => MONTHS.map((month, index) => ({ value: index + 1, text: month.text })), []);

  const dobDayOptions = useMemo<SelectOption[]>(() => {
    const days = [];
    const numDays = getNumDays(parseInt(watchDobMonth), parseInt(watchDobYear));

    for (let i = 1; i <= numDays; i++) {
      days.push({ text: i.toString(), value: i });
    }

    return days;
  }, [watchDobMonth, watchDobYear]);

  const dobYearOptions = useMemo<SelectOption[]>(() => {
    const years = [];

    for (let i = new Date().getFullYear(); i >= 1900; i--) {
      years.push({ text: i.toString(), value: i });
    }

    return years;
  }, []);

  function onSubmit(data: RegisterFormData) {
    setCreating(true);
    dispatch(
      createAccount({
        firstName: data.firstName,
        lastName: data.lastName,
        genderId: data.sex,
        dateOfBirth: `${data.dobMonth}/${data.dobDay}/${data.dobYear}`,
        mobile: data.phone,
        password: data.password,
        username: data.email,
        signupZipCode: data.zipcode,
        campaign: (campaign.branchDataToSend as any) ?? {
          campaign: utmCampaign,
          source: utmSource,
          channel: utmSource,
          medium: utmMedium,
        },
        attribution: campaign.attribution ?? {
          campaign: utmCampaign,
          source: utmSource,
          medium: utmMedium,
        },
      })
    );
  }

  function onClose() {
    setOpenFlyout(false);
  }

  const navButtons = [{ action: () => history.push(ROUTES.login), text: getString(ResourceKey.signInButtonSignIn) }];

  return (
    <Page title={getString(ResourceKey.SSORegisterTitle)} navButtons={navButtons} hideTitleInNavbar>
      <PageHeader title="Tell Us About Yourself" image={IMAGES.registerIcon} />
      <div className={styles.alreadyHaveAccount}>
        <span>{getString(ResourceKey.registerContentTitle)}</span>
        <br />
        <span>{getString(ResourceKey.signInHaveAccount)}</span>
        <Link to={ROUTES.login}>{getString(ResourceKey.signInHaveAccountSignIn)}</Link>
      </div>

      {auth.error && <NotificationsBox type="error" content={auth.error || getString(ResourceKey.errorGeneric)} />}

      <form onSubmit={handleSubmit(onSubmit)} title="Register account">
        <Input label="First name" id="firstName" error={errors.firstName?.message} required {...register('firstName')} />

        <Input label="Last name" id="lastName" error={errors.lastName?.message} required {...register('lastName')} />

        <Select
          label="Sex"
          id="sex"
          options={config.gendersForRadio.map((option) => ({ value: option.key, text: option.text }))}
          error={errors.sex?.message}
          required
          {...register('sex')}
        />

        <div className="form-group required" role="group" aria-labelledby="dobLabel">
          <div className="form-label" id="dobLabel">
            Date of birth
          </div>
          <div className="form-control-group">
            <Select
              id="dobMonth"
              label="Month"
              className={`form-control-month ${watchDobMonth ? '' : 'empty'}`}
              error={errors.dobMonth?.message}
              options={dobMonthOptions}
              required
              {...register('dobMonth')}
            />
            <Select
              id="dobDay"
              label="Day"
              className={`form-control-day ${watchDobDay ? '' : 'empty'}`}
              error={errors.dobDay?.message}
              options={dobDayOptions}
              required
              {...register('dobDay')}
            />
            <Select
              id="dobYear"
              label="Year"
              className={`form-control-year ${watchDobYear ? '' : 'empty'}`}
              error={errors.dobYear?.message}
              options={dobYearOptions}
              required
              {...register('dobYear')}
            />
          </div>
        </div>

        <Input
          label="Email"
          id="email"
          helpText={getString(ResourceKey.registerEmailVerifyText)}
          error={errors.email?.message}
          required
          {...register('email')}
        />

        <Input label="ZIP code" id="zipcode" error={errors.zipcode?.message} required {...register('zipcode')} />

        <Controller
          name="phone"
          render={({ field }) => (
            <div className={`form-group required ${errors.phone ? 'invalid' : ''}`}>
              <label className="form-label" htmlFor={field.name}>
                Phone
              </label>
              <PhoneInput
                value={field.value}
                inputProps={{
                  name: field.name,
                  id: field.name,
                  required: true,
                  'data-lpignore': true,
                  'aria-describedby': errors.phone ? `${field.name}_error` : undefined,
                  'data-tid': `inp_${field.name}`,
                }}
                onChange={(_value, _data, _event, formattedValue) => field.onChange(formattedValue)}
                onBlur={field.onBlur}
                inputClass="form-control"
                country="us"
                onlyCountries={['us']}
                placeholder="+1 (702) 123-4567"
                disableSearchIcon
              />
              {errors.phone && (
                <div className="form-control-error" id={`${field.name}_error`}>
                  {errors.phone?.message}
                </div>
              )}
            </div>
          )}
          control={control}
        />

        <Input label="Password" id="password" type="password" error={errors.password?.message} required {...register('password')} />

        <div className={styles.termsAndConditions}>
          <div>{getString(ResourceKey.registerLegalUrlLinks)}</div>
          <a href="https://www.humana.com/legal/privacy-policy" target="_blank" rel="noreferrer" aria-label="Privacy Policy (opens in new tab)">
            {getString(ResourceKey.registerPrivacyPolicy)}
          </a>
          ,&nbsp;
          <a href="https://www.humana.com/legal" target="_blank" rel="noreferrer" aria-label="Terms of Service (opens in new tab)">
            {getString(ResourceKey.registerTermsOfService)}
          </a>
          ,&nbsp;
          <a
            href="https://www.centerwell.com/en/privacy-practices-footer.html"
            target="_blank"
            rel="noreferrer"
            aria-label="Notice of Privacy Practices (opens in new tab)"
          >
            {getString(ResourceKey.registerPrivacyPractices)}
          </a>
          , and&nbsp;
          <a
            href="https://www.humana.com/legal/non-discrimination-disclosure"
            target="_blank"
            rel="noreferrer"
            aria-label="Nondiscrimination Notice (opens in new tab)"
          >
            {getString(ResourceKey.registerNondiscriminationNotice)}
          </a>
        </div>

        <Button text="Create account" disabled={auth.isFetching} loading={auth.isFetching || creating} testId="btn_pageBottom" className="btn-block" />
      </form>

      {openFlyout && (
        <BottomSheet title={getString(ResourceKey.partnerPopup)} visible onClose={onClose} partner={utmSource}>
          <PartnerRegisterPopup onRegister={onClose} partner={utmSource} />
        </BottomSheet>
      )}

      {showErrorAutoLoginFlyout && (
        <BottomSheet showCloseButton={false} title="Automatic Login Failed" onClose={() => history.push(ROUTES.login)} testId="sheetLogin" visible>
          <div className={styles.sheetContainer}>
            <div data-tid="txt_sheetLoginMessage">{getString(ResourceKey.errorAutoLogin)}</div>
            <Button
              className={styles.sheetButton}
              onClick={() => {
                history.push(ROUTES.login);
              }}
              text={getString(ResourceKey.genericButtonOk)}
            />
          </div>
        </BottomSheet>
      )}
    </Page>
  );
};

export default RegisterPage;
