import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';

declare const healConfig: any;

export const oktaConfig: OktaAuthOptions = {
  clientId: healConfig.oktaClientId,
  issuer: healConfig.oktaIssuer,
  redirectUri: window.location.origin + '/login/callback',
  postLogoutRedirectUri: window.location.origin + '/login',
  transformAuthState: async (oktaAuth, authState) => {
    const tokens = await oktaAuth.tokenManager.getTokensSync();
    const accessToken = tokens.accessToken;
    const isSSOLogin = !authState.isAuthenticated && !!accessToken;

    /*
     * A SAML flow user will have their accessToken set but won't have be an authenticated authState.
     * Here we'll look for that manually set accessToken and bad state. If it appears it is an SSOLogin
     * update authState to reflect authState.isAuthenticated. Now the user can navigate protected routes.
     */
    if (isSSOLogin) {
      authState.isAuthenticated = true;
      authState.accessToken = accessToken;
      return authState;
    }

    if (!authState.isAuthenticated) {
      return authState;
    }

    return authState;
  },
};

export const oktaAuth = new OktaAuth(oktaConfig);
