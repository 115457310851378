import { Dispatch } from 'redux';
import { AgreementsActions } from '../../appState/agreements/actions';
import { ReduxAction } from '../../appState/types';
import { HealAPI } from '../../services/api';
import { FileUpload } from '../../types/Files';
import { TimeSlot } from '../../types/TimeSlot';
import { resetServices } from '../services';
import { resetTimeSlots } from '../timeSlots';
import { CartPreferredDoctor, Location, PatientIdentity, Reason } from './types';

export enum CartActions {
  SET_PATIENT = 'CART_SET_PATIENT',
  SET_PREFERRED_DOCTOR = 'CART_SET_PREFERRED_DOCTOR',
  SET_REASON = 'CART_SET_REASON',
  SET_PAYMENT = 'CART_SET_PAYMENT',
  SET_TIMESLOT = 'CART_SET_TIMESLOT',
  SET_LOCATION = 'CART_SET_LOCATION',
  SET_PAYMENT_OPTIONS = 'CART_SET_PAYMENT_OPTIONS',
  SET_VISIT_NOTES = 'CART_SET_VISIT_NOTES',
  ADD_FILE = 'ADD_FILE',
  REMOVE_FILE = 'REMOVE_FILE',
  SET_COVID_SYMPTOMS = 'SET_COVID_SYMPTOMS',
  RESET_COVID_SYMPTOMS = 'RESET_COVID_SYMPTOMS',
  RESET = 'CART_RESET',
}

export function setLocation(location: Location) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CartActions.SET_LOCATION,
      data: location,
    });

    dispatch(resetTimeSlots());
  };
}

export function setPatient(patient: PatientIdentity) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CartActions.SET_PATIENT,
      data: patient,
    });

    dispatch({
      type: AgreementsActions.RESET_AGREEMENTS,
    });
    dispatch(resetServices());
  };
}

export function setPreferredDoctor(preferredDoctor?: CartPreferredDoctor): ReduxAction<CartPreferredDoctor> {
  return {
    type: CartActions.SET_PREFERRED_DOCTOR,
    data: preferredDoctor,
  };
}

export function setReason(reason: Reason) {
  const { info, ...reasonWithoutSymptoms } = reason;

  return (dispatch: Dispatch) => {
    dispatch({
      type: CartActions.SET_REASON,
      data: reason,
    });

    dispatch(resetTimeSlots());
  };
}

export function setPayment(amount: string): ReduxAction<{}> {
  return {
    type: CartActions.SET_PAYMENT,
    data: { amount },
  };
}

export function setTimeSlot(timeSlot: TimeSlot): ReduxAction<TimeSlot> {
  return {
    type: CartActions.SET_TIMESLOT,
    data: timeSlot,
  };
}

export function setVisitNotes(notes?: string): ReduxAction<string> {
  return {
    type: CartActions.SET_VISIT_NOTES,
    data: notes,
  };
}

export function resetCart(): ReduxAction<{}> {
  return {
    type: CartActions.RESET,
  };
}

export function setPaymentOptions(option: string): ReduxAction<string> {
  return {
    type: CartActions.SET_PAYMENT_OPTIONS,
    data: option,
  };
}

export function addFile(file: FileUpload): ReduxAction<string> {
  return {
    type: CartActions.ADD_FILE,
    data: file.createFileResponse!.id,
  };
}

function deleteFileWithRetry(patientId: string, fileId: string, retries: number = 3) {
  HealAPI.deleteFile(patientId, fileId).then((response) => {
    if (!response.ok) {
      if (retries > 0) {
        deleteFileWithRetry(patientId, fileId, --retries);
      }
    }
  });
}

export function deleteFile(patientId: string, fileId: string): ReduxAction<string> {
  deleteFileWithRetry(patientId, fileId);
  return { type: CartActions.REMOVE_FILE, data: fileId };
}

export function removeFile(fileId: string): ReduxAction<string> {
  return {
    type: CartActions.REMOVE_FILE,
    data: fileId,
  };
}

// @deprecated
export function setCovidSymptoms(covidSymptoms: string[]): ReduxAction<string[]> {
  return {
    type: CartActions.SET_COVID_SYMPTOMS,
    data: covidSymptoms,
  };
}

// @deprecated
export function resetCovidSymptoms(): ReduxAction {
  return {
    type: CartActions.RESET_COVID_SYMPTOMS,
  };
}
