import * as React from 'react';
import { css } from '../../utils/css';
import { AccessibleElement } from './accessibility/AccessibleElement';
import styles from './ToggleSwitch.module.scss';

export interface ToggleSwitchProps {
  onToggle: () => void;
  checkboxId: string;
  on: boolean;
  labelledBy?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  ariaLabel?: string;
}

export class ToggleSwitch extends React.Component<ToggleSwitchProps> {
  public render(): JSX.Element {
    return (
      <AccessibleElement
        className={css(styles.switchContainer, this.props.disabled && styles.disabled)}
        onClick={this.toggle}
        aria-labelledby={this.props.labelledBy}
        style={this.props.style}
        role="switch"
        aria-checked={this.props.on}
        aria-label={this.props.ariaLabel}
      >
        <input id={this.props.checkboxId} type="checkbox" onChange={this.toggle} className={styles.switchInput} checked={this.props.on} tabIndex={-1} />
        <span className={styles.switchLabel} data-on="Yes" data-off="No" />
        <span className={styles.switchHandle} />
      </AccessibleElement>
    );
  }

  private toggle = () => {
    if (!this.props.disabled) {
      this.props.onToggle();
    }
  };
}
