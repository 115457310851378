import { Dispatch } from 'redux';
import { HealAPI } from '../../services/api';

export enum WhatToExpectActions {
  WHAT_TO_EXPECT_REQUEST = 'WHAT_TO_EXPECT_REQUEST',
  WHAT_TO_EXPECT_FAILURE = 'WHAT_TO_EXPECT_FAILURE',
  WHAT_TO_EXPECT_SUCCESS = 'WHAT_TO_EXPECT_SUCCESS',
}

export function getWhatToExpect(visitCode: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: WhatToExpectActions.WHAT_TO_EXPECT_REQUEST });

    HealAPI.getWhatToExpect(visitCode).then((response) => {
      if (response.ok) {
        const wtePageItems = response.data?.data;

        if (wtePageItems) {
          dispatch({ type: WhatToExpectActions.WHAT_TO_EXPECT_SUCCESS, data: wtePageItems });
        }
      } else {
        dispatch({ type: WhatToExpectActions.WHAT_TO_EXPECT_FAILURE, data: response.data?.description });
      }
    });
  };
}
