import { History } from 'history';
import React from 'react';
import { SAMLState } from '../../appState/saml/types';
import { IMAGES, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import Button, { ButtonStyle } from '../core/Button';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import styles from './SamlLanding.module.scss';

interface DispatchActions {
  actions: {
    setAccessAndRefreshToken: (accessToken: string, refreshToken: string) => void;
    samlGetAccountData: (clientId: string) => void;
  };
}

export interface SamlLandingProps extends DispatchActions {
  saml: SAMLState;
  history: History;
  match: any;
}

export interface SamlLandingState {
  clientId: string;
}

export class SamlLanding extends React.Component<SamlLandingProps, SamlLandingState> {
  queryParams = new URLSearchParams(window.location.search);

  constructor(props: SamlLandingProps) {
    super(props);
    this.state = {
      clientId: props.match.params.clientId || '',
    };
  }

  public componentDidMount() {
    const at = this.queryParams.get('at');
    const rt = this.queryParams.get('rt');

    if (!at || !rt) {
      this.props.history.replace('/');
    } else {
      this.props.actions.setAccessAndRefreshToken(at, rt);
    }

    if (this.state.clientId) {
      this.props.actions.samlGetAccountData(this.state.clientId);
    }
  }

  public componentDidUpdate(prevProps: SamlLandingProps) {
    const email = this.queryParams.get('email');
    let userEmail = '';

    if (email) {
      userEmail = decodeURI(email);
    }

    if (!prevProps.saml.data && this.props.saml.data) {
      if (this.state.clientId.toLowerCase() === 'walgreens') {
        if (userEmail) {
          // account exists in Heal
          this.props.history.replace(ROUTES.partnerLink.replace(':clientId', this.state.clientId));
        } else {
          // account doesn't exist
          this.props.history.replace(ROUTES.partnerRegister.replace(':clientId', this.state.clientId));
        }
      }
    }
  }

  public render(): JSX.Element {
    return (
      <Page isLoading={this.props.saml.isFetching} showCloseButton showMenuButton={false} title={getString(ResourceKey.SSOLandingTitle)}>
        <PageHeader title={getString(ResourceKey.SSOLandingTitle)} subtitle={getString(ResourceKey.SSOLandingIntroduction)} image={IMAGES.bookVisitTitle} />
        <div className={styles.buttonContainer}>
          <Button text={getString(ResourceKey.SSOLinkButton)} onClick={this.gotoLink} testId="btn_ssoLink" />
          <Button buttonStyle={ButtonStyle.SECONDARY} text={getString(ResourceKey.SSORegisterButton)} onClick={this.gotoRegister} testId="btn_ssoRegister" />
        </div>
      </Page>
    );
  }

  private gotoLink = () => {
    if (this.state.clientId) {
      this.props.history.push(ROUTES.partnerLink.replace(':clientId', this.state.clientId));
    }
  };

  private gotoRegister = () => {
    if (this.state.clientId) {
      this.props.history.push(ROUTES.partnerRegister.replace(':clientId', this.state.clientId));
    }
  };
}
