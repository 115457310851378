import { ApiResponse } from 'apisauce';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Agreement, AgreementsResponse, AgreementValue } from '../../appState/agreements/types';
import { RootState } from '../../appState/rootState';
import { Status } from '../../appState/services';
import Page from '../../components/core/Page';
import { ThemeName } from '../../components/core/Theme';
import { ToggleSwitch } from '../../components/utils/ToggleSwitch';
import { HealAPI } from '../../services/api';
import { IMAGES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import Button from '../core/Button';
import styles from './Consents.module.scss';

const ConsentsPage = () => {
  const [fetchAgreementsStatus, setFetchAgreementStatus] = useState(Status.idle);
  const [submitAgreementsStatus, setSubmitAgreementsStatus] = useState(Status.idle);

  const [agreements, setAgreements] = useState<{ [id: string]: AgreementValue }>();

  const { userAccountInfo: account, userPreferences } = useSelector((state: RootState) => state);
  let { vanity } = useParams<{ vanity: string }>();
  const { theme } = userPreferences;

  const isLoading = fetchAgreementsStatus === Status.loading || submitAgreementsStatus === Status.loading;

  const mainPatient = useMemo(() => {
    return account.data?.patients.find((p) => p.mainPatientOfAccount === true);
  }, [account]);

  // Returns true if any of agreements is not signed.
  const hasUnsignedAgreements = useMemo(() => {
    if (!agreements) {
      return false;
    }

    return Object.values(agreements).some((agreement) => !agreement.data.agreementDetails);
  }, [agreements]);

  useEffect(() => {
    const shouldFetchAgreements = fetchAgreementsStatus === Status.idle && agreements === undefined && !!mainPatient;
    if (!shouldFetchAgreements || !mainPatient) {
      return;
    }

    setFetchAgreementStatus(Status.loading);
    // This page is now for fetching a single consent rather than multiple, but we'll keep the infrastructure
    // for supporting a list just in case.
    HealAPI.getAgreement(mainPatient.patientId, vanity).then((response) => {
      const agreementMap: {
        [key: string]: { value: boolean; data: Agreement };
      } = {};

      const agreement = response.data?.data;
      if (response.ok && !!agreement) {
        setFetchAgreementStatus(Status.succeeded);
        agreementMap[agreement.id] = { value: agreement.agreementDetails != null, data: agreement };
      } else {
        setFetchAgreementStatus(Status.failed);
      }

      setAgreements(agreementMap);
    });
  }, [mainPatient, vanity, agreements, fetchAgreementsStatus]);

  const renderImage = () => {
    if (!mainPatient || fetchAgreementsStatus === Status.loading) {
      return null;
    }

    return (
      <div className={styles.image}>
        <img alt="" src={theme.name === ThemeName.BLUE_SHIELD ? IMAGES.agreementsBSC : IMAGES.agreements} />
      </div>
    );
  };

  const renderAgreements = () => {
    if (!mainPatient || fetchAgreementsStatus === Status.loading) {
      return null;
    }

    if (agreements && Object.values(agreements).length > 0) {
      return (
        <div className={styles.agreementList}>
          {Object.values(agreements).map((agreement) => {
            const { id } = agreement.data;

            if (agreement.data.agreementDetails) {
              const labelAgreed = getString(ResourceKey.consentAgreed, [mainPatient?.fullName ?? 'Patient']);
              const date = new Date(agreement.data.agreementDetails.physicallySignedAt ?? agreement.data.agreementDetails.verballySignedAt);
              const dateString = moment(date).format('MM/DD/YYYY');
              return (
                <div className={styles.agreement} key={id}>
                  <label htmlFor={id} id={`${id}_label`}>
                    {labelAgreed}{' '}
                    <a href={agreement.data.fileUrl} target="_blank" rel="noreferrer">
                      {agreement.data.title}
                    </a>{' '}
                    on {dateString}.
                  </label>
                </div>
              );
            }

            const labelText = getString(ResourceKey.bookAgreementsLabel, [mainPatient?.fullName ?? 'Patient']);
            return (
              <div className={styles.agreement} key={id}>
                <label id={`${id}_label`}>
                  {labelText}{' '}
                  <a href={agreement.data.fileUrl} target="_blank" rel="noreferrer">
                    {agreement.data.title}
                  </a>
                </label>
                <ToggleSwitch
                  checkboxId={id}
                  on={agreement.value}
                  onToggle={() => {
                    // Toggle current item's value and leave the rest as is.
                    const updatedAgreements = {
                      ...agreements,
                      [agreement.data.id]: {
                        ...agreement,
                        value: !agreement.value,
                      },
                    };
                    setAgreements(updatedAgreements);
                  }}
                  ariaLabel={`${labelText} ${agreement.data.title}`}
                />
              </div>
            );
          })}
        </div>
      );
    }

    const noAgreements = getString(ResourceKey.consentNoAgreements);
    return (
      <div className={styles.agreementList}>
        <div className={styles.agreement}>
          <label>{noAgreements}</label>
        </div>
      </div>
    );
  };

  const renderSubmitError = () => {
    if (submitAgreementsStatus === Status.failed) {
      const errorMessage = getString(ResourceKey.consentSaveError);

      return (
        <div className={styles.agreementList}>
          <div className={styles.agreement}>
            <label>{errorMessage}</label>
          </div>
        </div>
      );
    }

    return null;
  };

  // Returns true if all toggles are on.
  const canSubmit = () => {
    if (isLoading) {
      return false;
    }

    let canSubmit = true;
    if (agreements) {
      Object.values(agreements).forEach((agreement) => {
        if (!agreement.value) {
          canSubmit = false;
        }
      });
    }

    return canSubmit;
  };

  const onSubmit = () => {
    if (mainPatient && agreements) {
      const agreementIds = Object.values(agreements).map((agreement) => agreement.data.id);
      setSubmitAgreementsStatus(Status.loading);
      HealAPI.submitAgreements(mainPatient.patientId, agreementIds).then((response: ApiResponse<AgreementsResponse>) => {
        if (response.ok) {
          setAgreements(undefined);
          setSubmitAgreementsStatus(Status.succeeded);
          setFetchAgreementStatus(Status.idle);
        } else {
          setSubmitAgreementsStatus(Status.failed);
        }
      });
    }
  };

  return (
    <Page showEmailBanner title={getString(ResourceKey.bookAgreementsTitle)} isLoading={isLoading}>
      {renderImage()}
      {renderAgreements()}
      {renderSubmitError()}
      {hasUnsignedAgreements && <Button text="I Agree" onClick={onSubmit} disabled={!canSubmit()} type="button" className="btn-block" />}
    </Page>
  );
};

export default ConsentsPage;
