import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { addFile } from '../../appState/cart/actions';
import { patchFileToVisit } from '../../appState/checkout/actions';
import { RootState } from '../../appState/rootState';
import { getVisits } from '../../appState/visit/actions';
import { ROUTES } from '../../services/constants';
import { createMarkup, getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { FileUpload } from '../../types/Files';
import { formatTime } from '../../utils/date';
import Button from '../core/Button';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import FileUploader from '../fileUploader/FileUploader';
import { Steps } from '../utils/Steps';
import styles from './UploadPage.module.scss';

export const VACCINE_RECORDS_KEY = 'vaccines';

const UploadPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { code: visitCode } = useParams<{ code: string }>();
  const { cart, visitList } = useSelector((state: RootState) => state);
  const [fileUploads, setFileUploads] = useState<FileUpload[]>([]);
  const visit = useMemo(() => visitList.data?.find((v) => v.code === visitCode), [visitList, visitCode]);
  const canSubmit = useMemo(() => !fileUploads.some((file) => file.status !== 'success'), [fileUploads]);

  useEffect(() => {
    if (!cart.who && !visit && !visitList.isFetching) {
      dispatch(getVisits());
    }
  }, [cart, visit, visitList, dispatch]);

  function patchFilesToVisit() {
    fileUploads.forEach((fileUpload, index) => {
      const { createFileResponse, visitCode, patientId } = fileUpload;

      if (createFileResponse && visitCode && patientId) {
        // Visit page may be rendered before patchFiles completes, refetch to avoid vaccine records status race condition
        const shouldRefetchVisit = !visit?.files.length && index === fileUploads.length - 1;

        dispatch(patchFileToVisit(createFileResponse.id, patientId, visitCode, 3, shouldRefetchVisit));
      } else {
        dispatch(addFile(fileUpload));
      }
    });
  }

  function renderContent() {
    let dateString = '';
    let patientString = '';

    if (visit) {
      dateString = formatTime(visit.timeSlot.startTime * 1000 - 86400000 * 3, 'dddd, MMM D, YYYY', visit.timeSlot.timeZone);
      patientString = visit.patient.fullName;
    } else if (cart.when && cart.who) {
      dateString = formatTime(moment.unix(cart.when.startTime).toDate().getTime() - 86400000 * 3, 'dddd, MMM D, YYYY');
      patientString = cart.who.firstName;
    } else {
      dateString = getString(ResourceKey.fileUploadDefaultDate);
      patientString = 'Patient';
    }

    return (
      <div>
        <PageHeader title={getString(ResourceKey.fileUploadTitle)} subtitle={getString(ResourceKey.fileUploadHeader)} />
        <div className={styles.text}>{getString(ResourceKey.fileUploadDescription).replace('Patient', patientString)}</div>

        <hr className={styles.separator} />

        <div
          className={styles.text}
          dangerouslySetInnerHTML={createMarkup(
            visit?.files?.length ? getString(ResourceKey.fileUploadOnFileMessage) : getString(ResourceKey.fileUploadDateMessage, [dateString])
          )}
        />
      </div>
    );
  }

  return (
    <Page cssClass={styles.container} showBackButton isLoading={visitList.isFetching} title={getString(ResourceKey.fileUploadTitle)}>
      {cart && visitCode === VACCINE_RECORDS_KEY && <Steps history={history} currentStep="schedule" />}

      {renderContent()}

      <FileUploader fileUploads={fileUploads} setFileUploads={setFileUploads} visit={visit} patientId={cart?.who?.id} documentType="vaccination" />

      <Button
        className={styles.submitButton}
        onClick={() => {
          patchFilesToVisit();
          visitCode === VACCINE_RECORDS_KEY ? history.push(ROUTES.bookReview) : history.replace(ROUTES.visits);
        }}
        disabled={!canSubmit}
        text={getString(fileUploads.length ? ResourceKey.genericButtonContinue : ResourceKey.genericButtonSkip)}
        testId="btn_continue"
      />
    </Page>
  );
};

export default UploadPage;
