import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { analyticsLogEvent } from '../../services/firebase';

export default function usePageView(page_title?: string) {
  const location = useLocation();

  useEffect(() => {
    const page_path = location.pathname + location.search;
    analyticsLogEvent('page_view', { page_path, page_title });
  }, [location, page_title]);
}
