import * as React from 'react';
import { PreferredDoctor } from '../../appState/patient/types';
import { IMAGES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { ButtonLink } from '../core/ButtonLink';
import { ButtonStyles, LegacyButton } from '../core/LegacyButton';
import { DoctorBio } from './DoctorBio';
import styles from './DoctorCard.module.scss';

export interface DoctorCardProps {
  doctor: PreferredDoctor;
  isPreferredDoctor: boolean;
  stillValid?: boolean;
  setPreferredDoctor: (newPreferredDoctorId: string | null) => void;
}

export class DoctorCard extends React.Component<DoctorCardProps> {
  public render(): JSX.Element {
    const { doctor } = this.props;

    return (
      <div className={styles.container}>
        <DoctorBio
          avatarUrl={doctor.avatarUrl}
          videoUrl={doctor.bioVideoUrl}
          videoPosterUrl={doctor.bioVideoPosterFrameUrl}
          bio={doctor.bio}
          prefix={doctor.prefix}
          firstName={doctor.firstName}
          lastName={doctor.lastName}
          suffix={doctor.suffix}
        />
        {this.renderButton()}
      </div>
    );
  }

  private renderButton(): JSX.Element {
    if (!this.props.isPreferredDoctor) {
      return (
        <LegacyButton
          text="Select"
          className={styles.selectButton}
          style={ButtonStyles.secondary}
          onClick={() => {
            this.props.setPreferredDoctor(this.props.doctor.providerId);
          }}
          testId={'btn_selectDoctor'}
        />
      );
    }

    let { prefix, firstName, lastName } = this.props.doctor;
    prefix = prefix ? prefix : '';
    firstName = firstName ? firstName : '';
    lastName = lastName ? lastName : '';

    return (
      <div className={styles.isPreferred}>
        {this.props.stillValid ? (
          <div>
            <img alt={getString(ResourceKey.checkmarkAlt)} src={IMAGES.checkmark} /> Your Preferred Doctor
          </div>
        ) : (
          <div>
            <img alt={getString(ResourceKey.warningAlt)} src={IMAGES.warning} /> {prefix} {firstName} {lastName} is no longer available.
          </div>
        )}
        <ButtonLink
          text="Remove"
          onClick={() => {
            this.props.setPreferredDoctor(null);
          }}
        />
      </div>
    );
  }
}
