import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IMAGES, ROUTES } from '../../services/constants';
import { createMarkup, getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import Page from '../core/Page';
import { ZeroData } from '../core/ZeroData';
import styles from './NotFound.module.scss';

export class NotFound extends React.Component<RouteComponentProps, object> {
  public render() {
    const navButtons = [
      {
        action: () => this.props.history.push(ROUTES.bookReview),
        text: getString(ResourceKey.topNavButtonBook),
      },
    ];

    return (
      <Page title="Page Not Found" navButtons={navButtons} hideTitleInNavbar>
        <div className={styles.container}>
          <ZeroData
            image={{ source: IMAGES.notFoundTitle }}
            text={getString(ResourceKey.notFoundPagePrimaryText)}
            textSecondary={getString(ResourceKey.notFoundPageSecondaryText)}
          />
          <div className={styles.contactContainer}>
            <div className={styles.contact}>
              <div dangerouslySetInnerHTML={createMarkup(getString(ResourceKey.notFoundPageMessageContactLine))} />
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
