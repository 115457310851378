import { ReduxAction } from '../../appState/types';
import { CampaignState } from './types';
import { CampaignActions } from './actions';

export const campaignInitialState: CampaignState = {
  branchDataToSend: undefined,
  name: undefined,
  medium: undefined,
  source: undefined,
  launchParams: undefined,
  attribution: undefined,
};

export function campaignReducer(state: CampaignState = campaignInitialState, action: ReduxAction<any>): CampaignState {
  const branchData = action.data as any;
  switch (action.type) {
    case CampaignActions.SET_CAMPAIGN:
      var sanitizedBranchData: { [key: string]: any } = {};
      // turn "~campaign" key into "campaign", for example
      if (branchData != null) {
        for (const [key, value] of Object.entries(branchData)) {
          const sanitizedKey = key.replace(/\W/g, '');
          // temporary check to remove array parameter "tags" which does not serialize
          // in our account creation request (expects campaign: <String, String>)
          // this value seems to be cached in /uber-th even after removal
          if (sanitizedKey !== 'tags') {
            sanitizedBranchData[sanitizedKey] = value;
          }
        }
      }

      // Some branch events (clean launch) don't contain branch data which reflect user behavior or actions.
      // We will suppress the event to reduce tracking noise.
      if (Object.keys(sanitizedBranchData).length === 2 && 'is_first_session' in sanitizedBranchData && 'clicked_branch_link' in sanitizedBranchData) {
        return state;
      }

      const name = sanitizedBranchData?.[`utm_campaign`] || sanitizedBranchData?.[`campaign`] || state?.name;
      const medium = sanitizedBranchData?.[`utm_medium`] || sanitizedBranchData?.[`feature`] || state?.medium;
      const source = sanitizedBranchData?.[`utm_source`] || sanitizedBranchData?.[`channel`] || state?.source;
      const attribution = {
        campaign: name,
        source,
        medium,
        referring_link: sanitizedBranchData?.[`referring_link`] || 'unknown',
        referring_click_timestamp: sanitizedBranchData?.[`click_timestamp`] || Date.now(),
      };

      return {
        ...state,
        branchDataToSend: branchData && sanitizedBranchData,
        name,
        medium,
        source,
        attribution,
      };

    case CampaignActions.SET_CAMPAIGN_LAUNCH_PARAMS:
      return {
        ...state,
        launchParams: action.data,
      };

    default:
      return state;
  }
}
