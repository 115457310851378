import { ReduxAction } from '../../appState/types';

export enum ThemeName {
  HEAL = 'heal',
  BLUE_SHIELD = 'blueShield',
}

export enum UserPreferencesActions {
  SET_FONT_SIZE = 'SET_FONT_SIZE',
  SET_SECONDARY_CONTACT_SKIPPED = 'SET_SECONDARY_CONTACT_SKIPPED',
  SET_RESEND_EMAIL_VERIFICATION_FROM_BANNER = 'SET_RESEND_EMAIL_VERIFICATION_FROM_BANNER',
  SET_BLUESHIELD_SSO_USER = 'SET_BLUESHIELD_SSO_USER',
  SET_THEME = 'SET_THEME',
}

export function setFontSize(fontSize: string): ReduxAction<{ fontSize: string }> {
  return {
    type: UserPreferencesActions.SET_FONT_SIZE,
    data: {
      fontSize,
    },
  };
}

export function setSecondaryContactSkipped(patientId: string, skipped: boolean = true): ReduxAction<{ patientId: string; skipped: boolean }> {
  return {
    type: UserPreferencesActions.SET_SECONDARY_CONTACT_SKIPPED,
    data: {
      patientId,
      skipped,
    },
  };
}

export function setResendEmailVerificationFromBanner(sent: boolean = true): ReduxAction<boolean> {
  return {
    type: UserPreferencesActions.SET_RESEND_EMAIL_VERIFICATION_FROM_BANNER,
    data: sent,
  };
}

export function setBlueshieldSsoUser(isBlueShieldSsoUser: boolean): ReduxAction<boolean> {
  return {
    type: UserPreferencesActions.SET_BLUESHIELD_SSO_USER,
    data: isBlueShieldSsoUser,
  };
}

export function setTheme(name: ThemeName, color: string, testMode?: boolean) {
  return {
    type: UserPreferencesActions.SET_THEME,
    data: {
      name,
      color,
      testMode,
    },
  };
}
