import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { ReactSVG } from 'react-svg';
import { CartState } from '../../appState/cart/types';
import { IMAGES } from '../../services/constants';
import { getRemoteConfigValue } from '../../services/firebase';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import mainStyles from '../../styles/index.module.scss';
import { TimeSlot } from '../../types/TimeSlot';
import { friendlyFullDate, getZeroTimeDate } from '../../utils/date';
import { Loading } from '../core/Loading';
import styles from './TimeSlots.module.scss';
import TimeSlotSelector from './TimeSlotSelector';

export interface TimeSlotsProps {
  onChangeDate: (date: Date) => void;
  onChangeTimeSlot: (selectedTimeSlot: TimeSlot) => void;
  timeSlots: TimeSlot[];
  isFetching: boolean;
  cart?: CartState;
  isOnsite: boolean;
  isPreferredDoctor: boolean;
}

const TimeSlots: React.FC<TimeSlotsProps> = (props) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(props.cart?.when ? new Date(props.cart?.when.startTime * 1000) : new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(props.cart?.when);
  const [dateToTimeSlotsMap, setDateToTimeSlotsMap] = useState<{ [date: string]: TimeSlot[] }>({});
  const [templateTimeSlots, setTemplateTimeSlots] = useState<TimeSlot[]>([]);
  const [initialDateSelected, setInitialDateSelected] = useState(false);
  const calendarButton = useRef<HTMLButtonElement>(null);

  const minimumAdvancedBookingDays = useMemo(() => getRemoteConfigValue('minimum_advanced_booking_days').asNumber(), []);
  const earliestStartTime = moment().add(minimumAdvancedBookingDays, 'd').unix();
  const nextAvailable = props.timeSlots.find((value) => {
    return value.startTime > earliestStartTime;
  });
  const earliestBookingDay = nextAvailable && new Date(nextAvailable.startTime * 1000);

  useEffect(() => {
    window.addEventListener('keyup', onKeyDownEscape);

    return () => {
      window.removeEventListener('keyup', onKeyDownEscape);
    };
  }, []);

  function onKeyDownEscape(ev: KeyboardEvent) {
    ev.stopPropagation();
    if (ev.code === 'Escape') {
      setShowDatePicker(false);
    }
  }

  // Update selectedTimeSlot w/ Redux value
  useEffect(() => {
    if (props.cart?.when?.id && props.cart.when.id !== selectedTimeSlot?.id) {
      setSelectedTimeSlot(props.cart.when);
    }
  }, [props.cart?.when, selectedTimeSlot]);

  // Generate dateToTimeSlotsMap & templateTimeSlots
  useEffect(() => {
    const map: { [date: string]: TimeSlot[] } = {};
    let templateTimeSlots: TimeSlot[] = [];

    props.timeSlots.forEach((timeSlot) => {
      const date = moment.unix(timeSlot.startTime).format('MMDDYYYY');

      if (!map[date]) {
        map[date] = [];
      }

      map[date].push(timeSlot);
      const mapDateTemplate = map[date].filter((timeSlot) => timeSlot.id !== 'ASAP');

      if (templateTimeSlots.length < mapDateTemplate.length) {
        templateTimeSlots = mapDateTemplate;
      }
    });

    setDateToTimeSlotsMap(map);
    setTemplateTimeSlots(templateTimeSlots);

    if (nextAvailable) {
      const nextAvailableDate = new Date(nextAvailable.startTime * 1000);

      if (nextAvailable.id !== 'ASAP' && !selectedTimeSlot && !initialDateSelected) {
        setSelectedDate(nextAvailableDate);
        setInitialDateSelected(true);
      }

      const todayTime = getZeroTimeDate().getTime();
      const nextAvailableTime = getZeroTimeDate(nextAvailableDate).getTime();
      const slotsOpen = (map[moment(nextAvailableDate).format('MMDDYYYY')] || []).length;
    }
  }, [props.timeSlots, props.cart?.what, selectedTimeSlot, initialDateSelected]);

  function getSelectedDateTimeslots() {
    if (!templateTimeSlots) {
      return [];
    }

    const timeSlotOnDay = dateToTimeSlotsMap[moment(selectedDate).format('MMDDYYYY')].filter((timeSlot) => timeSlot.id !== 'ASAP');

    if (props.isOnsite || props.isPreferredDoctor) {
      return timeSlotOnDay;
    }

    const timeSlots = [...templateTimeSlots];

    // Map the source (timeSlotOnDay) to the target (templateTimeSlots) array.
    for (let i = 0; i < timeSlots.length; i++) {
      const matchedTimeSlot = timeSlotOnDay.find(
        (slotOnDay) =>
          moment.unix(slotOnDay.startTime).format('h:mm A') === moment.unix(timeSlots[i].startTime).format('h:mm A') &&
          slotOnDay.duration === timeSlots[i].duration &&
          slotOnDay.timeZone === timeSlots[i].timeZone
      );

      if (matchedTimeSlot) {
        timeSlots[i] = matchedTimeSlot;
      } else {
        timeSlots[i] = { ...timeSlots[i], id: 'disabled' };
      }
    }

    return timeSlots;
  }

  function getNextOrPreviousDate(forward: boolean) {
    if (!forward && earliestBookingDay && selectedDate <= earliestBookingDay) {
      return null;
    }

    const day = new Date(selectedDate);
    let lookAheadCount = 150; // look up to 150 days away from current selected date...

    do {
      day.setDate(day.getDate() + (forward ? 1 : -1));

      if (dateToTimeSlotsMap[moment(day).format('MMDDYYYY')]?.length > 0) {
        return day;
      }
    } while (--lookAheadCount > 0);

    return null;
  }

  function nudgeDate(forward: boolean) {
    const day = getNextOrPreviousDate(forward)!; // must exist b/c the buttons are enabled.
    setSelectedDate(day);
    props.onChangeDate(day);
  }

  function renderDateSelector() {
    const enableRightButton = getNextOrPreviousDate(true) !== null;
    const enableLeftButton = getNextOrPreviousDate(false) !== null;
    const centerButtonContent = friendlyFullDate(selectedDate);

    return (
      <div className={styles.datePicker}>
        <button
          className={styles.datePickerArrow}
          data-tid="btn_previousDay"
          onClick={enableLeftButton ? () => nudgeDate(false) : undefined}
          aria-label="previous day"
          disabled={!enableLeftButton}
        >
          <ReactSVG
            alt={enableLeftButton ? 'Left arrow' : 'Left arrow disabled'}
            src={enableLeftButton ? IMAGES.arrowLeft : IMAGES.arrowLeftInactive}
            className={enableLeftButton ? mainStyles.themeSVGFill : ''}
          />
        </button>
        <div className={styles.buttonContainer}>
          <button
            className={styles.datePickerMiddle}
            onClick={() => {
              if (!props.isFetching) {
                setShowDatePicker(!showDatePicker);
              }
            }}
            data-tid="btn_selectedDay"
            ref={calendarButton}
            aria-label={`${centerButtonContent} schedule`}
          >
            <span style={{ verticalAlign: 'middle' }}>{centerButtonContent}</span>
            <div className={styles.iconWrapper}>
              <ReactSVG alt={getString(ResourceKey.openCalendarAlt)} src={IMAGES.calendarAccent} className="themeSVG" />
            </div>
          </button>
          <DayPickerDialog />
        </div>
        <button
          className={styles.datePickerArrow}
          data-tid="btn_nextDay"
          onClick={enableRightButton ? () => nudgeDate(true) : undefined}
          aria-label="next day"
          disabled={!enableRightButton}
        >
          <ReactSVG
            alt={enableRightButton ? 'Right arrow' : 'Right arrow disabled'}
            src={enableRightButton ? IMAGES.arrowRight : IMAGES.arrowRightInactive}
            className={enableRightButton ? mainStyles.themeSVGFill : ''}
          />
        </button>
      </div>
    );
  }

  function renderTimeSlotSelector() {
    if (props.isFetching) {
      return <Loading padding={20} />;
    } else if (dateToTimeSlotsMap[moment(selectedDate).format('MMDDYYYY')]) {
      return (
        <TimeSlotSelector
          onSelect={(timeSlot: TimeSlot) => {
            setSelectedTimeSlot(timeSlot);
            props.onChangeTimeSlot(timeSlot);
          }}
          selectedId={selectedTimeSlot?.id}
          timeSlots={getSelectedDateTimeslots()}
        />
      );
    } else {
      return <div className={styles.noTimeSlots}>{getString(ResourceKey.bookScheduleNoTimeSlots)}</div>;
    }
  }

  const DayPickerDialog = () => {
    const disabledDays: DayPicker.Modifier[] = [];
    const endDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

    if (showDatePicker) {
      if (props.timeSlots) {
        // 1 - Get array of days with at least one timeslot available
        const availableDays: number[] = [];
        let lastDateId = '';

        props.timeSlots.forEach((timeSlot) => {
          if (lastDateId !== timeSlot.id) {
            availableDays.push(getZeroTimeDate(new Date(timeSlot.startTime * 1000)).getTime());
            lastDateId = timeSlot.id;
          }
        });

        // 2 - Add days without availability to the disabledDays array
        const currentDate = getZeroTimeDate(new Date());
        const endTimeStamp = getZeroTimeDate(endDate).getTime() + 1000 * 60 * 60 * 24; // 1 day after endDate

        while (currentDate.getTime() <= endTimeStamp) {
          if (availableDays.indexOf(currentDate.getTime()) === -1) {
            disabledDays.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
      }
      disabledDays.push({ before: earliestBookingDay ?? new Date() });
    }
    return !showDatePicker ? null : (
      <div className={styles.datepickerDialog} role="dialog" aria-modal aria-label="Choose Date">
        <div className={styles.container}>
          <DayPicker
            aria-describedby="dayPickerLabel"
            disabledDays={disabledDays}
            fromMonth={new Date()}
            initialMonth={selectedDate || new Date()}
            onDayClick={(day: Date, modifiers: DayModifiers, e: React.MouseEvent<HTMLDivElement>) => {
              if (!modifiers.disabled) {
                if (!selectedDate || selectedDate.getTime() !== day.getTime()) {
                  setSelectedDate(day);
                  props.onChangeDate(day);
                  setSelectedTimeSlot(null);
                }

                setShowDatePicker(false);
                calendarButton.current?.focus();
              }
            }}
            selectedDays={selectedDate || new Date()}
            toMonth={endDate}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.box}>
      {renderDateSelector()}
      {renderTimeSlotSelector()}
    </div>
  );
};

export default TimeSlots;
