import { AvailablePDActions } from './actions';
import { AvailablePreferredDoctorsResponse, AvailablePreferredDoctorsState } from './types';

export const availablePdsInitialState: AvailablePreferredDoctorsState = {
  isFetching: false,
  error: null,
  success: false,
  data: null,
};

//
// AVAILABLE PREFERRED DOCTORS
//
function handleAvailablePreferredDoctorsRequest(state: AvailablePreferredDoctorsState): AvailablePreferredDoctorsState {
  return {
    ...state,
    isFetching: true,
    error: null,
    success: false,
  };
}

function handleAvailablePreferredDoctorsSuccess(
  state: AvailablePreferredDoctorsState,
  data: AvailablePreferredDoctorsResponse
): AvailablePreferredDoctorsState {
  return {
    ...state,
    isFetching: false,
    success: true,
    error: null,
    data,
  };
}

function handleAvailablePreferredDoctorsFail(state: AvailablePreferredDoctorsState, error: any): AvailablePreferredDoctorsState {
  return {
    ...state,
    isFetching: false,
    success: false,
    error,
    data: null,
  };
}

export function availablePDReducer(
  state: AvailablePreferredDoctorsState = availablePdsInitialState,
  // action: Action & { data: any }
  action: any
): AvailablePreferredDoctorsState {
  switch (action.type) {
    case AvailablePDActions.GET_AVAILABLE_PD_REQUEST:
      return handleAvailablePreferredDoctorsRequest(state);
    case AvailablePDActions.GET_AVAILABLE_PD_SUCCESS:
      return handleAvailablePreferredDoctorsSuccess(state, action.data);
    case AvailablePDActions.GET_AVAILABLE_PD_FAIL:
      return handleAvailablePreferredDoctorsFail(state, action.data);

    default:
      return state;
  }
}
