import React from 'react';
import { css } from '../../utils/css';
import { camelCase } from '../../utils/strings';
import { Loading } from './Loading';

export enum ButtonStyle {
  PRIMARY = 'btn-primary',
  SECONDARY = 'btn-secondary',
  SUCCESS = 'btn-success',
  TEXT = 'btn-text',
  NONE = 'btn-no-style',
}

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  text: string;
  type?: 'button' | 'submit' | 'reset';
  buttonStyle?: ButtonStyle;
  onClick?: () => void;
  loading?: boolean;
  testId?: string;
}

/**
 * Simplified Button component using a native 'button' element instead of a 'div' with browser manipulations.
 *
 * The old 'LegacyButton' element should be replaced with this one over time.
 */
const Button: React.FC<ButtonProps> = ({ text, buttonStyle = ButtonStyle.PRIMARY, onClick, className, disabled, loading, testId, children, ...props }) => {
  const classNames = css('btn', buttonStyle, { disabled: !!disabled || !!loading, loading: !!loading }, className);

  // TODO: Remove '_enabled' suffix and update Cypress tests to instead use should('not.be.disabled')
  const dataTestId = (testId || `btn_${camelCase(text)}`) + (disabled ? '' : '_enabled');

  return (
    <button
      disabled={loading || disabled}
      aria-disabled={loading || disabled}
      className={classNames}
      onClick={() => !disabled && !loading && onClick && onClick()}
      aria-label={props['aria-label'] || text}
      data-tid={dataTestId}
      {...props}
    >
      {loading ? <Loading color="#FFFFFF" width={40} height="auto" /> : children || text}
    </button>
  );
};

export default Button;
