import React, { useState } from 'react';
import { createMarkup, getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { css } from '../../utils/css';
import { BottomSheet } from './BottomSheet';
import Button from './Button';

type Props = { className?: string };

export function ContactHealButton({ className }: Props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button text="Contact CenterWell" testId="btn_contactHeal" className={css(className, 'btn-block')} onClick={() => setOpen(true)} />
      <BottomSheet
        title={getString(ResourceKey.bottomSheetCallCenterWellTitle)}
        testId="bs_contactHeal"
        visible={open}
        onClose={() => setOpen(false)}
        showCloseButton
      >
        <div style={{ margin: '20px auto', maxWidth: 280 }}>
          <p dangerouslySetInnerHTML={createMarkup(getString(ResourceKey.bottomSheetCallCenterWellText1))} />
          <p dangerouslySetInnerHTML={createMarkup(getString(ResourceKey.bottomSheetCallCenterWellText2))} />
          <Button text={getString(ResourceKey.bottomSheetButtonClose)} testId="btn_close" className="btn-block" onClick={() => setOpen(false)} />
        </div>
      </BottomSheet>
    </>
  );
}
