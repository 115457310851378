import { create } from 'apisauce';
import * as React from 'react';
import { css } from '../../utils/css';
import { upperCaseFirst } from '../../utils/strings';
import { isLocal } from '../../utils/utils';
import { VersionInfo } from '../utils/ErrorBoundary';
import styles from './VersionLabel.module.scss';

const headers: { [key: string]: string } = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

declare var healConfig: any;

export interface VersionLabelProps {
  className?: string;
}

export interface VersionLabelState {
  versionInfo?: VersionInfo;
}

const api = create({
  baseURL: '/',
  headers,
});

export class VersionLabel extends React.Component<VersionLabelProps, VersionLabelState> {
  constructor(props: VersionLabelProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (healConfig.env === 'dev' || (healConfig.env === 'qa' && !isLocal)) {
      api.get('/_healinternal/version.json').then((response: any) => {
        if (response.data) {
          this.setState({
            versionInfo: response.data.versionData as VersionInfo,
          });
        }
      });
    }
  }

  render(): JSX.Element | null {
    if (healConfig.env === 'local' || healConfig.env === 'dev' || healConfig.env === 'qa') {
      // Capitalize environment name
      const env = healConfig.env === 'qa' ? healConfig.env.toUpperCase() : upperCaseFirst(healConfig.env);

      // NODE_ENV is set to 'production' after build process (including on dev & QA environments)
      const isLocal = process.env.NODE_ENV === 'development';

      return (
        <div className={css(styles.label, this.props.className)}>
          <strong>{env}</strong>
          <br />
          {!isLocal && (healConfig.env === 'dev' || healConfig.env === 'qa') && `${this.state.versionInfo?.version}_${this.state.versionInfo?.commit}`}
        </div>
      );
    }

    return null;
  }
}
