import * as React from 'react';
import { IMAGES } from '../../services/constants';
import { createMarkup, getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { ZeroData } from '../core/ZeroData';
import styles from './ClientError.module.scss';

export interface ClientErrorProps {}

export class ClientError extends React.Component<ClientErrorProps, object> {
  public render() {
    return (
      <div>
        <ZeroData
          image={{ source: IMAGES.errorTitle }}
          text={getString(ResourceKey.errorPagePrimaryText)}
          textSecondary={getString(ResourceKey.errorPageSecondaryText)}
        />

        <div className={styles.browserCompatibility}>Please ensure you are using a modern browser (Chrome, Safari, Edge, Firefox).</div>

        <div className={styles.contact}>
          <div dangerouslySetInnerHTML={createMarkup(getString(ResourceKey.errorPageMessageContactLine))} />
        </div>

        <div className={styles.buttonWrapper}>
          <button className={styles.button} onClick={() => window.location.reload()}>
            Retry
          </button>
        </div>
      </div>
    );
  }
}
