import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AccountState } from '../../appState/account/types';
import { Address, AddressRequest, AddressState } from '../../appState/address/types';
import { CartState, Location } from '../../appState/cart/types';
import Page from '../../components/core/Page';
import { NotificationsBox } from '../../components/utils/NotificationsBox';
import { HealAPI } from '../../services/api';
import { ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import Button from '../core/Button';
import { PageHeader } from '../core/PageHeader';
import { AddressEditor } from './AddressEditor';
import { getAddressByType } from './Util';

export interface AddLocationProps extends RouteComponentProps<{ type: string }> {
  actions: {
    addAddress: (data: AddressRequest) => void;
    setLocation: (data: Location) => void;
  };
  accountState: AccountState;
  addressState: AddressState;
  cart: CartState;
}

export interface AddLocationState {
  address: Address;
  error?: string;
  waitingForNewAddressId: boolean;
  allowSubmit: boolean;
}

export class AddLocation extends React.Component<AddLocationProps, AddLocationState> {
  constructor(props: AddLocationProps) {
    super(props);

    let address = {} as Address;
    if (this.props.addressState.data) {
      address = getAddressByType(this.props.addressState.data, 'HOME');
    }

    this.state = {
      address,
      waitingForNewAddressId: false,
      allowSubmit: false, // Initially false because the field will either be blank or it will match the initial value, both of which are rejection criteria.
    };
  }

  public componentDidUpdate(prevProps: AddLocationProps) {
    const { props } = this;

    if (!prevProps.addressState.data && props.addressState.data) {
      const savedAddress = getAddressByType(props.addressState.data, this.state.address.addressType); // May return an unusual address object if it does not find an address
      this.setState({ address: savedAddress });

      if (this.state.waitingForNewAddressId && savedAddress && this.state.address.addressId !== savedAddress.addressId) {
        props.actions.setLocation({
          streetAddress: savedAddress.streetAddress,
          latitude: savedAddress.latitude,
          longitude: savedAddress.longitude,
          zipcode: savedAddress.zipcode,
          state: savedAddress.state,
          city: savedAddress.city,
          unit: savedAddress.unit,
          entryInstructions: savedAddress.instructions,
          addressId: savedAddress.addressId,
          addressType: savedAddress.addressType,
        });

        props.history.push(ROUTES.bookPatient);
      }
    }
  }

  public render(): JSX.Element {
    return (
      <Page title="Location" showBackButton isLoading={!this.props.addressState.data}>
        <PageHeader title={getString(ResourceKey.bookLocationAddTitle)} />
        <NotificationsBox type="error" content={this.props.addressState.error || this.state.error} />
        <AddressEditor address={this.state.address} onChange={this.onAddressChange} onAllowSubmitChange={this.onAllowSubmitChange}/>
        <Button
          text={getString(ResourceKey.bookLocationButtonSave)}
          onClick={this.onSubmit}
          disabled={!this.canSubmit()}
          testId="btn_pageBottom"
          className="btn-block"
          style={{ marginTop: 40 }}
        />
      </Page>
    );
  }

  private onAddressChange = (address: Address) => {
    this.setState({ address });
    this.setState({ error: undefined });
  };

  /**
   * Delegate some of the responsibility for checking whether the input
   * can be submitted to AddressEditor.tsx. This is a workaround to help
   * simplify code in light of the way this was originally implemented.
   * @param allowSubmit - A boolean, typically from AddressEditor, comprising internal validation checks (e.g., whether the address matches the last suggestion selected)
   **/
  private onAllowSubmitChange = (allowSubmit: boolean) => {
    this.setState({ allowSubmit });
  }

  private canSubmit = (): boolean => {
    if (this.props.addressState.isModifying || this.props.addressState.isFetching) {
      return false;
    }

    return this.state.allowSubmit && !!this.state.address.streetAddress && this.state.address.streetAddress.length > 0 && !!this.state.address.addressType;
  };

  private onSubmit = () => {
    const { address } = this.state;

    HealAPI.checkCoverage(address.zipcode).then((response) => {
      if (response.ok && response.data?.data) {
        if (response.data.data.coverageStatus !== 'NOT_COVERED') {
          this.setState({ waitingForNewAddressId: true }, () => {
            this.props.actions.addAddress({
              streetAddress: address.streetAddress,
              latitude: address.latitude,
              longitude: address.longitude,
              zipcode: address.zipcode,
              country: address.country,
              city: address.city,
              state: address.state,
              unit: address.unit,
              instructions: address.instructions,
              addressType: address.addressType,
              establishment: address.establishment,
            });
          });
        } else {
          this.setState({
            error: getString(ResourceKey.bookLocationErrorNotCovered),
          });
        }
      }
    });
  };
}
