import * as React from 'react';
import snarkdown from 'snarkdown';
import { grabInitials } from '../../utils/strings';
import { Identity } from '../core/Identity';
import styles from './DoctorBio.module.scss';

export interface DoctorBioProps {
  avatarUrl?: string;
  videoUrl?: string;
  videoPosterUrl?: string;
  bio: string;
  prefix?: string;
  firstName: string;
  lastName?: string;
  suffix?: string;
}

export class DoctorBio extends React.Component<DoctorBioProps> {
  public render(): JSX.Element {
    return (
      <div className={styles.container}>
        {this.renderVideo()}
        {this.renderPhoto()}
        <div className={styles.bio} dangerouslySetInnerHTML={{ __html: snarkdown(this.props.bio) }} />
      </div>
    );
  }

  private renderVideo(): JSX.Element | null {
    if (!this.props.videoUrl) {
      return null;
    }
    const { prefix, firstName, lastName, suffix } = this.props;

    return (
      <div>
        <div className={styles.name} style={{ marginBottom: 10 }}>
          {`${prefix} ${firstName} ${lastName} ${suffix}`}
        </div>
        <video controls width="100%" poster={this.props.videoPosterUrl}>
          <source src={this.props.videoUrl} type="video/mp4" />
        </video>
      </div>
    );
  }

  private renderPhoto(): JSX.Element | null {
    const { avatarUrl, prefix, firstName, lastName, suffix } = this.props;

    if (this.props.videoUrl) {
      return null;
    }

    return (
      <div>
        <div className={styles.image}>
          <Identity initials={grabInitials(`${firstName} ${lastName}`)} size={120} url={avatarUrl} />
        </div>
        <div className={styles.name}>{`${prefix} ${firstName} ${lastName} ${suffix}`}</div>
      </div>
    );
  }
}
