import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WhatToExpectPageItem } from '../../appState/whatToExpect/types';
import Page from '../../components/core/Page';
import { WhatToExpectItems } from '../../components/whatToExpect/WhatToExpectItems';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { PageHeader } from '../core/PageHeader';

export interface DispatchActions {
  actions: {
    getWhatToExpect: (visitCode: string) => void;
  };
}

export interface WhatToExpectProps extends DispatchActions, RouteComponentProps {
  match: any;
  wte?: WhatToExpectPageItem[];
  wteIsFetching?: boolean;
  onClose: () => void;
}
export interface WhatToExpectState {}

export class WhatToExpect extends React.Component<WhatToExpectProps, WhatToExpectState> {
  public componentDidMount() {
    if (this.props.actions.getWhatToExpect) {
      this.props.actions.getWhatToExpect(this.props.match.params.code);
    }
  }

  public render(): JSX.Element {
    return (
      <Page title={getString(ResourceKey.whatToExpectTitle)} isLoading={this.props.wteIsFetching} showBackButton>
        <PageHeader title={getString(ResourceKey.whatToExpectTitle)} subtitle={getString(ResourceKey.whatToExpectSubTitle)} />
        <WhatToExpectItems wte={this.props.wte} />
      </Page>
    );
  }
}
