import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { IMAGES } from '../../../services/constants';
import { css } from '../../../utils/css';
import { isMobile } from '../../../utils/utils';
import { Escape } from '../../utils/accessibility/Escape';
import { FocusTrap } from '../../utils/accessibility/FocusTrap';
import Button, { ButtonStyle } from '../Button';
import { ButtonLink } from '../ButtonLink';
import { Flyout } from '../Flyout';
import Logo from '../Logo';
import LogoIcon from '../LogoIcon';
import { MenuNav } from './MenuNav';
import styles from './TopNav.module.scss';

export interface ButtonProps {
  action: () => void;
  text: string;
}

export interface TopNavProps {
  title?: string;
  secondary?: boolean;
  onBackPress?: () => void;
  onClosePress?: () => void;
  buttons?: ButtonProps[];
  hideMenu?: boolean;
}

const TopNav = (props: TopNavProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const { authState } = useOktaAuth();

  const renderLeft = () => {
    const shouldShowIcon = !props.onBackPress && !authState?.isAuthenticated && isMobile;
    if (shouldShowIcon) {
      return <LogoIcon className={css(styles.logoIcon, styles.left)} />;
    }

    const shouldShowBackButton = !!props.onBackPress;

    return (
      <div className={styles.left}>
        {shouldShowBackButton && (
          <Button
            buttonStyle={ButtonStyle.NONE}
            onClick={onBackClick}
            data-tid="btn_back"
            text=""
            className={styles.backButton}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <ReactSVG src={IMAGES.caretLeft} alt="" className="themeSVG" style={{ paddingTop: 5, paddingRight: 5 }} />
            <span style={{ fontSize: 14 }}>Back</span>
          </Button>
        )}
      </div>
    );
  };

  const renderMiddle = () => {
    const iconMayOverlapRightButton = isMobile && !authState?.isAuthenticated;

    return (
      <div className={styles.middle}>
        {!iconMayOverlapRightButton && (isMobile ? <LogoIcon className={styles.logo} /> : <Logo className={styles.logoIcon} />)}
      </div>
    );
  };

  const renderRight = () => {
    if (props.hideMenu) {
      return null;
    }

    if (props.onClosePress) {
      return (
        <Button buttonStyle={ButtonStyle.NONE} className={styles.right} onClick={props.onClosePress} data-tid="btn_close" text="Close">
          <Close />
        </Button>
      );
    }

    const onClickHandler = showMenu ? onMenuClose : onMenuClick;
    const clickableContent = showMenu ? <Close /> : <Hamburger />;

    let buttons;
    if (props.buttons && props.buttons.length > 0) {
      buttons = (
        <div>
          {props.buttons.map((button, index) => {
            return <ButtonLink className={styles.textButton} key={index} text={button.text} onClick={button.action} />;
          })}
        </div>
      );
    }

    return (
      <div className={styles.right}>
        {buttons}
        <Button buttonStyle={ButtonStyle.NONE} data-tid="btn_menu" onClick={onClickHandler} text="Toggle sidebar menu" aria-expanded={showMenu}>
          {clickableContent}
        </Button>
      </div>
    );
  };

  const renderMenuFlyOut = () => {
    if (props.hideMenu) {
      return null;
    }

    return (
      <div className={css(styles.flyOut)}>
        <FocusTrap>
          <Flyout visible={showMenu} onClose={onFlyoutClose} from="RIGHT" topOffset={60}>
            <Escape onEscape={onFlyoutClose} />
            <MenuNav isAuthenticated={!!authState?.isAuthenticated} />
          </Flyout>
        </FocusTrap>
      </div>
    );
  };

  const onBackClick = () => {
    props.onBackPress && props.onBackPress();
  };

  const onMenuClick = () => {
    setShowMenu(true);
  };

  const onFlyoutClose = () => {
    setShowMenu(false);
  };

  const onMenuClose = () => {
    setShowMenu(false);
  };

  return (
    <header>
      <div
        role="navigation"
        className={css(styles.topNav, styles.authorized, {
          [styles.hideMenu]: !!props.hideMenu,
        })}
      >
        {renderLeft()}
        {renderMiddle()}
        {renderRight()}
        {renderMenuFlyOut()}
      </div>
    </header>
  );
};

export default TopNav;

const Hamburger = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 -2 40 40">
      <g fill="none" fillRule="evenodd" stroke="#545759" strokeLinecap="round" strokeWidth="2">
        <path d="M10 12h20M10 19h20M10 26h20" />
      </g>
    </svg>
  );
};

const Close = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 -2 40 40">
      <g fill="none" fillRule="evenodd" stroke="#545759" strokeLinecap="round" strokeWidth="2">
        <path d="M29.799 28.532L10 9M29.799 9L10 28.532" />
      </g>
    </svg>
  );
};
