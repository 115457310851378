import { History } from 'history';
import React from 'react';
import Page from '../../components/core/Page';
import { ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';

interface DispatchActions {
  actions: {
    samlCallbackLogin: (token: string) => void;
  };
}

export interface OauthSSOProps extends DispatchActions {
  history: History;
}

// This component is to serve as a callback at the end of SAML SSO flow to log a person
// in via the freshly received oauth tokens, hence isLoading
export class OauthSSO extends React.Component<OauthSSOProps> {
  public componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const accessToken = queryParams.get('at');
    if (accessToken) {
      this.props.actions.samlCallbackLogin(accessToken);
    }
    this.props.history.replace(ROUTES.bookPatient);
  }

  public render(): JSX.Element {
    return <Page showCloseButton showMenuButton={false} title={getString(ResourceKey.signInOauth)} isLoading />;
  }
}
