export interface Dictionary {
  [className: string]: boolean;
}

export type CssInput = string | Dictionary | null | undefined | boolean;

export function css(...args: CssInput[]): string {
  let classes = [];

  for (let arg of args) {
    if (arg) {
      if (typeof arg === 'string') {
        classes.push(arg);
      } else {
        for (let key in arg as any) {
          if ((arg as any)[key]) {
            classes.push(key);
          }
        }
      }
    }
  }

  return classes.join(' ');
}
