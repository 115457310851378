import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { hideBottomSheet } from '../../appState/notification/actions';
import { NotificationState } from '../../appState/notification/types';
import { RootState } from '../../appState/rootState';
import { BottomSheet } from '../../components/core/BottomSheet';
import { createMarkup, getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { LegacyButton } from '../core/LegacyButton';
import styles from './BottomSheetContainer.module.scss';

export interface BottomSheetContainerActions {
  hideBottomSheet: () => void;
}

export interface BottomSheetContainerProps {
  notification?: NotificationState;
  actions?: BottomSheetContainerActions;
}

class BottomSheetContainer extends React.Component<React.PropsWithChildren<BottomSheetContainerProps>> {
  public render(): JSX.Element {
    return (
      <div>
        {this.props.children}
        {this.renderBottomSheet()}
      </div>
    );
  }

  private renderBottomSheet(): JSX.Element | null {
    if (!this.props.notification || !this.props.notification.bottomSheet) {
      return null;
    }
    return (
      <BottomSheet title={this.props.notification.bottomSheet.title} visible showCloseButton onClose={this.closeBottomSheet}>
        <div className={styles.sheetContainer}>
          <div dangerouslySetInnerHTML={createMarkup(this.props.notification.bottomSheet.message || '')} />
          <LegacyButton
            className={styles.sheetButton}
            text={this.props.notification.bottomSheet.primaryButtonText || getString(ResourceKey.genericButtonClose)}
            onClick={() => {
              if (this.props.notification && this.props.notification.bottomSheet && this.props.notification.bottomSheet.primaryButtonAction) {
                this.props.notification.bottomSheet.primaryButtonAction();
              }
              this.closeBottomSheet();
            }}
            disabled={false}
            testId="btn_close"
          />
          {this.props.notification.bottomSheet.secondaryButtonText && (
            <LegacyButton
              className={styles.sheetButton}
              text={this.props.notification.bottomSheet.secondaryButtonText}
              onClick={() => {
                if (this.props.notification && this.props.notification.bottomSheet && this.props.notification.bottomSheet.secondaryButtonAction) {
                  this.props.notification.bottomSheet.secondaryButtonAction();
                }
                this.closeBottomSheet();
              }}
              disabled={false}
              testId="btn_secondary"
            />
          )}
        </div>
      </BottomSheet>
    );
  }

  private closeBottomSheet = () => {
    if (this.props.actions) {
      this.props.actions.hideBottomSheet();
    }
  };
}

function mapStateToProps(state: RootState, ownProps: BottomSheetContainerProps): BottomSheetContainerProps {
  return {
    ...ownProps,
    notification: state.notification,
  };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: BottomSheetContainerProps): BottomSheetContainerProps {
  return {
    ...ownProps,
    actions: bindActionCreators(
      {
        hideBottomSheet,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BottomSheetContainer);
