import * as React from 'react';
import { IMAGES } from '../../services/constants';
import styles from './NotificationsBox.module.scss';

export interface NotificationsBoxProps {
  type: 'info' | 'warning' | 'error' | 'success';
  content?: string;
  jsxContent?: JSX.Element;
  title?: string;
}

export const NotificationsBox: React.FC<NotificationsBoxProps> = ({ type, content, jsxContent, title }) => {
  const className = styles[type];

  if (content || jsxContent) {
    return (
      <div aria-live="polite" role="alert" className={`${styles.notificationBox} ${className}`} data-tid="notification_box">
        {title && (
          <div className={styles.header}>
            <div className={styles.title} data-tid="notification_title">
              {title}
            </div>
          </div>
        )}
        {type === 'success' && <img src={IMAGES.checkmark} alt="Thank you" className={styles.checkmark} />}
        {content && <div dangerouslySetInnerHTML={{ __html: content }} data-tid="notification_body" />}
        {jsxContent}
      </div>
    );
  }

  return null;
};
