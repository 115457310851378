import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { object, string } from 'yup';
import { addPatient, clearError, editPatient } from '../../appState/patient/actions';
import { CreateOrEditPatientRequest, Patient, PatientSecondaryContact } from '../../appState/patient/types';
import { RootState } from '../../appState/rootState';
import { FEATURES, IMAGES, ROUTES } from '../../services/constants';
import { createMarkup, getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { getNumDays, MONTHS, parseDateOfBirth } from '../../utils/date';
import { YUP_VALIDATIONS } from '../../utils/forms';
import { grabInitials } from '../../utils/strings';
import { isLocal } from '../../utils/utils';
import { BottomSheet } from '../core/BottomSheet';
import Button from '../core/Button';
import { Identity } from '../core/Identity';
import Input from '../core/Input';
import InputButton from '../core/InputButton';
import { Loading } from '../core/Loading';
import Select, { SelectOption } from '../core/Select';
import { SecondaryContactForm } from '../secondaryContact/SecondaryContactForm';
import { NotificationsBox } from '../utils/NotificationsBox';
import styles from './PatientForm.module.scss';

interface PatientFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  relationshipId: string;
  sex: string;
  dobMonth: string;
  dobDay: string;
  dobYear: string;
  primaryPayerId: string;
  primaryMemberId: string;
  primaryGroupId: string;
  secondaryPayerId: string;
  secondaryMemberId: string;
  secondaryGroupId: string;
}

enum BottomSheetType {
  SECONDARY_CONTACT,
  DISABLE_NAME_DOB,
  DISABLE_EMAIL,
}

interface PatientFormProps {
  patient?: Patient;
  formComplete?: () => void;
}

const PatientForm: React.FC<PatientFormProps> = ({ patient, formComplete }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { patientList, config, userAccountInfo } = useSelector((state: RootState) => state);
  const [bottomSheetType, setBottomSheetType] = useState<BottomSheetType>();
  const [secondaryContact, setSecondaryContact] = useState<PatientSecondaryContact | undefined>(patient?.secondaryContact);

  useEffect(() => {
    if (!secondaryContact && secondaryContact !== patient?.secondaryContact) {
      setSecondaryContact(patient?.secondaryContact);
    }
  }, [patient, secondaryContact]);

  const patientPreferredDoctor = useMemo(() => patient?.preferredDoctor?.doctor, [patient]);
  const disableNameEmailDobFields = useMemo(() => patient && patient.completedVisits > 0, [patient]);
  const showEmailNotVerified = useMemo(
    () => patient?.mainPatientOfAccount && userAccountInfo.data?.features.includes(FEATURES.verifyEmailShowBanner),
    [patient, userAccountInfo]
  );
  const disableMainPatientEmailField = useMemo(() => patient?.mainPatientOfAccount, [patient]);

  const defaultValues = useMemo(() => {
    // Has primary?
    let primary: { primaryPayerId?: string; primaryMemberId?: string; primaryGroupId?: string } = {
      primaryPayerId: '',
      primaryMemberId: '',
      primaryGroupId: '',
    };
    if ((patient?.insurances || []).length > 0) {
      primary.primaryPayerId = patient?.insurances[0].payer.id ?? '';
      primary.primaryMemberId = patient?.insurances[0].memberId ?? '';
      primary.primaryGroupId = patient?.insurances[0].groupId ?? '';
    }

    // Has secondary?
    let secondary: { secondaryPayerId?: string; secondaryMemberId?: string; secondaryGroupId?: string } = {
      secondaryPayerId: '',
      secondaryMemberId: '',
      secondaryGroupId: '',
    };
    if ((patient?.insurances || []).length > 1) {
      secondary.secondaryPayerId = patient?.insurances[1].payer.id ?? '';
      secondary.secondaryMemberId = patient?.insurances[1].memberId ?? '';
      secondary.secondaryGroupId = patient?.insurances[1].groupId ?? '';
    }

    return {
      firstName: patient?.firstName ?? '',
      lastName: patient?.lastName ?? '',
      email: patient?.email ?? '',
      phone: patient?.phone ?? '+1',
      relationshipId: patient?.relationshipId,
      sex: patient?.genderId ?? undefined,
      dobMonth: patient?.dateOfBirth ? parseDateOfBirth(patient.dateOfBirth).month : undefined,
      dobDay: patient?.dateOfBirth ? parseDateOfBirth(patient.dateOfBirth).day : undefined,
      dobYear: patient?.dateOfBirth ? parseDateOfBirth(patient.dateOfBirth).year : undefined,
      ...primary,
      ...secondary,
      primaryRequestGroupId: false,
      secondaryRequestGroupId: false,
    };
  }, [patient]);

  interface PatientFormDataShape extends PatientFormData {
    primaryRequestGroupId: boolean;
    secondaryRequestGroupId: boolean;
  }

  const {
    watch,
    control,
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
    getValues,
    setValue,
    reset,
    trigger,
  } = useForm<PatientFormDataShape>({
    mode: 'onTouched',
    resolver: yupResolver(
      object().shape({
        firstName: YUP_VALIDATIONS.firstName,
        lastName: YUP_VALIDATIONS.lastName,
        email: YUP_VALIDATIONS.email,
        phone: YUP_VALIDATIONS.phone,
        sex: YUP_VALIDATIONS.sex,
        dobMonth: YUP_VALIDATIONS.dobMonth,
        dobDay: YUP_VALIDATIONS.dobDay,
        dobYear: YUP_VALIDATIONS.dobYear,
        primaryPayerId: string(),
        primaryMemberId: YUP_VALIDATIONS.primaryMemberId,
        primaryGroupId: YUP_VALIDATIONS.primaryGroupId,
        secondaryPayerId: string(),
        secondaryMemberId: YUP_VALIDATIONS.secondaryMemberId,
        secondaryGroupId: YUP_VALIDATIONS.secondaryGroupId,
      })
    ),
    defaultValues,
  });

  // Reset form w/ defaultValues if props.patient updates defaultValues
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  // If props.patient is updated, clear patientList.error
  useEffect(() => {
    dispatch(clearError());
  }, [patient, dispatch]);

  // Clear patientList.error on dismount
  useEffect(() => {
    return () => {
      if (patientList.error) {
        dispatch(clearError());
      }
    };
  }, [patientList.error, dispatch]);

  // Date of birth <option>s
  const watchDobMonth = watch('dobMonth');
  const watchDobDay = watch('dobDay');
  const watchDobYear = watch('dobYear');

  const dobMonthOptions = useMemo<SelectOption[]>(() => MONTHS.map((month, index) => ({ value: index + 1, text: month.text })), []);

  const dobDayOptions = useMemo<SelectOption[]>(() => {
    const days = [];
    const numDays = getNumDays(parseInt(watchDobMonth), parseInt(watchDobYear));

    for (let i = 1; i <= numDays; i++) {
      days.push({ text: i.toString(), value: i });
    }

    return days;
  }, [watchDobMonth, watchDobYear]);

  const dobYearOptions = useMemo<SelectOption[]>(() => {
    const years = [];

    for (let i = new Date().getFullYear(); i >= 1900; i--) {
      years.push({ text: i.toString(), value: i });
    }

    return years;
  }, []);

  // Sex & relationship <option>s
  const sexOptions = useMemo(() => config.gendersForRadio.map((gender) => ({ value: gender.key, text: gender.text })), [config]);
  const relationshipIdOptions = useMemo(
    () =>
      config.relationshipsForDropdown
        .filter((relationship) => relationship.text !== 'You')
        .map((relationship) => ({ value: relationship.key, text: relationship.text })),
    [config]
  );

  const primaryPayerIdOptions = useMemo(() => {
    const options = config.payersForDropdown.filter((p) => p.text !== 'Medicaid').map((payer) => ({ value: payer.key, text: payer.text }));
    const patientHasInsuranceNotInConfig =
      (patient?.insurances || []).length > 0 && !config.payersForDropdown.some((payer) => payer.key === patient!.insurances[0].payer.id);
    if (patientHasInsuranceNotInConfig) {
      options.push({ value: patient!.insurances[0].payer.id, text: patient!.insurances[0].payer.name });
    }

    return options;
  }, [config, patient]);

  const secondaryPayerIdOptions = useMemo(() => {
    const options = config.payersForDropdown.map((payer) => ({ value: payer.key, text: payer.text }));
    const patientHasInsuranceNotInConfig =
      (patient?.insurances || []).length > 1 && !config.payersForDropdown.some((payer) => payer.key === patient!.insurances[1].payer.id);
    if (patientHasInsuranceNotInConfig) {
      options.push({ value: patient!.insurances[1].payer.id, text: patient!.insurances[1].payer.name });
    }

    return options;
  }, [config, patient]);

  const watchPrimaryPayerId = watch('primaryPayerId');
  const watchSecondaryPayerId = watch('secondaryPayerId');

  const primaryRequestGroupId = useMemo(() => {
    return config.payersForDropdown.find((i) => i.data?.payerId === watchPrimaryPayerId)?.data?.requestGroupId;
  }, [config, watchPrimaryPayerId]);

  const secondaryRequestGroupId = useMemo(() => {
    return config.payersForDropdown.find((i) => i.data?.payerId === watchSecondaryPayerId)?.data?.requestGroupId;
  }, [config, watchSecondaryPayerId]);

  /**
   * Synthetic fields used to pass config payers' requestGroupId values into the
   * validation functions. Without this, the optionality of the groupId isn't known
   * by the validators, and form submission could be disabled, even when the groupId
   * input is hidden. Can be accessed using the .when() matcher.
   * @see utils/forms.ts@YUP_VALIDATIONS
   */
  useEffect(() => {
    register('primaryRequestGroupId');
    register('secondaryRequestGroupId');
  }, []);

  useEffect(() => {
    setValue('primaryRequestGroupId', primaryRequestGroupId ?? false);
    trigger('primaryGroupId');
  }, [primaryRequestGroupId]);

  useEffect(() => {
    setValue('secondaryRequestGroupId', secondaryRequestGroupId ?? false);
    trigger('secondaryGroupId');
  }, [secondaryRequestGroupId]);

  /**
   * Construct our own version of React Hook Forms' `isDirty`.
   * The version provided by React Hook Forms remains true after one of the fields
   * becomes dirty, even if it is subsequently reverted to its original value. The dev
   * panel recognizes that none of the fields are dirty, and `dirtyFields` is an empty
   * object, but the isDirty boolean remains true.
   */
  const formIsDirty = Object.keys(dirtyFields).length > 0;

  function onSubmit(data: PatientFormData) {
    // Set relationship to 'You' for mainPatientOfAccount profile
    const youRelationship = config.relationshipsForDropdown.find((relationship) => relationship.text === 'You');
    const relationshipId = (patient?.mainPatientOfAccount ? youRelationship?.key : data.relationshipId) ?? '';

    const insurances = [];
    if (data.primaryPayerId && data.primaryMemberId) {
      insurances.push({ payerId: data.primaryPayerId, memberId: data.primaryMemberId, groupId: primaryRequestGroupId ? data.primaryGroupId : undefined });

      if (data.secondaryPayerId && data.secondaryPayerId) {
        insurances.push({
          payerId: data.secondaryPayerId,
          memberId: data.secondaryMemberId,
          groupId: secondaryRequestGroupId ? data.secondaryGroupId : undefined,
        });
      }
    }

    const patientRequest: CreateOrEditPatientRequest = {
      firstName: data.firstName,
      lastName: data.lastName,
      mobile: data.phone,
      dateOfBirth: `${data.dobMonth}/${data.dobDay}/${data.dobYear}`,
      genderId: data.sex,
      email: data.email,
      relationshipId,
      insurances,
      secondaryContact: secondaryContact ? { ...secondaryContact } : undefined,
    };

    if (patient) {
      dispatch(editPatient(patient.id, patientRequest));
    } else {
      dispatch(addPatient(patientRequest));
    }
    // TODO: revisit this...
    formComplete && formComplete();
  }

  function renderBottomSheet() {
    let title;
    let content;

    switch (bottomSheetType) {
      case BottomSheetType.DISABLE_EMAIL:
        title = getString(ResourceKey.bottomSheetBlockEmailTitle);
        content = <div dangerouslySetInnerHTML={createMarkup(getString(ResourceKey.bottomSheetBlockEmailText))} />;
        break;
      case BottomSheetType.SECONDARY_CONTACT:
        title = getString(ResourceKey.bookPatientSecondaryContactTitle);
        content = (
          <SecondaryContactForm
            patientId={patient?.id}
            contact={secondaryContact}
            onSaved={(contact) => {
              setSecondaryContact(contact);
              setBottomSheetType(undefined);
            }}
          />
        );
        break;
      case BottomSheetType.DISABLE_NAME_DOB:
        title = getString(ResourceKey.bottomSheetBlockNameBirthDateTitle);
        content = getString(ResourceKey.bottomSheetBlockNameBirthDateText);
        break;
    }

    return (
      <BottomSheet title={title} visible={bottomSheetType !== undefined} showCloseButton onClose={() => setBottomSheetType(undefined)}>
        <div className={styles.sheetContainer}>
          {content}
          {bottomSheetType !== BottomSheetType.SECONDARY_CONTACT && (
            <Button
              className={styles.sheetButton}
              text={getString(ResourceKey.genericButtonClose)}
              onClick={() => setBottomSheetType(undefined)}
              testId="btn_close"
            />
          )}
        </div>
      </BottomSheet>
    );
  }

  const InfoButton = ({ onClick, ariaLabel }: { onClick: () => void; ariaLabel: string }) => (
    <button onClick={onClick} aria-label={ariaLabel} style={{ padding: 0, border: 'none', background: 'none' }}>
      <ReactSVG src={IMAGES.questionInfo} className={styles.infoButton} alt={ariaLabel} />
    </button>
  );

  return patientList.isFetching || patientList.success ? (
    <Loading padding={60} />
  ) : (
    <>
      {patientList.error && (
        <NotificationsBox
          type="error"
          content={
            patientList.error.validation.length
              ? patientList.error.validation.reduce((previous, current) => previous + '<br />' + current)
              : patientList.error.description
          }
        />
      )}

      <form onSubmit={handleSubmit(onSubmit)} title={patient ? 'Edit Patient Details' : 'Add Patient Details'}>
        <Input
          label="First name"
          id="firstName"
          error={errors.firstName?.message}
          readOnly={disableNameEmailDobFields}
          accessory={
            disableNameEmailDobFields && (
              <InfoButton
                onClick={() => setBottomSheetType(BottomSheetType.DISABLE_NAME_DOB)}
                ariaLabel="Opens new dialog with information about why this field is disabled"
              />
            )
          }
          required
          {...register('firstName')}
        />

        <Input
          label="Last name"
          id="lastName"
          error={errors.lastName?.message}
          readOnly={disableNameEmailDobFields}
          accessory={
            disableNameEmailDobFields && (
              <InfoButton
                onClick={() => setBottomSheetType(BottomSheetType.DISABLE_NAME_DOB)}
                ariaLabel="Opens new dialog with information about why this field is disabled"
              />
            )
          }
          required
          {...register('lastName')}
        />

        <Input
          label="Email"
          id="email"
          error={errors.email?.message}
          helpText={showEmailNotVerified && 'This email has not been verified'}
          readOnly={disableNameEmailDobFields || disableMainPatientEmailField}
          accessory={
            (disableNameEmailDobFields || disableMainPatientEmailField) && (
              <InfoButton
                onClick={() => setBottomSheetType(BottomSheetType.DISABLE_EMAIL)}
                ariaLabel="Opens new dialog with information about why this field is disabled."
              />
            )
          }
          required
          {...register('email')}
        />

        <Controller
          name="phone"
          render={({ field }) => (
            <div className={`form-group required ${errors.phone ? 'invalid' : ''}`}>
              <label className="form-label" htmlFor={field.name}>
                Phone
              </label>
              <PhoneInput
                value={field.value}
                inputProps={{
                  name: field.name,
                  id: field.name,
                  required: true,
                  'data-lpignore': true,
                  'aria-describedby': errors.phone ? `${field.name}_error` : undefined,
                  'data-tid': `inp_${field.name}`,
                }}
                onChange={(_value, _data, _event, formattedValue) => field.onChange(formattedValue)}
                onBlur={field.onBlur}
                inputClass="form-control"
                country="us"
              />
              {errors.phone && (
                <div className="form-control-error" id={`${field.name}_error`}>
                  {errors.phone?.message}
                </div>
              )}
            </div>
          )}
          control={control}
        />

        {!patient?.mainPatientOfAccount && (
          <Select
            label="Relationship to account holder"
            id="relationshipId"
            options={relationshipIdOptions}
            error={errors.relationshipId?.message}
            required
            {...register('relationshipId')}
          />
        )}

        <Select label="Sex" id="sex" options={sexOptions} error={errors.sex?.message} required {...register('sex')} />

        <div className="form-group required" role="group" aria-labelledby="dobLabel">
          <div className="form-label-wrapper">
            <div className="form-label" id="dobLabel">
              Date of birth
            </div>
            {disableNameEmailDobFields && (
              <div className="form-control-accessory">
                <InfoButton
                  ariaLabel="Opens new dialog with information about these date fields are disabled"
                  onClick={() => setBottomSheetType(BottomSheetType.DISABLE_NAME_DOB)}
                />
              </div>
            )}
          </div>
          <div className="form-control-group">
            <Select
              id="dobMonth"
              label="Month"
              className={`form-control-month ${watchDobMonth ? '' : 'empty'}`}
              error={errors.dobMonth?.message}
              options={dobMonthOptions}
              disabled={disableNameEmailDobFields}
              required
              {...register('dobMonth')}
            />
            <Select
              id="dobDay"
              label="Day"
              className={`form-control-day ${watchDobDay ? '' : 'empty'}`}
              error={errors.dobDay?.message}
              options={dobDayOptions}
              disabled={disableNameEmailDobFields}
              required
              {...register('dobDay')}
            />
            <Select
              id="dobYear"
              label="Year"
              className={`form-control-year ${watchDobYear ? '' : 'empty'}`}
              error={errors.dobYear?.message}
              options={dobYearOptions}
              disabled={disableNameEmailDobFields}
              required
              {...register('dobYear')}
            />
          </div>
        </div>

        <div className={styles.insuranceFields}>
          <Select
            label="Primary insurance"
            placeholder="Primary insurance"
            id="primaryPayerId"
            options={primaryPayerIdOptions}
            error={errors.primaryPayerId?.message}
            {...register('primaryPayerId', {
              onChange: (e) => {
                trigger('primaryMemberId');
                trigger('primaryGroupId');
                trigger('primaryRequestGroupId');
              },
            })}
          />
          <Input
            label="Member ID"
            id="primaryMemberId"
            error={errors.primaryMemberId?.message}
            required={watchPrimaryPayerId !== ''}
            {...register('primaryMemberId')}
          />
          {primaryRequestGroupId && (
            <Input label="Group ID" id="primaryGroupId" error={errors.primaryGroupId?.message} required {...register('primaryGroupId')} />
          )}
        </div>
        <div className={styles.insuranceFields}>
          <Select
            label="Secondary insurance"
            id="secondaryPayerId"
            options={secondaryPayerIdOptions}
            error={errors.secondaryPayerId?.message}
            {...register('secondaryPayerId', {
              onChange: (e) => {
                trigger('secondaryMemberId');
                trigger('secondaryGroupId');
                trigger('secondaryRequestGroupId');
              },
            })}
          />
          <Input
            label="Member ID"
            id="secondaryMemberId"
            error={errors.secondaryMemberId?.message}
            required={watchSecondaryPayerId !== ''}
            {...register('secondaryMemberId')}
          />
          {secondaryRequestGroupId && (
            <Input
              label="Group ID"
              id="secondaryGroupId"
              error={errors.secondaryGroupId?.message}
              required={watchSecondaryPayerId !== ''}
              {...register('secondaryGroupId')}
            />
          )}
        </div>

        {patient && userAccountInfo.data?.features.includes(FEATURES.preferredDoctor) && (
          <InputButton
            label="Preferred doctor"
            id="preferredDoctor"
            accessory={<ReactSVG src={IMAGES.caretRight} alt="" className="themeSVG" />}
            onClick={() => {
              if (patient) {
                history.push(ROUTES.patientSelectDoctor.replace(':id', patient.id));
              }
            }}
          >
            {patientPreferredDoctor && (
              <div className={styles.preferredDoctor}>
                <Identity
                  initials={patientPreferredDoctor.firstName ? grabInitials(`${patientPreferredDoctor.firstName} ${patientPreferredDoctor.lastName}`) : 'DR'}
                  size={22}
                  url={patientPreferredDoctor.avatarUrl}
                />
                <div className={styles.name}>{`${patientPreferredDoctor.firstName} ${patientPreferredDoctor.lastName}`}</div>
              </div>
            )}
          </InputButton>
        )}

        <InputButton
          label="Secondary contact"
          id="secondaryContact"
          accessory={<ReactSVG src={IMAGES.caretRight} alt="" className="themeSVG" />}
          onClick={() => setBottomSheetType(BottomSheetType.SECONDARY_CONTACT)}
        >
          {secondaryContact && `${secondaryContact.firstName} ${secondaryContact.lastName}`}
        </InputButton>

        <Button text={patient ? 'Save' : 'Add'} disabled={Object.values(errors).some(Boolean) || !formIsDirty} className="btn-block" />
      </form>

      {renderBottomSheet()}

      {isLocal && <DevTool control={control} placement="bottom-left" />}
    </>
  );
};

export default PatientForm;
