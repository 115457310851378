import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './appState/store';
import OktaSecurity from './components/core/OktaSecurity';
import Theme from './components/core/Theme';
import Routes from './components/routes/Routes';
import BottomSheetContainer from './components/utils/BottomSheetContainer';
import ErrorBoundary from './components/utils/ErrorBoundary';
import './services/firebase';

function App() {
  return (
    <Router>
      <Provider store={store}>
        <OktaSecurity>
          <PersistGate loading={null} persistor={persistor}>
            <Theme>
              <ErrorBoundary>
                <BottomSheetContainer>
                  <Routes />
                </BottomSheetContainer>
              </ErrorBoundary>
            </Theme>
          </PersistGate>
        </OktaSecurity>
      </Provider>
    </Router>
  );
}

export default App;
