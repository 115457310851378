import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { samlCallbackLogin } from '../../appState/auth/actions';
import { RootState } from '../../appState/rootState';
import { OauthSSO, OauthSSOProps } from '../oauthSSO/OauthSSO';

function mapStateToProps(state: RootState, ownProps: OauthSSOProps): OauthSSOProps {
  return {
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: OauthSSOProps): OauthSSOProps {
  return {
    ...ownProps,
    actions: bindActionCreators(
      {
        samlCallbackLogin,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OauthSSO);
