import * as H from 'history';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';

export interface Props {
  to: string;
}

export const RedirectWithSearch = (props: Props) => {
  const history = useHistory();

  const pathWithQueryParams: H.LocationDescriptorObject = {
    pathname: props.to,
    search: history.location.search,
  };
  return <Redirect to={pathWithQueryParams} />;
};
