import * as React from 'react';
import { css } from '../../utils/css';
import styles from './ZeroData.module.scss';

export interface ZeroDataProps {
  text: string;
  textSecondary?: string;
  grayscale?: boolean;
  image?: {
    source: string;
  };
  testId?: string;
}

export class ZeroData extends React.Component<ZeroDataProps, object> {
  public render(): JSX.Element {
    let image: JSX.Element | null = null;
    if (this.props.image) {
      image = (
        <div
          className={css(styles.image, {
            [styles.grayscale]: !!this.props.grayscale,
          })}
        >
          <img alt={this.props.text} src={this.props.image.source} />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        {image}
        <div className={styles.text}>{this.props.text}</div>
        <div className={styles.textSecondary} data-tid={this.props.testId}>
          {this.props.textSecondary}
        </div>
      </div>
    );
  }
}
