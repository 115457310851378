import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../appState/rootState';
import { getWhatToExpect } from '../../appState/whatToExpect/actions';
import { WhatToExpect, WhatToExpectProps } from '../whatToExpect/WhatToExpect';

function mapStateToProps(state: RootState, ownProps: WhatToExpectProps): WhatToExpectProps {
  return {
    ...ownProps,
    wte: state.whatToExpect.data,
    wteIsFetching: state.whatToExpect.isFetching,
  };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: WhatToExpectProps): WhatToExpectProps {
  return {
    ...ownProps,
    actions: bindActionCreators(
      {
        getWhatToExpect,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WhatToExpect);
