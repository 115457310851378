import { ApiResponse, ApisauceInstance, create } from 'apisauce';
import { Account } from '../appState/account/types';
import { Address, AddressRequest, Coverage } from '../appState/address/types';
import { AgreementResponse, AgreementsResponse } from '../appState/agreements/types';
import { PatientCreateUserRequest } from '../appState/auth/types';
import { CreateVisitRequest } from '../appState/checkout/types';
import { ConfigResponse } from '../appState/config/types';
import { CreateOrEditPatientRequest, Patient } from '../appState/patient/types';
import { PatientDoctorRatingRequestParams } from '../appState/rating/types';
import { ServiceOfferingsResponse } from '../appState/serviceOfferings/types';
import { ServicePricingRequest, ServicePricingResponse } from '../appState/servicePricing';
import { store } from '../appState/store';
import { CancelVisitRequest, Visit } from '../appState/visit/types';
import { WhatToExpectPageItem } from '../appState/whatToExpect/types';
import { CreateFileRequest, CreateFileResponse, PatchFileRequest } from '../types/Files';
import { InsuranceEligibility, InsuranceEligibilityRequest } from '../types/InsuranceEligibility';
import { TimeSlot } from '../types/TimeSlot';
import { oktaAuth } from './auth';

export type Headers = {
  [header: string]: string | undefined;
};

export interface ServerResponse {
  status: string;
  description: string;
  diagnostic: string;
  validation: string[];
  requestId: string;
}

export type BaseResponse<T> = {
  data: T;
  message: string;
  diagnostic: string;
};

export type DataResponse<T> = {
  status: string;
  description: string;
  validation: string[];
  data: T;
  total: number;
  requestParams: { asc: boolean; search: string; offset: number; limit: number; orderBy: string; status: string };
  requestId: string;
};

declare const healConfig: any;

async function createApi(contentType: string = 'application/json'): Promise<ApisauceInstance> {
  const token = await oktaAuth.getAccessToken();

  const headers: Headers = {
    'Content-Type': contentType,
  };

  if (token) {
    headers.Authorization = 'Bearer ' + token;
  }

  // Set x-heal headers for local backend
  if (healConfig.env === 'local' && process.env.NODE_ENV === 'development') {
    headers['x-heal-groups'] = 'PATIENT';
    headers['x-heal-user'] = store.getState().auth.username;
  }

  const API = create({
    baseURL: healConfig.apiBaseUrl,
    headers,
    withCredentials: true,
  });

  return Promise.resolve(API);
}

function createApiWithNoToken(contentType: string = 'application/json'): ApisauceInstance {
  return create({
    baseURL: healConfig.apiBaseUrl,
    headers: {
      'Content-Type': contentType,
    },
  });
}

function createApiWithToken(accessToken: string): ApisauceInstance {
  return create({
    baseURL: healConfig.apiBaseUrl,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  });
}

export class HealAPI {
  public static saveVisitAddon(parentVisitCode: string, serviceCode: string, patientId: string, paymentId?: string) {
    return createApi().then((api) => api.put<BaseResponse<Visit>>(`/v7/visits/${parentVisitCode}/services/${serviceCode}`, { patientId, paymentId }));
  }

  public static getServicePricing(data: ServicePricingRequest) {
    return createApi().then((api) => api.get<ServicePricingResponse>(`/v1/services/${data.serviceCode}/pricing`, data));
  }

  public static getServicesOnsite(patientId: string, partnerAddressId: string) {
    return createApi().then((api) => api.get<ServiceOfferingsResponse>('/v3/onsite/serviceOfferings', { patientId, partnerAddressId }));
  }

  public static getServices(patientId: string, addressId: string) {
    return createApi().then((api) => api.get<ServiceOfferingsResponse>('/v1/services', { patientId, addressId }));
  }

  public static forgotPassword(email: string): Promise<ApiResponse<any>> {
    return createApi().then((api) => api.post('/user/reset_password', { username: email }));
  }

  public static async resendEmailVerification() {
    return (await createApi()).post<BaseResponse<boolean>>('/v2/account/resend_email_validation', {});
  }

  public static resetPassword(newPassword: string, token: string): Promise<ApiResponse<any>> {
    return createApi().then((api) => api.post('/password-reset', { newPassword, token }));
  }

  public static async createAccount(data: PatientCreateUserRequest) {
    return (await createApi()).post<BaseResponse<Account>>('/v8/account', data);
  }

  public static getConfig() {
    return createApi().then((api) => api.get<ConfigResponse>('/config'));
  }

  public static async getCurrentStatus() {
    return (await createApi()).get<BaseResponse<Account>>('/v8/account');
  }

  public static async checkCoverage(zipcode: string) {
    return createApiWithNoToken().get<BaseResponse<Coverage>, ServerResponse>('/public/v4/checkCoverage', { zipcode });
  }

  /*
   * Closes user's Okta session via Heal Backend. A user may be unable to close Okta session in browser if they authenticated via SAML flow and in
   * other cases such as switching between Partner and Patient web app with an existing Agent Okta session.
   * Okta React is known to have poor documentation around ensuring a user is logged out properly (OktaSession closed & tokens revoked).
   */
  public static closeOktaSessionViaBackend() {
    return createApi().then((api) => api.get(`/logout`));
  }

  public static async addAddress(data: AddressRequest) {
    return (await createApi()).post<BaseResponse<Address>, ServerResponse>('/v2/account/address', data);
  }

  public static async addPatient(data: CreateOrEditPatientRequest) {
    return (await createApi()).post<BaseResponse<Patient>, ServerResponse>('/v4/patient', data);
  }

  public static async editPatient(id: string, data: CreateOrEditPatientRequest) {
    return (await createApi()).put<BaseResponse<Patient>, ServerResponse>(`/v4/patient/${id}`, data);
  }

  public static async deletePatient(id: string) {
    return (await createApi()).delete<ServerResponse>(`/v4/patient/${id}`);
  }

  public static createFile(patientId: string, data: CreateFileRequest) {
    return createApi().then((api) => api.post<BaseResponse<CreateFileResponse>>(`/patient/${patientId}/files`, data));
  }

  public static patchFile(patientId: string, fileId: string, data: PatchFileRequest) {
    return createApi().then((api) => api.patch<BaseResponse<CreateFileResponse>>(`/patient/${patientId}/files/${fileId}`, data));
  }

  public static deleteFile(patientId: string, fileId: string) {
    return createApi().then((api) => api.delete<{}>(`/patient/${patientId}/files/${fileId}`, {}));
  }

  public static uploadFile(url: string, file: File): Promise<ApiResponse<any>> {
    return createApiWithNoToken(file.type).put(url, file);
  }

  public static async getVisits() {
    return (await createApi()).get<DataResponse<Visit[]>, ServerResponse>('/v7/visits', { asc: false, limit: 100 });
  }

  public static async getVisit(visitCode: string) {
    return (await createApi()).get<BaseResponse<Visit>, ServerResponse>(`/v7/visits/${visitCode}`);
  }

  public static async saveVisit(data: CreateVisitRequest) {
    return (await createApi()).post<BaseResponse<Visit>, ServerResponse>(data.partnerAddressId ? '/v7/visits/onsite' : '/v7/visits', {
      bookingSource: 'patient_web',
      ...data,
    });
  }

  public static async cancelVisit(visitCode: string, data: CancelVisitRequest) {
    return (await createApi()).post<BaseResponse<string>, ServerResponse>(`/v7/visits/${visitCode}/cancel`, data);
  }

  public static async rescheduleVisit(visitCode: string, timeSlotId: string) {
    return (await createApi()).post<BaseResponse<Visit>, ServerResponse>(`/v7/visits/${visitCode}/reschedule`, { timeSlotId });
  }

  public static submitRating(patientId: string, visitCode: string, data: PatientDoctorRatingRequestParams): Promise<ApiResponse<any>> {
    return createApi().then((api) => api.post(`/v10/patient/${patientId}/visit/${visitCode}/doctorRating`, data));
  }

  public static getHealCarePlan(visitCode: string): Promise<any> {
    return createApi('application/pdf').then((api) => api.get(`/patient/visit/${visitCode}/downloadhcp`, {}, { responseType: 'blob' }));
  }

  public static setPreferredDoctor(patientId: string, providerId: string | null): Promise<ApiResponse<any>> {
    if (providerId) {
      const data = {
        providerId,
      };
      return createApi().then((api) => api.post(`/v10/patient/${patientId}/preferredDoctor`, data));
    } else {
      return createApi().then((api) => api.delete(`/v10/patient/${patientId}/preferredDoctor`));
    }
  }

  public static setSecondaryContact(patientId: string, data: {}): Promise<ApiResponse<any>> {
    return createApi().then((api) => api.post(`/v10/patient/${patientId}/secondaryContact`, data));
  }

  public static getQuickActions(userAccountId: string, patientId: string): Promise<ApiResponse<any>> {
    return createApi().then((api) => api.get(`/v10/account/${userAccountId}/quickActions/${patientId}`));
  }

  public static getAvailablePreferredDoctors(patientId: string): Promise<ApiResponse<any>> {
    return createApi().then((api) => api.get(`/v10/patient/${patientId}/preferredDoctorsAvailable`));
  }

  public static async getTimeSlots(serviceCode: string, userAccountAddressId: string, visitCode?: string, providerId?: string) {
    return (await createApi()).get<BaseResponse<TimeSlot[]>>('/v3/visit/timeslots', { serviceCode, userAccountAddressId, providerId, visitCode });
  }

  public static async getTimeSlotsOnsite(serviceCode: string, partnerAddressId: string) {
    return (await createApi()).get<BaseResponse<TimeSlot[]>>('/v2/onsite/timeslots', { serviceCode, partnerAddressId });
  }

  public static async insuranceEligibility(data: InsuranceEligibilityRequest) {
    return (await createApi()).post<BaseResponse<InsuranceEligibility>>('/v3/insuranceEligibility', data);
  }

  public static async getWhatToExpect(visitCode: string) {
    return (await createApi()).get<BaseResponse<WhatToExpectPageItem>, ServerResponse>(`/v7/visits/${visitCode}/wte`);
  }

  public static samlGetAccountData(clientId: string, accessToken: string): Promise<ApiResponse<any>> {
    return createApiWithToken(accessToken).get(`/saml/${clientId}/account`);
  }

  public static samlRegisterAccount(clientId: string, accessToken: string, data: any): Promise<ApiResponse<any>> {
    return createApiWithToken(accessToken).post(`/saml/${clientId}/account`, data);
  }

  public static linkSAML(clientId: string, accessToken: string, data: any): Promise<ApiResponse<any>> {
    return createApiWithToken(accessToken).post(`/saml/${clientId}/account/link`, data);
  }

  public static samlRegisterBlueShieldAccount(clientId: string, accessToken: string, data: { phone?: string }): Promise<ApiResponse<any>> {
    return createApiWithToken(accessToken).post(`/saml/${clientId}/account/implicit`, data);
  }

  public static updateUserCampaign(campaign: any): Promise<ApiResponse<{ data: boolean }>> {
    return createApi().then((api) => api.put('/v8/account/campaign', { campaign }));
  }

  public static getOnsite(publicCode: string): Promise<ApiResponse<any>> {
    return createApiWithNoToken().get(`/public/landing/partner/${publicCode}/patient`);
  }

  public static getAgreements(
    patientId: string,
    addressId?: string,
    partnerAddressId?: string,
    serviceCode?: string
  ): Promise<ApiResponse<AgreementsResponse>> {
    return createApi().then((api) =>
      api.get(`/v1/patients/${patientId}/agreements`, {
        addressId,
        partnerAddressId,
        signed: false,
        serviceCode,
      })
    );
  }

  public static getAgreement(patientId: string, vanity: string) {
    return createApi().then((api) => api.get<AgreementResponse>(`/v1/patients/${patientId}/agreements/${vanity}`));
  }

  public static submitAgreements(patientId: string, agreementIds: string[]): Promise<ApiResponse<AgreementsResponse>> {
    return createApi().then((api) =>
      api.post(`/v1/patients/${patientId}/agreements`, {
        legalDocumentIds: agreementIds,
      })
    );
  }
}
