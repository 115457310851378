import { Dispatch } from 'redux';
import { HealAPI } from '../../services/api';
import { TimeSlot } from '../../types/TimeSlot';
import { CancelVisitRequest } from './types';

export enum VisitActions {
  CANCEL_VISIT_REQUEST = 'CANCEL_VISIT_REQUEST',
  CANCEL_VISIT_SUCCESS = 'CANCEL_VISIT_SUCCESS',
  CANCEL_VISIT_FAIL = 'CANCEL_VISIT_FAIL',
  CANCEL_VISIT_CLEAR = 'CANCEL_VISIT_CLEAR',
  GET_VISITS_REQUEST = 'GET_VISITS_REQUEST',
  GET_VISITS_SUCCESS = 'GET_VISITS_SUCCESS',
  GET_VISITS_FAIL = 'GET_VISITS_FAIL',
  VISIT_RESCHEDULED = 'VISIT_RESCHEDULED',
}

export function cancelVisit(visitCode: string, data: CancelVisitRequest) {
  return (dispatch: Dispatch) => {
    dispatch({ type: VisitActions.CANCEL_VISIT_REQUEST });
    return HealAPI.cancelVisit(visitCode, data).then(
      (response) => {
        if (response.ok) {
          const message = response.data?.data && response.data?.data !== '' ? response.data?.data : "We hope that you feel better. We've canceled your visit.";
          dispatch({
            type: VisitActions.CANCEL_VISIT_SUCCESS,
            data: {
              visitCode,
              message,
            },
          });
        } else {
          dispatch({
            type: VisitActions.CANCEL_VISIT_FAIL,
            data: {
              visitCode: visitCode,
              message: response.data?.description,
            },
          });
        }
      },
      (response) => {
        dispatch({
          type: VisitActions.CANCEL_VISIT_FAIL,
          data: {
            visitCode: visitCode,
            message: response.data!.description,
          },
        });
      }
    );
  };
}

export function cancelVisitClearState() {
  return {
    type: VisitActions.CANCEL_VISIT_CLEAR,
  };
}

export function visitRescheduled(visitCode: string, timeSlot: TimeSlot, canReschedule: boolean) {
  return {
    type: VisitActions.VISIT_RESCHEDULED,
    data: {
      visitCode,
      timeSlot,
      canReschedule,
    },
  };
}

export function getVisits() {
  return (dispatch: Dispatch) => {
    dispatch({ type: VisitActions.GET_VISITS_REQUEST });

    HealAPI.getVisits().then(
      (response) => {
        if (response.ok) {
          dispatch({
            type: VisitActions.GET_VISITS_SUCCESS,
            data: response.data?.data,
          });
        } else {
          dispatch({
            type: VisitActions.GET_VISITS_FAIL,
            data: response.data?.description,
          });
        }
      },
      (response) => {
        dispatch({
          type: VisitActions.GET_VISITS_FAIL,
          data: response.data,
        });
      }
    );
  };
}
