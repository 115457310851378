import * as React from 'react';
import { KeyCodes } from '../../../utils/keycodes';

export class AccessibleElement extends React.Component<any> {
  public render(): JSX.Element {
    const { disableOnKeyDownAll, ...rest } = this.props;
    const allProps: any = { ...rest };
    allProps.tabIndex = allProps.tabIndex || 0;
    allProps.role = this.props.role || 'button';
    if (allProps.tabIndex === -1) {
      delete allProps.tabIndex;
      delete allProps.role;
    }

    if (!this.props.disableOnKeyDownAll) {
      allProps.onKeyDown = (e: any) => {
        if (this.props.onClick && (e.keyCode === KeyCodes.enter || e.keyCode === KeyCodes.space)) {
          this.props.onClick();
        }
      };
    }

    if (this.props.elementType === 'td') {
      delete allProps.elementType;
      return <td {...allProps}>{this.props.children}</td>;
    } else {
      return <div {...allProps}>{this.props.children}</div>;
    }
  }
}
