import { useEffect, useRef } from 'react';


function useIsMounted() {
  const componentIsMounted = useRef(false);
  useEffect(() => {
    componentIsMounted.current = true;
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  /*
  * Previous implementation was returning `componentIsMounted.current`. 
  * However, this was unexpectedly returning false in some cases when component was mounted.
  */
  return componentIsMounted;
}

export default useIsMounted;
