import * as React from 'react';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';

interface ImageLoaderProps {
  images: string[];
}

export class ImageLoader extends React.Component<ImageLoaderProps> {
  public render(): JSX.Element {
    return <div style={{ display: 'none' }}>{this.renderImages()}</div>;
  }

  private renderImages = () => {
    return this.props.images.map((image) => {
      return <img alt={getString(ResourceKey.pageLoadingAlt)} key={image} src={image} />;
    });
  };
}
