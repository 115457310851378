import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TimeSlot } from '../types/TimeSlot';

export interface TimeSlotsState {
  isFetching: boolean;
  error?: string;
  data?: TimeSlot[];
}

export const timeSlotsInitialState: TimeSlotsState = {
  isFetching: false,
};

export const timeSlotsSlice = createSlice({
  name: 'timeSlots',
  initialState: timeSlotsInitialState,
  reducers: {
    getTimeSlotsRequest: (state) => {
      state.isFetching = true;
      state.data = undefined;
      state.error = undefined;
    },
    getTimeSlotsSuccess: (state, action: PayloadAction<TimeSlot[]>) => {
      state.isFetching = false;
      state.data = action.payload;
      state.error = undefined;
    },
    getTimeSlotsFail: (state, action: PayloadAction<string>) => {
      state.isFetching = false;
      state.data = undefined;
      state.error = action.payload;
    },
    resetTimeSlots: () => timeSlotsInitialState,
  },
});

export const { getTimeSlotsRequest, getTimeSlotsSuccess, getTimeSlotsFail, resetTimeSlots } = timeSlotsSlice.actions;
