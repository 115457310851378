import { FileConfig, FILES } from '../services/constants';

export function isChildVisit(serviceCode: string): boolean {
  return serviceCode.endsWith('_CHILD') || serviceCode === 'PED';
}

export function getFileConfig(documentType: string): FileConfig {
  return FILES[documentType] ? FILES[documentType] : FILES.other;
}

export function isIOS() {
  let userAgent = navigator.userAgent;
  return userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1;
}

export function optional(expression: () => any): any {
  try {
    return expression();
  } catch (e) {
    return null;
  }
}

export function isHouseCallService(serviceCode: string) {
  const houseCallServiceCodes = [
    'ANPS_ADULT',
    'ANPS_CHILD',
    'LACTN_CNSLT',
    'PTHERAPY_ADULT',
    'SCRN_BASIC_ADU',
    'SCRN_BASIC_CHI',
    'SCRN_COMPL_ADU',
    'SCRN_COMPL_CHI',
    'SICK_ADULT',
    'SICK_CHILD',
    'TDAP_ADULT',
  ];

  return houseCallServiceCodes.indexOf(serviceCode) !== -1;
}

export function isFluShotService(serviceCode: string) {
  const fluShotServiceCodes = [
    'FLUSHOT_ADULT',
    'FLUSHOT_CHILD',
  ];

  return fluShotServiceCodes.includes(serviceCode);
}
