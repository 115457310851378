import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../appState/rootState';
import styles from '../../styles/index.module.scss';
import { setTheme } from '../../appState/userPreferences/actions';
import { ThemeState } from '../../appState/userPreferences/types';
import { bindActionCreators } from 'redux';

declare var healConfig: any;

interface StateProps {
  blueShieldSSOUser?: boolean;
  theme?: ThemeState;
}

interface DispatchProps {
  actions?: {
    setTheme: (theme: ThemeName, color: string, testMode?: boolean) => void;
  };
}

type Props = StateProps & DispatchProps;

export enum ThemeName {
  HEAL = 'heal',
  BLUE_SHIELD = 'blueShield',
}

export const ThemeColor = {
  [ThemeName.HEAL]: styles.themeHeal,
  [ThemeName.BLUE_SHIELD]: styles.themeBlueShield,
};

class Theme extends React.Component<React.PropsWithChildren<Props>> {
  componentDidMount() {
    this.updateTheme();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.blueShieldSSOUser !== this.props.blueShieldSSOUser) {
      this.updateTheme();
    }
  }

  render() {
    let themeClassName = 'theme-';

    if (this.props.theme) {
      themeClassName += this.props.theme.name;
    } else {
      themeClassName += ThemeName.HEAL;
    }

    return <div className={themeClassName}>{this.props.children}</div>;
  }

  private updateTheme() {
    const { blueShieldSSOUser, actions, theme } = this.props;
    const { env } = healConfig;

    let themeName = blueShieldSSOUser ? ThemeName.BLUE_SHIELD : ThemeName.HEAL;

    // Test mode
    if (env === 'local' || env === 'dev' || env === 'qa') {
      const queryParams = new URLSearchParams(window.location.search);

      switch (queryParams.get('theme')) {
        case 'bsc':
          actions?.setTheme(ThemeName.BLUE_SHIELD, ThemeColor[ThemeName.BLUE_SHIELD], true);
          break;
        case 'heal':
        case 'none':
          actions?.setTheme(ThemeName.HEAL, ThemeColor[ThemeName.HEAL], false);
          break;
        default:
          if (!theme?.testMode) {
            actions?.setTheme(themeName, ThemeColor[themeName], false);
          }
          break;
      }
    } else {
      actions?.setTheme(themeName, ThemeColor[themeName]);
    }
  }
}

function mapStateToProps(state: RootState, ownProps: Props): Props {
  return {
    ...ownProps,
    blueShieldSSOUser: state.userPreferences.blueshieldSSOUser,
    theme: state.userPreferences.theme,
  };
}

function mapDispatchToProps(dispatch: any, ownProps: Props): Props {
  return {
    ...ownProps,
    actions: bindActionCreators(
      {
        setTheme,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Theme);
