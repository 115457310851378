import { AnyAction } from 'redux';
import { CurrentStatus } from '../../appState/status/actions';
import { ServerResponse } from '../../services/api';
import { Account } from '../account/types';
import { PatientActions } from './actions';
import { Patient, PatientSecondaryContact, PatientState } from './types';

export const patientInitialState: PatientState = {
  isFetching: false,
  error: undefined,
  data: undefined,
  success: false,
};

/*
 * Get patients
 */
function handleGetPatientsRequest(state: PatientState): PatientState {
  return {
    ...state,
    isFetching: true,
    error: undefined,
    data: undefined,
    success: false,
  };
}

function handleGetPatientsSuccess(state: PatientState, data: Account): PatientState {
  const patientList: { [id: string]: Patient } = {};

  if (data.patients) {
    data.patients.reduce((map, patient) => {
      map[patient.patientId] = patient;
      return map;
    }, patientList);
  }

  return {
    ...state,
    isFetching: false,
    error: undefined,
    data: patientList,
    success: false,
  };
}

function handleGetPatientsFailure(state: PatientState, error: ServerResponse): PatientState {
  return {
    ...state,
    isFetching: false,
    success: false,
    error,
    data: state.data,
  };
}

/*
 * Add patient
 */
function handleAddPatientRequest(state: PatientState): PatientState {
  return {
    ...state,
    isFetching: true,
    success: false,
    error: undefined,
  };
}

function handleAddPatientSuccess(state: PatientState, data: Patient): PatientState {
  return {
    isFetching: false,
    success: true,
    error: undefined,
    successMessage: 'Successfully added profile',
    data: {
      ...state.data,
      [data.patientId]: data,
    },
  };
}

function handleAddPatientFailure(state: PatientState, error: ServerResponse): PatientState {
  return {
    ...state,
    isFetching: false,
    success: false,
    error,
  };
}

/*
 * Edit patient
 */
function handleEditPatientRequest(state: PatientState): PatientState {
  return {
    ...state,
    isFetching: true,
    error: undefined,
    success: false,
  };
}

function handleEditPatientSuccess(state: PatientState, data: Patient): PatientState {
  return {
    isFetching: false,
    success: true,
    error: undefined,
    data: {
      ...state.data,
      [data.patientId]: data,
    },
  };
}

function handleEditPatientFailure(state: PatientState, error: ServerResponse): PatientState {
  return {
    ...state,
    isFetching: false,
    success: false,
    error,
  };
}

/*
 * Delete patient
 */
function handleDeletePatientRequest(state: PatientState): PatientState {
  return {
    ...state,
    isFetching: true,
    success: false,
    error: undefined,
  };
}

function handleDeletePatientSuccess(state: PatientState, id: string): PatientState {
  const newData = { ...state.data };
  delete newData[id];

  return {
    isFetching: false,
    success: true,
    error: undefined,
    data: newData,
  };
}

function handleDeletePatientFailure(state: PatientState, error: ServerResponse): PatientState {
  return {
    ...state,
    isFetching: false,
    success: false,
    error,
  };
}

/*
 * Other
 */
function handleClearError(state: PatientState): PatientState {
  return {
    ...state,
    error: undefined,
  };
}

function handleUpdateSecondaryContact(state: PatientState, patientId: string, secondaryContact: PatientSecondaryContact): PatientState {
  let patient = state.data![patientId];
  patient.secondaryContact = { ...secondaryContact };
  return {
    ...state,
    data: {
      ...state.data,
      [patientId]: patient,
    },
  };
}

export function patientReducer(state: PatientState = patientInitialState, action: AnyAction): PatientState {
  switch (action.type) {
    case CurrentStatus.GET_CURRENT_STATUS_REQUEST:
      return handleGetPatientsRequest(state);
    case CurrentStatus.GET_CURRENT_STATUS_SUCCESS:
      return handleGetPatientsSuccess(state, action.data);
    case CurrentStatus.GET_CURRENT_STATUS_FAIL:
      return handleGetPatientsFailure(state, action.data);

    case PatientActions.ADD_PATIENT_REQUEST:
      return handleAddPatientRequest(state);
    case PatientActions.ADD_PATIENT_SUCCESS:
      return handleAddPatientSuccess(state, action.data);
    case PatientActions.ADD_PATIENT_FAIL:
      return handleAddPatientFailure(state, action.data);

    case PatientActions.EDIT_PATIENT_REQUEST:
      return handleEditPatientRequest(state);
    case PatientActions.EDIT_PATIENT_SUCCESS:
      return handleEditPatientSuccess(state, action.data);
    case PatientActions.EDIT_PATIENT_FAIL:
      return handleEditPatientFailure(state, action.data);

    case PatientActions.DELETE_PATIENT_REQUEST:
      return handleDeletePatientRequest(state);
    case PatientActions.DELETE_PATIENT_SUCCESS:
      return handleDeletePatientSuccess(state, action.data);
    case PatientActions.DELETE_PATIENT_FAIL:
      return handleDeletePatientFailure(state, action.data);

    case PatientActions.CLEAR_SUCCESS_PATIENT:
      return {
        ...state,
        successMessage: undefined,
      };

    case PatientActions.CLEAR_ERROR_PATIENT:
      return handleClearError(state);

    case PatientActions.UPDATE_SECONDARY_CONTACT:
      return handleUpdateSecondaryContact(state, action.data.patientId, action.data.secondaryContact);

    default:
      return state;
  }
}
