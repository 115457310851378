import * as React from 'react';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { css } from '../../utils/css';
import styles from './PatientPricingCheckbox.module.scss';

export interface Props {
  checkboxId: string;
  avatar?: string;
  initials?: string;
  label: string;
  value: string;
  footer?: string;
  testId: string;
  selected: boolean;
  onSelect: () => void;
  disabled?: boolean;
}

export const PatientPricingCheckbox = (props: Props) => {
  const classNames = css(styles.item, styles.patient, {
    [styles.selected]: !!props.selected,
    [styles.selectable]: true,
    [styles.disabled]: !!props.disabled,
  });

  return (
    <div className={classNames} onClick={props.onSelect} data-tid={props.testId}>
      <div className={styles.checkbox}>
        <input type="checkbox" id={props.checkboxId} checked={props.selected} readOnly disabled={props.disabled} />
      </div>
      <div className={styles.imageContainer}>
        {(props.avatar || props.initials) && (
          <div>
            {props.avatar && <img alt={getString(ResourceKey.selectableItemPhotoAlt)} className={styles.avatar} src={props.avatar} />}
            {!props.avatar && <div className={styles.avatar}>{props.initials}</div>}
          </div>
        )}
      </div>
      <div className={styles.content}>
        <label className={css(styles.label, styles.patientName)} htmlFor={props.checkboxId} onClick={(e) => e.preventDefault()}>
          {props.label}
        </label>
        {!!props.value && <div className={css(styles.value, styles.patientPrice)}>{props.value}</div>}
        {props.footer && <div className={css(styles.footer, styles.patientInsurance)}>{props.footer}</div>}
      </div>
    </div>
  );
};
