import { CartActions } from '../../appState/cart/actions';
import { PatientActions } from '../../appState/patient/actions';
import { ReduxAction } from '../../appState/types';
import { TimeSlot } from '../../types/TimeSlot';
import { Patient } from '../patient/types';
import { CartPreferredDoctor, CartState, Location, PatientIdentity, Reason } from './types';

export const cartInitialState: CartState = {
  where: null,
  who: null,
  preferredDoctor: null,
  what: null,
  when: null,
  howMuch: null,
  paymentOptions: '',
  filesPendingVisitCodeAssociation: [],
  covidSymptoms: undefined,
};

function handleDeletePatientSuccess(state: CartState, id: string): CartState {
  if (state.who && id === state.who.id) {
    return {
      ...state,
      who: null,
      when: null,
      what: null,
    };
  }
  return state;
}

function handleEditPatientSuccess(state: CartState, data: Patient) {
  if (state.who && state.who.id === data.patientId) {
    const newWho: PatientIdentity = {
      id: data.patientId,
      firstName: data.firstName,
      lastName: data.lastName,
    };
    return {
      ...state,
      who: newWho,
      what: !state.who || state.who.id !== data.patientId ? null : state.what,
      when: !state.who || state.who.id !== data.patientId ? null : state.when,
    };
  }
  return state;
}

function handleAddFile(state: CartState, fileId: string): CartState {
  return {
    ...state,
    filesPendingVisitCodeAssociation: (state.filesPendingVisitCodeAssociation || []).concat([fileId]),
  };
}

function handleRemoveFile(state: CartState, fileId: string): CartState {
  return {
    ...state,
    filesPendingVisitCodeAssociation: (state.filesPendingVisitCodeAssociation || []).filter((f) => {
      return f !== fileId;
    }),
  };
}

export function cartReducer(state: CartState = cartInitialState, action: ReduxAction<any>): CartState {
  switch (action.type) {
    case CartActions.SET_LOCATION:
      return {
        ...state,
        where: action.data as Location,
        what: !state.where || state.where.addressId !== action.data.addressId ? null : state.what,
        when: !state.where || state.where.addressId !== action.data.addressId ? null : state.when,
        preferredDoctor: !state.where || state.where.addressId !== action.data.addressId ? null : state.preferredDoctor,
      };

    case CartActions.SET_PATIENT:
      return {
        ...state,
        who: action.data as PatientIdentity,
        what: !state.who || state.who.id !== action.data.id ? null : state.what,
        when: !state.who || state.who.id !== action.data.id ? null : state.when,
        preferredDoctor: !state.who || state.who.id !== action.data.id ? null : state.preferredDoctor,
      };

    case CartActions.SET_PREFERRED_DOCTOR:
      return {
        ...state,
        preferredDoctor: action.data as CartPreferredDoctor,
        what: !state.preferredDoctor || !action.data || state.preferredDoctor.providerId !== action.data.providerId ? null : state.what,
        when: !state.preferredDoctor || !action.data || state.preferredDoctor.providerId !== action.data.providerId ? null : state.when,
      };

    case CartActions.SET_REASON:
      return {
        ...state,
        what: action.data as Reason,
        when: !state.what || state.what.id !== action.data.id ? null : state.when,
      };

    case CartActions.SET_PAYMENT:
      return {
        ...state,
        howMuch: action.data.amount as string,
      };

    case CartActions.SET_TIMESLOT:
      return {
        ...state,
        when: action.data as TimeSlot,
      };

    case CartActions.SET_PAYMENT_OPTIONS:
      return {
        ...state,
        paymentOptions: action.data,
      };

    case CartActions.SET_VISIT_NOTES:
      return {
        ...state,
        notes: action.data as string,
      };

    case CartActions.ADD_FILE:
      return handleAddFile(state, action.data);

    case CartActions.REMOVE_FILE:
      return handleRemoveFile(state, action.data);

    case PatientActions.DELETE_PATIENT_SUCCESS:
      return handleDeletePatientSuccess(state, action.data);

    case PatientActions.EDIT_PATIENT_SUCCESS:
      return handleEditPatientSuccess(state, action.data);

    case CartActions.SET_COVID_SYMPTOMS:
      return {
        ...state,
        covidSymptoms: action.data as string[],
      };

    case CartActions.RESET_COVID_SYMPTOMS:
      return {
        ...state,
        covidSymptoms: undefined,
      };

    case CartActions.RESET:
      return cartInitialState;

    default:
      return state;
  }
}
