import * as React from 'react';
import styles from './SecondaryContactCard.module.scss';

import { ButtonLink } from '../core/ButtonLink';

export interface SecondaryContactCardProps {
  firstName: string;
  lastName?: string;
  phone: string;
  relationship: string;
  onClick?: () => void;
}

export default class SecondaryContactCard extends React.Component<SecondaryContactCardProps> {
  public render() {
    const { firstName, lastName, phone, relationship } = this.props;
    return (
      <div className={styles.container}>
        <ButtonLink text="Edit" onClick={this.props.onClick!} style={{ display: 'inline', padding: 0, float: 'right' }} />
        <div className={styles.name}>{`${firstName} ${lastName}`}</div>
        <div>{phone}</div>
        <div>{relationship}</div>
      </div>
    );
  }
}
