import { create } from 'apisauce';
import React, { ErrorInfo } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../appState/rootState';
import { ClientError } from '../../components/error/ClientError';
import { isLocal } from '../../utils/utils';

const headers: { [key: string]: string } = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const api = create({
  baseURL: '/',
  headers,
});

export interface VersionInfo {
  branch: string;
  commit: string;
  version: string;
  buildTime: string;
  buildNumber: string;
}

interface ErrorBoundaryProps {}

interface ErrorBoundaryState {
  error?: Error;
  errorInfo?: ErrorInfo;
  versionInfo?: VersionInfo;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    if (this.state.error) {
      return <ClientError />;
    }

    return <div style={{ fontSize: '16px' }}>{this.props.children as JSX.Element}</div>;
  }

  public componentDidMount() {
    this.createErrorListener();
    !isLocal && this.getVersionInfo();
  }

  private createErrorListener() {
    window.onerror = (message, file, line, column, errorObject) => {
      this.errorHandling(message as string, file, line, column, errorObject);
    };
  }

  private errorHandling(message: string, file: any, line: any, column: any, errorObject?: Error) {
    // trying to get stack from IE
    const newStack = [];
    if (!errorObject) {
      let f = arguments.callee.caller;
      while (f) {
        newStack.push(f.name);
        f = f.caller;
      }
    }
    const data: Error = {
      name: '',
      message,
      stack: errorObject && errorObject.stack ? errorObject.stack : newStack.join(),
    };
    const errorInfo: ErrorInfo = {
      componentStack: message,
    };
    this.setState({
      error: data,
      errorInfo,
    });
  }

  private getVersionInfo() {
    api.get('_healinternal/version.json').then((response: any) => {
      if (response.data) {
        this.setState({
          versionInfo: response.data.versionData as VersionInfo,
        });
      }
    });
  }
}

function mapStateToProps(state: RootState, ownProps: ErrorBoundaryProps): ErrorBoundaryProps {
  return {
    ...ownProps,
    baseFontSize: state.userPreferences.baseFontSize,
  };
}

export default connect(mapStateToProps)(ErrorBoundary);
