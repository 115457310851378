import { VisitActions } from '../../appState/visit/actions';
import { TimeSlot } from '../../types/TimeSlot';
import { Visit, VisitsState } from './types';

export const visitsInitialState: VisitsState = {
  isFetching: false,
  error: undefined,
  data: undefined,
};

function handleGetVisitsRequest(state: VisitsState): VisitsState {
  return {
    ...state,
    isFetching: true,
    error: undefined,
    data: undefined,
  };
}

function handleGetVisitsSuccess(state: VisitsState, data: Visit[]): VisitsState {
  return {
    ...state,
    isFetching: false,
    error: undefined,
    data,
  };
}

function handleGetVisitsFailure(state: VisitsState, error: string): VisitsState {
  return {
    ...state,
    isFetching: false,
    error,
    data: undefined,
  };
}

function handleCancelVisitRequest(state: VisitsState): VisitsState {
  return {
    ...state,
    isCanceling: true,
    isFetching: false,
    error: undefined,
  };
}

function handleCancelVisitSuccess(state: VisitsState, data: { visitCode: string; message?: string }): VisitsState {
  if (state.data) {
    const newData = [...state.data];

    newData.find((visit, index) => {
      if (visit.code === data.visitCode) {
        newData[index] = {
          ...newData[index],
          groupType: 'CANCELLED',
        };
        return true;
      }
      return false;
    });

    return {
      ...state,
      isCanceling: false,
      cancelResponse: data.message,
      isFetching: false,
      error: undefined,
      data: newData,
    };
  } else {
    return {
      ...state,
      isCanceling: false,
      cancelResponse: data.message,
    };
  }
}

function handleCancelVisitFail(state: VisitsState, data: any): VisitsState {
  return {
    ...state,
    isCanceling: false,
    cancelError: data.message,
  };
}

function handleCancelVisitClear(state: VisitsState): VisitsState {
  return {
    ...state,
    cancelResponse: undefined,
    cancelError: undefined,
  };
}

function handleVisitRescheduled(state: VisitsState, data: { visitCode: string; timeSlot: TimeSlot; canReschedule: boolean }): VisitsState {
  if (state.data) {
    const newData = [...state.data];

    newData.find((visit, index) => {
      if (visit.code === data.visitCode) {
        newData[index] = {
          ...newData[index],
          timeSlot: data.timeSlot,
          canReschedule: data.canReschedule,
        };
        return true;
      }
      return false;
    });

    return {
      ...state,
      data: newData,
    };
  }

  return state;
}

export function visitReducer(state: VisitsState = visitsInitialState, action: any): VisitsState {
  switch (action.type) {
    case VisitActions.CANCEL_VISIT_REQUEST:
      return handleCancelVisitRequest(state);
    case VisitActions.CANCEL_VISIT_SUCCESS:
      return handleCancelVisitSuccess(state, action.data);
    case VisitActions.CANCEL_VISIT_FAIL:
      return handleCancelVisitFail(state, action.data);
    case VisitActions.CANCEL_VISIT_CLEAR:
      return handleCancelVisitClear(state);

    case VisitActions.VISIT_RESCHEDULED:
      return handleVisitRescheduled(state, action.data);

    case VisitActions.GET_VISITS_REQUEST:
      return handleGetVisitsRequest(state);
    case VisitActions.GET_VISITS_SUCCESS:
      return handleGetVisitsSuccess(state, action.data);
    case VisitActions.GET_VISITS_FAIL:
      return handleGetVisitsFailure(state, action.data);
    default:
      return state;
  }
}
