import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../appState/rootState';
import { samlGetAccountData, setAccessAndRefreshToken } from '../../appState/saml/actions';
import { SamlLanding, SamlLandingProps } from '../saml/SamlLanding';

function mapStateToProps(state: RootState, ownProps: SamlLandingProps): SamlLandingProps {
  return {
    ...ownProps,
    saml: state.saml,
  };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: SamlLandingProps): SamlLandingProps {
  return {
    ...ownProps,
    actions: bindActionCreators(
      {
        setAccessAndRefreshToken,
        samlGetAccountData,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SamlLanding);
