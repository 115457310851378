import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { setLocation, setPreferredDoctor } from '../../appState/cart/actions';
import { FlagName, setFlag } from '../../appState/flag/actions';
import { getQuickActions } from '../../appState/preferredDoctor/quickActions/actions';
import { RootState } from '../../appState/rootState';
import { PDSelectDoctor, PDSelectDoctorProps } from '../preferredDoctor/PDSelectDoctor';

function mapStateToProps(state: RootState, ownProps: PDSelectDoctorProps): PDSelectDoctorProps {
  return {
    ...ownProps,
    cart: state.cart,
    userAccountInfo: state.userAccountInfo,
    quickActions: state.quickActions,
    showIntro: !state.flag.name?.[FlagName.PREFERRED_DOCTOR_INTRO_SEEN],
  };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: PDSelectDoctorProps): PDSelectDoctorProps {
  return {
    ...ownProps,
    actions: bindActionCreators(
      {
        getQuickActions,
        setPreferredDoctor,
        setLocation,
        setFlag,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PDSelectDoctor);
