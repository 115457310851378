import { ApiResponse } from 'apisauce';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedPatient, removeSelectedPatient, setSelectedPatientBookSuccess, setStep } from '../../appState/fluShots/actions';
import { Patient } from '../../appState/patient/types';
import { RootState } from '../../appState/rootState';
import { ServicePricing } from '../../appState/serviceOfferings/types';
import { Visit } from '../../appState/visit/types';
import { BaseResponse, HealAPI } from '../../services/api';
import { IMAGES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { css } from '../../utils/css';
import { formatTimeslot, isChild, isInfant } from '../../utils/date';
import Button from '../core/Button';
import { ButtonLink } from '../core/ButtonLink';
import { PatientPricingCheckbox } from '../utils/PatientPricingCheckbox';
import styles from './FluShots.module.scss';

interface FluShotsProps {
  onClose: () => void;
}

export enum FluShotsStep {
  INIT,
  SELECT_PATIENTS,
  CONFIRM,
  BOOK_SUCCESS,
  BOOK_ERROR,
}

interface FluShotsPricing {
  [id: string]: {
    servicePricing?: ServicePricing;
    selected: boolean;
    disabled: boolean;
  };
}

const FluShots: React.FC<FluShotsProps> = ({ onClose }) => {
  const [patients, setPatients] = useState<FluShotsPricing>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasFetchedPricing, setHasFetchedPricing] = useState(false);
  const { patientList, fluShots } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchServicePricing(patientId: string, serviceCode: string, addressId: string) {
      const response = await HealAPI.getServicePricing({ serviceCode, patientId, addressId });

      if (response.ok && response.data?.data) {
        return { servicePricing: response.data.data, selected: false, disabled: false };
      } else {
        return { selected: false, disabled: true };
      }
    }

    const fetchedPricing: FluShotsPricing = {};

    // Fetch service pricing for each patient
    if (patientList?.data && !hasFetchedPricing) {
      Object.keys(patientList.data).forEach(async (id) => {
        if (!patients[id] && fluShots.visit) {
          const isMinor = patientList.data?.[id].dateOfBirth ? isChild(patientList.data?.[id].dateOfBirth) : false;
          fetchedPricing[id] = await fetchServicePricing(id, isMinor ? 'FLUSHOT_CHILD' : 'FLUSHOT_ADULT', fluShots.visit.address.addressId);
        }
      });

      setPatients(fetchedPricing);
      setHasFetchedPricing(true);
    }
  }, [patients, patientList, fluShots, hasFetchedPricing]);

  return (
    <div>
      {fluShots.step === FluShotsStep.INIT && renderInit()}
      {fluShots.step === FluShotsStep.SELECT_PATIENTS && renderSelectPatients()}
      {fluShots.step === FluShotsStep.CONFIRM && renderConfirm()}
      {fluShots.step === FluShotsStep.BOOK_SUCCESS && renderBookSuccess()}
      {fluShots.step === FluShotsStep.BOOK_ERROR && renderBookError()}
    </div>
  );

  function renderInit() {
    return (
      <div>
        <img src={IMAGES.fluShot} alt="" className={styles.img} />
        <h1 className={styles.title}>Add flu shots</h1>
        <p className={styles.subtitle}>For you and your family</p>

        <div className={styles.content}>
          <p className={styles.description}>You can add flu shots for you and family members on your account for your upcoming visit.</p>
        </div>

        <Button
          text="Add flu shots →"
          onClick={() => dispatch(setStep(FluShotsStep.SELECT_PATIENTS))}
          className={css('btn-block', styles.actionButton)}
          testId="btn_addFluShots"
          aria-label="Add flu shots"
        />
        <ButtonLink text="Not now" onClick={() => onClose()} className={styles.btnLink} testId="btn_notNow" />
      </div>
    );
  }

  function renderSelectPatients() {
    const patientEntires = patientList?.data && Object.entries(patientList.data);
    const hasSelectedPatients = !!fluShots.selectedPatients && Object.keys(fluShots.selectedPatients).length > 0;

    return (
      <div>
        <h1 className={styles.title}>Select patients</h1>
        <p className={styles.subtitle}>Who will be getting a flu shot?</p>
        <div className={styles.content}>
          {patientEntires?.map(([id, patient], index) => {
            if (patient.hasMedicaid || isInfant(patient.dateOfBirth, 'MM/DD/YYYY')) {
              return null;
            }

            // Set value text to 'Loading', 'Ineligible', or 'chargeAmount'
            let value = 'Loading';
            if (patients[id]) {
              value = patients[id].servicePricing ? `$${patients[id].servicePricing?.chargeAmount}` : 'Ineligible';
            }

            const disabled = !patients[id] || patients[id].disabled;

            return (
              <PatientPricingCheckbox
                key={`chk${index}-label`}
                checkboxId={`chk${index}-label`}
                label={patient.fullName}
                value={value}
                footer={patient.hasInsurance ? patient.insuranceName : 'No insurance'}
                avatar={patient.avatarUrl}
                initials={patient.initials}
                testId={`select_patient_${id}`}
                selected={!!fluShots.selectedPatients?.[id]}
                onSelect={() => onPatientClick(id)}
                disabled={disabled}
              />
            );
          })}
        </div>

        <div className={styles.total}>
          <div className={styles.label}>Total</div>
          <div className={styles.value}>${getPriceTotal()}</div>
        </div>

        <Button
          text="Continue →"
          onClick={() => dispatch(setStep(FluShotsStep.CONFIRM))}
          testId="btn_continue"
          className={css('btn-block', styles.actionButton)}
          aria-label="Continue"
          disabled={!hasSelectedPatients}
        />
      </div>
    );
  }

  function renderConfirm() {
    return (
      <div>
        <h1 className={styles.title}>Confirm flu shots</h1>
        <p className={styles.subtitle}>Review & confirm</p>

        <div className={styles.content}>{renderVisitDetails()}</div>

        <Button
          text="Book flu shots"
          onClick={() => bookFluShots()}
          className={css('btn-block', styles.actionButton)}
          testId="btn_bookFluShots"
          loading={isSubmitting}
        />
        <ButtonLink
          text="← Select patients"
          onClick={() => dispatch(setStep(FluShotsStep.SELECT_PATIENTS))}
          className={styles.btnLink}
          testId="btn_notNow"
          disabled={isSubmitting}
        />
      </div>
    );
  }

  function renderBookSuccess() {
    const selectedPatients = fluShots.selectedPatients ? Object.keys(fluShots.selectedPatients) : [];

    return (
      <div>
        <img src={IMAGES.checkmark} alt="Thank you" className={styles.checkmark} />
        <h1 className={styles.title}>Thank you</h1>
        <p className={styles.subtitle}>{selectedPatients.length > 1 ? 'Your flu shots have been booked' : 'Your flu shot has been booked'}</p>

        <div className={styles.content}>{renderVisitDetails()}</div>

        <div className={styles.divider} />

        <div className={styles.contactSupport}>
          Questions? Call <a href="tel:+18446444325">(844) 644-4325</a> or see our{' '}
          <a href="https://www.centerwellprimarycare.com/en/primary-care-anywhere/faqs.html">FAQs</a>
        </div>
      </div>
    );
  }

  function renderBookError() {
    const selectedPatients = fluShots.selectedPatients ? Object.entries(fluShots.selectedPatients) : [];
    const errorPatients = selectedPatients.filter(([id, data]) => !data.bookSuccess);

    return (
      <div>
        <h1 className={styles.title}>There was a problem</h1>
        <p className={styles.subtitle}>We had trouble adding {errorPatients.length > 1 ? `${errorPatients.length} flu shots` : 'a flu shot'} to this visit</p>

        <div className={styles.content}>
          <div className={styles.selectedPatients}>
            {selectedPatients.map(([id, data]) => {
              const patient = patientList?.data?.[id];
              return patient ? renderPatientAvatar(patient, !data.bookSuccess) : null;
            })}
          </div>

          <p className={styles.description} style={{ marginTop: 30 }}>
            A member of our Patient Support team will contact you shortly to schedule for you.
          </p>
        </div>

        <div className={styles.divider} />

        <div className={styles.contactSupport}>
          Questions? Call <a href="tel:+18446444325">(844) 644-4325</a> or see our{' '}
          <a href="https://www.centerwellprimarycare.com/en/primary-care-anywhere/faqs.html">FAQs</a>
        </div>
      </div>
    );
  }

  function renderVisitDetails() {
    const timeSlot = fluShots.visit?.timeSlot;
    const selectedPatients = fluShots.selectedPatients ? Object.keys(fluShots.selectedPatients) : [];

    return (
      <div>
        <div className={styles.selectedPatients}>
          {selectedPatients.map((patientId) => {
            const patient = patientList?.data?.[patientId];
            return patient ? renderPatientAvatar(patient) : null;
          })}
        </div>

        <div className={styles.section}>
          <div className={styles.label}>
            <img src={IMAGES.location} className={styles.icon} alt="" />
            Location
          </div>
          <div className={styles.description}>{fluShots.visit?.address.address}</div>
        </div>

        <div className={styles.section}>
          <div className={styles.label}>
            <img src={IMAGES.calendar} className={styles.icon} alt="" />
            Time Slot
          </div>
          <div className={styles.description}>
            {timeSlot && formatTimeslot(timeSlot.startTime * 1000, (timeSlot.startTime + timeSlot.duration) * 1000, timeSlot.timeZone)}
          </div>
        </div>
      </div>
    );
  }

  function renderPatientAvatar(patient: Patient, error?: boolean) {
    return (
      <div className={styles.selectedPatient} key={patient.patientId}>
        {patient.avatarUrl ? (
          <img alt={getString(ResourceKey.selectableItemPhotoAlt)} className={styles.avatar} src={patient.avatarUrl} />
        ) : (
          <div className={styles.avatar}>{patient.initials}</div>
        )}
        <div
          className={css(styles.patientName, {
            [styles.error]: error === true,
            [styles.success]: error === false,
          })}
        >
          {error === true && <img src={IMAGES.alertYellow} alt="" />}
          {error === false && <img src={IMAGES.success} alt="" />}
          {patient.fullName}
        </div>
      </div>
    );
  }

  function onPatientClick(patientId: string) {
    if (fluShots.selectedPatients?.[patientId]) {
      dispatch(removeSelectedPatient(patientId));
    } else if (patients[patientId]) {
      const servicePricing = patients[patientId].servicePricing;

      if (servicePricing) {
        dispatch(addSelectedPatient(patientId, servicePricing.chargeAmount));
      }
    }
  }

  function getPriceTotal() {
    const selectedPatients = fluShots.selectedPatients ? Object.values(fluShots.selectedPatients) : [];
    return selectedPatients.reduce((total, patient) => total + patient.chargeAmount, 0).toFixed(2);
  }

  function bookFluShots() {
    const selectedPatients = fluShots.selectedPatients ? Object.keys(fluShots.selectedPatients) : [];
    const bookingRequests: Promise<ApiResponse<BaseResponse<Visit>>>[] = [];
    let bookingError = false;

    if (fluShots.visit) {
      setIsSubmitting(true);

      selectedPatients.forEach((id) => {
        const patient = patients[id];
        const serviceCode = patient.servicePricing?.serviceCode;

        if (serviceCode) {
          bookingRequests.push(
            new Promise((resolve, reject) => {
              fluShots.visit &&
                HealAPI.saveVisitAddon(fluShots.visit.code, serviceCode, id)
                  .then((response) => {
                    const patientId: string = JSON.parse(response.config?.data).patientId;
                    dispatch(setSelectedPatientBookSuccess(patientId, response.ok));

                    if (!response.ok) {
                      bookingError = true;
                    }

                    resolve(response);
                  })
                  .catch((error) => {
                    bookingError = true;
                    resolve(error);
                  });
            })
          );
        }
      });

      Promise.all(bookingRequests)
        .then((responses) => {
          dispatch(setStep(bookingError ? FluShotsStep.BOOK_ERROR : FluShotsStep.BOOK_SUCCESS));
        })
        .catch((error) => {
          dispatch(setStep(FluShotsStep.BOOK_ERROR));
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  }
};
export default FluShots;
