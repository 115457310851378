interface LogoIconProps {
  className?: string;
}

const LogoIcon: React.FC<LogoIconProps> = ({ className }) => {
  return (
    <span className={className} data-tid="img_logoIcon">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 40" version="1.1">
        <title>CenterWell Logo Icon</title>
        <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <clipPath id="main">
              <rect width="32" height="36" fill="white" />
            </clipPath>
          </defs>
          <g clip-path="url(#main)">
            <path
              d="M22.0959 18.3682C21.7039 18.3682 21.3039 18.4002 20.9199 18.4482C20.8959 18.2242 20.8879 18.0082 20.8879 17.7842C20.8879 17.5602 20.9039 17.3442 20.9199 17.1202C21.3119 17.1682 21.7039 17.1922 22.0959 17.1922C26.4719 17.1922 30.2719 14.2242 31.3199 9.97616L31.5919 8.86416L30.4879 8.57616C29.6959 8.36816 28.8879 8.26416 28.0719 8.26416C23.6959 8.26416 19.8959 11.2322 18.8479 15.4802C18.6639 16.2322 18.5679 17.0002 18.5679 17.7762C18.5679 18.5522 18.6639 19.3202 18.8479 20.0722C19.9039 24.3202 23.6959 27.2882 28.0719 27.2882C28.8879 27.2882 29.7039 27.1842 30.4879 26.9762L31.5919 26.6882L31.3199 25.5762C30.2639 21.3282 26.4719 18.3602 22.0959 18.3602M28.0719 10.5842C28.2719 10.5842 28.4719 10.5922 28.6639 10.6082C27.5279 13.1682 24.9759 14.8722 22.0879 14.8722C21.8879 14.8722 21.6879 14.8642 21.4959 14.8482C22.6319 12.2882 25.1839 10.5842 28.0719 10.5842ZM28.0719 24.9682C25.1839 24.9682 22.6319 23.2642 21.4959 20.7042C21.6959 20.6882 21.8879 20.6802 22.0879 20.6802C24.9759 20.6802 27.5279 22.3842 28.6639 24.9442C28.4639 24.9602 28.2719 24.9682 28.0719 24.9682Z"
              fill="#003DA5"
            />
            <path
              d="M18.512 2.75181C18.104 2.03981 17.608 1.39181 17.032 0.815813L16.224 0.0078125L15.4 0.799813C12.248 3.83181 11.576 8.60781 13.768 12.3998C13.968 12.7438 14.192 13.0638 14.424 13.3758C14.24 13.5038 14.056 13.6318 13.864 13.7358C13.672 13.8478 13.472 13.9438 13.272 14.0398C13.12 13.6798 12.944 13.3198 12.752 12.9838C10.56 9.19181 6.09599 7.39181 1.88799 8.59981L0.791992 8.91981L1.09599 10.0238C1.31199 10.8078 1.62399 11.5678 2.03199 12.2718C3.76799 15.2718 6.92799 17.0318 10.256 17.0318C11.128 17.0318 12.016 16.9118 12.896 16.6558C13.64 16.4398 14.352 16.1358 15.024 15.7518C15.696 15.3678 16.312 14.8958 16.872 14.3598C20.024 11.3278 20.696 6.55181 18.504 2.75981M4.04799 11.1038C3.95199 10.9278 3.85599 10.7598 3.77599 10.5758C6.55999 10.2798 9.31199 11.6318 10.76 14.1358C10.856 14.3038 10.952 14.4798 11.032 14.6638C8.24799 14.9598 5.49599 13.6078 4.04799 11.1038ZM16.184 3.40781C16.296 3.56781 16.4 3.73581 16.504 3.91181C17.952 6.40781 17.752 9.47181 16.096 11.7358C15.984 11.5758 15.88 11.4078 15.776 11.2318C14.328 8.73581 14.528 5.67181 16.184 3.40781Z"
              fill="#78BE20"
            />
            <path
              d="M16.8798 21.2159C16.3198 20.6799 15.7038 20.2079 15.0318 19.8239C14.3598 19.4399 13.6478 19.1359 12.9038 18.9199C8.69581 17.7119 4.2318 19.5119 2.0398 23.3039C1.6318 24.0079 1.3198 24.7679 1.1038 25.5519L0.799805 26.6559L1.8958 26.9759C2.7758 27.2319 3.6558 27.3519 4.5358 27.3519C7.86381 27.3519 11.0238 25.5919 12.7598 22.5919C12.9598 22.2479 13.1278 21.8959 13.2798 21.5279C13.4798 21.6239 13.6798 21.7199 13.8718 21.8319C14.0638 21.9439 14.2478 22.0639 14.4238 22.1919C14.1838 22.5039 13.9678 22.8319 13.7678 23.1759C11.5758 26.9679 12.2478 31.7359 15.3998 34.7759L16.2238 35.5679L17.0318 34.7519C17.6078 34.1759 18.1038 33.5199 18.5118 32.8159C20.7038 29.0239 20.0318 24.2559 16.8798 21.2159ZM10.7518 21.4319C9.30381 23.9279 6.55981 25.2879 3.76781 24.9919C3.8558 24.8159 3.9438 24.6399 4.0478 24.4639C5.4878 21.9599 8.23981 20.6079 11.0318 20.9039C10.9438 21.0799 10.8558 21.2559 10.7598 21.4319M16.5118 31.6559C16.4158 31.8319 16.3038 31.9919 16.1918 32.1599C14.5358 29.8959 14.3358 26.8319 15.7838 24.3359C15.8798 24.1679 15.9918 23.9999 16.1038 23.8319C17.7518 26.0959 17.9518 29.1599 16.5118 31.6559Z"
              fill="#00A3D8"
            />
          </g>
        </svg>
      </svg>
    </span>
  );
};

export default LogoIcon;
