import { Dispatch } from 'redux';
import { showBottomSheet } from '../../appState/notification/actions';
import { HealAPI } from '../../services/api';
import { CreateOrEditPatientRequest, PatientPreferredDoctor, PatientSecondaryContact } from './types';

export interface PatientFormState {
  id?: string;
  mainPatientOfAccount?: boolean;
  isLoading?: boolean;
  isCheckingInsurance?: boolean;
  completedVisits: number;
  firstName: string;
  lastName: string;
  mobile: string;
  avatarUrl: string;
  dateOfBirth: string;
  genderId: string;
  gender: string;
  email: string;
  relationshipId: string;
  eligibilityId: string;
  hasMedicaid: boolean;
  payerId: string;
  memberId: string;
  groupId: string;
  hasModifiedInsurance: boolean;
  bottomSheetType?: string;
  preferredDoctor?: PatientPreferredDoctor;
  secondaryContact?: PatientSecondaryContact;
}

export enum PatientActions {
  ADD_PATIENT_REQUEST = 'ADD_PATIENT_REQUEST',
  ADD_PATIENT_SUCCESS = 'ADD_PATIENT_SUCCESS',
  ADD_PATIENT_FAIL = 'ADD_PATIENT_FAIL',

  EDIT_PATIENT_REQUEST = 'EDIT_PATIENT_REQUEST',
  EDIT_PATIENT_SUCCESS = 'EDIT_PATIENT_SUCCESS',
  EDIT_PATIENT_FAIL = 'EDIT_PATIENT_FAIL',

  DELETE_PATIENT_REQUEST = 'DELETE_PATIENT_REQUEST',
  DELETE_PATIENT_SUCCESS = 'DELETE_PATIENT_SUCCESS',
  DELETE_PATIENT_FAIL = 'DELETE_PATIENT_FAIL',

  CLEAR_SUCCESS_PATIENT = 'CLEAR_SUCCESS_PATIENT',
  CLEAR_ERROR_PATIENT = 'CLEAR_ERROR_PATIENT',

  UPDATE_SECONDARY_CONTACT = 'UPDATE_SECONDARY_CONTACT',
}

export function addPatient(data: CreateOrEditPatientRequest) {
  return (dispatch: Dispatch) => {
    dispatch({ type: PatientActions.ADD_PATIENT_REQUEST });

    HealAPI.addPatient(data).then(
      (response) => {
        if (response.ok) {
          const patient = response.data?.data;

          if (patient) {
            dispatch({
              type: PatientActions.ADD_PATIENT_SUCCESS,
              data: patient,
            });
          } else {
            // TODO: Response should be 4xx instead of 200
            dispatch({ type: PatientActions.ADD_PATIENT_FAIL, data: response.data });
          }
        } else {
          dispatch({ type: PatientActions.ADD_PATIENT_FAIL, data: response.data });

          if (response.data && response.data.status !== 'VALIDATION_ERROR') {
            dispatch(showBottomSheet('Error', response.data.description || 'Patient Edit Error!', true));
          }
        }
      },
      (response) => {
        dispatch({
          type: PatientActions.EDIT_PATIENT_FAIL,
          data: response.data,
        });
      }
    );
  };
}

export function editPatient(id: string, data: CreateOrEditPatientRequest) {
  return (dispatch: Dispatch) => {
    dispatch({ type: PatientActions.EDIT_PATIENT_REQUEST });

    HealAPI.editPatient(id, data).then(
      (response) => {
        if (response.ok) {
          const patient = response.data?.data;

          if (patient) {
            dispatch({
              type: PatientActions.EDIT_PATIENT_SUCCESS,
              data: patient,
            });
          } else {
            // TODO: Response should be 4xx instead of 200
            dispatch({ type: PatientActions.EDIT_PATIENT_FAIL, data: response.data });
          }
        } else {
          dispatch({ type: PatientActions.EDIT_PATIENT_FAIL, data: response.data });

          if (response.data && response.data.status !== 'VALIDATION_ERROR') {
            dispatch(showBottomSheet('Error', response.data.description || 'Patient Edit Error!', true));
          }
        }
      },
      (response) => {
        dispatch({
          type: PatientActions.EDIT_PATIENT_FAIL,
          data: response.data,
        });
      }
    );
  };
}

export function deletePatient(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: PatientActions.DELETE_PATIENT_REQUEST });

    HealAPI.deletePatient(id).then(
      (response) => {
        if (response.ok && response.data?.status === 'OK') {
          dispatch({
            type: PatientActions.DELETE_PATIENT_SUCCESS,
            data: id,
          });
        } else {
          dispatch({
            type: PatientActions.DELETE_PATIENT_FAIL,
            data: { ...response.data },
          });
        }
      },
      (response) => {
        dispatch({
          type: PatientActions.DELETE_PATIENT_FAIL,
          data: { ...response.data },
        });
      }
    );
  };
}

export function clearSuccessMessage() {
  return (dispatch: Dispatch) => {
    dispatch({ type: PatientActions.CLEAR_SUCCESS_PATIENT });
  };
}

export function clearError() {
  return (dispatch: Dispatch) => {
    dispatch({ type: PatientActions.CLEAR_ERROR_PATIENT, data: null });
  };
}

export function updateSecondaryContact(patientId: string, secondaryContact: PatientSecondaryContact) {
  return {
    type: PatientActions.UPDATE_SECONDARY_CONTACT,
    data: { patientId, secondaryContact },
  };
}
