import { CheckoutState } from './types';
import { CheckoutActions } from './actions';
import { ReduxAction } from '../../appState/types';
import { Visit } from '../visit/types';

export const checkoutInitialState: CheckoutState = {
  isFetching: false,
  error: undefined,
  data: undefined,
  success: false,
};

function resetCheckoutError(state: CheckoutState): CheckoutState {
  return {
    ...state,
    error: undefined,
  };
}

function resetCheckoutState(state: CheckoutState): CheckoutState {
  return checkoutInitialState;
}

function handleSaveVisitRequest(state: CheckoutState): CheckoutState {
  return {
    ...state,
    isFetching: true,
    success: false,
    error: undefined,
  };
}

function handleSaveVisitSuccess(state: CheckoutState, data: Visit): CheckoutState {
  return {
    ...state,
    isFetching: false,
    success: true,
    error: undefined,
    data,
  };
}

function handleSaveVisitFail(state: CheckoutState, error: string): CheckoutState {
  return {
    ...state,
    isFetching: false,
    success: false,
    error,
    data: undefined,
  };
}

export function checkoutReducer(state: CheckoutState = checkoutInitialState, action: ReduxAction<any>): CheckoutState {
  switch (action.type) {
    case CheckoutActions.RESET_ERROR:
      return resetCheckoutError(state);
    case CheckoutActions.SAVE_VISIT_REQUEST:
      return handleSaveVisitRequest(state);
    case CheckoutActions.SAVE_VISIT_SUCCESS:
      return handleSaveVisitSuccess(state, action.data);
    case CheckoutActions.SAVE_VISIT_FAIL:
      return handleSaveVisitFail(state, action.data);
    case CheckoutActions.RESET_CHECKOUT:
      return resetCheckoutState(state);
    default:
      return state;
  }
}
