import * as React from 'react';
import { createMarkup } from '../../services/languages';
import styles from './PageHeader.module.scss';

export interface PageHeaderProps {
  title: string;
  subtitle?: string;
  image?: string;
}

export const PageHeader = ({ title, subtitle, image }: PageHeaderProps) => {
  return (
    <div className={styles.container}>
      {image && <img alt="" src={image} className={styles.image} />}
      <h1 className={styles.title}>
        <span data-tid="txt_title">{title}</span>
      </h1>
      {subtitle && (
        <h2 className={styles.subtitle}>
          <span dangerouslySetInnerHTML={createMarkup(subtitle)} data-tid="txt_subtitle" />
        </h2>
      )}
    </div>
  );
};
