import { Dispatch } from 'redux';
import { HealAPI } from '../../services/api';
import { ReduxAction } from '../types';

export enum OnsiteActions {
  SET_ONSITE_PUBLIC_CODE = 'SET_ONSITE_PUBLIC_CODE',
  FETCH_ONSITE_PENDING = 'FETCH_ONSITE_PENDING',
  FETCH_ONSITE_SUCCESS = 'FETCH_ONSITE_SUCCESS',
  FETCH_ONSITE_FAILURE = 'FETCH_ONSITE_FAILURE',
  CLEAR_ONSITE = 'CLEAR_ONSITE',
}

export function fetchOnsite(publicCode: string) {
  return (dispatch: Dispatch) => {
    dispatch(setOnsitePublicCode(publicCode));
    dispatch({ type: OnsiteActions.FETCH_ONSITE_PENDING });
    HealAPI.getOnsite(publicCode).then(
      (response) => {
        if (response.ok) {
          dispatch({
            type: OnsiteActions.FETCH_ONSITE_SUCCESS,
            data: response.data,
          });
        } else {
          dispatch({
            type: OnsiteActions.FETCH_ONSITE_FAILURE,
            data: response.data,
          });
        }
      },
      (response) => {
        dispatch({
          type: OnsiteActions.FETCH_ONSITE_FAILURE,
          data: response.data,
        });
      }
    );
  };
}

export function clearOnsite(): ReduxAction {
  return { type: OnsiteActions.CLEAR_ONSITE };
}

export function setOnsitePublicCode(value: string): ReduxAction<string> {
  return { type: OnsiteActions.SET_ONSITE_PUBLIC_CODE, data: value };
}
