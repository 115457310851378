import * as React from 'react';

export class Spinner extends React.PureComponent {
  render(): JSX.Element {
    return (
      <svg width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{ background: 'none' }}>
        <g transform="rotate(0 50 50)">
          <rect x="47.5" y="24" rx="3.8000000000000003" ry="1.92" width="5" height="12" fill="#e3215f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-1.375s" repeatCount="indefinite" />
          </rect>
        </g>
        <g transform="rotate(30 50 50)">
          <rect x="47.5" y="24" rx="3.8000000000000003" ry="1.92" width="5" height="12" fill="#e3215f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-1.25s" repeatCount="indefinite" />
          </rect>
        </g>
        <g transform="rotate(60 50 50)">
          <rect x="47.5" y="24" rx="3.8000000000000003" ry="1.92" width="5" height="12" fill="#e3215f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-1.125s" repeatCount="indefinite" />
          </rect>
        </g>
        <g transform="rotate(90 50 50)">
          <rect x="47.5" y="24" rx="3.8000000000000003" ry="1.92" width="5" height="12" fill="#e3215f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-1s" repeatCount="indefinite" />
          </rect>
        </g>
        <g transform="rotate(120 50 50)">
          <rect x="47.5" y="24" rx="3.8000000000000003" ry="1.92" width="5" height="12" fill="#e3215f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-0.875s" repeatCount="indefinite" />
          </rect>
        </g>
        <g transform="rotate(150 50 50)">
          <rect x="47.5" y="24" rx="3.8000000000000003" ry="1.92" width="5" height="12" fill="#e3215f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-0.75s" repeatCount="indefinite" />
          </rect>
        </g>
        <g transform="rotate(180 50 50)">
          <rect x="47.5" y="24" rx="3.8000000000000003" ry="1.92" width="5" height="12" fill="#e3215f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-0.625s" repeatCount="indefinite" />
          </rect>
        </g>
        <g transform="rotate(210 50 50)">
          <rect x="47.5" y="24" rx="3.8000000000000003" ry="1.92" width="5" height="12" fill="#e3215f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-0.5s" repeatCount="indefinite" />
          </rect>
        </g>
        <g transform="rotate(240 50 50)">
          <rect x="47.5" y="24" rx="3.8000000000000003" ry="1.92" width="5" height="12" fill="#e3215f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-0.375s" repeatCount="indefinite" />
          </rect>
        </g>
        <g transform="rotate(270 50 50)">
          <rect x="47.5" y="24" rx="3.8000000000000003" ry="1.92" width="5" height="12" fill="#e3215f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-0.25s" repeatCount="indefinite" />
          </rect>
        </g>
        <g transform="rotate(300 50 50)">
          <rect x="47.5" y="24" rx="3.8000000000000003" ry="1.92" width="5" height="12" fill="#e3215f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-0.125s" repeatCount="indefinite" />
          </rect>
        </g>
        <g transform="rotate(330 50 50)">
          <rect x="47.5" y="24" rx="3.8000000000000003" ry="1.92" width="5" height="12" fill="#e3215f">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="0s" repeatCount="indefinite" />
          </rect>
        </g>
      </svg>
    );
  }
}
