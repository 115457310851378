import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { resetCart } from '../../appState/cart/actions';
import { CartState } from '../../appState/cart/types';
import { clearOnsite, fetchOnsite } from '../../appState/onsite/actions';
import { OnsiteState } from '../../appState/onsite/types';
import { RootState } from '../../appState/rootState';
import { resetTimeSlots } from '../../appState/timeSlots';
import { IMAGES, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { Box } from '../core/Box';
import Button from '../core/Button';
import { LegacyButton } from '../core/LegacyButton';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import { Steps } from '../utils/Steps';
import styles from './OnsitePage.module.scss';

export interface OnsiteProps extends RouteComponentProps<{ code: string }> {
  onsite: OnsiteState;
  cart: CartState;
  actions: {
    clearOnsite: () => void;
    resetCart: () => void;
    fetchOnsite: (code: string) => void;
    resetTimeSlots: () => void;
  };
}

class OnsitePage extends React.Component<OnsiteProps> {
  public componentDidMount() {
    this.props.actions.resetCart();
    this.props.actions.resetTimeSlots();

    if (this.props.match.params.code) {
      this.props.actions.fetchOnsite(this.props.match.params.code);
    }
  }

  public render(): JSX.Element {
    return (
      <Page title={getString(ResourceKey.workplaceTitle)} showBackButton={false} showMenuButton={false} isLoading={this.props.onsite.isFetching}>
        <Steps history={this.props.history} currentStep="event" />
        {this.renderWorkplaceBook()}

        <div className={`${styles.card} ${styles.first}`}>
          <div className={styles.text}>{getString(ResourceKey.wallStreetText)}</div>
          <img alt={getString(ResourceKey.workplaceWallStreetLogoAlt)} className={styles.wallStreet} src={IMAGES.wallStreetLogo} />
        </div>
        <div className={styles.card}>
          <div className={styles.text}>
            <div>{getString(ResourceKey.partnerQuote)}</div>
            <div className={styles.textAuthor}>{getString(ResourceKey.partnerQuoteAuthor)}</div>
          </div>
          <img alt={getString(ResourceKey.workplaceYelpStarsAlt)} className={styles.yelp} src={IMAGES.yelpStars} />
          <div className={`${styles.text} ${styles.yelp}`}>{getString(ResourceKey.partnerYelp)}</div>
        </div>
      </Page>
    );
  }

  private renderWorkplaceBook(): JSX.Element | null {
    const onsiteData = this.props.onsite.data;
    if (!onsiteData) {
      return (
        <>
          <PageHeader title="CenterWell event" subtitle="There are no CenterWell events scheduled at this time." />
          <p>Try booking a CenterWell house call. You can book a visit for you or your family, 365 days a year, 8am - 8pm on your schedule.</p>
          <Button text="Book a house call" testId="btn_bookRegularVisit" className="btn-block" onClick={this.onRegularVisitClicked} />
        </>
      );
    }

    const anyUpcomingOnsites = onsiteData.dates && onsiteData.dates.length > 0;
    return (
      <Box>
        <div className={styles.bookContainer}>
          <img alt={`${onsiteData.partnerName} logo`} className={styles.logo} src={onsiteData.partnerLogoUrl} data-tid="img_logo" />
          <div className={styles.title} data-tid="txt_partnerName">
            {onsiteData.partnerName}
          </div>
          <div className={styles.subtitle} style={{ whiteSpace: 'pre-wrap' }} data-tid="txt_onsiteMessage">
            {onsiteData.services && onsiteData.services.length > 0
              ? getString(ResourceKey.workplaceOnsiteMessage)
              : getString(ResourceKey.workplaceOnsiteMessageNoTimeslots)}
          </div>
          {this.renderDetails()}
          {anyUpcomingOnsites && (
            <LegacyButton
              className={styles.action}
              text={getString(ResourceKey.workplaceOnsiteBookNow)}
              onClick={this.onBookCampaignClicked}
              testId="btn_bookCampaign"
            />
          )}
          {!anyUpcomingOnsites && (
            <LegacyButton
              className={styles.action}
              text={getString(ResourceKey.workplaceRegularBookAHouseCall)}
              onClick={this.onRegularVisitClicked}
              testId="btn_bookRegularVisit"
            />
          )}
          <div className={styles.closeButton} onClick={this.onCloseClicked}>
            <Close />
          </div>
        </div>
      </Box>
    );
  }

  private renderDetails(): JSX.Element | null {
    const onsiteData = this.props.onsite.data!;
    const anyUpcomingOnsites = onsiteData.dates && onsiteData.dates.length > 0;
    if (!anyUpcomingOnsites) {
      return null;
    }

    return (
      <div className={styles.details}>
        <div className={styles.line}>
          <span className={styles.key}>
            {getString(onsiteData.services.length > 1 ? ResourceKey.workplaceOnsiteServices : ResourceKey.workplaceOnsiteService)}
          </span>
          <span className={styles.value} data-tid="txt_service">
            {onsiteData.services.join(', ')}
          </span>
        </div>
        <div className={styles.line}>
          <span className={styles.key}>{getString(onsiteData.dates.length > 1 ? ResourceKey.workplaceOnsiteDates : ResourceKey.workplaceOnsiteDate)}</span>
          <span className={styles.value} data-tid="txt_date">
            {this.formatDates(onsiteData.dates)}
          </span>
        </div>
        {onsiteData.partnerStreetAddress && (
          <div className={styles.line}>
            <span className={styles.key}>{getString(ResourceKey.workplaceOnsiteAddress)}</span>
            <span className={styles.value} data-tid="txt_address">
              {onsiteData.partnerStreetAddress}
            </span>
          </div>
        )}
        {onsiteData.room && (
          <div className={styles.line}>
            <span className={styles.key}>{getString(ResourceKey.workplaceOnsiteRoom)}</span>
            <span className={styles.value} data-tid="txt_address">
              {onsiteData.room}
            </span>
          </div>
        )}
      </div>
    );
  }

  private formatDates(dates: string[]) {
    if (dates.length > 3) {
      dates = dates.slice(0, 3);
      dates.push('more');
    }
    return dates.length > 0 ? dates.join(', ') : getString(ResourceKey.workplaceOnsiteNoDates);
  }

  private onBookCampaignClicked = () => {
    this.props.history.push(ROUTES.bookPatient);
  };

  private onRegularVisitClicked = () => {
    this.goToBookHouseCall();
  };

  private onCloseClicked = () => {
    this.goToBookHouseCall();
  };

  private goToBookHouseCall() {
    this.props.actions.resetCart();
    this.props.actions.resetTimeSlots();
    this.props.actions.clearOnsite();
    this.props.history.push(ROUTES.bookPatient);
  }
}

const Close: React.StatelessComponent<any> = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 -2 40 40" className="themeSVGStroke">
      <g fill="none" fillRule="evenodd" stroke="#E3215F" strokeLinecap="round" strokeWidth="2">
        <path d="M29.799 28.532L10 9M29.799 9L10 28.532" />
      </g>
    </svg>
  );
};

function mapStateToProps(state: RootState, ownProps: OnsiteProps): OnsiteProps {
  return {
    ...ownProps,
    onsite: state.onsite,
    cart: state.cart,
  };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: OnsiteProps): OnsiteProps {
  return {
    ...ownProps,
    actions: bindActionCreators(
      {
        clearOnsite,
        resetCart,
        fetchOnsite,
        resetTimeSlots,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OnsitePage);
