import { Account } from '../../appState/account/types';
import { CurrentStatus } from '../../appState/status/actions';
import { FEATURES } from '../../services/constants';
import { ThemeName, UserPreferencesActions } from './actions';
import { UserPreferencesState } from './types';

export const ThemeColor = {
  [ThemeName.HEAL]: '', //styles.themeHeal,
  [ThemeName.BLUE_SHIELD]: '', //styles.themeBlueShield,
};

export const userPreferencesInitialState: UserPreferencesState = {
  baseFontSize: '16px',
  secondaryContactSkipped: {},
  blueshieldSSOUser: false,
  engagementPointSSOUser: false,
  theme: {
    name: ThemeName.HEAL,
    color: ThemeColor[ThemeName.HEAL],
  },
};

export function userPreferencesReducer(
  state: UserPreferencesState = userPreferencesInitialState,
  // action: Action & { data: any }
  action: any
): UserPreferencesState {
  switch (action.type) {
    case UserPreferencesActions.SET_FONT_SIZE:
      return {
        ...state,
        baseFontSize: action.data.fontSize,
      };

    case UserPreferencesActions.SET_SECONDARY_CONTACT_SKIPPED:
      return {
        ...state,
        secondaryContactSkipped: {
          ...state.secondaryContactSkipped,
          [action.data.patientId]: action.data.skipped,
        },
      };

    case UserPreferencesActions.SET_RESEND_EMAIL_VERIFICATION_FROM_BANNER:
      return { ...state, verifyEmailSentFromBanner: action.data };

    case CurrentStatus.GET_CURRENT_STATUS_SUCCESS:
      const account: Account = action.data;
      const blueshieldSSOUser = account.features.indexOf(FEATURES.blueShieldUser) >= 0;
      const engagementPointSSOUser = account.features.indexOf(FEATURES.engagementPointUser) >= 0;
      return { ...state, blueshieldSSOUser, engagementPointSSOUser };

    case UserPreferencesActions.SET_BLUESHIELD_SSO_USER:
      return { ...state, blueshieldSSOUser: action.data };

    case UserPreferencesActions.SET_THEME:
      return {
        ...state,
        theme: {
          name: action.data.name,
          color: action.data.color,
          testMode: action.data.testMode,
        },
      };

    default:
      return state;
  }
}
