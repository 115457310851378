// Keep the keys sorted alphabetically.
export enum ResourceKey {
  agentAccountAlt,
  agentAccountPageTitle,
  agentAccountPageContent,
  agentAccountButtonText,
  agentAccountBackText,
  ANPSWarning,
  blueShieldLabel,
  blueShieldLoginButton,
  blueShieldLogoAlt,
  bookAgreementsLabel,
  bookAgreementsTitle,
  bookFailureReceivedCopy,
  bookLocationAddressPlaceholder,
  bookLocationAddTitle,
  bookLocationButtonSave,
  bookLocationButtonSetLocation,
  bookLocationEntryInstructionsPlaceholder,
  bookLocationErrorNotCovered,
  bookLocationErrorNoZip,
  bookLocationHeaderTitle,
  bookLocationNotCovered,
  bookLocationSelectOrEdit,
  bookLocationTitle,
  bookLocationTypePlaceholder,
  bookLocationUnitPlaceholder,
  bookReviewDoctorMessage,
  bookMainHeaderMessageReturning,
  bookMainHeaderMessageWorkplace,
  bookMainLookingForWorkplaceMessage,
  bookCompleteAppointment,
  bookReviewWhatMessage,
  bookReviewWhenMessage,
  bookReviewWhereMessage,
  bookReviewWhereWithMarketMessage,
  bookReviewWhoMessage,
  bookReviewWorkplaceMessage,
  bookOnsiteThankYouHeader,
  bookOnsiteThankYouVisit,
  bookPatientAddNewPatient,
  bookPatientAddPageTitle,
  bookPatientButtonAdd,
  bookPatientButtonContinue,
  bookPatientButtonSave,
  bookPatientDateOfBirth,
  bookPatientEmailAddress,
  bookPatientEmailError,
  bookPatientEmailNotVerified,
  bookPatientFirstNameError,
  bookPatientFirstNamePlaceholder,
  bookPatientGender,
  bookPatientHasInsurance,
  bookPatientHasMedicaid,
  bookPatientInsuranceGroup,
  bookPatientInsuranceMember,
  bookPatientInsuranceProvider,
  bookPatientLastNameError,
  bookPatientLastNamePlaceholder,
  bookPatientMemberIdError,
  bookPatientMinorHasVaccinations,
  bookPatientMinorNotice,
  bookPatientPageHeaderTitle,
  bookPatientPhoneError,
  bookPatientPhoneNumber,
  bookPatientProfileCompleteInsurance,
  bookPatientRelationship,
  bookPatientSecondaryContact,
  bookPatientSecondaryContactCurrent,
  bookPatientSecondaryContactMessage,
  bookPatientSecondaryContactRelationship,
  bookPatientSecondaryContactTitle,
  bookPatientTitle,
  bookPatientUpdatePageTitle,
  bookPatientVaccinationsDescription,
  bookPatientVaccinationsTitle,
  bookReasonButtonSubmit,
  bookReasonCheckboxLegalGuardian,
  bookReasonCovid19Message,
  bookReasonEmergencyInfo,
  bookReasonHeader,
  bookReasonHighDemandTitle,
  bookReasonHighDemandMessage,
  bookReasonNoServices,
  bookReasonSymptomsError,
  bookReasonSymptomsHeader,
  bookReasonSymptomsPlaceholder,
  bookReasonSymptomsPlaceholderOptional,
  bookReasonTitle,
  bookReviewInsuranceLabel,
  bookScheduleBookAsap,
  bookScheduleBookAsapSubtitle,
  bookScheduleContinue,
  bookScheduleHeaderMessage,
  bookScheduleNoTimeSlots,
  bookScheduleSelectDateTime,
  bookScheduleTitle,
  bookScheduleHighDemandNotice,
  bookSuccessConfirmationCopy,
  bookSuccessPurchaseConfirmed,
  bookSymptomsCheckerHeader,
  bookThankYouBookAHouseCall,
  bookThankYouBookAnother,
  bookThankYouGladToHelp,
  bookThankYouHeader,
  bookThankYouHeaderTelemedicine,
  bookThankYouTitle,
  bookThankYouVisit,
  bookWith,
  bookWithASAP,
  bookWithPCP,
  bottomSheetBlockEmailTitle,
  bottomSheetBlockEmailText,
  bottomSheetBlockNameBirthDateTitle,
  bottomSheetBlockNameBirthDateText,
  bottomSheetButtonClose,
  bottomSheetCallCenterWellText1,
  bottomSheetCallCenterWellText2,
  bottomSheetCallCenterWellTitle,
  bottomSheetCancelReschedule,
  bottomSheetCancelVisitButtonBookAnother,
  bottomSheetCancelVisitButtonCancel,
  bottomSheetCancelVisitCanceledTitle,
  bottomSheetCancelVisitHeader,
  bottomSheetCancelVisitNotesPlaceholder,
  bottomSheetCancelVisitScheduledFor,
  bottomSheetCancelVisitTellUsWhy,
  bottomSheetCancelVisitTitle,
  bottomSheetCVCInfo,
  bottomSheetCVCTitle,
  bottomSheetDeductibleText,
  bottomSheetDeductibleTitle,
  bottomSheetIncompleteProfileText,
  bottomSheetIncompleteProfileTitle,
  bottomSheetInsuranceTitle,
  bottomSheetLegalGuardianText,
  bottomSheetLegalGuardianTitle,
  bottomSheetLocationErrorText,
  bottomSheetLocationErrorTitle,
  bottomSheetMedicaidText,
  bottomSheetMedicaidTitle,
  bottomSheetOrCancelVisitTellUsWhy,
  bottomSheetReschedulePickTimeMessage,
  bottomSheetRescheduleThankYou,
  bottomSheetRescheduleThankYouSuccess,
  bottomSheetRescheduleVisitButton,
  bottomSheetRescheduleVisitErrorMessage,
  bottomSheetRescheduleVisitErrorTitle,
  bottomSheetRescheduleVisitMessage,
  bottomSheetRescheduleVisitPickAnotherTime,
  bottomSheetRescheduleVisitRescheduledTitle,
  bottomSheetRescheduleVisitScheduledFor,
  bottomSheetRescheduleVisitTitle,
  bottomSheetRescheduleVisitTryAgainButton,
  buttonClose,
  buttonSubmit,
  californiaLicensed,
  campaignTfsVisitNote,
  checkmarkAlt,
  consentAgreed,
  consentInvalidServiceCode,
  consentNoAgreements,
  consentSaveError,
  contactPageTitle,
  continue,
  continueWithBlueShield,
  creditCardAlt,
  dateOfBirthDayAria,
  dateOfBirthMonthAria,
  dateOfBirthYearAria,
  documentsTitle,
  documentsDescription,
  documentsPatientsTitle,
  documentsPatientsDescription,
  documentsPatientsChange,
  documentsPatientsManage,
  documentsDoneButton,
  engagementPointLabel,
  engagementPointLoginButton,
  engagementPointLogoAlt,
  errorAutoLogin,
  errorCreditCard,
  errorEmailVerificationLinkExpiredDescription,
  errorEmailVerificationLinkExpiredTitle,
  errorGeneric,
  errorGenericWithContact,
  errorPageMessageContactLine,
  errorPagePrimaryText,
  errorPageSecondaryText,
  errorSignInGeneric,
  errorTitle,
  fileUploadCapture,
  fileUploadDateMessage,
  fileUploadDefaultDate,
  fileUploadDescription,
  fileUploadHeader,
  fileUploadOnFileMessage,
  fileUploadTitle,
  forgotPasswordHeader,
  forgotPasswordPageTitle,
  forgotPasswordSupportLink,
  forgotPasswordTitle,
  forgotRecoverPassword,
  forgotRequestSent,
  genericButtonBook,
  genericButtonCancel,
  genericButtonClose,
  genericButtonContinue,
  genericButtonDelete,
  genericButtonEdit,
  genericButtonManage,
  genericButtonNo,
  genericButtonOk,
  genericButtonRemove,
  genericButtonRetry,
  genericButtonSave,
  genericButtonSkip,
  genericButtonSubmit,
  genericButtonYes,
  genericVisitFileDownloadError,
  humanaTitle,
  humanaSubtitle,
  humanaService1,
  humanaService2,
  humanaService3,
  humanaService4,
  invalidCredentials,
  infoNA,
  insuranceBottomSheetButtonClose,
  insuranceButtonUpdate,
  insuranceEditTitle,
  insuranceInfoAlt,
  insuranceMemberID,
  insuranceText,
  invalidZipcode,
  leftAlt,
  legalPageTitle,
  loginAuthenticationFailed,
  mapMarkerAlt,
  medicaidInfoAlt,
  menuNavBookPage,
  menuNavCopyright,
  menuNavDocumentsPage,
  menuNavHealthProfile,
  menuNavHelpPage,
  menuNavLegalPage,
  menuNavPatientsPage,
  menuNavPaymentPage,
  menuNavSignOut,
  menuNavVisitsPage,
  menuNavChat,
  nextAvailable,
  noneOfTheAbove,
  notFoundPageMessageContactLine,
  notFoundPagePrimaryText,
  notFoundPageSecondaryText,
  openCalendarAlt,
  pageLoadingAlt,
  partnerPopup,
  partnerPopupBullet1,
  partnerPopupBullet2,
  partnerPopupBullet3,
  partnerPortalUrl,
  partnerQuote,
  partnerQuoteAuthor,
  partnerYelp,
  patientAvatarAlt,
  patientsButtonAddPatient,
  patientsButtonRemovePatient,
  patientsButtonRemovePatientCancel,
  patientsButtonRemovePatientPromptText,
  patientsPageTitle,
  paymentManagementTitle,
  paymentManagementSelectTitle,
  paymentManagementEditPaymentDetails,
  pcpNotAvailable1,
  pcpNotAvailable2,
  pcpNotFound1,
  pcpNotFound2,
  pcpTimeSlotDivider,
  popupButton,
  preferredDoctorAlt,
  preferredDoctorTitle,
  preselectionError,
  promoAddTitle,
  promoButtonApplyCode,
  promoButtonRemoveCode,
  promoDiscountLabel,
  promoEditTitle,
  promoError,
  promoErrorWarning,
  promoPlaceholder,
  questionMarkAlt,
  registerAlt,
  registerButtonCreateAccount,
  registerButtonSignIn,
  registerCheckboxRememberMe,
  registerContentTitle,
  registerContentTitleWithMarket,
  registerContentTitleWithMarketAndDate,
  registerEmail,
  registerEmailError,
  registerEmailVerifyText,
  registerFirstName,
  registerFirstNameError,
  registerLastName,
  registerLastNameError,
  registerLegalUrlLinks,
  registerNondiscriminationNotice,
  registerPassword,
  registerPasswordConfirm,
  registerPasswordError,
  registerPasswordMatch,
  registerPasswordValidation1,
  registerPasswordValidation2,
  registerPasswordValidation3,
  passwordConstraintsId,
  confirmPasswordConstraintsId,
  registerPhone,
  registerPhoneError,
  registerPrivacyPolicy,
  registerPrivacyPractices,
  registerTermsOfService,
  removeInsuranceAlt,
  resetPasswordAlt,
  resetPasswordButtonBackToSignIn,
  resetPasswordButtonSubmit,
  resetPasswordConfirmPlaceholder,
  resetPasswordError,
  resetPasswordPageTitle,
  resetPasswordPlaceholder,
  resetPasswordSubTitle,
  resetPasswordSupportLink,
  resetPasswordTitle,
  resetPasswordValidation1,
  resetPasswordValidation2,
  resetPasswordValidation3,
  resetRequestSent,
  residency,
  rightAlt,
  SAMLLinkAccount,
  SAMLLinkHeader,
  SAMLLinkInfo,
  blueshieldLinkInfo,
  SAMLRegister,
  SAMLRegisterHeader,
  samlGenericError,
  samlErrorCreatingAccount,
  saveYourPreferredDoctor,
  selectableItemPhotoAlt,
  services,
  signInButtonSignIn,
  signInEmailError,
  signInEmailPlaceholder,
  signInForgotPassword,
  signInHaveAccount,
  signInHaveAccountSignIn,
  signInOauth,
  signInPasswordError,
  signInPasswordPlaceholder,
  signInNewToCenterWell,
  signInRegister,
  signInRememberMeLabel,
  signInSupportLink,
  signInTitle,
  SSOAddress,
  SSOLandingIntroduction,
  SSOLandingTitle,
  SSOLandingWelcome,
  SSOLinkBottomButton,
  SSOLinkButton,
  SSOLinkHeader,
  SSOLinkInfo,
  SSOLinkTitle,
  SSORegisterBottomButton,
  SSORegisterButton,
  SSORegisterGroupID,
  SSORegisterHeader,
  SSORegisterInfo,
  SSORegisterMemberID,
  SSORegisterTitle,
  SSOZipCode,
  symptomsCheckAlt,
  TdapWarning,
  topNavButtonBook,
  topNavMyVisits,
  verifyEmailBanner,
  verifyEmailBannerResendButton,
  verifyEmailContent,
  verifyEmailResendButton,
  verifyEmailResendSuccess,
  verifyEmailResendSupport,
  verifyEmailTitle,
  visitsAllPatients,
  visitsBookAgainText,
  visitsButtonBookAgain,
  visitsButtonBookAgainThankYou,
  visitsButtonCancelVisit,
  visitsButtonJoinVideoCall,
  visitsButtonReadLess,
  visitsButtonReadMore,
  visitsButtonReschedule,
  visitsButtonWhatToExpect,
  visitsCenterWellCarePlanSignIn,
  visitsCenterWellCarePlanSignInHeader,
  visitsStatusActive,
  visitsStatusCanceled,
  visitsStatusCompleted,
  visitsStatusEnroute,
  visitsStatusRefunded,
  visitsStatusUpcoming,
  visitsTapToUpload,
  visitsTapToUploadMore,
  visitsTitle,
  visitsVaccinationRecordsOnFile,
  visitsVaccinationRecordsRequired,
  visitsWhatToExpectMessage,
  visitsWhatToExpectTitle,
  visitsZeroDataLine1,
  visitsZeroDataLine2,
  wallStreetText,
  warningAlt,
  whatToExpectTitle,
  whatToExpectSubTitle,
  workplaceOnsiteAddress,
  workplaceOnsiteBookNow,
  workplaceOnsiteDate,
  workplaceOnsiteDates,
  workplaceOnsiteMessage,
  workplaceOnsiteMessageNoTimeslots,
  workplaceOnsiteNoDates,
  workplaceOnsiteRoom,
  workplaceOnsiteService,
  workplaceOnsiteServices,
  workplaceOR,
  workplaceRegularBookAHouseCall,
  workplaceRegularMessage,
  workplaceRegularTitle,
  workplaceTitle,
  workplaceWallStreetLogoAlt,
  workplaceYelpStarsAlt,
}
