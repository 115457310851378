import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { resetCart, setPatient } from '../../appState/cart/actions';
import { showBottomSheet } from '../../appState/notification/actions';
import { addPatient, clearSuccessMessage, clearError, editPatient } from '../../appState/patient/actions';
import { RootState } from '../../appState/rootState';
import { getCurrentStatus } from '../../appState/status/actions';
import { EditPatient, EditPatientProps } from '../routes/EditPatient';

function mapStateToProps(state: RootState, ownProps: EditPatientProps): EditPatientProps {
  return {
    ...ownProps,
    patientList: state.patientList,
    isAdd: true,
    account: state.userAccountInfo.data,
    config: state.config,
  };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: EditPatientProps): EditPatientProps {
  return {
    ...ownProps,
    actions: bindActionCreators(
      {
        addPatient,
        editPatient,
        setPatient,
        clearSuccessMessage,
        clearError,
        getCurrentStatus,
        showBottomSheet,
        resetCart,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPatient);
