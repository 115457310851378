import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export enum CookieName {
  heal_session_id = 'heal_session_id',
  redirect_after_login = 'redirect_after_login',
  user_name = 'user_name',
  user_avatar = 'user_avatar',
  public_code = 'public_code',
  heal_branch_data = 'heal_branch_data',
}

export async function ensureHealCookies() {
  const sessionExpiry = 30; // 30 days
  const domain = getDomain();

  const healSessionId = Cookies.get(CookieName.heal_session_id);
  if (!healSessionId) {
    Cookies.set(CookieName.heal_session_id, uuidv4(), {
      domain,
      path: '/',
      expires: sessionExpiry,
    });
  }
}

function getDomain(): string {
  const { hostname } = document.location!;
  if (hostname === 'localhost.heal.com' || hostname === 'localhost') {
    return '';
  }

  return hostname.replace('my', '').replace('patient', '');
}

export function setHealUserCookie(userName?: string, userAvatar?: string) {
  const healSessionId = Cookies.get('heal_session_id');
  const userExpiry = 30 * 12 * 15; // 15 years
  const domain = getDomain();

  if (!healSessionId) {
    const sessionExpiry = 30; // 30 days
    Cookies.set(CookieName.heal_session_id, uuidv4(), {
      domain,
      path: '/',
      expires: sessionExpiry,
    });
  }

  // Set cookies to simulate logged in state in heal.com
  if (userName) {
    Cookies.set(CookieName.user_name, userName, {
      domain,
      path: '/',
      expires: userExpiry,
    });
  }
  if (userAvatar) {
    Cookies.set(CookieName.user_avatar, userAvatar, {
      domain,
      path: '/',
      expires: userExpiry,
    });
  }
}

export function removeHealUserCookie() {
  removeCookie(CookieName.user_name);
  removeCookie(CookieName.user_avatar);
  ensureHealCookies();
}

export function removeCookie(cookieName: string) {
  Cookies.remove(cookieName, { domain: getDomain(), path: '/' });
}

export function getCookie(cookieName: string): string | null {
  const cookieValue = Cookies.get(cookieName);
  return cookieValue === undefined ? null : cookieValue;
}

export function setCookie(cookieName: string, cookieValue: string, expiry: number = 30) {
  Cookies.set(cookieName, cookieValue, {
    domain: getDomain(),
    path: '/',
    expires: expiry,
  });
}
