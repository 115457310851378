import { ResourceKey } from './ResourceKey';
import en from './en';

export function getString(key: ResourceKey, variables?: any) {
  let resource = en[key];

  if (!resource) {
    resource = 'BUG: Please define a string';
    return resource;
  }

  if (variables && variables.length) {
    let pos = 0;
    while (resource.indexOf('%s') > -1 && typeof variables[pos] !== 'undefined') {
      resource = resource.replace('%s', variables[pos]);
      pos++;
    }
  }

  return resource;
}

export function createMarkup(markup: string) {
  return { __html: markup };
}
