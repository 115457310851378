import { History } from 'history';
import React from 'react';
import { AccountState } from '../../appState/account/types';
import { Address } from '../../appState/address/types';
import { CartPreferredDoctor, CartState } from '../../appState/cart/types';
import { FlagName } from '../../appState/flag/actions';
import { PreferredDoctor } from '../../appState/patient/types';
import { QuickAction, QuickActionsPatient, QuickActionsState, Timeslot } from '../../appState/preferredDoctor/quickActions/types';
import { DoctorBio } from '../../components/healDoctor/DoctorBio';
import { OnDemand } from '../../components/utils/OnDemand';
import { OrSeparator } from '../../components/utils/OrSeparator';
import { IMAGES, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { css } from '../../utils/css';
import { grabInitials } from '../../utils/strings';
import { BottomSheet } from '../core/BottomSheet';
import { LegacyButton } from '../core/LegacyButton';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import { RedirectWithSearch } from '../core/RedirectWithSearch';
import { Steps } from '../utils/Steps';
import styles from './PDSelectDoctor.module.scss';

interface DispatchActions {
  actions: {
    getQuickActions: (userAccountId: string, patientId: string) => void;
    setPreferredDoctor: (preferredDoctor?: CartPreferredDoctor) => void;
    setLocation: (data: any) => void;
    setFlag: (name: string, value: boolean) => void;
  };
}

export interface PDSelectDoctorProps extends DispatchActions {
  cart: CartState;
  userAccountInfo: AccountState;
  history: History;
  quickActions: QuickActionsState;
  showIntro: boolean;
}

interface PDSelectDoctorState {
  selectedDoctor?: PreferredDoctor;
}

export class PDSelectDoctor extends React.Component<PDSelectDoctorProps, PDSelectDoctorState> {
  public state: Readonly<PDSelectDoctorState> = {};

  public componentDidMount() {
    if (this.props.userAccountInfo.data && this.props.cart.who) {
      this.props.actions.getQuickActions(this.props.userAccountInfo.data.userAccountId, this.props.cart.who.id);
    }
    if (this.props.showIntro && this.props.cart.who && this.props.cart.who.preferredDoctor) {
      this.props.actions.setFlag(FlagName.PREFERRED_DOCTOR_INTRO_SEEN, true);
    }
  }

  public componentDidUpdate(prevProps: PDSelectDoctorProps) {
    if (!prevProps.userAccountInfo.data && this.props.userAccountInfo.data && this.props.cart.who) {
      this.props.actions.getQuickActions(this.props.userAccountInfo.data.userAccountId, this.props.cart.who.id);
    }
  }

  public render(): JSX.Element {
    if (!this.props.cart.who) {
      return <RedirectWithSearch to={ROUTES.bookPatient} />;
    }

    const loading =
      !this.props.quickActions.data || this.props.quickActions.isFetching || !this.props.userAccountInfo.data || this.props.userAccountInfo.isFetching;

    let pdNotAvailable = false;
    let patient;
    if (!loading) {
      patient = this.props.quickActions.data!;
      if (!patient || !patient.cards || patient.cards.length === 0) {
        return <RedirectWithSearch to={ROUTES.bookReason} />;
      }
      if (patient.cards.length === 1 && patient.cards[0].cardLayout === 'doctor_unavailable') {
        pdNotAvailable = true;
      }
    }

    return (
      <Page showBackButton isLoading={loading} title="See a preferred doctor">
        <Steps history={this.props.history} currentStep="doctor" showDoctor />
        <PageHeader title="See a preferred doctor" subtitle={this.getPageTitle()} />
        {this.props.showIntro && this.renderIntroCard()}
        {this.renderPatientCards(patient)}
        {this.renderBioBottomSheet()}
        {pdNotAvailable && (
          <div className={styles.onDemand}>
            See a caring, qualified doctor
            <LegacyButton text="Book on-demand" onClick={this.goToOnDemand} disabled={false} testId="btn_onDemand" />
          </div>
        )}
        {!this.props.showIntro && !pdNotAvailable && (
          <div>
            <OrSeparator />
            <OnDemand onClick={this.goToOnDemand} />
          </div>
        )}
      </Page>
    );
  }

  private goToOnDemand = () => {
    this.props.actions.setPreferredDoctor(undefined);
    this.props.history.push(ROUTES.bookReason);
  };

  private renderPatientCards(patient?: QuickActionsPatient): JSX.Element | null {
    if (!patient || this.props.showIntro) {
      return null;
    }

    return (
      <div className={styles.listContainer}>
        {patient.cards!.map((card) => {
          return (
            <ListItem
              key={'Key' + Math.random()}
              uniqueKey={card.data.doctor!.providerId}
              card={card}
              selected={!!this.props.cart.preferredDoctor && this.props.cart.preferredDoctor.providerId === card.data.doctor!.providerId}
              testId="select_doctor"
              userAccountInfo={this.props.userAccountInfo}
              changePreferredDoctor={this.changePreferredDoctor}
              onDoctorClicked={() => {
                const pd = card.data.doctor!;
                this.props.actions.setPreferredDoctor({
                  providerId: pd.providerId,
                  prefix: pd.prefix,
                  firstName: pd.firstName,
                  lastName: pd.lastName,
                  avatarUrl: pd.avatarUrl,
                  services: pd.services,
                  type: card.data.recommendationType,
                  stillValid: true,
                });

                this.props.history.push(ROUTES.bookReason);
              }}
              onBioClicked={() => this.setState({ selectedDoctor: card.data.doctor! })}
            />
          );
        })}
      </div>
    );
  }

  private renderBioBottomSheet(): JSX.Element | null {
    if (!this.state.selectedDoctor || this.props.showIntro) {
      return null;
    }
    const doctor = this.state.selectedDoctor;
    return (
      <BottomSheet visible={!!this.state.selectedDoctor} showCloseButton onClose={this.closeBottomSheet}>
        <div className={styles.sheetContainer}>
          <DoctorBio
            avatarUrl={doctor.avatarUrl}
            videoUrl={doctor.bioVideoUrl}
            videoPosterUrl={doctor.bioVideoPosterFrameUrl}
            bio={doctor.bio}
            prefix={doctor.prefix}
            firstName={doctor.firstName}
            lastName={doctor.lastName}
            suffix={doctor.suffix}
          />
          <LegacyButton
            className={styles.sheetButton}
            text={getString(ResourceKey.genericButtonClose)}
            onClick={this.closeBottomSheet}
            disabled={false}
            testId="btn_close"
          />
        </div>
      </BottomSheet>
    );
  }

  private closeBottomSheet = () => {
    this.setState({ selectedDoctor: undefined });
  };

  private getPageTitle(): string {
    if (this.props.showIntro) {
      return `Introducing preferred doctors`;
    } else {
      return `We recommend doctors based on your home address`;
    }
  }

  private renderIntroCard(): JSX.Element {
    return (
      <div className={styles.introCard}>
        <img alt={getString(ResourceKey.preferredDoctorAlt)} src={IMAGES.bookVisitTitle} />
        <div className={styles.text}>
          When you choose a preferred doctor, we will show you their schedule first. You can always manage your preferred doctor selection in your Profile.
        </div>
        <LegacyButton text="Continue" onClick={() => this.props.actions.setFlag(FlagName.PREFERRED_DOCTOR_INTRO_SEEN, true)} testId="btn_continue_intro_pd" />
      </div>
    );
  }

  private changePreferredDoctor = () => {
    this.props.history.push(ROUTES.patientSelectDoctor.replace(':id', this.props.cart.who!.id));
  };
}

interface ListItemProps {
  card: QuickAction;
  uniqueKey?: string;
  selected: boolean;
  testId?: String;
  onBioClicked: () => void;
  onDoctorClicked: () => void;
  changePreferredDoctor: () => void;
  userAccountInfo: AccountState;
}

class ListItem extends React.Component<ListItemProps, object> {
  private silhouetteRef: any;
  private avatarRef: any;

  public render(): JSX.Element {
    const doctor = this.props.card.data.doctor;
    const timeslot = this.props.card.data.timeSlot;
    const addressId = this.props.card.data.addressId;
    const address = this.props.userAccountInfo.data?.addresses.filter((a) => {
      return a.addressId === addressId;
    })[0];
    const cardLayout: string = this.props.card.cardLayout;
    const isBookCard = cardLayout === 'book_recommended';
    const isBookHero = cardLayout === 'book_hero';
    const isDoctorUnavailable = cardLayout === 'doctor_unavailable';
    const containerStyles = css(styles.listItem, {
      [styles.bookCard]: isBookCard,
      [styles.bookHero]: isBookHero,
      [styles.doctorUnavailable]: isDoctorUnavailable,
      [styles.selected]: this.props.selected,
    });

    return (
      <table
        cellSpacing="0"
        className={containerStyles}
        data-tid={this.props.testId}
        data-key={this.props.uniqueKey}
        onClick={() => {
          if (isBookCard || isBookHero) {
            this.props.onDoctorClicked();
          }
        }}
      >
        <tbody>
          <tr>
            <td className={css(styles.listItemTextContainer, styles.header)}>
              {isBookHero && (
                <img
                  ref={(r) => {
                    this.silhouetteRef = r;
                  }}
                  className={styles.avatarHero}
                  alt="Doctor avatar"
                  src={IMAGES.preferredDoctorSilhouette}
                />
              )}
              {doctor.avatarUrl && isBookHero && (
                <img
                  alt="Doctor avatar"
                  ref={(r) => {
                    this.avatarRef = r;
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.onBioClicked();
                  }}
                  className={css(styles.avatarHero, styles.hidden)}
                  onLoad={() => {
                    this.silhouetteRef.style.display = 'none';
                    this.silhouetteRef.style.visibility = 'hidden';
                    this.avatarRef.style.display = 'block';
                    this.avatarRef.style.visibility = 'visible';
                  }}
                  src={doctor.avatarUrl}
                />
              )}
              {!isBookHero && <div className={styles.subtitle}>{this.getSubtitle()}</div>}
              <div className={styles.textTitle}>{`${doctor.prefix} ${doctor.firstName} ${doctor.lastName}`}</div>
              {isBookHero && <div className={styles.subtitle}>{this.getSubtitle()}</div>}
            </td>

            {isBookCard && (
              <td
                rowSpan={2}
                className={styles.rightSection}
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.onBioClicked();
                }}
              >
                {doctor!.avatarUrl && <img className={styles.avatar} src={doctor.avatarUrl} alt="doctor avatar" />}
                {!doctor.avatarUrl && <div className={styles.avatar}>{grabInitials(doctor.firstName + ' ' + doctor.lastName)}</div>}
                <div className={styles.readBio}>Read bio</div>
              </td>
            )}
          </tr>
          <tr>
            <td className={styles.listItemTextContainer}>
              {(isBookCard || isBookHero) && address && this.renderBookCard(timeslot, address, isBookHero)}
              {isDoctorUnavailable && this.renderDoctorUnavailable()}
            </td>
          </tr>
          {isDoctorUnavailable && (
            <tr>
              <td>
                <div className={styles.footerButton} onClick={this.props.changePreferredDoctor}>
                  View available doctors
                  <img role="none" src={IMAGES.caretRight} alt="View doctors" />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  private renderBookCard(timeslot: Timeslot, address: Address, isBookHero: boolean): JSX.Element {
    return (
      <div className={styles.content}>
        {isBookHero && (
          <div
            className={styles.readBio}
            onClick={(e) => {
              e.stopPropagation();
              this.props.onBioClicked();
            }}
          >
            Read bio
          </div>
        )}
        <b>{'Earliest available'}</b>
        <br />
        {`${timeslot.shortDateString} ${timeslot.shortTimeString}`}
        <br />
        {`${address.address}`}
      </div>
    );
  }

  private renderDoctorUnavailable(): JSX.Element {
    return (
      <div className={styles.content}>
        {`Your doctor is no longer available in your area. We apologize for the inconvenience. 
        Please click on the button below to select a new doctor to take care of your health needs.`}
      </div>
    );
  }

  private getSubtitle(): string {
    if (this.props.card.cardLayout === 'doctor_unavailable') {
      return 'IMPORTANT MESSAGE';
    } else {
      if (this.props.card.data.recommendationType === 'PREFERRED') {
        return 'PREFERRED DOCTOR';
      } else {
        return 'RECOMMENDED DOCTOR';
      }
    }
  }
}
