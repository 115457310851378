import { yupResolver } from '@hookform/resolvers/yup';
import { create } from 'apisauce';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { object } from 'yup';
import { RootState } from '../../appState/rootState';
import { HealFile, Visit } from '../../appState/visit/types';
import { VerifyEmail } from '../../components/login/VerifyEmail';
import { NotificationsBox } from '../../components/utils/NotificationsBox';
import { oktaAuth } from '../../services/auth';
import { FEATURES, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { css } from '../../utils/css';
import { YUP_VALIDATIONS } from '../../utils/forms';
import { getFileConfig } from '../../utils/heal';
import { downloadFile } from '../../utils/utils';
import { BottomSheet } from '../core/BottomSheet';
import Button, { ButtonStyle } from '../core/Button';
import Input from '../core/Input';
import styles from './VisitFile.module.scss';

interface VisitFileFormData {
  password: string;
}

export interface VisitFileProps {
  file: HealFile;
  visit: Visit;
  isBlueShieldUser?: boolean;
  testId: string;
}

const VisitFile: React.FC<VisitFileProps> = ({ file, visit, isBlueShieldUser, testId }) => {
  const history = useHistory();
  const { userAccountInfo } = useSelector((state: RootState) => state);
  const [openFlyout, setOpenFlyout] = useState(false);
  const [showVerificationBlock, setShowVerificationBlock] = useState(false);
  const [error, setError] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const fileConfig = useMemo(() => getFileConfig(file.documentType), [file]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<VisitFileFormData>({
    mode: 'onTouched',
    resolver: yupResolver(object().shape({ password: YUP_VALIDATIONS.password })),
  });

  function onSubmit(data: VisitFileFormData) {
    setError('');
    setIsFetching(true);

    oktaAuth
      .signIn({
        username: userAccountInfo.data?.email,
        password: data.password,
      })
      .then(() => {
        const { documentType, extension, contentType, visitCode, id, downloadUrl } = file;

        create({ baseURL: undefined, headers: { Accept: contentType } })
          .get(downloadUrl, undefined, { responseType: 'blob' })
          .then((response) => {
            if (response.ok && response.data) {
              const filename = `${new Date(visit.timeSlot.startTime * 1000).toISOString().substring(0, 10)}_${documentType}.${extension}`;
              downloadFile(response.data, filename, contentType);
              setOpenFlyout(false);
            } else {
              setError('Failed to download the file, please refresh the page and try again. Contact CenterWell if the issue persist.');
            }
          });
      })
      .catch((err: any) => {
        setError('Invalid password. Please try again.');
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  function fetchFile() {
    const { documentType, extension, contentType, visitCode, id, downloadUrl } = file;
    const startTime = visit.timeSlot.startTime * 1000;
    const dateOfVisit = new Date(startTime).toISOString().substring(0, 10);
    const filename = `${dateOfVisit}_${documentType}.${extension}`;

    create({ baseURL: undefined, headers: { Accept: contentType } })
      .get(downloadUrl, undefined, { responseType: 'blob' })
      .then((response) => {
        if (response.ok && response.data) {
          downloadFile(response.data, filename, contentType);
        } else {
          setError(getString(ResourceKey.genericVisitFileDownloadError));
        }
      });
  }

  function renderAuthenticationBottomSheet() {
    return (
      <BottomSheet
        title={getString(ResourceKey.visitsCenterWellCarePlanSignIn, [fileConfig.title])}
        visible={openFlyout}
        onClose={() => setOpenFlyout(false)}
        testId={`${visit.code}_carePlanBottomSheet`}
        showCloseButton
      >
        <div className={styles.bottomSheetContent}>
          <div className={styles.description} data-tid="txt_HCPheader">
            {getString(ResourceKey.visitsCenterWellCarePlanSignInHeader)}
          </div>

          {error && <NotificationsBox type="error" content={error || getString(ResourceKey.errorSignInGeneric)} />}

          {userAccountInfo.data?.email && (
            <div className="form-group">
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <div className="form-control-static">
                <strong id="email">{userAccountInfo.data.email}</strong>
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)} title="Clinical Care Plan">
            <Input label="Password" id="password" type="password" error={errors.password?.message} lpIgnore={false} required {...register('password')} />
            <Button className="btn-block" text={fileConfig.button} loading={isFetching} testId="btn_submit" />
          </form>

          <Button
            type="button"
            buttonStyle={ButtonStyle.TEXT}
            text="Forgot password"
            onClick={() => history.push(ROUTES.forgotPassword)}
            className={styles.forgotPassword}
          />
        </div>
      </BottomSheet>
    );
  }

  function renderEmailVerificationBlock() {
    if (!showVerificationBlock || !userAccountInfo.data) {
      return null;
    }
    return <VerifyEmail onClose={() => setShowVerificationBlock(false)} />;
  }

  return (
    <div className={styles.fileContainer} data-tid={testId}>
      <div className={styles.fileInner}>
        <ReactSVG className={css(styles.fileIcon, 'themeSVGFill')} src={fileConfig.icon} />

        <div>
          <div className={styles.fileTitle}>{fileConfig.title}</div>
          <div>{fileConfig.description}</div>
        </div>
      </div>

      <Button
        className="btn-block"
        onClick={() => {
          if (isBlueShieldUser) {
            fetchFile();
          } else if (userAccountInfo.data?.features.includes(FEATURES.verifyEmailBlockCarePlan)) {
            setShowVerificationBlock(true);
          } else {
            setOpenFlyout(true);
          }
        }}
        buttonStyle={ButtonStyle.SECONDARY}
        text={fileConfig.button}
        testId="btn_closeBottomSheet"
      />

      {renderAuthenticationBottomSheet()}
      {renderEmailVerificationBlock()}
    </div>
  );
};

export default VisitFile;
