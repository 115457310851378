import { Dispatch } from 'redux';
import { HealAPI } from '../../../services/api';

export enum QuickActionsActions {
  GET_QUICK_ACTIONS_REQUEST = 'GET_QUICK_ACTIONS_REQUEST',
  GET_QUICK_ACTIONS_SUCCESS = 'GET_QUICK_ACTIONS_SUCCESS',
  GET_QUICK_ACTIONS_FAIL = 'GET_QUICK_ACTIONS_FAIL',
}

export function getQuickActions(userAccountId: string, patientId: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: QuickActionsActions.GET_QUICK_ACTIONS_REQUEST });
    HealAPI.getQuickActions(userAccountId, patientId).then(
      (response: any) => {
        dispatch({
          type: QuickActionsActions.GET_QUICK_ACTIONS_SUCCESS,
          data: response.data.data,
        });
      },
      (response: any) => {
        dispatch({
          type: QuickActionsActions.GET_QUICK_ACTIONS_FAIL,
          data: response.data.data,
        });
      }
    );
  };
}
