import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, RouteProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { AccountState } from '../../appState/account/types';
import { CartState } from '../../appState/cart/types';
import { OnsiteState } from '../../appState/onsite/types';
import { RootState } from '../../appState/rootState';
import { FEATURES, ROUTES } from '../../services/constants';
import { CookieName, getCookie } from '../../utils/cookies';
import { css } from '../../utils/css';
import { AccessibleElement } from './accessibility/AccessibleElement';
import styles from './Steps.module.scss';

export interface OwnProps {
  history: RouteComponentProps['history'];
  currentStep: string;
  showDoctor?: boolean;
  account?: AccountState;
}

interface StateProps {
  cart: CartState;
  onsite: OnsiteState;
}

interface DispatchProps {}

export type Props = OwnProps & StateProps;

class Component extends React.Component<Props & DispatchProps & RouteProps> {
  public render(): JSX.Element {
    const steps: { title: string; key: string; path: string }[] = [];

    if (this.props.onsite.data) {
      let publicCode = getCookie(CookieName.public_code) || this.props.onsite.publicCode || '';
      steps.push({
        key: 'event',
        title: 'Event',
        path: ROUTES.workplace.replace(':code', publicCode),
      });
    }

    steps.push({
      key: 'patient',
      title: 'Patient',
      path: ROUTES.bookPatient,
    });

    if (!this.props.onsite.data) {
      const pdEnabled = this.props.account?.data && this.props.account.data.features.indexOf(FEATURES.preferredDoctor) >= 0;
      if (this.props.showDoctor || pdEnabled || !!this.props.cart.preferredDoctor) {
        steps.push({
          key: 'doctor',
          title: 'Doctor',
          path: ROUTES.bookDoctor,
        });
      }
    }

    steps.push({
      key: 'service',
      title: 'Service',
      path: ROUTES.bookReason,
    });
    steps.push({
      key: 'schedule',
      title: 'Schedule',
      path: ROUTES.bookSchedule,
    });
    steps.push({
      key: 'review',
      title: 'Review',
      path: ROUTES.bookReview,
    });
    let selected = true;

    return (
      <div role="navigation" aria-label="Booking steps" className={styles.stepsContainer}>
        {steps.map((step, i) => {
          const dotClassNames = css(styles.stepDot, {
            [styles.selected]: selected,
            [styles.current]: this.props.currentStep === step.key,
          });
          const barClassNames = css(styles.stepBar, {
            [styles.selected]: selected,
          });
          const labelClassNames = css(styles.stepLabel, {
            [styles.selected]: selected,
            [styles.current]: this.props.currentStep === step.key,
          });
          if (this.props.currentStep === step.key) {
            selected = false;
          }
          let titleStyle: any = {
            left: `${(100 / (steps.length - 1)) * i}%`,
          };

          if (i === 0) {
            titleStyle.left = '20px';
            titleStyle.textAlign = 'left';
          } else if (i === steps.length - 1) {
            titleStyle.left = 'inherit';
            titleStyle.right = '-20px';
            titleStyle.textAlign = 'right';
          }
          const _selected = selected;
          const onClick = () => {
            if (_selected) {
              this.props.history.push(step.path);
            }
          };
          let accessibilityState = 'Disabled';
          if (this.props.currentStep === step.key) {
            accessibilityState = 'Current Step';
          } else if (selected) {
            accessibilityState = 'Active';
          }
          return (
            <div key={step.key}>
              {i > 0 && (
                <div
                  style={{
                    left: `${(100 / (steps.length - 1)) * (i - 1)}%`,
                    width: `${100 / (steps.length - 1)}%`,
                  }}
                  className={barClassNames}
                />
              )}
              <div onClick={onClick} style={{ left: `${(100 / (steps.length - 1)) * i + 1}%` }} className={dotClassNames} />
              <AccessibleElement
                aria-label={`${step.title}, ${accessibilityState}, Step ${i + 1} of ${steps.length}`}
                aria-disabled={!selected}
                onClick={onClick}
                style={titleStyle}
                className={labelClassNames}
              >
                {step.title}
              </AccessibleElement>
            </div>
          );
        })}
      </div>
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  const { onsite, cart } = state;
  return {
    onsite,
    cart,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {};
}

export const Steps = connect(mapStateToProps, mapDispatchToProps)(Component);
