import React from 'react';

interface InputButtonProps {
  id: string;
  label?: string;
  accessory?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const InputButton: React.FC<InputButtonProps> = ({ children, id, label, accessory, onClick }) => {
  return (
    <div className="form-group">
      {label && (
        <div className="form-label" id={`${id}_label`}>
          {label}
        </div>
      )}

      <button type="button" className="form-control form-control-button" aria-labelledby={label && `${id}_label`} onClick={onClick} data-tid={`inp_${id}`}>
        {children}
        {accessory && <div className="form-control-accessory">{accessory}</div>}
      </button>
    </div>
  );
};

export default InputButton;
