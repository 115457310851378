import { NotificationConfig } from './types';

export enum NotificationActions {
  SHOW_BOTTOM_SHEET = 'SHOW_BOTTOM_SHEET',
  HIDE_BOTTOM_SHEET = 'HIDE_ERROR_OR_BOTTOM_SHEET',
}

export function showBottomSheet(title?: string, message?: string, isError?: boolean, primaryButtonText?: string) {
  return showBottomSheetWithConfig({
    title,
    message,
    isError,
    primaryButtonText,
  });
}

export function showBottomSheetWithConfig(config: NotificationConfig) {
  return {
    type: NotificationActions.SHOW_BOTTOM_SHEET,
    data: config,
  };
}

export function hideBottomSheet() {
  return {
    type: NotificationActions.HIDE_BOTTOM_SHEET,
  };
}
