import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IMAGES, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { LegacyButton } from '../core/LegacyButton';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import styles from './AgentAccount.module.scss';

declare const healConfig: any;

class AgentAccount extends Component<RouteComponentProps, {}> {
  public render(): JSX.Element {
    const navButtons = [
      {
        action: () => {
          this.props.history.push(ROUTES.login);
        },
        text: getString(ResourceKey.signInButtonSignIn),
      },
    ];

    return (
      <Page title={getString(ResourceKey.agentAccountPageTitle)} cssClass={styles.container} navButtons={navButtons} hideTitleInNavbar>
        <div className={styles.titleImage}>
          <img alt={getString(ResourceKey.agentAccountAlt)} src={IMAGES.notFoundTitle} />
        </div>

        <PageHeader title={getString(ResourceKey.agentAccountPageTitle)} />

        <div className={styles.content}>
          <p>{getString(ResourceKey.agentAccountPageContent)}</p>

          <a href={healConfig.partnerPortalUrl || getString(ResourceKey.partnerPortalUrl)} className={styles.button} data-tid="btn_partnerPortal">
            {getString(ResourceKey.agentAccountButtonText)}
          </a>

          <LegacyButton
            className={styles.link}
            onClick={() => {
              this.props.history.push(ROUTES.login);
            }}
            text={getString(ResourceKey.agentAccountBackText)}
            testId="btn_backToPatientLogIn"
          />
        </div>
      </Page>
    );
  }
}

export default AgentAccount;
