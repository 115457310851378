import * as React from 'react';
import { css } from '../../utils/css';
import styles from './Checkbox.module.scss';

export interface CheckboxProps {
  alignmentClasses?: {
    leftClass?: string;
    rightClass?: string;
  };
  text: string;
  isChecked: boolean;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  checkboxId?: string;
  hasExternalLabel?: boolean;
  testId?: string;
  disabled?: boolean;
  tabIndex?: number;
}

export class Checkbox extends React.Component<CheckboxProps> {
  public render(): JSX.Element {
    if (this.props.hasExternalLabel) {
      return <div className={css(styles.checkbox, this.props.className)}>{this.renderInner()}</div>;
    } else {
      return (
        <div className={css(styles.checkbox, this.props.className)}>
          <label>{this.renderInner()}</label>
        </div>
      );
    }
  }

  private renderInner(): JSX.Element {
    const { text, isChecked, alignmentClasses } = this.props;

    return (
      <div>
        <div className={alignmentClasses?.leftClass}>
          <input
            type="checkbox"
            value={text}
            id={this.props.checkboxId}
            checked={isChecked}
            onChange={this.props.onChange}
            onClick={this.props.onClick}
            data-tid={this.props.testId}
            disabled={this.props.disabled}
            tabIndex={this.props.tabIndex}
          />
          <span />
        </div>
        <label htmlFor={this.props.checkboxId} className={alignmentClasses?.rightClass}>
          {text}
        </label>
      </div>
    );
  }
}
