import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HealAPI } from '../services/api';
import { Service } from './visitConfig/types';

export interface ServicesState {
  services: Service[];
  error: string | null;
  status: Status;
}

export enum Status {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed',
}

const servicesInitialState: ServicesState = {
  services: [],
  status: Status.idle,
  error: null,
};

export interface ServicesRequest {
  patientId: string;
  addressId: string;
}

export interface OnsiteServicesRequest {
  patientId: string;
  partnerAddressId: string;
}

export const fetchServices = createAsyncThunk<any, any, { rejectValue: string | undefined }>(
  'services/fetchServices',
  async ({ patientId, addressId }, thunkApi) => {
    const response = await HealAPI.getServices(patientId, addressId);

    const services = response.data?.data?.services;
    if (response.ok && services) {
      return response.data?.data.services;
    }

    return thunkApi.rejectWithValue(response.data?.description);
  }
);

export const fetchOnsiteServices = createAsyncThunk<any, any, { rejectValue: string | undefined }>(
  'services/fetchServices',
  async ({ patientId, partnerAddressId }: OnsiteServicesRequest, thunkApi) => {
    const response = await HealAPI.getServicesOnsite(patientId, partnerAddressId);

    const services = response.data?.data?.services;
    if (response.ok && services) {
      return response.data?.data.services;
    }

    return thunkApi.rejectWithValue(response.data?.description);
  }
);

export const servicesSlice = createSlice({
  name: 'services',
  initialState: servicesInitialState,
  reducers: {
    resetServices: (state) => {
      state.error = servicesInitialState.error;
      state.services = servicesInitialState.services;
      state.status = servicesInitialState.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServices.pending, (state, action) => {
      state.status = Status.loading;
      state.error = null;
      state.services = [];
    });
    builder.addCase(fetchServices.fulfilled, (state, { payload }) => {
      state.services = payload || [];
      state.status = Status.succeeded;
      state.error = null;
    });
    builder.addCase(fetchServices.rejected, (state, { payload }) => {
      state.services = [];
      state.status = Status.idle;
      state.error = payload || 'Something went wrong fetching services. Please try again.';
    });
  },
});

export const { resetServices } = servicesSlice.actions;
export default servicesSlice.reducer;
