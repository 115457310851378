import { AnyAction, combineReducers } from 'redux';
import { accountReducer } from './account/reducer';
import { addressReducer } from './address/reducer';
import { agreementsReducer } from './agreements/reducer';
import { AuthActions } from './auth/actions';
import { authReducer } from './auth/reducer';
import { campaignReducer } from './campaign/reducer';
import { cartReducer } from './cart/reducer';
import { checkoutReducer } from './checkout/reducer';
import { configReducer } from './config/reducer';
import { flagReducer } from './flag/reducer';
import { fluShotsReducer } from './fluShots/reducer';
import { notificationReducer } from './notification/reducer';
import { onsiteReducer } from './onsite/reducer';
import { patientReducer } from './patient/reducer';
import { availablePDReducer } from './preferredDoctor/available/reducer';
import { quickActionsReducer } from './preferredDoctor/quickActions/reducer';
import { SAMLReducer } from './saml/reducer';
import { servicePricingSlice } from './servicePricing';
import { servicesSlice } from './services';
import { thankYouReducer } from './thankYou/reducer';
import { timeSlotsSlice } from './timeSlots';
import { userPreferencesInitialState, userPreferencesReducer } from './userPreferences/reducer';
import { visitReducer } from './visit/reducer';
import { whatToExpectReducer } from './whatToExpect/reducer';

const appReducer = combineReducers({
  services: servicesSlice.reducer,
  servicePricing: servicePricingSlice.reducer,
  addressList: addressReducer,
  auth: authReducer,
  cart: cartReducer,
  agreements: agreementsReducer,
  checkout: checkoutReducer,
  config: configReducer,
  notification: notificationReducer,
  onsite: onsiteReducer,
  patientList: patientReducer,
  quickActions: quickActionsReducer,
  saml: SAMLReducer,
  thankYou: thankYouReducer,
  timeSlots: timeSlotsSlice.reducer,
  userAccountInfo: accountReducer,
  visitList: visitReducer,
  whatToExpect: whatToExpectReducer,
  availablePreferredDoctors: availablePDReducer,
  flag: flagReducer,
  userPreferences: userPreferencesReducer,
  campaign: campaignReducer,
  fluShots: fluShotsReducer,
});

export const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === AuthActions.LOGOUT_SUCCESS) {
    const { blueshieldSSOUser, engagementPointSSOUser } = state.userPreferences;
    state = {
      userPreferences: {
        ...userPreferencesInitialState,
        blueshieldSSOUser,
        engagementPointSSOUser,
      },
    };
  }

  return appReducer(state, action);
};
