import * as React from 'react';
import styles from './OnDemand.module.scss';
import { LegacyButton, ButtonStyles } from '../core/LegacyButton';

export interface OnDemandProps {
  message?: string;
  cta?: string;
  onClick: () => void;
}

export class OnDemand extends React.Component<OnDemandProps> {
  public render(): JSX.Element {
    return (
      <div className={styles.onDemand}>
        {this.props.message || 'See a caring, qualified doctor sooner'}
        <LegacyButton
          text={this.props.cta || 'Get on-demand care'}
          className={styles.button}
          style={ButtonStyles.secondary}
          onClick={this.props.onClick}
          testId={'btn_onDemand'}
        />
      </div>
    );
  }
}
