import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { saveVisit } from '../../appState/checkout/actions';
import { RootState } from '../../appState/rootState';
import { BookVisit, BookVisitProps } from '../bookVisit/BookVisit';

function mapStateToProps(state: RootState, ownProps: BookVisitProps): BookVisitProps {
  return {
    ...ownProps,
    cart: state.cart,
    visitsState: state.visitList,
    addressState: state.addressList,
    account: state.userAccountInfo,
    patientList: state.patientList,
    onsite: state.onsite,
    campaign: state.campaign,
    checkout: state.checkout,
  };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: BookVisitProps): BookVisitProps {
  return {
    ...ownProps,
    actions: bindActionCreators(
      {
        saveVisit,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookVisit);
