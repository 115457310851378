import { getVisits } from '../../appState/visit/actions';
import { HealAPI } from '../../services/api';
import { PatientDoctorRatingRequestParams } from './types';

export enum RatingActions {
  SUBMIT_RATING_REQUEST = 'SUBMIT_RATING_REQUEST',
  SUBMIT_RATING_SUCCESS = 'SUBMIT_RATING_SUCCESS',
  SUBMIT_RATING_FAIL = 'SUBMIT_RATING_FAIL',
}

export function submitRating(patientId: string, visitCode: string, data: PatientDoctorRatingRequestParams) {
  return (dispatch: any) => {
    dispatch({ type: RatingActions.SUBMIT_RATING_REQUEST });
    HealAPI.submitRating(patientId, visitCode, data).then(
      (response) => {
        dispatch({
          type: RatingActions.SUBMIT_RATING_SUCCESS,
          data: response.data,
        });
        dispatch(getVisits());
      },
      (response) => {
        dispatch({
          type: RatingActions.SUBMIT_RATING_FAIL,
          data: response.data,
        });
      }
    );
  };
}
