import * as React from 'react';
import { ReactSVG } from 'react-svg';
import { IMAGES } from '../../services/constants';
import { css } from '../../utils/css';
import { KeyCodes } from '../../utils/keycodes';
import { Checkbox } from '../core/Checkbox';
import { AccessibleElement } from './accessibility/AccessibleElement';
import styles from './SelectableItem.module.scss';

export const SelectableItemStyles = {
  patient: styles.patient,
  service: styles.service,
  review: styles.review,
};

export interface SelectableItemProps {
  showCaret?: boolean;
  icon?: string;
  avatar?: string;
  initials?: string;
  label?: string;
  value?: string;
  footer?: string;
  testId: string;
  required?: boolean;
  selected: boolean;
  checkboxId?: string;
  checkboxVisible?: boolean;
  checkboxExternalLabel?: boolean;
  ariaLabel?: string;
  role?: string;
  tabIndex?: number;
  onSelect?: () => void;
  onEdit?: () => void;
  editLabel?: string;
  style: string;
  disabled?: boolean;
  labelClassName?: string;
  valueClassName?: string;
  footerClassName?: string;
  disableSelectedStyle?: boolean;
}

export const SelectableItem = (props: SelectableItemProps) => {
  const classNames = css(styles.item, props.style, {
    [styles.selected]: !!props.selected && !props.disableSelectedStyle,
    [styles.selectable]: !!props.onSelect,
    [styles.disabled]: !!props.disabled,
  });

  const onCheckChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onSelect) {
      e.stopPropagation();
      props.onSelect();
    }
  };

  const onEditClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    props.onEdit && props.onEdit();
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>, onClick?: () => void) => {
    if (onClick && (e?.keyCode === KeyCodes.enter || e?.keyCode === KeyCodes.space)) {
      onClick();
    }
  };

  return (
    <AccessibleElement
      aria-label={props.ariaLabel}
      role={props.role}
      tabIndex={props.tabIndex}
      data-tid={`${props.testId}`}
      disableOnKeyDownAll
      onClick={props.disabled ? undefined : props.onSelect}
      className={classNames}
      onKeyDown={(e: any) => {
        if (!props.disabled) {
          onKeyDown(e, () => {
            if (props.onSelect) {
              props.onSelect();
            }
          });
        }
      }}
    >
      {props.checkboxVisible && (
        <Checkbox
          className={styles.checkbox}
          text=""
          isChecked={props.selected}
          onChange={onCheckChanged}
          disabled={props.disabled}
          checkboxId={props.checkboxId}
          hasExternalLabel={props.checkboxExternalLabel}
          tabIndex={-1}
        />
      )}
      <div className={styles.imageContainer}>
        {props.icon && <img className={styles.icon} src={props.icon} alt="" />}
        {(props.avatar || props.initials) && (
          <div aria-hidden>
            {props.avatar && <img alt="" className={styles.avatar} src={props.avatar} />}
            {!props.avatar && <div className={styles.avatar}>{props.initials}</div>}
          </div>
        )}
      </div>
      <div className={styles.content}>
        {props.checkboxVisible && props.checkboxExternalLabel ? (
          <label className={css(styles.label, props.labelClassName)} htmlFor={props.checkboxId} onClick={(e) => e.preventDefault()}>
            {props.label}
          </label>
        ) : (
          <div className={css(styles.label, props.labelClassName, { [styles.required]: !!props.required })}>{props.label}</div>
        )}
        {props.required && !props.value && <div className={css(styles.value, styles.required)}>Required</div>}
        {!!props.value && <div className={css(styles.value, props.valueClassName)}>{props.value}</div>}
        {props.onEdit && (
          <button
            tabIndex={0}
            aria-label={`${props.editLabel || 'Edit'} ${props.label}`}
            className={styles.edit}
            onClick={onEditClicked}
            data-tid={`${props.testId}_edit`}
            onKeyDown={(e: any) => {
              if (!props.disabled) {
                onKeyDown(e, () => {
                  onEditClicked(e);
                });
              }
            }}
          >
            {props.editLabel || 'Edit'}
          </button>
        )}
        {props.footer && <div className={css(styles.footer, props.footerClassName)}>{props.footer}</div>}
      </div>
      {props.showCaret && !props.checkboxVisible && <ReactSVG src={IMAGES.caretRight} alt="" className={css(styles.caret, 'themeSVG')} />}
    </AccessibleElement>
  );
};
