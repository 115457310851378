import styles from './BrowserNotSupported.module.scss';

const BrowserNotSupportedPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Your browser is not supported</h1>
        <p>We recommend using the latest version of Chrome, Firefox, Edge, or Safari.</p>
      </div>
    </div>
  );
};

export default BrowserNotSupportedPage;
