import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { oktaAuth } from '../../services/auth';
import { ROUTES } from '../../services/constants';
import { CookieName, setCookie } from '../../utils/cookies';

const OktaSecurity: React.FC<{}> = ({ children }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const _branch_match_id = queryParams.get('_branch_match_id');
  const history = useHistory();

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={() => {
        // User is being redirected, save branch_match_id if it exists
        _branch_match_id && setCookie('branch_match_id', _branch_match_id);

        setCookie(CookieName.redirect_after_login, history.location.pathname + history.location.search, 1 / 48);
        history.push(ROUTES.logout);
      }}
      restoreOriginalUri={async (_oktaAuth: OktaAuth, originalUri: string) => history.replace(toRelativeUrl(originalUri ?? '', window.location.origin))}
      children={children}
    />
  );
};

export default OktaSecurity;
