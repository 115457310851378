import * as React from 'react';
import { KeyCodes } from '../../../utils/keycodes';

export interface FocusTrapProps {}

export interface FocusTrapState {
  trapId: string;
}

export class FocusTrap extends React.Component<FocusTrapProps, FocusTrapState> {
  private firstFocusableElement?: any;
  private lastFocusableElement?: any;

  constructor(props: FocusTrapProps) {
    super(props);
    this.state = {
      trapId: 'bottomSheet_' + Math.random() * 9999999,
    };
  }

  public componentDidMount() {
    window.addEventListener('keydown', this.handleWindowKeyDown, true);
  }

  public componentWillUnmount() {
    window.removeEventListener('keydown', this.handleWindowKeyDown, true);
  }

  public render(): JSX.Element {
    return (
      <div id={this.state.trapId} onKeyDown={this.handleKeyDown}>
        {this.props.children}
      </div>
    );
  }

  private handleWindowKeyDown = (e: KeyboardEvent) => {
    const parentElement = document.getElementById(this.state.trapId);
    if (e && e.target && !this.elementContains(parentElement, e.target as HTMLElement)) {
      if (e.keyCode === KeyCodes.tab) {
        this.findFocusableElements(parentElement);
        if (e.shiftKey) {
          this.lastFocusableElement?.focus();
        } else {
          this.firstFocusableElement?.focus();
        }
      }
    }
  };

  private elementContains(parent: HTMLElement | null, child: HTMLElement | null): boolean {
    let isContained = false;

    if (parent && child) {
      if (parent.contains) {
        isContained = parent.contains(child);
      }
    }

    return isContained;
  }

  private handleKeyDown = (e: any) => {
    this.firstFocusableElement = undefined;
    this.findFocusableElements(e.currentTarget);
    if (e.keyCode === KeyCodes.tab) {
      if (e.shiftKey) {
        this.handleTabBackward(e);
      } else {
        this.handleTabForward(e);
      }
    }
  };

  private findFocusableElements = (element: any) => {
    for (let i = 0; i < element.childNodes.length; i++) {
      let e = element.childNodes[i];
      if (e.tabIndex >= 0) {
        if (this.firstFocusableElement === undefined) {
          this.firstFocusableElement = e;
        }
        this.lastFocusableElement = e;
      }
      this.findFocusableElements(e);
    }
  };

  private handleTabForward = (e: any) => {
    if (e.target === this.lastFocusableElement) {
      e.preventDefault();
      this.firstFocusableElement?.focus();
    }
  };

  private handleTabBackward = (e: any) => {
    if (e.target === this.firstFocusableElement) {
      e.preventDefault();
      this.lastFocusableElement?.focus();
    }
  };
}
