import { NotificationActions } from './actions';
import { NotificationState } from './types';

export const notificationInitialState: NotificationState = {
  bottomSheet: undefined,
};

export function notificationReducer(
  state: NotificationState = notificationInitialState,
  // action: Action & { data: NotificationConfig }
  action: any
): NotificationState {
  switch (action.type) {
    case NotificationActions.HIDE_BOTTOM_SHEET:
      return { ...notificationInitialState };
    case NotificationActions.SHOW_BOTTOM_SHEET:
      return {
        bottomSheet: action.data,
      };
    default:
      return state;
  }
}
