import * as React from 'react';
import { css } from '../../utils/css';
import styles from './Loading.module.scss';

export interface LoadingProps {
  color?: string;
  width?: number | string;
  height?: number | string;
  padding?: number | string;
  className?: string;
}

export class Loading extends React.Component<LoadingProps> {
  public render(): JSX.Element {
    return (
      <div
        style={this.getStyles()}
        dangerouslySetInnerHTML={{ __html: loadingCircles }}
        className={css(styles.loading, this.props.className)}
        data-tid="loading"
      />
    );
  }

  private getStyles(): React.CSSProperties {
    const { color, height, width, padding } = this.props;

    return {
      fill: color,
      stroke: color,
      height,
      width,
      padding,
      margin: '0 auto',
      boxSizing: 'content-box',
      overflow: 'hidden',
    };
  }
}

/* tslint:disable */
const loadingCircles = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  <circle cx="50" cy="50" r="32" stroke-width="8" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round" transform="rotate(34.6709 50 50)">
    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
  </circle>
</svg>
`;
// const loadingBars = `
//   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
//     <path transform="translate(2)" d="M0 12 V20 H4 V12z">
//       <animate attributeName="d" values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z" dur="1.2s" repeatCount="indefinite" begin="0" keytimes="0;.2;.5;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8" calcMode="spline" />
//     </path>
//     <path transform="translate(8)" d="M0 12 V20 H4 V12z">
//       <animate attributeName="d" values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z" dur="1.2s" repeatCount="indefinite" begin="0.2" keytimes="0;.2;.5;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8" calcMode="spline" />
//     </path>
//     <path transform="translate(14)" d="M0 12 V20 H4 V12z">
//       <animate attributeName="d" values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z" dur="1.2s" repeatCount="indefinite" begin="0.4" keytimes="0;.2;.5;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8" calcMode="spline" />
//     </path>
//     <path transform="translate(20)" d="M0 12 V20 H4 V12z">
//       <animate attributeName="d" values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z" dur="1.2s" repeatCount="indefinite" begin="0.6" keytimes="0;.2;.5;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8" calcMode="spline" />
//     </path>
//     <path transform="translate(26)" d="M0 12 V20 H4 V12z">
//       <animate attributeName="d" values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z" dur="1.2s" repeatCount="indefinite" begin="0.8" keytimes="0;.2;.5;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8" calcMode="spline" />
//     </path>
//   </svg>
// `;
/* tslint:enable */
