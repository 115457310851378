import { Visit } from '../../appState/visit/types';

export enum FluShotsStep {
  INIT,
  SELECT_PATIENTS,
  CONFIRM,
  BOOK_SUCCESS,
  BOOK_ERROR,
}

export enum FluShotsActions {
  ADD_SELECTED_PATIENT = 'ADD_FLU_SHOTS_SELECTED_PATIENT',
  REMOVE_SELECTED_PATIENT = 'REMOVE_FLU_SHOTS_SELECTED_PATIENT',
  RESET = 'RESET_FLU_SHOTS',
  SET_SELECTED_PATIENT_BOOK_SUCCESS = 'SET_FLU_SHOTS_SELECTED_PATIENT_BOOK_SUCCESS',
  SET_STEP = 'SET_FLU_SHOTS_STEP',
  SET_VISIT = 'SET_FLU_SHOTS_VISIT',
}

export function addSelectedPatient(patientId: string, chargeAmount: number) {
  return {
    type: FluShotsActions.ADD_SELECTED_PATIENT,
    payload: { patientId, chargeAmount },
  };
}

export function removeSelectedPatient(patientId: string) {
  return {
    type: FluShotsActions.REMOVE_SELECTED_PATIENT,
    payload: patientId,
  };
}

export function resetFluShots() {
  return {
    type: FluShotsActions.RESET,
  };
}

export function setSelectedPatientBookSuccess(patientId: string, bookSuccess: boolean) {
  return {
    type: FluShotsActions.SET_SELECTED_PATIENT_BOOK_SUCCESS,
    payload: { patientId, bookSuccess },
  };
}

export function setStep(step: FluShotsStep) {
  return {
    type: FluShotsActions.SET_STEP,
    payload: step,
  };
}

export function setVisit(visit: Visit) {
  return {
    type: FluShotsActions.SET_VISIT,
    payload: visit,
  };
}
