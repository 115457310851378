import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { object } from 'yup';
import { showBottomSheet } from '../../appState/notification/actions';
import { RootState } from '../../appState/rootState';
import { samlGetAccountData, setAccessAndRefreshToken } from '../../appState/saml/actions';
import { setBlueshieldSsoUser } from '../../appState/userPreferences/actions';
import { HealAPI } from '../../services/api';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { YUP_VALIDATIONS } from '../../utils/forms';
import Button, { ButtonStyle } from '../core/Button';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import { NotificationsBox } from '../utils/NotificationsBox';
import { OrSeparator } from '../utils/OrSeparator';
import styles from './SamlLinkBlueShield.module.scss';

declare const healConfig: any;

interface LinkFormData {
  phone: string;
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function SamlLinkBlueShield() {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string>();

  const dispatch = useDispatch();
  const saml = useSelector((state: RootState) => state.saml);
  const query = useQuery();

  const allowLink = query.get('link') === 'true';
  const at = query.get('at') ?? '';
  const rt = query.get('rt') ?? '';

  useEffect(() => {
    dispatch(setBlueshieldSsoUser(true));
  }, [dispatch]);

  useEffect(() => {
    if (at && rt) {
      dispatch(setAccessAndRefreshToken(at, rt));
      dispatch(samlGetAccountData('blueshield'));
    }
  }, [dispatch, at, rt]);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<LinkFormData>({
    mode: 'onTouched',
    resolver: yupResolver(
      object().shape({
        phone: YUP_VALIDATIONS.phone,
      })
    ),
  });

  useEffect(() => {
    reset({
      ...getValues(),
      phone: saml.data?.phone || '',
    });
  }, [saml.data, reset, getValues]);

  function onSubmit(data: LinkFormData) {
    setError(undefined);
    setSaving(true);
    HealAPI.samlRegisterBlueShieldAccount('blueshield', at, { phone: data.phone })
      .then((response) => {
        if (response.ok) {
          window.location.href = `${healConfig.blueshieldSsoBranch}?at=${encodeURIComponent(at)}&rt=${encodeURIComponent(rt)}&link=false`;
        } else {
          dispatch(
            showBottomSheet(getString(ResourceKey.errorTitle), 'There was an error processing your request, please call CenterWell at (844) 644-4325', true)
          );
        }
      })
      .finally(() => setSaving(false));
  }

  const renderCreateAccount = (buttonText: string) => {
    return (
      <div>
        <form onSubmit={handleSubmit(onSubmit)} title="Link BlueShield">
          {!saml.data?.phone && (
            <div className={styles.missingPhone}>
              <div className={styles.title}>Missing phone number</div>
              <div className={styles.subtext}>To create an account, please enter your phone number below:</div>
              <Controller
                name="phone"
                render={({ field }) => (
                  <div className={`form-group required ${errors.phone ? 'invalid' : ''}`}>
                    <label className="form-label" htmlFor={field.name}>
                      Phone
                    </label>
                    <PhoneInput
                      value={field.value}
                      inputProps={{
                        name: field.name,
                        id: field.name,
                        required: true,
                        'data-lpignore': true,
                        'aria-describedby': errors.phone ? `${field.name}_error` : undefined,
                        'data-tid': `inp_${field.name}`,
                      }}
                      onChange={(_value, _data, _event, formattedValue) => field.onChange(formattedValue)}
                      onBlur={field.onBlur}
                      inputClass="form-control"
                      country="us"
                    />
                    {errors.phone && (
                      <div className="form-control-error" id={`${field.name}_error`}>
                        {errors.phone?.message}
                      </div>
                    )}
                  </div>
                )}
                control={control}
              />
            </div>
          )}
          <div className={styles.privacyText}>
            {getString(ResourceKey.registerLegalUrlLinks)}
            <br />
            <a href="https://www.humana.com/legal/privacy-policy" rel="noreferrer">
              {getString(ResourceKey.registerPrivacyPolicy)}
            </a>
            ,&nbsp;
            <a href="https://www.humana.com/legal" rel="noreferrer">
              {getString(ResourceKey.registerTermsOfService)}
            </a>
            ,&nbsp;
            <a href="https://www.centerwell.com/en/privacy-practices-footer.html" target="_blank" rel="noreferrer">
              {getString(ResourceKey.registerPrivacyPractices)}
            </a>
            <span>, and&nbsp;</span>
            <a href="https://www.humana.com/legal/non-discrimination-disclosure" target="_blank" rel="noreferrer">
              {getString(ResourceKey.registerNondiscriminationNotice)}
            </a>
          </div>
          <Button text={buttonText} testId="btn_createAccount" loading={saving} className="btn-block" />
        </form>
        <br />
        <Button
          buttonStyle={ButtonStyle.SECONDARY}
          text="Cancel"
          onClick={() => (window.location.href = 'https://www.heal.com/blueshield')}
          testId="btn_cancelButton"
          className={'btn-block ' + styles.skipLinkingButton}
        />
      </div>
    );
  };

  const renderCallHeal = () => {
    return (
      <div>
        <b>Would you like to link your BlueShield account with your CenterWell account?</b>
        <br />
        <br />
        Benefits of linking accounts:
        <ul>
          <li>Access your full CenterWell visit history and Clinical Care Plans</li>
          <li>Use your BlueShield account to log into CenterWell</li>
        </ul>
        To link your account, please call Patient Support:
        <Button
          className={'btn-block ' + styles.action}
          text="Call 844-644-4325"
          onClick={() => (window.location.href = 'tel:+18446444325')}
          testId="btn_callHeal"
        />
        <OrSeparator />
        <br />
        {renderCreateAccount('Create new account')}
        <br />
        <div className={styles.footer}>
          Note: If you choose to create a new account you will not be able to access your previous CenterWell visit history or Clinical Care Plans
        </div>
      </div>
    );
  };

  return (
    <Page title={getString(ResourceKey.SSORegisterTitle)}>
      <PageHeader title={getString(ResourceKey.SSORegisterTitle)} />

      {error && <NotificationsBox type="error" content={error} />}

      {allowLink && renderCallHeal()}
      {!allowLink && renderCreateAccount('Create account')}
    </Page>
  );
}
