import { ServerResponse } from '../../services/api';
import { AuthActions } from './actions';
import { AuthState } from './types';

export const authInitialState: AuthState = {
  isFetching: false,
  error: null,
  data: null,
};

function handleLoginLocal(state: AuthState, username: string): AuthState {
  return {
    ...state,
    username,
  };
}

function handleLoginRequest(state: AuthState): AuthState {
  return {
    ...state,
    isFetching: true,
    error: null,
    data: null,
  };
}

function handleLoginSuccess(state: AuthState, data: any): AuthState {
  let isLoggedIn = data && data.status === 'OK';
  let error = isLoggedIn ? null : data?.description ?? 'ERROR';

  return {
    ...state,
    isFetching: false,
    error,
    data,
  };
}

function handleLoginFailure(state: AuthState, error: any): AuthState {
  return {
    ...state,
    isFetching: false,
    error,
    data: null,
  };
}

function handleLogoutRequest(state: AuthState): AuthState {
  return {
    ...state,
    isFetching: true,
  };
}

function handleLogoutSuccess(state: AuthState): AuthState {
  return {
    ...state,
    isFetching: false,
    error: null,
    data: null,
  };
}

function handleCreateAccountRequest(state: AuthState): AuthState {
  return {
    ...state,
    isFetching: true,
    error: null,
    data: null,
  };
}

function handleCreateAccountSuccess(state: AuthState, data: any): AuthState {
  return {
    ...state,
    isFetching: false,
    error: null,
    data,
  };
}

function handleCreateAccountFailure(state: AuthState, error: ServerResponse): AuthState {
  return {
    ...state,
    isFetching: false,
    error: error.description,
    data: null,
  };
}

function clearError(state: AuthState): AuthState {
  return {
    ...state,
    error: null,
  };
}

export function authReducer(
  state: AuthState = authInitialState,
  // action: Action & { data: any }
  action: any
): AuthState {
  switch (action.type) {
    case AuthActions.LOGIN_REQUEST:
      return handleLoginRequest(state);
    case AuthActions.LOGIN_SUCCESS:
      return handleLoginSuccess(state, action.data);
    case AuthActions.LOGIN_FAIL:
      return handleLoginFailure(state, action.data);
    case AuthActions.SET_LOCAL_USERNAME:
      return handleLoginLocal(state, action.data);

    case AuthActions.LOGOUT_REQUEST:
      return handleLogoutRequest(state);
    case AuthActions.LOGOUT_SUCCESS:
      return handleLogoutSuccess(state);

    case AuthActions.CREATE_ACCOUNT_REQUEST:
      return handleCreateAccountRequest(state);
    case AuthActions.CREATE_ACCOUNT_SUCCESS:
      return handleCreateAccountSuccess(state, action.data);
    case AuthActions.CREATE_ACCOUNT_FAIL:
      return handleCreateAccountFailure(state, action.data);

    case AuthActions.CLEAR_ERROR:
      return clearError(state);

    default:
      return state;
  }
}
