import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../appState/rootState';
import { setResendEmailVerificationFromBanner } from '../../appState/userPreferences/actions';
import { Loading } from '../../components/core/Loading';
import TopNav from '../../components/core/navigation/TopNav';
import { NotificationsBox } from '../../components/utils/NotificationsBox';
import { HealAPI } from '../../services/api';
import { FEATURES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { css } from '../../utils/css';
import { ButtonLink } from './ButtonLink';
import styles from './Page.module.scss';
import { Spinner } from './Spinner';

export interface NavButtonProps {
  action: () => void;
  text: string;
}

export interface Props {
  cssClass?: string;
  isLoading?: boolean;
  loader?: boolean;
  navButtons?: NavButtonProps[];
  onBackPress?: () => void;
  onClosePress?: () => void;
  showBackButton?: boolean;
  showCloseButton?: boolean;
  showMenuButton?: boolean;
  title: string;
  showEmailBanner?: boolean;
  hideTitleInNavbar?: boolean; // Title is also hidden by NavBar when user is not authenticated
}

const Page: React.FC<Props> = (props) => {
  const [resendingEmail, setResendingEmail] = useState(false);
  const [resendingEmailSent, setResendingEmailSent] = useState(false);
  const { userAccountInfo: account, userPreferences } = useSelector((state: RootState) => state);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `${props.title ? `${props.title} | ` : ''}CenterWell Patient Portal`;
    return () => {
      document.title = 'CenterWell Patient Portal';
    };
  }, [props.title]);

  let content = props.children;
  if (props.isLoading) {
    content = <Loading padding={60} />;
  }

  const renderEmailBanner = () => {
    if (props.showEmailBanner && account.data && account.data.features.indexOf(FEATURES.verifyEmailShowBanner) !== -1) {
      if (resendingEmail) {
        return (
          <div style={{ textAlign: 'center', height: 38, marginBottom: 20 }}>
            <Spinner />
          </div>
        );
      } else {
        if (resendingEmailSent && userPreferences.verifyEmailSentFromBanner) {
          return <NotificationsBox type="info" jsxContent={<div style={{ textAlign: 'center' }}>{getString(ResourceKey.verifyEmailResendSuccess)}</div>} />;
        } else if (!userPreferences.verifyEmailSentFromBanner) {
          const button = <ButtonLink style={{ padding: 0 }} text={getString(ResourceKey.verifyEmailBannerResendButton)} onClick={onClickResendEmail} />;
          return (
            <NotificationsBox
              type="info"
              jsxContent={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>{getString(ResourceKey.verifyEmailBanner)}</div>
                  {button}
                </div>
              }
            />
          );
        }
      }
    }
    return null;
  };

  function onBackPress() {
    if (props.onBackPress) {
      props.onBackPress();
    } else {
      history.goBack();
    }
  }

  const onClickResendEmail = () => {
    setResendingEmail(true);
    HealAPI.resendEmailVerification().then((response) => {
      // TODO handle response failure
      setResendingEmail(false);
      setResendingEmailSent(true);
      dispatch(setResendEmailVerificationFromBanner(true));
    });
  };

  return (
    <div className={css(styles.container, props.cssClass)}>
      <TopNav
        buttons={props.navButtons}
        title={props.hideTitleInNavbar ? undefined : props.title}
        onBackPress={props.showBackButton ? onBackPress : undefined}
        onClosePress={props.showCloseButton ? props.onClosePress : undefined}
      />
      <div role="main" className={styles.content}>
        {renderEmailBanner()}
        {content}
      </div>
    </div>
  );
};

export default Page;
