import { ReduxAction } from '../../appState/types';

export enum FlagName {
  PREFERRED_DOCTOR_INTRO_SEEN = 'preferred_doctor_intro_seen',
}

export enum FlagActions {
  SET_FLAG = 'SET_FLAG',
  CLEAR_FLAG = 'CLEAR_FLAG',
}

export function setFlag(name: string, value: boolean): ReduxAction<{ name: string; value: boolean }> {
  return {
    type: FlagActions.SET_FLAG,
    data: {
      name,
      value,
    },
  };
}

export function clearFlag(name: string): ReduxAction<{ name: string }> {
  return {
    type: FlagActions.SET_FLAG,
    data: {
      name,
    },
  };
}
