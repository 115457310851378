import * as React from 'react';
import { IMAGES, PARTNERS } from '../../services/constants';
import { getString } from '../../services/languages/index';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { LegacyButton } from '../core/LegacyButton';
import styles from './PartnerRegisterPopup.module.scss';

export interface PartnerRegisterPopupProps {
  onRegister: () => void;
  partner?: string;
}

export class PartnerRegisterPopup extends React.Component<PartnerRegisterPopupProps> {
  public render(): JSX.Element {
    return (
      <div className={styles.depth} style={{ maxHeight: window.innerHeight - 120 }}>
        <div className={styles.card}>
          <div className={styles.bulletContainer}>
            <div className={styles.bullet}>
              <img src={IMAGES.bullet1} alt="" />
            </div>
            <div className={styles.bullet}>{getString(ResourceKey.partnerPopupBullet1)}</div>
          </div>
          <div className={styles.bulletContainer}>
            <div className={styles.bullet}>
              <img src={IMAGES.bullet2} alt="" />
            </div>
            <div className={styles.bullet}>{getString(ResourceKey.partnerPopupBullet2)}</div>
          </div>
          <div className={styles.bulletContainer}>
            <div className={styles.bullet}>
              <img src={IMAGES.bullet3} alt="" />
            </div>
            <div className={styles.bullet}>{getString(ResourceKey.partnerPopupBullet3)}</div>
          </div>
          <LegacyButton className={styles.action} text={getString(ResourceKey.popupButton)} onClick={this.props.onRegister} testId={'btn_campaignRegister'} />
        </div>
        {this.props.partner === PARTNERS.humana && (
          <div className={styles.card}>
            <div className={styles.highlightsTitle}>{getString(ResourceKey.humanaTitle)}</div>
            <div className={styles.highlightsSubtitle}>{getString(ResourceKey.humanaSubtitle)}</div>

            <div className={styles.highlight}>
              <img src={IMAGES.iconClock} alt="" />
              <p>{getString(ResourceKey.humanaService1)}</p>
            </div>
            <div className={styles.highlight}>
              <img src={IMAGES.iconMedicalBag} alt="" />
              <p>{getString(ResourceKey.humanaService2)}</p>
            </div>
            <div className={styles.highlight}>
              <img src={IMAGES.iconDoctor} alt="" />
              <p>{getString(ResourceKey.humanaService3)}</p>
            </div>
            <div className={styles.highlight}>
              <img src={IMAGES.iconLab} alt="" />
              <p>{getString(ResourceKey.humanaService4)}</p>
            </div>
          </div>
        )}
        {this.props.partner !== PARTNERS.humana && (
          <div>
            <div className={styles.card}>
              <div className={styles.text}>{getString(ResourceKey.insuranceText)}</div>
              <img className={styles.insuranceLogo} src={IMAGES.insuranceLogos} alt="" />
              <img className={styles.mobileInsuranceLogo} src={IMAGES.insuranceLogosMobile} alt="" />
            </div>
            <div className={styles.card}>
              <div className={styles.text}>{getString(ResourceKey.wallStreetText)}</div>
              <img className={styles.wallStreet} src={IMAGES.wallStreetLogo} alt="" />
            </div>
            <div className={styles.card}>
              <div className={styles.text}>
                <div>{getString(ResourceKey.partnerQuote)}</div>
                <div className={styles.textAuthor}>{getString(ResourceKey.partnerQuoteAuthor)}</div>
              </div>
              <img className={styles.yelp} src={IMAGES.yelpStars} alt="" />
              <div className={styles.text}>{getString(ResourceKey.partnerYelp)}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
