import { Dispatch } from 'redux';
import { HealAPI } from '../../../services/api';

export enum AvailablePDActions {
  GET_AVAILABLE_PD_REQUEST = 'GET_AVAILABLE_PD_REQUEST',
  GET_AVAILABLE_PD_SUCCESS = 'GET_AVAILABLE_PD_SUCCESS',
  GET_AVAILABLE_PD_FAIL = 'GET_AVAILABLE_PD_FAIL',
}

export function getAvailablePreferredDoctors(patientId: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: AvailablePDActions.GET_AVAILABLE_PD_REQUEST });
    HealAPI.getAvailablePreferredDoctors(patientId).then(
      (response: any) => {
        dispatch({
          type: AvailablePDActions.GET_AVAILABLE_PD_SUCCESS,
          data: response.data,
        });
      },
      (response: any) => {
        dispatch({
          type: AvailablePDActions.GET_AVAILABLE_PD_FAIL,
          data: response.data,
        });
      }
    );
  };
}
