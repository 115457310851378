import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { object } from 'yup';
import { RootState } from '../../appState/rootState';
import { linkSAML } from '../../appState/saml/actions';
import { IMAGES, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { YUP_VALIDATIONS } from '../../utils/forms';
import Button, { ButtonStyle } from '../core/Button';
import Input from '../core/Input';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import { NotificationsBox } from '../utils/NotificationsBox';
import { OrSeparator } from '../utils/OrSeparator';
import styles from './SamlLink.module.scss';

interface LinkFormData {
  memberId: string;
  groupId: string;
  memberNumber: string;
  email: string;
  password: string;
  clientId: string;
}

export function SamlLink() {
  const [creating, setCreating] = useState(false);
  const { saml } = useSelector((state: RootState) => state);

  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId;

  const {
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors },
  } = useForm<LinkFormData>({
    mode: 'onTouched',
    resolver: yupResolver(
      object().shape({
        email: YUP_VALIDATIONS.email,
        password: YUP_VALIDATIONS.passwordRegister,
      })
    ),
    defaultValues: {
      email: saml.data?.email || '',
      password: '',
      memberId: saml.data?.memberId || '',
      memberNumber: saml.data?.memberNumber || '',
      groupId: saml.data?.groupId || '',
    },
  });

  useEffect(() => {
    reset({
      ...getValues(),
      email: saml.data?.email || '',
      memberId: saml.data?.memberId || '',
      memberNumber: saml.data?.memberNumber || '',
      groupId: saml.data?.groupId || '',
    });
  }, [saml.data, reset, getValues]);

  function onSubmit(data: LinkFormData) {
    setCreating(true);
    dispatch(
      linkSAML(clientId, {
        email: data.email,
        password: data.password,
        memberId: data.memberId,
        memberNumber: data.memberNumber,
        groupId: data.groupId,
      })
    );
  }

  return (
    <Page title={getString(ResourceKey.SAMLLinkHeader)}>
      <PageHeader title={getString(ResourceKey.SAMLLinkHeader)} subtitle={getString(ResourceKey.SAMLLinkInfo)} image={IMAGES.samlLinkLogo} />

      {saml.error && <NotificationsBox type="error" content={saml.error.description || getString(ResourceKey.errorSignInGeneric)} />}

      <form onSubmit={handleSubmit(onSubmit)} title="Register account">
        <Input
          label="Email"
          id="email"
          helpText={getString(ResourceKey.registerEmailVerifyText)}
          error={errors.email?.message}
          required
          {...register('email')}
        />

        <Input label="Password" id="password" type="password" error={errors.password?.message} required {...register('password')} />

        <div className={styles.forgot}>
          <Link to={ROUTES.forgotPassword}>{getString(ResourceKey.signInForgotPassword)}</Link>
        </div>

        <div>
          <Button type="submit" text={getString(ResourceKey.SAMLLinkAccount)} testId="btn_linkHeal" className="btn-block" loading={!saml.error && creating} />
          <OrSeparator />
          <Button
            type="button"
            buttonStyle={ButtonStyle.SECONDARY}
            text={getString(ResourceKey.SAMLRegister)}
            testId="btn_linkHeal"
            className="btn-block"
            onClick={() => history.push(ROUTES.partnerRegister.replace(':clientId', clientId))}
          />
        </div>
      </form>
    </Page>
  );
}
