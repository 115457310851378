import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { submitRating } from '../../appState/rating/actions';
import { RootState } from '../../appState/rootState';
import { getCurrentStatus } from '../../appState/status/actions';
import { getVisits } from '../../appState/visit/actions';
import { Visit } from '../../appState/visit/types';
import { IMAGES, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { BottomSheet } from '../core/BottomSheet';
import { LegacyButton } from '../core/LegacyButton';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import Select from '../core/Select';
import { ZeroData } from '../core/ZeroData';
import { DoctorBio } from '../healDoctor/DoctorBio';
import VisitCard from '../visits/VisitCard';
import styles from './VisitsPage.module.scss';

const VisitsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userAccountInfo, cart, visitList, patientList, userPreferences } = useSelector((state: RootState) => state);
  const [selectedPatientId, setSelectedPatientId] = useState('');
  const [selectedVisit, setSelectedVisit] = useState<Visit>();
  const [hasFetchedVisits, setHasFetchedVisits] = useState(false);

  const isLoading = useMemo(() => visitList.isFetching || !visitList || patientList.isFetching || !patientList.data, [visitList, patientList]);

  useEffect(() => {
    if (!visitList.isFetching && !hasFetchedVisits) {
      dispatch(getVisits());
      setHasFetchedVisits(true);
    }
  }, [visitList, hasFetchedVisits, dispatch]);

  function renderPicker() {
    let patientOptions = [
      {
        value: '',
        text: getString(ResourceKey.visitsAllPatients),
      },
    ];

    if (patientList.data) {
      patientOptions = [
        ...patientOptions,
        ...Object.keys(patientList.data).map((key) => {
          const patient = patientList.data![key];
          return {
            value: patient.patientId,
            text: patient.fullName,
          };
        }),
      ];
    }

    if (patientOptions.length > 2) {
      return (
        <Select
          label="Filter by patient"
          onChange={(event) => setSelectedPatientId(event.currentTarget.value)}
          options={patientOptions}
          value={selectedPatientId}
          defaultBlank={false}
          testId="dd_patients"
          className={styles.select}
        />
      );
    }

    return null;
  }

  function renderCards() {
    if (!visitList) {
      return null;
    }

    const filteredVisits = visitList.data?.filter(
      (visit) => (!selectedPatientId || visit.patient.id === selectedPatientId) && patientList.data?.[visit.patient.id]
    );

    if (filteredVisits && filteredVisits.length && patientList.data) {
      return filteredVisits.map((visit) => {
        return (
          <VisitCard
            key={visit.code}
            account={userAccountInfo}
            cart={cart}
            visit={visit}
            isBlueShieldUser={userPreferences.blueshieldSSOUser}
            submitRating={(patientId, visitCode, data) => dispatch(submitRating(patientId, visitCode, data))}
            getVisits={() => dispatch(getVisits())}
            getCurrentStatus={() => dispatch(getCurrentStatus())}
            onBioClicked={(visit) => setSelectedVisit(visit)}
            isPDValid={patientList.data?.[visit.patient.id].preferredDoctor?.stillValid}
          />
        );
      });
    }

    return (
      <ZeroData
        text={getString(ResourceKey.visitsZeroDataLine1)}
        textSecondary={getString(ResourceKey.visitsZeroDataLine2)}
        grayscale
        image={{ source: IMAGES.visitsHouse }}
      />
    );
  }

  function renderBioBottomSheet() {
    if (!selectedVisit || !selectedVisit.doctor) {
      return null;
    }

    return (
      <BottomSheet visible={!!selectedVisit} showCloseButton onClose={() => setSelectedVisit(undefined)}>
        <div className={styles.sheetContainer}>
          <DoctorBio
            avatarUrl={selectedVisit.doctor.avatarUrl}
            videoUrl={selectedVisit.doctor.bioVideoUrl}
            videoPosterUrl={selectedVisit.doctor.bioVideoPosterFrameUrl}
            bio={selectedVisit.doctor.bio}
            prefix=""
            firstName={`${selectedVisit.doctor.firstName} ${selectedVisit.doctor.lastName}`}
            lastName=""
            suffix=""
          />
          <LegacyButton
            className={styles.sheetButton}
            text={getString(ResourceKey.genericButtonClose)}
            onClick={() => setSelectedVisit(undefined)}
            disabled={false}
            testId="btn_close"
          />
        </div>
      </BottomSheet>
    );
  }

  const navButtons = [{ action: () => history.push(ROUTES.bookReview), text: getString(ResourceKey.topNavButtonBook) }];

  return (
    <Page isLoading={isLoading} showEmailBanner navButtons={navButtons} title={getString(ResourceKey.visitsTitle)} hideTitleInNavbar>
      <PageHeader title={getString(ResourceKey.visitsTitle)} />
      {renderPicker()}
      {!isLoading && renderCards()}
      {renderBioBottomSheet()}
    </Page>
  );
};

export default VisitsPage;
