import * as React from 'react';
import { KeyCodes } from '../../../utils/keycodes';

export interface EscapeProps {
  onEscape?: () => void;
}

export class Escape extends React.Component<EscapeProps> {
  public componentDidMount() {
    window.addEventListener('keydown', this.handleWindowKeyDown, true);
  }

  public componentWillUnmount() {
    window.removeEventListener('keydown', this.handleWindowKeyDown, true);
  }

  public render(): JSX.Element | null {
    return (this.props.children as JSX.Element) || null;
  }

  private handleWindowKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === KeyCodes.escape && this.props.onEscape) {
      this.props.onEscape();
    }
  };
}
