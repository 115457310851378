import { useEffect } from 'react';

/**
 * Runs a callback function on window 'keydown' events.
 *
 * @param {string|string[]} key The keyboard key(s) to listen for.
 * @param onKeyDown Callback function to run when key(s) are pressed.
 * @param disable Manually disable callback function while listener is still attached to window.
 */
export default function useOnKeyDown(key: string | string[], onKeyDown: () => void, disable?: boolean) {
  useEffect(() => {
    function handleOnKeyDown(event: KeyboardEvent) {
      if (disable) {
        return;
      }

      if (typeof key === 'string') {
        event.key === key && onKeyDown();
      } else {
        key.includes(event.key) && onKeyDown();
      }
    }

    window.addEventListener('keydown', handleOnKeyDown, true);

    return () => {
      window.removeEventListener('keydown', handleOnKeyDown, true);
    };
  }, [key, onKeyDown, disable]);
}
