import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { resetCart, setLocation, setPatient } from '../../appState/cart/actions';
import { getAvailablePreferredDoctors } from '../../appState/preferredDoctor/available/actions';
import { RootState } from '../../appState/rootState';
import { getCurrentStatus } from '../../appState/status/actions';
import { HealDoctors, HealDoctorsProps } from '../healDoctor/HealDoctors';

function mapStateToProps(state: RootState, ownProps: HealDoctorsProps): HealDoctorsProps {
  return {
    ...ownProps,
    healDoctors: state.availablePreferredDoctors,
    addressList: state.addressList,
    patientList: state.patientList,
  };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: HealDoctorsProps): HealDoctorsProps {
  return {
    ...ownProps,
    actions: bindActionCreators(
      {
        resetCart,
        setLocation,
        setPatient,
        getAvailablePreferredDoctors,
        getCurrentStatus,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HealDoctors);
