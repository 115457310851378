import * as React from 'react';
import { css } from '../../utils/css';
import { KeyCodes } from '../../utils/keycodes';
import { AccessibleElement } from '../utils/accessibility/AccessibleElement';
import styles from './LegacyButton.module.scss';
import { Loading } from './Loading';

export const ButtonStyles = {
  primary: styles.primary,
  secondary: styles.secondary,
};

export interface LegacyButtonProps {
  text: string;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  testId: string;
  loader?: boolean;
  style?: string;
}

/**
 * Legacy Button component that uses a 'div' and browser manipulations to imitate a 'button' element.
 * Should be replaced with new 'Button' component.
 */
export class LegacyButton extends React.Component<LegacyButtonProps> {
  public render(): JSX.Element {
    const classNames = css(
      styles.button,
      {
        [styles.disabled]: !!this.props.disabled,
        [this.props.style || styles.primary]: true,
      },
      this.props.className
    );
    return (
      <AccessibleElement
        disabled={this.props.disabled}
        aria-disabled={this.props.disabled}
        title={this.props.text}
        className={classNames}
        role="button"
        onClick={this.onClick}
        onKeyDown={this.onKeyDown}
        data-tid={`${this.props.testId}${!this.props.disabled ? '_enabled' : ''}`}
      >
        {this.props.loader && <Loading color="#FFFFFF" width={40} height={38} />}
        {!this.props.loader && this.props.text}
      </AccessibleElement>
    );
  }

  private onClick = () => {
    if (!this.props.disabled) {
      this.props.onClick();
    }
  };

  private onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!this.props.disabled && event.keyCode === KeyCodes.enter) {
      this.props.onClick();
    }
  };
}
