import * as React from 'react';
import { css } from '../../utils/css';
import { KeyCodes } from '../../utils/keycodes';
import styles from './ButtonLink.module.scss';

export interface ButtonLinkProps {
  text: string;
  onClick: () => void;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  testId?: string;
  ariaLabel?: string;
}

export class ButtonLink extends React.Component<ButtonLinkProps, object> {
  public render(): JSX.Element {
    const classNames = css(styles.button, this.props.className, {
      [styles.disabled]: !!this.props.disabled,
    });

    return (
      <div className={classNames} style={this.props.style}>
        <a
          aria-label={this.props.ariaLabel}
          tabIndex={0}
          title={this.props.text}
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
          data-tid={this.props.testId}
        >
          {this.props.text}
        </a>
      </div>
    );
  }

  private onClick = () => {
    if (!this.props.disabled) {
      this.props.onClick();
    }
  };

  private onKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (!this.props.disabled && event.keyCode === KeyCodes.enter) {
      this.props.onClick();
    }
  };
}
