import { AddressType } from '../appState/address/types';
import bullet1 from '../assets/img/1.png';
import bullet2 from '../assets/img/2.png';
import bullet3 from '../assets/img/3.png';
import add from '../assets/img/add.svg';
import agreements from '../assets/img/agreements.png';
import agreementsBSC from '../assets/img/agreements_bsc.png';
import alertPink from '../assets/img/alert-pink.svg';
import alertWhite from '../assets/img/alert-white.svg';
import alertYellow from '../assets/img/alert-yellow.svg';
import arrowLeft from '../assets/img/arrow_left.svg';
import arrowLeftInactive from '../assets/img/arrow_left_inactive.svg';
import arrowRight from '../assets/img/arrow_right.svg';
import arrowRightInactive from '../assets/img/arrow_right_inactive.svg';
import asap from '../assets/img/asap.svg';
import badgeAppStore from '../assets/img/badge_app-store.svg';
import badgeGooglePlay from '../assets/img/badge_google-play.svg';
import blueShieldLogo from '../assets/img/blueShieldLogo.svg';
import visitsBookAgain from '../assets/img/book_again.svg';
import bookVisitTitle from '../assets/img/book_title.png';
import calendar from '../assets/img/calendar.svg';
import calendarAccent from '../assets/img/calendar_accent.svg';
import visitsCall from '../assets/img/call.svg';
import cameraAdd from '../assets/img/camera-add.png';
import visitsCancel from '../assets/img/cancel.svg';
import fileCarePlan from '../assets/img/carePlan.svg';
import caretDown from '../assets/img/caret_down.svg';
import caretLeft from '../assets/img/caret_left.svg';
import caretRight from '../assets/img/caret_right.svg';
import carePlan from '../assets/img/care_plan.png';
import creditCardAmex from '../assets/img/cc/amex.svg';
import creditCardApplePay from '../assets/img/cc/applepay.svg';
import creditCardDefault from '../assets/img/cc/credit_card_default_icon.svg';
import creditCardDinersClub from '../assets/img/cc/diners.svg';
import creditCardDiscover from '../assets/img/cc/discover.svg';
import creditCardJcb from '../assets/img/cc/jcb.svg';
import creditCardMastercard from '../assets/img/cc/mastercard.svg';
import creditCardUnknown from '../assets/img/cc/unknown.svg';
import creditCardVisa from '../assets/img/cc/visa.svg';
import statusCheck from '../assets/img/check.svg';
import checkmarkGrey from '../assets/img/checkmark-grey.svg';
import checkmark from '../assets/img/checkmark.svg';
import greenCheck from '../assets/img/check_green.svg';
import iconClock from '../assets/img/clock.svg';
import insurance from '../assets/img/insurance.svg';
import fileDefault from '../assets/img/compose.svg';
import locationCurrentPosition from '../assets/img/current_location.svg';
import hidePassword from '../assets/img/display_icon.svg';
import iconDoctor from '../assets/img/doctor.svg';
import showPassword from '../assets/img/do_not_display_icon.svg';
import edit from '../assets/img/edit.svg';
import engagementPointLogo from '../assets/img/engagementPointLogo.svg';
import statusError from '../assets/img/error.svg';
import flags from '../assets/img/flags.png';
import fluShot from '../assets/img/flu_shot.png';
import greenCheckmark from '../assets/img/green-checkmark.png';
import HCPNotAuthorized from '../assets/img/hcp_not_authorized.png';
import HCPNotReady from '../assets/img/hcp_not_ready.gif';
import healthProfile from '../assets/img/health_profile.svg';
import healCarePlan from '../assets/img/Heal_Care_Plan.png';
import ratingSolidHeart from '../assets/img/heart_active.svg';
import ratingEmptyHeart from '../assets/img/heart_inactive.svg';
import locationHomeActive from '../assets/img/home_active.svg';
import locationHomeInactive from '../assets/img/home_inactive.svg';
import visitsHouse from '../assets/img/house.svg';
import info from '../assets/img/info.svg';
import insuranceLogos from '../assets/img/insurance_logos@1.5x.svg';
import insuranceLogosMobile from '../assets/img/insurance_logos_mobile@1.5x.svg';
import iconLab from '../assets/img/lab.svg';
import fileLabsChart from '../assets/img/labsChart.svg';
import legalTitle from '../assets/img/legal.svg';
import location from '../assets/img/location.svg';
import lockupHumana from '../assets/img/lockup_humana.svg';
import loginTitle from '../assets/img/login.png';
import logo from '../assets/img/logo.png';
import logo124 from '../assets/img/logo124.png';
import locationMarker from '../assets/img/map_marker.svg';
import fileMedicalRecord from '../assets/img/medicalRecord.svg';
import iconMedicalBag from '../assets/img/medical_bag.svg';
import menuNavBookNow from '../assets/img/menu_booknow.svg';
import menuNavChat from '../assets/img/menu_chat.svg';
import menuNavDocuments from '../assets/img/menu_documents.svg';
import menuNavHelp from '../assets/img/menu_help.svg';
import menuNavLegal from '../assets/img/menu_legal.svg';
import menuNavPatients from '../assets/img/menu_patients.svg';
import menuNavSignOut from '../assets/img/menu_sign_out.svg';
import menuNavVisits from '../assets/img/menu_visits.svg';
import notFoundTitle from '../assets/img/not_found.png';
import onsiteEvent from '../assets/img/office-onsite.png';
import locationOtherActive from '../assets/img/other_place_active.svg';
import locationOtherInactive from '../assets/img/other_place_inactive.svg';
import patient from '../assets/img/patient.svg';
import preferredDoctorSilhouette from '../assets/img/pd_silhouette.png';
import filePhysicianReport from '../assets/img/physicianReport.svg';
import questionInfo from '../assets/img/question.svg';
import reason from '../assets/img/reason.svg';
import registerIcon from '../assets/img/register_icon3x.png';
import samlLinkLogo from '../assets/img/Saml_Link.png';
import savings from '../assets/img/savings.png';
import secondary from '../assets/img/secondary.png';
import shareIconFacebook from '../assets/img/share_facebook.svg';
import shareIconMore from '../assets/img/share_more.png';
import errorTitle from '../assets/img/site_needs_doctor.png';
import success from '../assets/img/success.png';
import fileSuperbill from '../assets/img/superbill.svg';
import symptomCheck from '../assets/img/symptomCheck.png';
import thankYouHouse from '../assets/img/thankYou-house.svg';
import thankYouPet from '../assets/img/thankYou-pet.svg';
import thankYouProvider from '../assets/img/thankYou-provider.svg';
import trash from '../assets/img/trash.svg';
import visitCanceled from '../assets/img/visit_canceled.png';
import visitCompleted from '../assets/img/visit_completed.png';
import wallStreetLogo from '../assets/img/wall-street-journal-logo@1.5x.svg';
import warning from '../assets/img/warning.svg';
import whatToExpect from '../assets/img/what_to_expect.png';
import { default as bookVisitWorkplace, default as bookVisitWorkplaceInactive } from '../assets/img/workplace_icon.png';
import locationWorkActive from '../assets/img/work_active.svg';
import locationWorkInactive from '../assets/img/work_inactive.svg';
import yelpStars from '../assets/img/Yelp_stars.svg';
import { getString } from './languages/index';
import { ResourceKey } from './languages/ResourceKey';

export const ROUTES = {
  login: '/login',
  logout: '/logout',
  register: '/register',
  agentAccount: '/login/agent',
  loginCallback: '/login/callback',
  blueShieldLogin: '/login/blueshield',

  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/u/:id',

  book: '/book',
  bookReview: '/book/review',
  bookLocation: '/book/location',
  bookReason: '/book/reason',
  bookSchedule: '/book/schedule',
  bookThankYou: '/book/thank-you/:code',
  bookPatient: '/book/patient',
  bookDoctor: '/book/doctor',
  bookAgreements: '/book/agreements',

  uploadPage: '/upload/:code',
  documentsPage: '/documents',

  workplace: '/event/:code',

  patients: '/patient',
  patientsAdd: '/patient/add',
  patientsEdit: '/patient/edit/:id',
  patientSelectDoctor: '/patient/:id/select-doctor',

  legal: '/legal',
  visits: '/visits',
  visitsWhatToExpect: '/visit/prep/:code',
  consentsPage: '/consents/:vanity?',

  partnerLanding: '/partnerCall/:clientId',
  blueshieldLink: '/partnerCallback/blueshield/link',
  partnerLink: '/partnerCallback/:clientId/link',
  partnerRegister: '/partnerCallback/:clientId/register',
  partnerError: '/partnerCallback/:clientId/error/:errorCode',
  errorPage: '/error/:errorCode?',
  ssoOauth: '/oauthCallback',
};

export const INSECURE_ROUTES = [
  ROUTES.partnerLanding,
  '/',
  ROUTES.loginCallback,
  ROUTES.agentAccount,
  ROUTES.blueShieldLogin,
  ROUTES.login,
  ROUTES.logout,
  ROUTES.forgotPassword,
  ROUTES.resetPassword,
  ROUTES.register,
  ROUTES.legal,
  ROUTES.blueshieldLink,
  ROUTES.ssoOauth,
  ROUTES.partnerRegister,
  ROUTES.partnerLink,
  ROUTES.partnerError,
  ROUTES.errorPage,
];

export const REDIRECT_BLOCK_LIST = [ROUTES.loginCallback, ROUTES.logout];

export const LINKS = {
  forgotPasswordSite: 'https://resetpassword.heal.com/',
  healSite: 'https://www.heal.com/',
  helpSite: 'https://www.centerwellprimarycare.com/en/primary-care-anywhere/faqs.html',
  legalTermsSite: 'https://www.humana.com/legal',
  legalPrivacySite: 'https://www.humana.com/legal/privacy-policy',
  legalPracticesSite: 'https://www.centerwell.com/en/privacy-practices-footer.html',
  legalNondiscriminationSite: 'https://www.humana.com/legal/non-discrimination-disclosure',
};

export const IMAGES = {
  add,
  agreements,
  agreementsBSC,
  alertPink,
  alertWhite,
  alertYellow,
  arrowLeft,
  arrowLeftInactive,
  arrowRight,
  arrowRightInactive,
  asap,
  badgeAppStore,
  badgeGooglePlay,
  blueShieldLogo,
  bookVisitTitle,
  bookVisitWorkplace,
  bookVisitWorkplaceInactive,
  bullet1,
  bullet2,
  bullet3,
  calendar,
  calendarAccent,
  cameraAdd,
  carePlan,
  caretDown,
  caretLeft,
  caretRight,
  checkmark,
  checkmarkGrey,
  creditCardAmex,
  creditCardApplePay,
  creditCardDefault,
  creditCardDinersClub,
  creditCardDiscover,
  creditCardJcb,
  creditCardMastercard,
  creditCardUnknown,
  creditCardVisa,
  edit,
  engagementPointLogo,
  errorTitle,
  fileCarePlan,
  fileDefault,
  fileLabsChart,
  fileMedicalRecord,
  filePhysicianReport,
  fileSuperbill,
  flags,
  fluShot,
  greenCheck,
  greenCheckmark,
  HCPNotAuthorized,
  HCPNotReady,
  healCarePlan,
  healthProfile,
  hidePassword,
  iconClock,
  iconDoctor,
  iconLab,
  iconMedicalBag,
  info,
  insurance,
  insuranceLogos,
  insuranceLogosMobile,
  legalTitle,
  location,
  locationCurrentPosition,
  locationHomeActive,
  locationHomeInactive,
  locationMarker,
  locationOtherActive,
  locationOtherInactive,
  locationWorkActive,
  locationWorkInactive,
  lockupHumana,
  loginTitle,
  logo,
  logo124,
  menuNavBookNow,
  menuNavChat,
  menuNavDocuments,
  menuNavHelp,
  menuNavLegal,
  menuNavPatients,
  menuNavSignOut,
  menuNavVisits,
  notFoundTitle,
  onsiteEvent,
  patient,
  preferredDoctorSilhouette,
  questionInfo,
  ratingEmptyHeart,
  ratingSolidHeart,
  reason,
  registerIcon,
  samlLinkLogo,
  savings,
  secondary,
  shareIconFacebook,
  shareIconMore,
  showPassword,
  statusCheck,
  statusError,
  success,
  symptomCheck,
  thankYouHouse,
  thankYouPet,
  thankYouProvider,
  trash,
  visitCanceled,
  visitCompleted,
  visitsBookAgain,
  visitsCall,
  visitsCancel,
  visitsHouse,
  wallStreetLogo,
  warning,
  whatToExpect,
  yelpStars,
};

export const PRELOAD_IMAGES = {
  location: [
    IMAGES.locationCurrentPosition,
    IMAGES.locationHomeActive,
    IMAGES.locationHomeInactive,
    IMAGES.locationWorkActive,
    IMAGES.locationWorkInactive,
    IMAGES.locationOtherActive,
    IMAGES.locationOtherInactive,
    IMAGES.locationMarker,
  ],
  reasons: [
    'https://s3.amazonaws.com/heal-service-icons/android_xxhdpi_sick_icn_normal.png',
    'https://s3.amazonaws.com/heal-service-icons/android_xxhdpi_physical_icn_normal.png',
    'https://s3.amazonaws.com/heal-service-icons/android_xxhdpi_syringe_icn_normal.png',
    'https://s3.amazonaws.com/heal-service-icons/android_xxhdpi_bottle_icn_normal.png',
    'https://s3.amazonaws.com/heal-service-icons/android_xxhdpi_other_icn_normal.png',
    'https://s3.amazonaws.com/heal-service-icons/android_xxhdpi_sick_icn_active.png',
    'https://s3.amazonaws.com/heal-service-icons/android_xxhdpi_physical_icn_active.png',
    'https://s3.amazonaws.com/heal-service-icons/android_xxhdpi_syringe_icn_active.png',
    'https://s3.amazonaws.com/heal-service-icons/android_xxhdpi_bottle_icn_active.png',
    'https://s3.amazonaws.com/heal-service-icons/android_xxhdpi_other_icn_active.png',
  ],
  book: [IMAGES.bookVisitTitle, IMAGES.location, IMAGES.calendar, IMAGES.calendarAccent, IMAGES.reason, IMAGES.patient],
  schedule: [IMAGES.asap, IMAGES.arrowLeft, IMAGES.arrowRight, IMAGES.arrowLeftInactive, IMAGES.arrowRightInactive, IMAGES.caretLeft, IMAGES.caretRight],
};

export const FEATURES = {
  preferredDoctor: 'preferred_doctor',
  hideSecondaryContactInWTE: 'secondary_contact_hide_wte',
  verifyEmailShowBanner: 'email_verification_banner',
  marketRealignment: 'market_realignment',
  verifyEmailBlockBooking: 'cannot_book_visits',
  verifyEmailBlockCarePlan: 'cannot_download_hcp',
  disableVisitETA: 'disable_visit_eta',
  blueShieldUser: 'blue_shield_user',
  engagementPointUser: 'engagementpoint_user',
  fluShotAddon: 'flu_shot_addon',
};

export const PARTNERS = {
  humana: 'humana',
};

export const REDIRECT_AFTER_LOGIN_BLOCKLIST = [ROUTES.login, ROUTES.logout];

export interface FileConfig {
  title: string;
  description: string;
  icon: string;
  button: string;
}

export const FILES: {
  [key: string]: FileConfig;
} = {
  agreement: {
    title: 'Agreement',
    description: 'Agreement document is ready to view.',
    icon: IMAGES.fileCarePlan,
    button: 'View Agreement',
  },
  carePlan: {
    title: 'Clinical Care Plan',
    description: 'The doctor has outlined your follow-up visit notes.',
    icon: IMAGES.fileCarePlan,
    button: 'View Clinical Care Plan',
  },
  labResult: {
    title: 'Lab Results',
    description: 'Your lab results are available to view.',
    icon: IMAGES.fileLabsChart,
    button: 'View Lab results',
  },
  superbill: {
    title: 'Superbill',
    description: 'The super bill is available to view.',
    icon: IMAGES.fileSuperbill,
    button: 'View Superbill',
  },
  medicalRecord: {
    title: 'Medical Records',
    description: 'Medical records are available to view.',
    icon: IMAGES.fileMedicalRecord,
    button: 'View Medical Records',
  },
  physicianReport: {
    title: 'Physician’s Report',
    description: 'Physician’s report is available to view.',
    icon: IMAGES.filePhysicianReport,
    button: 'View Report',
  },
  medicalLeave: {
    title: 'Medical Leave',
    description: 'Medical leave document is ready to view.',
    icon: IMAGES.fileDefault,
    button: 'View Document',
  },
  other: {
    title: 'Document',
    description: 'Press below to view document.',
    icon: IMAGES.fileDefault,
    button: 'View Document',
  },
  referral: {
    title: 'Referral',
    description: 'Referral document is ready to view.',
    icon: IMAGES.fileDefault,
    button: 'View Referral',
  },
  vaccination: {
    title: 'Vaccination Records',
    description: 'Press below to view vaccination records.',
    icon: IMAGES.fileDefault,
    button: 'View vaccination records',
  },
};

export const ErrorCodes: {
  [key: string]: { title?: ResourceKey; description: ResourceKey };
} = {
  '1': { description: ResourceKey.samlErrorCreatingAccount },
  '60001': {
    title: ResourceKey.errorEmailVerificationLinkExpiredTitle,
    description: ResourceKey.errorEmailVerificationLinkExpiredDescription,
  },
};
