import { Dispatch } from 'redux';
import { HealAPI } from '../../services/api';

export enum ConfigActions {
  GET_CONFIG_REQUEST = 'GET_CONFIG_REQUEST',
  GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS',
  GET_CONFIG_FAIL = 'GET_CONFIG_FAIL',
  SET_CONFIG = 'SET_CONFIG',
}

export function getConfig() {
  return (dispatch: Dispatch) => {
    dispatch({ type: ConfigActions.GET_CONFIG_REQUEST });
    HealAPI.getConfig().then(
      (response) => {
        const config = response.data?.data;
        if (response.ok && config) {
          dispatch({
            type: ConfigActions.GET_CONFIG_SUCCESS,
            data: response.data,
          });
        } else {
          dispatch({
            type: ConfigActions.GET_CONFIG_FAIL,
            data:
              response.data?.description ?? 'Something went wrong. Please try again. If you need help, please call (844) 644-4325',
          });
        }
      },
      (response: any) => {
        dispatch({
          type: ConfigActions.GET_CONFIG_FAIL,
          data: response.data?.description ?? 'Something went wrong. Please try again. If you need help, please call (844) 644-4325',
        });
      }
    );
  };
}
